<template>
  <div class="tg-comment-card">
    <a :href="(item.locationlink !== '' && item.locationlink !== null) ? item.locationlink : ('/news/' + item.id)">
      <div class="tg-comment-card_cover">
        <tg-flexbox>
          <tg-uniform-scale ratio="56.47%">
            <img :src="item.pic | imgURL('tgbuswap_240mw')"
                 :style="{position: 'relative', width: '100%', height: '100%', objectFit: 'cover'}"
                 alt="">
          </tg-uniform-scale>
        </tg-flexbox>
        <p class='scoreBar'
           v-if="item.type === 2"
           :class="{'oneC': item.reviewscore >= 0 && item.reviewscore < 2,
                    'twoC': item.reviewscore >= 2 && item.reviewscore < 4,
                    'threeC': item.reviewscore >= 4 && item.reviewscore < 6,
                    'fourC': item.reviewscore >= 6 && item.reviewscore < 8,
                    'fiveC': item.reviewscore >= 8}"><span>{{item.reviewscore}}</span></p>
      </div>
      <div class="tg-comment-card_info">
        <dl>
          <dd>
            <h6>{{item.title}}</h6>
          </dd>
        </dl>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'tg-comment-card',
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
};
</script>

