// tslint:disable:no-any
/**
 * Entry client
 */

import { createApp } from '@src/main';
import * as GraphqlAPI from './api/index'
import * as GamelibAPI from './api/gamelib-api/api'
import * as CodepitAPI from './api/codepit-api'
import * as CmsAPI from './api/cms'
GraphqlAPI.setBaseURL(process.env.VUE_APP_CLIENT_NEWSGRAPHQL_URL)
GamelibAPI.setApiUrl(`${process.env.VUE_APP_CLIENT_GRAPHQL_URL}`)
CodepitAPI.setBaseURL(`${process.env.VUE_APP_CLIENT_CMSLITE_URL}`)
CmsAPI.setMobileGameBaseURL(`${process.env.VUE_APP_CLIENT_MOBILEGAME_URL}`)
const { app, router, store } = createApp();

if ((window as any).__INITIAL_STATE__) {
  store.replaceState((window as any).__INITIAL_STATE__);
}

router.onReady(() => {
  app.$mount('#app');
});
