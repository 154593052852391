<template>
  <div class="tg-live-info-card">
    <tg-flexbox gutter>
      <tg-flexbox class="tg-live-info-card_item"
                  flex="100"
                  v-for="item in data"
                  :key="item._id">
        <a :href="'/live/' + item._id">
          <div class="banner-info">
            <img :src="item.cdn+'/340_180/'+item.cover"
                 class="banner-info-img">
            <div :class="{'banner-info-title': true, 'jijiang': item.status === 1, 'over': item.status === 3}">
              <span class="state"
                    v-if="item.status === 1">即将开始</span>
              <span class="state"
                    v-if="item.status === 2">正在直播</span>
              <span class="state"
                    v-if="item.status === 3">已结束</span>
              <span class="time"
                    v-if='item.liveTime[0] !== "" && item.liveTime[1] !== ""'>{{item.liveTime.join(' ~ ')}}</span>
            </div>
          </div>
          <div class="introduce">
            <div class="introduce-title">{{item.title}}</div>
            <div class="introduce-content">{{item.description}}</div>
          </div>
        </a>
      </tg-flexbox>
    </tg-flexbox>
  </div>
</template>

<script>
export default {
  name: 'TgLiveInfoCard',
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
};
</script>
