<template>
  <div class="tg-e3-nav-bar"
       id="e3-nav-bar-container">
    <ul class="tg-e3-nav-bar_wrapper">
      <li class="tg-e3-nav-bar_item"
          v-for="(item, index) in itemsSource"
          :key="item.label"
          :class="{'is-active': currentIndex == index}">
        <span @click="getData(index)"
              class="tg-nav-bar_link">{{item.label}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsSource: [
        {
          label: '最新',
        },
        {
          label: '前线',
        },
        {
          label: '视频',
        },
        {
          label: '采访',
        },
        {
          label: '试玩',
        },
        {
          label: '任天堂',
        },
        {
          label: '微软',
        },
        {
          label: '育碧',
        },
        {
          label: '世嘉',
        },
        {
          label: 'SE',
        },
        {
          label: '万代南梦宫',
        },
      ],
      currentIndex: 0,
    };
  },
  methods: {
    getData(index) {
      this.currentIndex = index;
      this.$parent.$emit('changeIndex', index, this.itemsSource[index].label);
    },
  },
};
</script>

