<template>
  <div class="tg-cj-list">
    <div v-if="path === '/chinajoy/list/CJ现场'">
      <tg-cj-elegance-more :data="data"
                           v-if="data && data.length > 0"></tg-cj-elegance-more>
    </div>
    <div v-else>
      <tg-pic-panel v-if="data && data.length > 0"
                    :data="data[0]" />
      <div v-for="(item, index) in data.slice(1) || []"
           :key="index">
        <tg-card-group :data="item"
                       :bottomBorder="true" />
      </div>
    </div>
  </div>
</template>

<script>
import TgCjEleganceMore from '../elegance-more/TgCjEleganceMore';
import TgCardGroup from '../card-group/TgCardGroup';
import TgPicPanel from '../pic-panel/TgPicPanel';
export default {
  name: 'tg-cj-list',
  data() {
    return {
      path: '',
    };
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
  mounted() {
    this.path = this.$route.path;
  },
  components: {
    TgCardGroup,
    TgPicPanel,
    TgCjEleganceMore,
  },
};
</script>

