import Vue, { CreateElement, VNode } from 'vue';
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import * as gamelibApi from '@src/api/gamelib-api';
import * as thumbor from '@src/api/thumbor';

/**
 * Component: GameList
 */
@Component
export class TgGameList extends Vue {
  @Prop({ type: Array, required: true })
  public readonly itemsSource!: gamelibApi.model.Game[];

  private render(h: CreateElement): VNode {
    return (
      <tg-list staticClass="tg-game-list" show-separator>
        {this.itemsSource.map(game => (
          <tg-list-item key={game.id} tag="a" href={`/gamelib/game/${game.id}`}>
            {game.covers && game.covers.length > 0 ? (
              <img
                slot="left"
                staticClass="tg-game-list_cover"
                src={thumbor.resolve(game.covers[0].path, 'tgm-gl-game-list-cover_80_80')}
                alt={game.name}
              />
            ) : (
              ''
            )}
            <h3 staticClass="tg-game-list_name-primary">
              {gamelibApi.helper.filters.gameNamePrimary(game)}
            </h3>
            <h4 staticClass="tg-game-list_name-secondary">
              {gamelibApi.helper.filters.gameNameSecondary(game)}
            </h4>
          </tg-list-item>
        ))}
      </tg-list>
    );
  }
}
