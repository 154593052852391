<template>
  <div class="tg-cj-competition">
    <tg-cj-title :title="title"></tg-cj-title>
    <tg-pic-panel v-if="data && data.length > 0"
                  :data="toCardData(data[0])" />
    <div v-for="(item, index) in data.slice(1, 5) || []"
         :key="index">
      <tg-card-group :data="toCardData(item)"
                     :bottomBorder="index != 3" />
    </div>
  </div>
</template>

<script>
import TgCjTitle from '../title/TgCjTitle';
import TgCardGroup from '../card-group/TgCardGroup';
import TgPicPanel from '../pic-panel/TgPicPanel';
export default {
  name: 'tg-cj-competition',
  data() {
    return {
      title: '电竞',
    };
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
  methods: {
    toCardData(item) {
      return {
        id: item.id,
        banner: item.pic,
        titlefull: item.title,
        tags: item.tags,
        displaydate: item.date,
      };
    },
  },
  components: {
    TgCjTitle,
    TgCardGroup,
    TgPicPanel,
  },
};
</script>

