import actions from './actions';
import mutations from './mutations';
const state = {
  banner: [],
  exhibitionNews: [],
  relate: [],
  zoneNews: [],
  loadingMore: false,
  canLoadMore: [],
  // pageNums: [],
  pageNum: 1,
  keywords: [],
  search: {
    news: [],
    loadingMore: false,
    canLoadMore: true,
    pageNum: 1
  }
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions
};
