<template>
  <div class="tg-ps5-home_viewpoint">
    <tg-ps5-home-title title="巴士观点"
                       class="tg-ps5-home_container"></tg-ps5-home-title>
    <!--复用tgs中的样式 tgs/swiper/TgTgsSwiper.scss-->
    <div class='tg-tgs-swiper'>
      <div class="swiper-container"
           :id="emblyId">
        <div class="swiper-wrapper">
          <div class="swiper-slide"
               v-for="(item, index) in data"
               :key='index'>
            <!--复用tgs中的样式 tgs/pic-panel/TgPicPanel.scss-->
            <div class="tg-pic-panel">
              <a :href="item.locationlink == null ? '/news/' + (item.id || item.nid) : item.locationlink">
                <tg-flexbox direction="column">
                  <tg-flexbox flex="1"
                              class="tg-pic-panel_imgbox"><img :src="'https://xyoss.g.com.cn/xy-production' + item.pic"
                         alt=""></tg-flexbox>
                  <tg-flexbox class="tg-pic-panel_text">{{item.title}}</tg-flexbox>
                </tg-flexbox>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import TgPS5HomeTitle from './TgPS5HomeTitle'
export default {
  name: 'tg-ps5-home-viewpoint',
  data() {
    return {
      emblyId: 'tgs-ps5home-viewpoint-swiper',
      size: '340_180'
    }
  },
  props: {
    data: {
      type: Array,
      require: true,
    }
  },
  methods: {
    // toCardData(item) {
    //   return {
    //     id: item.id,
    //     banner: item.pic,
    //     titlefull: item.title,
    //     tags: item.tags,
    //     displaydate: item.date,
    //   };
    // }
  },
  components: {
    'tg-ps5-home-title': TgPS5HomeTitle
  },
  mounted() {
    /* eslint-disable */
    this.$nextTick(() => {
      /* eslint-disable */
      new Swiper('#' + this.emblyId, {
        direction: 'horizontal',
        loop: true,
        slideToClickedSlide: true,
        loopAdditionalSlides: 5,
        // autoplay: true,
        centeredSlides: true,
        autoplayDisableOnInteraction: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
      });
    })
  }
};
</script>
