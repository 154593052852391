import * as types from '../../mutations-types';

export default {
  [types.SEARCH_FETCH_ZIXUN_LISTS]: (state, res) => {
    state.searchLoading = false;
    state.pageNum = state.pageNum + 1;
    if (res.search.result) {
      if (res.search.result.length > 0) {
        state.searchZxNews.push(...res.search.result);
      }
      if (res.search.result.length < 20) {
        state.canLoadMore = false;
      } else {
        state.canLoadMore = true;
      }
    }
  },

  [types.SEARCH_FETCH_GAME_LISTS]: (state, res) => {
    state.searchLoading = false;
    state.pageNum = state.pageNum + 1;
    if (res.esearch.gameresults) {
      if (res.esearch.gameresults.length > 0) {
        state.searchZxNews.push(...res.esearch.gameresults);
      }
      if (res.esearch.gameresults.length < 20) {
        state.canLoadMore = false;
      } else {
        state.canLoadMore = true;
      }
    }
  },


  [types.SEARCH_FETCH_MOBILEGAME_LISTS]: (state, res) => {
    state.searchLoading = false;
    state.pageNum = state.pageNum + 1;
    if (res.code === 1) {
      if (res.data.data.length > 0) {
        state.searchZxNews.push(...res.data.data);
      }
      if (state.searchZxNews.length < res.data.total) {
        state.canLoadMore = true;
      } else {
        state.canLoadMore = false;
      }
    }
  },

  [types.SEARCH_FETCH_HOT_SEARCH]: (state, res) => {
    if (res.news && res.news.length > 0) {
      let reg = new RegExp(`<a.*?>(.*?)</a>`, 'gmi');
      let keywords = [];
      res.news[0].contentraw.replace(reg, function (str, keyword) {
        keywords.push(keyword);
      });
      state.hotSearchKeyWords = keywords;
    }
  },
};
