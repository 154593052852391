/**
 * Register hook functions
 */
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
  'asyncData',
  'asyncDataUser',
]);
