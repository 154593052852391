<template>
  <div>
    <tg-flexbox flex="100"
                :data-container='`{"id": "tgbus_wap_home_banner", "name": "banner轮播图"}`'>
      <tg-swiper :items-source="banner"
                 v-if="banner.length > 0"></tg-swiper>
    </tg-flexbox>
    <!-- 热门新闻 start -->
    <tg-flexbox flex="100"
                :data-container='`{"id": "tgbus_wap_home_rmxw", "name": "热门新闻"}`'>
      <tg-hot-news :items-source="hotNews"
                   v-if="hotNews.length > 0"></tg-hot-news>
    </tg-flexbox>
    <!-- 热门新闻 end -->
    <!-- 今日资讯 start -->
    <tg-flexbox flex="100">
      <tg-tody-information :items-source="todayInform"
                           v-if="todayInform.length > 0"></tg-tody-information>
    </tg-flexbox>
    <!-- 今日资讯 end -->
    <!-- 深度评测 start -->
    <tg-flexbox flex="100"
                class="tga-deep-evaluation">
      <tg-flexbox flex="100">
        <h2 class="tga-section-title">深度评测</h2>
      </tg-flexbox>
      <tg-flexbox flex="100"
                  :data-container='`{"id": "tgbus_wap_home_sdpc", "name": "深度评测"}`'>
        <tg-scroll-view direction="horizontal"
                        gap="false">
          <tg-flexbox gutter
                      wrap="nowrap">
            <tg-flexbox v-for="item in deepProfile"
                        :key="item.date"
                        flex="none">
              <tg-comment-card :item="item"></tg-comment-card>
            </tg-flexbox>
          </tg-flexbox>
        </tg-scroll-view>
      </tg-flexbox>
    </tg-flexbox>
    <!-- 深度评测 end -->
    <!-- 游戏视频 start -->
    <tg-flexbox flex="100">
      <tg-game-video :items-source="yxsp"
                     v-if="yxsp.length > 0"></tg-game-video>
    </tg-flexbox>
    <!-- 游戏视频 end -->
    <!-- 游戏攻略 start -->
    <tg-flexbox flex="100">
      <tg-walkthrough :items-source="yxgl"
                      v-if="yxgl.length > 0"></tg-walkthrough>
    </tg-flexbox>
    <!-- 游戏攻略 end -->
  </div>
</template>

<script>
import * as ad from '@src/api/ad';
import TgCommentCard from '@src/components/home/recommend/comment-card/TgCommentCard';
import TgHotNews from '@src/components/home/recommend/hot-news/TgHotNews';
import TgTodyInformation from '@src/components/home/recommend/tody-information/TgTodyInformation';
import TgWalkthrough from '@src/components/home/recommend/walkthrough/TgWalkthrough';
import TgGameVideo from '@src/components/home/recommend/game-video/TgGameVideo';
import isDevelopment from '@src/utils/isDevelopment';

export default {
  async asyncData({ route, store }) {
    await Promise.all([store.dispatch('home/INIT_DATA'), store.dispatch('home/FETCH')]);

    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: '首页',
      meta: `
  <meta name="description" content="bestjourney.net 中国综合游戏门户">
`,
    };
  },
  async mounted() {
    window.w9028 = document.documentElement.clientWidth - 32;
    window.h9028 = ((document.documentElement.clientWidth - 32) * 90) / 640;
    window.w9029 = document.documentElement.clientWidth - 32;
    window.h9029 = ((document.documentElement.clientWidth - 32) * 90) / 640;
    const AD_ID_ARR = [ad.AD_ID_HOME_SPREAD, ad.AD_ID_FLOW];
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
      ad.appendAdAll(AD_ID_ARR);
    } else {
      ad.appendAdAll(AD_ID_ARR);
    }
  },
  computed: {
    banner() {
      return this.$store.state.home.banner;
    },
    hotNews() {
      return this.$store.state.home.hotNews;
    },
    homeAdv() {
      return this.$store.state.home.homeAdv;
    },
    deepProfile() {
      return this.$store.state.home.deepProfile;
    },
    todayInform() {
      return this.$store.state.home.todayInform;
    },
    yxsp() {
      return this.$store.state.home.yxsp;
    },
    yxgl() {
      return this.$store.state.home.yxgl;
    },
  },
  methods: {},
  components: {
    TgCommentCard,
    TgHotNews,
    TgTodyInformation,
    TgWalkthrough,
    TgGameVideo,
  },
};
</script>