<template>
  <div class="tg-live-header-card">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in activeData" :key="item.id">
          <tg-flexbox>
            <a class="tg-live-header-card_item" :href="'/live/' + item._id">
              <div class="banner-info">
                <img :src="item.cdn+'/375_154/'+item.cover" class="banner-info-img" />
                <div
                  :class="{'banner-info-title': true, 'jijiang': item.status === 1, 'over': item.status === 3}"
                >
                  <span class="state" v-if="item.status === 1">即将开始</span>
                  <span class="state" v-if="item.status === 2">正在直播</span>
                  <span class="state" v-if="item.status === 3">已结束</span>
                  <!-- <span class="discuss">
                    加入讨论
                    <fa-icon icon="angle-double-right"></fa-icon>
                  </span>-->
                </div>
              </div>
              <div class="introduce">
                <div class="introduce-title">{{item.title}}</div>
                <div class="introduce-time-position">
                  <span class="time" v-if="item.liveTime && item.liveTime[0] !== ''">
                    <fa-icon icon="clock"></fa-icon>
                    {{item.liveTime[0]}}
                  </span>
                  <span class="location">
                    <fa-icon icon="location-arrow"></fa-icon>
                    {{item.location}}
                  </span>
                </div>
                <div class="introduce-content">{{item.description}}</div>
              </div>
            </a>
          </tg-flexbox>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'TgLiveHeaderCard',
  props: {
    activeData: {
      type: Array,
      require: true,
    },
  },
  mounted() {
    /* eslint-disable */
    const mySwiper = new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  },
};
</script>
