import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';

/**
 * Control List
 */
@Component
export class TgList extends Vue {
  @Prop({ type: Boolean, default: false })
  public readonly showSeparator!: boolean;

  public get classes(): ClassName {
    return [{ 'is-show-separator': this.showSeparator }];
  }

  private render(h: CreateElement): VNode {
    return (
      <ul staticClass="tg-list" class={this.classes}>
        {this.$slots.default}
      </ul>
    );
  }
}

/**
 * Control ListItem
 */
@Component
export class TgListItem extends Vue {
  @Prop({ type: String, default: 'li' })
  public readonly tag!: string;

  private render(h: CreateElement): VNode {
    return h(
      this.tag,
      {
        staticClass: 'tg-list_item',
      },
      [
        this.$slots.left ? (
          <div staticClass="tg-list_item-left">{this.$slots.left}</div>
        ) : (
          ''
        ),
        <div staticClass="tg-list_item-content">{this.$slots.default}</div>,
        this.$slots.right ? (
          <div staticClass="tg-list_item-right">{this.$slots.right}</div>
        ) : (
          ''
        ),
      ],
    );
  }
}
