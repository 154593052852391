<template>
  <div class="tg-mg tga-main-with-bar-newsdetail">
    <tg-scroll-view direction="vertical" scroller="window" content-wrapper :gap="false" back-to-top>
      <tg-mobile-game-filter></tg-mobile-game-filter>
      <tg-mobile-game-list></tg-mobile-game-list>
    </tg-scroll-view>
    <tg-footer-bar pcHref="//shouji.bestjourney.net/gamedb"></tg-footer-bar>
    <tg-header-bar headerInfo="手游游戏库" pcHref="//shouji.bestjourney.net/gamedb"></tg-header-bar>
  </div>
</template>

<script>
import TgMobileGameFilter from '@src/components/mobile-game/filter/TgMobileGameFilter';
import TgMobileGameList from '@src/components/mobile-game/list/TgMobileGameList';
import isDevelopment from '@src/utils/isDevelopment';
export default {
  data() {
    return {};
  },
  async asyncData({ route, store }) {
    await Promise.all([
      store.dispatch('mobileGame/INIT_DATA'),
      store.dispatch('mobileGame/FETCH'),
    ]);
    // 返回 SSR 上下文，用于插入到 head 内
    let headInfo = {
      title: `手机游戏库_手游频道 - 电玩巴士`,
      notCommonEnd: true,
      meta: `
    <meta name="Description" content="电玩巴士手游游戏库为用户提供最新最全的手机游戏下载、手机游戏资讯、手机游戏排行等信息。 " />
    <meta name="Keywords" content="手机游戏,安卓游戏,ios游戏,手机游戏下载" />
    <meta name="robots" content="all"> 
      `,
    };
    return headInfo;
  },
  mounted() {
    if (isDevelopment && this.$options.asyncData) {
      this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },
  components: {
    TgMobileGameFilter,
    TgMobileGameList,
  },
};
</script>
