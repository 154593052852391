/**
 * 推荐页面
 */
import { home } from '../../../api/cms';
import { homeC } from '../../containers.config.js';
import * as types from '../../mutations-types';
const initRequest = container => {
  let promises = [];
  for (var key in container) {
    let query = `{
      groups(group_name:"tgbus_wap_home") {
        status`;
    for (var item of container[key]) {
      query =
        query +
        `
      ${item.containerId}:containers(container_id:"${item.containerId}") {
          container_name
          container_id
          hasSource {
            ${item.hasSource}
          }
        }`;
    }
    query =
      query +
      `
      }
    }`;
    promises.push(home.homeAPI({ query: query }));
  }
  return promises;
};

export default {
  INIT_DATA: ({ state }) => {
    state.banner = [];
    state.hotNews = [];
    state.todayInform = [];
    state.deepProfile = [];
    state.yxgl = [];
    state.yxsp = [];
    state.homeAdv = [];
    state.newsloadingState = 1;
    state.newspageNum = 1;
    state.sploadingState = 1;
    state.sppageNum = 1;
    state.glloadingState = 1;
    state.glpageNum = 1;

    state.loading = true;
    state.shopPS4News = [];
    state.shopSwitchNews = [];
  },
  FETCH: async ({ commit }) => {
    let promise = null;
    let promises = initRequest(homeC);
    let listLabel = ['巴士首页要闻', '游戏视频', '游戏攻略'];
    for (var item in listLabel) {
      let query =
        `{
        list (website: "tgbus", tags:["${listLabel[item]}"]${item == 0 ? ', exclude_tags:"厂商稿件"' : ''}) {
          total
          result(start: 1, offset: 10){
            nid
            banner
            titlefull
            tags
            displaydate
            locationlink
          }
        }
      }`;
      promises.push(home.homeAPI({ query: query }));
    }

    promise = Promise.all([...promises]).then(resC => {
      commit(types.HOME_FETCH_BANNER, resC[0]);
      commit(types.HOME_FETCH_HOT_NEWS, resC[1]);
      commit(types.HOME_FETCH_DEEP_PROFILE, resC[2]);
      commit(types.HOME_FETCH_TODAY_INFORM, resC[3]);
      commit(types.HOME_FETCH_YXSP, resC[4]);
      commit(types.HOME_FETCH_YXGL, resC[5]);
    });
    return promise;
  },
  FETCH_HOME_NEWS: ({ commit, state }, container) => {
    state.loading = true;
    let query = `{
  groups(group_name: "tgbus_wap_home") {
    status
    ${container}: containers(container_id: "${container}") {
      container_name
      container_id
      hasSource {
        pic, title, date, id, locationlink
      }
    }
  }
} `;
    return home.homeAPI({ query: query }).then(res => {
      commit(types.HOME_FETCH_LIST_NEWS, { res });
    });
  },

  FETCH_HOME_SALES: ({ commit, state }, index) => {
    state.loading = true;
    let typeAll = ['1073741829', '6', '48', '49', '130']
    let yesterday = new Date(new Date().setHours(0, 0, 0, 0)) / 1000
    let saleQuery = `{
  game_release_schedule(first: 20, begin_time: ${yesterday}, filter: { platforms: "${typeAll[index]}" }) {
    relation_link
    related_game {
      id
      name
    }
    cover {
      path
    }
    title
    platforms {
      platform
      release_date
      release_date_string
      release_date_timestamp
    }
    tags {
      description
      name
    }
  }
} `
    return home.gameGraphqi(saleQuery).then(res => {
      commit(types.HOME_FETCH_SALE_NEWS, { res });
    });
  },
  FETCH_HOME_SHOP: ({ commit, state }, container) => {
    state.loading = true;
    if (container == 1 || container == 2) {
      let promises = [];
      promises.push(home.shop({
        groupid: 'tgbus_wap_home_shop_' + container + '_ps4'
      }));
      promises.push(home.shop({
        groupid: 'tgbus_wap_home_shop_' + container + '_switch'
      }));
      return Promise.all([...promises]).then(res => {
        commit(types.HOME_FETCH_SHOP_NEWS, { container, res });
      });
    } else {
      return home.shop({
        groupid: 'tgbus_wap_home_shop_' + container
      }).then(res => {
        commit(types.HOME_FETCH_SHOP_NEWS, { container, res });
      });
    }


  },
  FETCH_MORE: ({ commit, state }, params = {}) => {
    state[params.loadingStateName] = 2;
    state[params.glpageNumName] = state[params.glpageNumName] + 1;
    let queryMore =
      `{
  list(website: "tgbus", tags: ["` +
      params.tag +
      `"], exclude_tags:"厂商稿件") {
        total
        result(start: ` +
      state[params.glpageNumName] +
      `, offset: 10){
          nid
          banner
          titlefull
          tags
          displaydate
          locationlink
        }
      }
    }`;
    home.homeAPI({ query: queryMore }).then(res => {
      commit(types.HOME_FETCH_MORE_DATA, { params, res });
    });
  },
};
