 <template>
  <div class="tg-mg-list">
    <div class="tg-mg_container">
      <p class="tg-mg-list_result">
        <span>
          <label>共筛选出</label>
          <label>{{total}}</label>
          <label>款游戏</label>
        </span>
        <span>
          <label :class="{'active': activeIndex === 0}" @click="sortGames(0)">最新</label>
          <label>|</label>
          <label :class="{'active': activeIndex === 1}" @click="sortGames(1)">高分</label>
        </span>
      </p>
      <div class="tg-mg-list_list">
        <tg-card-item v-for="(item,index) in games" :key="index" class="item" :itemsSource="item"></tg-card-item>
      </div>
      <div class="tg-mg-list_loadmore" @click="loadMore" v-if="total == 0 && !isLoading">暂无相关内容</div>
      <div
        class="tg-mg-list_loadmore"
        @click="loadMore"
        v-else-if="games.length < total && !isLoading"
      >加载更多</div>
      <div class="tg-mg-list_loadmore" v-else-if="isLoading">加载中...</div>
      <div class="tg-mg-list_loadmore" v-else>没有更多内容了</div>
    </div>
  </div>
</template>
<script>
import emptyVue from './empty.vue';
import TgCardItem from '@src/components/mobile-game/card-item/TgCardItem';
import TgMobileGameTitle from '@src/components/mobile-game/title/TgMobileGameTitle';
export default {
  data() {
    return {
      activeIndex: 0,
      params: [],
    };
  },
  components: {
    TgMobileGameTitle,
    TgCardItem,
  },
  created() {
    emptyVue.$off('fetchGames');
  },
  computed: {
    games() {
      return this.$store.state.mobileGame.games;
    },
    total() {
      return this.$store.state.mobileGame.total;
    },
    isLoading() {
      return this.$store.state.mobileGame.isLoading;
    },
  },
  mounted() {
    emptyVue.$on('fetchGames', params => {
      this.params = params;
      this.reLoadData();
    });
  },
  methods: {
    sortGames(activeIndex) {
      if (activeIndex !== this.activeIndex) {
        this.activeIndex = activeIndex;
        this.reLoadData();
      }
    },
    reLoadData() {
      this.$store.state.mobileGame.pageNum = 0;
      this.$store.state.mobileGame.games = [];
      this.$store.state.mobileGame.total = 0;
      if (this.activeIndex === 0) {
        this.$delete(this.params, 'score');
        this.$set(this.params, 'new', 0);
      } else {
        this.$delete(this.params, 'new');
        this.$set(this.params, 'score', 0);
      }
      this.$store.dispatch('mobileGame/FETCH_LIST', this.params);
    },
    loadMore() {
      this.$store.dispatch('mobileGame/FETCH_LIST', this.params);
    },
  },
};
</script>
