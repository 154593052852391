<template>
  <div class="tg-news-detail news-detail tga-main-with-bar-newsdetail"
       v-if="isShow">
    <div v-if="articleMain.typeid !== 8">
      <tg-news-detail :articleMain="articleMain"
                      :gameMain="gameMain"
                      @changeScrollDirection="scrollTopDirection"
                      :newsId="newsId"></tg-news-detail>
      <tg-footer-bar :pcHref="pcHref"></tg-footer-bar>
      <tg-header-bar :channel="channel"
                     :pcHref="pcHref"
                     :headerInfo="headerInfo"></tg-header-bar>
      <tg-download-app v-show="showDownloadApp"></tg-download-app>
      <advertisement :id="ad.AD_ID_DETAIL_FLOAT"
                     class="tg-news-detail_ad-float"
                     type="dropdown"
                     mode="link" />
    </div>
    <!-- 图赏 -->
    <div v-else>
      <tg-note :articleMain="articleMain"></tg-note>
    </div>
  </div>
</template>

<script>
import TgNewsDetail from '@src/components/news-detail/TgNewsDetail';
import TgNote from '@src/components/news-detail/note/TgNote';
import isDevelopment from '@src/utils/isDevelopment';
import { imgURL as imgURLShare } from '@src/filters/index';
import * as ad from '@src/api/ad';
import Advertisement from '@src/components/advertisement';
import TgDownloadApp from '../controls/navigation/download-app/TgDownloadApp.vue';
const isInteger = function(value) {
  value = Number(value);
  return typeof value === 'number' && value <= Math.pow(2, 31) && value >= 1;
};
export default {
  async asyncData({ route, store }) {
    const newsId = route.query.newsid || route.params.newsid;
    if (!isInteger(newsId)) {
      return;
    }
    await Promise.all([
      store.dispatch('article/INIT_DATA'),
      store.dispatch('article/FETCH', {
        newsId,
      }),
    ]);
    const main = store.state.article.articleMain;
    let headInfo = {
      title: main.titlefull,
      meta: `
  <meta name="keywords" content="${[...(main.keywords || []), ...main.tags].join(
        ', ',
      )}, tgbus, 电玩巴士">
  <meta name="description" content="电玩巴士 ${main.description}">
  <meta property="og:url" content="https://m.bestjourney.net/news/${newsId}" />
  <meta property="og:title" content="${main.titlefull} - TGBUS - 电玩巴士" />
  <meta property="og:description" content="${main.description}" />
  <meta property="og:image" content="${main.banner}" />
    `,
    };
    // ps4 switch xbox
    if (['2', '3', '4'].indexOf(main.channelid.toString()) > -1) {
      headInfo.title = main.titlefull + ' | 电玩巴士';
      headInfo.meta = `
      <meta name="Description" content="${main.description}" />
      <meta name="Keywords" content="${[...(main.keywords || []), ...main.tags].join(
        ', ',
      )},PS4,Xbox One,Switch,NS,PS4游戏,XboxOne游戏,Switch游戏,NS游戏,主机游戏" />
      <meta name="robots" content="all">
        `;
      headInfo.notCommonEnd = true;
      return headInfo;
    }
    switch (main.channelid.toString()) {
      case '6':
        headInfo.title = main.titlefull + ' | Hi科技';
        headInfo.meta = `
      <meta name="Description" content="${main.description}" />
      <meta name="Keywords" content="${[...(main.keywords || []), ...main.tags].join(
          ', ',
        )},5G,IOT,消费电子,人工智能AI, 手机数码,新能源汽车,自动驾驶汽车,智能家居,电脑影音" />
      <meta name="robots" content="all">
        `;
        headInfo.notCommonEnd = true;
        break;
      case '7':
        headInfo.title = main.titlefull + ' | 安卓中文网';
        headInfo.meta = `
      <meta name="Description" content="${main.description}" />
      <meta name="Keywords" content="${[...(main.keywords || []), ...main.tags].join(
          ', ',
        )},android,安卓,安卓手机,手机评测,5G,IoT,华为,三星,小米,vivo,oppo" />
      <meta name="robots" content="all">
        `;
        headInfo.notCommonEnd = true;
        break;
      case '8':
        headInfo.title = main.titlefull + ' | iPhone中文网';
        headInfo.meta = `
      <meta name="Description" content="${main.description}" />
      <meta name="Keywords" content="${[...(main.keywords || []), ...main.tags].join(
          ', ',
        )},iPhone,iPhoneXS,iPhoneX,iPhone8,iPhone游戏,iPhone配件,iOS" />
      <meta name="robots" content="all">
        `;
        headInfo.notCommonEnd = true;
        break;
    }

    // 返回 SSR 上下文，用于插入到 head 内
    return headInfo;
  },
  data() {
    return {
      ad,
      showDownloadApp: true,
      isShow: false,
      wxapi: {},
      newsId: null,
      channelTransformation: {
        '6': 'tech',
        '7': 'android',
        '8': 'iphone',
      },
    };
  },
  async mounted() {
    this.newsId = this.$route.query.newsid || this.$route.params.newsid;
    if (!isInteger(this.newsId)) {
      location.href = '/404';
      return;
    }
    if (
      !isDevelopment &&
      this.articleMain.typeid === 4 &&
      this.articleMain.locationlink !== ''
    ) {
      location.href = this.articleMain.locationlink;
      return;
    }
    if (
      !isDevelopment &&
      (this.articleMain.titlefull === '' || this.articleMain.status !== 1)
    ) {
      location.href = '/404';
      return;
    }
    if (!isDevelopment && this.articleMain.gameid !== '') {
      this.$store.dispatch('article/FETCH_GAME', {
        gameid: this.articleMain.gameid,
      });
    }
    if (isDevelopment && this.$options.asyncData) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
    const channelIds = {
      // 文章所属频道tech
      '6': 'https://hm.bestjourney.net/hm.js?ec876d8b20beb8c5254a8b816dc29e85',
      // 文章所属频道android
      '7': 'https://hm.bestjourney.net/hm.js?805dc8a07eff052a35614357c2853559',
      // 文章所属频道iphone
      '8': 'https://hm.bestjourney.net/hm.js?e9510b11f4be96624e87e58aa9021619',
    };
    const articleChannelId = this.articleMain.channelid.toString();
    const scriptSrc = channelIds[articleChannelId];
    if (scriptSrc) {
      var hm = document.createElement('script');
      hm.src = scriptSrc;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(hm, s);
    }
    this.isShow = true;
  },
  beforeMount() {
    // 分享内容
    this.wxapi = require('@src/utils/wxapi.js').default;
    this.wxapi.wxRegister(this.wxRegCallback);
  },
  methods: {
    wxRegCallback() {
      // 用于微信JS-SDK回调
      this.wxShareTimeline();
      this.wxShareAppMessage();
    },
    wxShareTimeline() {
      // 将配置注入通用方法
      this.wxapi.OnMenuShareTimeline(this.shareOption);
    },
    wxShareAppMessage() {
      // 将配置注入通用方法
      this.wxapi.OnMenuShareAppMessage(this.shareOption);
    },
    scrollTopDirection(toTop) {
      this.showDownloadApp = !toTop;
    }
  },
  computed: {
    articleMain() {
      return this.$store.state.article.articleMain;
    },
    gameMain() {
      return this.$store.state.article.gameMain;
    },
    channel() {
      if (
        this.$store.state.article.articleMain.channelid &&
        this.channelTransformation[this.$store.state.article.articleMain.channelid]
      ) {
        // eslint-disable-next-line standard/computed-property-even-spacing
        return this.channelTransformation[
          this.$store.state.article.articleMain.channelid
        ];
      } else {
        return 'tgbus';
      }
    },
    pcHref() {
      return 'https://www.bestjourney.net/news/' + this.$route.params.newsid;
    },
    headerInfo() {
      const channelid = this.$store.state.article.articleMain.channelid;
      let info = '';
      switch (channelid.toString()) {
        case '3':
          info = 'Switch中文网';
          break;
        case '4':
          info = 'Xbox One中文网';
          break;
        case '2':
          info = 'PS4中文网';
          break;
      }
      return info;
    },
    shareOption() {
      return {
        title: this.articleMain.titlefull, // 分享标题, 请自行替换
        desc: this.articleMain.description, // 分享描述, 请自行替换
        link: window.location.href, // 分享链接，根据自身项目决定是否需要split
        imgUrl: imgURLShare(this.articleMain.banner, '600_'), // 分享图标, 请自行替换，需要绝对路径
        success: () => {
          // alert('分享成功');
        },
        error: () => {
          // alert('已取消分享');
        },
      };
    },
  },
  components: {
    TgNewsDetail,
    TgNote,
    Advertisement,
    TgDownloadApp
  },
};
</script>
