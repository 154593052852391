/* 策略容器配置 */
const e3C = {
  banner: [{
    containerId: 'tgbus_wap_e3_banner2021',
    containerName: 'tgbus-wap e32021首页banner轮播图',
    hasSource: ['pic', 'title', 'date', 'id', 'locationlink']
  }],
  exhibitionNews: [{
    containerId: 'tgbus_wap_e3_exhibitionNews2021',
    containerName: 'tgbus-wap e32021首页展会要闻',
    hasSource: ['pic', 'title', 'date', 'id', 'locationlink']
  }],
  relate: [{
    containerId: 'tgbus_wap_e3_relate2021',
    containerName: 'tgbus-wap e32021首页相关列表',
    hasSource: ['pic', 'title', 'tags', 'date', 'id', 'reviewscore', 'summary', 'type', 'locationlink']
  }]
};

export {
  e3C
};
