/**
 * GraphQL API for game-lib
 */

// tslint:disable:no-relative-imports no-any no-reserved-keywords max-func-body-length max-line-length no-http-string

import axios, { AxiosResponse, AxiosPromise } from 'axios';
import { Query, Direction } from './model';

let apiUrl: string = 'https://graphql-xy.bestjourney.net';

/**
 * Set url for graphql api.
 * @param url the url
 */
export function setApiUrl(url: string): void {
  apiUrl = url;
}

let retry: number = 3;

/**
 * Set retry count when graphql api get fail.
 * @param count count for trying
 */
export function setRetryCount(count: number): void {
  retry = count;
}

// region ======== Overload for get() ========

// endregion

/**
 * Get data from graphql api
 *
 * @param query the graphql query code
 */
export async function get(query: string): Promise<Query>;

/**
 * Get data from graphql api
 *
 * @param type the field names of Query
 * @param query the graphql query code
 */
export async function get<K extends keyof Query>(
  type: K,
  query: string,
): Promise<Query[K]>;

export async function get<K extends keyof Query>(
  type: K,
  query?: string,
): Promise<Query | Query[K]> {
  interface QueryError {
    message: string;
    locations: {
      line: number;
      column: number;
    }[];
  }
  interface GraphQLResponseBody {
    data: Query | null;
    errors?: QueryError[];
  }

  let hasFieldName: boolean = true;
  if (!query) {
    // tslint:disable:no-parameter-reassignment
    query = type;
    hasFieldName = false;
  }

  let errors: any;

  for (let index: number = 0;index < retry;index++) {
    try {
      const body: GraphQLResponseBody = (await axios.post<GraphQLResponseBody>(
        `${apiUrl}/graphql`,
        JSON.stringify({
          query,
          variables: '',
        }),
        { timeout: 10000 },
      )).data;
      if (body.data) {
        if (hasFieldName) {
          if (body.data[type] === null || body.data[type] === undefined) {
            throw new Error(`data.${type} is null or undefined.`);
          }

          return body.data[type];
        } else {
          return body.data;
        }
      }
      if (body.errors) {
        errors = body.errors;
        continue;
      }
    } catch (error) {
      errors = error;
      continue;
    }
  }

  throw errors;
}

function objectSerialize(object: any): string {
  if (object === undefined) {
    return 'undefined';
  } else if (object === null) {
    return 'null';
  } else if (typeof object === 'string') {
    if (object === Direction.ASC || object === Direction.DESC) {
      return object;
    }

    return `"${object}"`;
  } else if (typeof object === 'number') {
    return object.toString();
  } else if (Array.isArray(object)) {
    return `[${object.map(o => objectSerialize(0)).join(', ')}]`;
  } else if (typeof object === 'object') {
    // tslint:disable-next-line:no-unsafe-any
    return `{${Object.entries(object)
      .map(([key, value]) => `${key}: ${objectSerialize(value)}`)
      .join(', ')}}`;
  }

  return '';
}

export function argumentsSerialize(argumentsOptions: object): string {
  return `(${Object.entries(argumentsOptions)
    .map(([key, value]) => `${key}: ${objectSerialize(value)}`)
    .join(', ')})`;
}
