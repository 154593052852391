/**
 * source: http://graphql-xy.bestjourney.net/graphql
 * timestamp: Fri Jun 08 2018 16:35:07 GMT+0800 (DST)
 */

// tslint:disable:no-reserved-keywords

export interface Schema {
  query: Query;
}

export interface Article {
  article_genre: string;
  click_count: number;
  comment_count: number;
  dbkey: string;
  desc: string;
  img_location: ImageLocation[];
  img_location_count: number;
  like_count: number;
  parsed_content: string;
  parsed_content_char_count: number;
  parsed_content_main_body: string;
  publish_time: number;
  referer: Article;
  repost_count: number;
  response_url: string;
  small_img_location: ImageLocation[];
  small_img_location_count: number;
  tags: string[];
  timestamp: string;
  title: string;
  update_time: number;
  url: string;
  user: User[];
  user_img_location: ImageLocation[];
  video_img_location: ImageLocation[];
  video_img_location_count: number;
  video_location: VideoLocation[];
  video_location_count: number;
}

export interface Collection {
  content_text: string;
  count: number;
  cover: CollectionCover;
  games: Game[];
  id: number;
  links: Link[];
  name: string;
  names: Names;
  // detection hook
  type: string;
}

export interface Cover {
  desc: string;
  format: string;
  height: string;
  index: string;
  path: string;
  size: number;
  src: string;
  type: string;
  width: string;
}

export interface CollectionCover {
  desc: string;
  format: string;
  height: number;
  index: string;
  is_deleted: number;
  path: string;
  size: number;
  src: string;
  type: string;
  width: number;
}

export interface Date {
  date: number;
  platform: Collection;
  region: string;
}

/**
 * Sorting direction
 */
export enum Direction {
  /**
   * 升序
   */
  ASC = 'ASC',

  /**
   * 降序
   */
  DESC = 'DESC',
}

export interface EsGames {
  game: Game;
  highlight: Highlight;
  score: number;
}

export interface EsSearch {
  gameresults: EsGames[];
  max_score: number;
  total: number;
}

export interface Filter {
  game_engines?: number;
  category?: number;
  platform?: number;
  themes?: number;
  genres?: number;
  game_modes?: number;
  player_perspectives?: number;
}

export interface Game {
  audio_lang: string;
  avatars: Game[];
  bundles: Game[];
  buy_in_app: string;
  category: number;
  category_name: string;
  collections: Collection[];
  covers: Cover[];
  developers: Collection[];
  dlcs: Game[];
  expansions: Game[];
  external_links: Links[];
  features: string[];
  first_release_date: number;
  franchises: Collection[];
  game: Game[];
  game_engines: Collection[];
  game_modes: Collection[];
  game_status: string;
  games: Game[];
  genres: Collection[];
  id: number;
  interface_lang: string;
  keywords: Collection[];
  name: string;
  names: Name[];
  news: Article[];
  platforms: Collection[];
  player_perspectives: Collection[];
  prices: Price[];
  publishers: Publisher[];
  ratings: Rating[];
  redirect_id: number;
  release_dates: Date[];
  scores: Score[];
  screenshots: Screenshot[];
  sections: Section[];
  size: string;
  standalone_expansions: Game[];
  steam_owner_data: SteamOwnersData;
  storyline_html: string;
  storyline_text: string;
  subtitle_lang: string;
  summary_html: string;
  summary_text: string;
  system_requirements_html: string;
  system_requirements_text: string;
  system_themes: Game[];
  themes: Collection[];
  updated_at: number;
  videos: Video[];
  weibos: Article[];
  weight: number;
}

export interface Highlight {
  game_name: string[];
}

export interface ImageLocation {
  checksum: string;
  img_desc: string;
  img_format: string;
  img_height: number;
  img_index: number;
  img_path: string;
  img_size: number;
  img_src: string;
  img_type: string;
  img_width: number;
}

export interface InputPrompt {
  game_names: string[];
}

/**
 * item type
 */
export enum ItemType {
  /**
   * game
   */
  GAME = 'GAME',
}

export interface Link {
  title: string;
  url: string;
}

export interface Links {
  id: number;
  name: string;
  url: string;
}

export interface Name {
  content: string;
  lang: string;
}

export interface Names {
  alias: string;
  en_us: string;
  formal: string;
  ja_jp: string;
  old: string;
  short: string;
  zh_cn: string;
}

export interface OrderBy {
  field?: string;
  direction?: Direction;
}

export interface Price {
  currency: string;
  date: number;
  price: number;
  store: string;
  store_name: string;
  type: number;
  type_name: string;
  url: string;
  url_response: string;
}

export interface Publisher {
  publisher: Collection;
  region: string;
}

export interface Query {
  collection: Collection;
  developer: Collection;
  esearch: EsSearch;
  essearch_prompt: InputPrompt;
  franchise: Collection;
  game: Game;
  game_engine: Collection;
  game_engines: Collection[];
  game_list: Game[];
  game_mode: Collection;
  game_modes: Collection[];
  games: Game[];
  genre: Collection;
  genres: Collection[];
  keyword: Collection;
  platform: Collection;
  player_perspective: Collection;
  player_perspectives: Collection[];
  publisher: Collection;
  score_leaderboards: Game[];
  search: Game[];
  sections: Section[];
  theme: Collection;
  themes: Collection[];
}

export interface Rating {
  contents_html: string;
  contents_text: string;
  id: number;
  name: string;
  system: string;
}

export interface Score {
  aggregated_score: number;
  aggregated_score_count: number;
  date: number;
  score: number;
  score_count: number;
  store: string;
  store_name: string;
  total_score: number;
  url: string;
}

export interface Screenshot {
  desc: string;
  format: string;
  height: string;
  index: string;
  path: string;
  size: number;
  src: string;
  type: string;
  width: string;
}

export interface Section {
  content_html: string;
  id: string;
  is_show: number;
  position: number;
}

export interface SteamOwnersData {
  date: string;
  high: number;
  id: string;
  low: number;
}

export interface User {
  user_badge: number;
  user_id: number;
  user_name: string;
  user_url: string;
}

export interface VideoLocation {
  checksum: string;
  flag: Boolean;
  message: string;
  video_code: string;
  video_duration: string;
  video_format: string;
  video_fps: string;
  video_frame_count: number;
  video_height: number;
  video_index: number;
  video_path: string;
  video_src: string;
  video_width: number;
}

export interface Video {
  desc: string;
  format: string;
  height: string;
  index: string;
  length: string;
  path: string;
  size: number;
  src: string;
  status: string;
  type: string;
  width: string;
}
