/**
 * 手游游戏库
 */
import { mobileGame } from '../../../api/cms';
import * as types from '../../mutations-types';
export default {
  INIT_DATA: ({ state }) => {
    state.games = [];
    state.isLoading = false;
    state.total = 0;
    state.pageNum = 0;
    state.filters = []
  },

  FETCH: async ({ commit, state }) => {
    return Promise.all([mobileGame.filters(), mobileGame.getGames({ page: 1, per_page: 20 })]).then(res => {
      const [filters, games] = res
      commit(types.MOBILEGAME_FETCH, { filters, games });
    });
  },
  FETCH_LIST: async ({ commit, state }, params = {}) => {
    state.pageNum = state.pageNum + 1
    state.isLoading = true
    return mobileGame.getGames({ page: state.pageNum, per_page: 20, ...params }).then(games => {
      if (games.code === 1) {
        state.games.push(...games.data.data)
        state.total = games.data.total
      } else {
        console.log(games.msg)
      }
      state.isLoading = false
    });
  }
}
