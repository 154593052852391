<template>
  <div class="search tg-search">
    <tg-search-nav @changeChannel="handleChangeChannel"
                   :channels="channels"
                   :hiChannelIds="hiChannelIds"></tg-search-nav>
    <loading :show="true"
             v-if="searchZxNews.length == 0 && searchLoading" />
    <!-- 搜索到资讯和游戏-->
    <div v-if="searchZxNews.length !== 0"
         class='tg-search_searchCon'>
      <!-- 资讯 -->
      <tg-scroll-view direction="vertical"
                      scroller="window"
                      back-to-top
                      :gap="false">
        <template v-if="!isMobileGame">
          <tg-news-item v-for="(item, index) in searchZxNews"
                        :isGame="isGame"
                        :key="index"
                        class="tg-search_news-item"
                        :subDomain="getSubDomain"
                        :data="item"></tg-news-item>
        </template>
        <template v-else>
          <tg-card-item v-for="(item,index) in searchZxNews"
                        :key="index"
                        class="item"
                        :itemsSource="item"></tg-card-item>
        </template>
        <div v-if="searchZxNews.length !== 0">
          <div class="tg-search_loadmore"
               @click="onLoadMore"
               v-if="canLoadMore">{{searchLoading ? '正在加载...' : '加载更多'}}</div>
          <div class="tg-search_loadmore"
               v-else>没有更多数据了</div>
        </div>
        <!-- 加载更多 end -->
      </tg-scroll-view>
    </div>
    <!-- 没有符合搜索结果 -->
    <tg-no-match v-if="searchLoading === false && searchZxNews.length === 0"></tg-no-match>
    <!-- <tg-header-bar search></tg-header-bar> -->
    <tg-footer-bar :pcHref="pcHref"></tg-footer-bar>
  </div>
</template>

<script>
import TgCardItem from '@src/components/mobile-game/card-item/TgCardItem'
import TgNoMatch from '@src/components/search/no-match/TgNoMatch';
import TgSearchNav from '@src/components/search/search-nav/TgSearchNav';
import Loading from '@src/components/search/loading/loading';
import isDevelopment from '@src/utils/isDevelopment';
const hiChannelIds = ['6', '7', '8'];
const mobileGameSearch = 'mobilegame'
const channels = {
  current: 'new',
  new: {
    label: '资讯',
    current: 'dw',
    children: {
      dw: {
        label: '电玩',
        ids: [2, 3, 4, 10],
      },
      wy: {
        label: '网游',
        ids: [11],
      },
      sy: {
        label: '手游',
        ids: [12],
      },
      sm: {
        label: '数码',
        ids: [6, 7, 8],
      },
    }
  },
  game: {
    current: 'game',
    label: '游戏',
    children: {
      game: {
        isGame: true,
        label: '主机游戏',
        ids: [],
      },
      mobilegame: {
        isMobileGame: true,
        label: '手游游戏',
        ids: []
      }
    }
  }
}
export default {
  data() {
    const cChannels = channels[channels.current]
    return {
      currentChannel: cChannels[cChannels.current],
      isGame: false,
      isMobileGame: false,
      channels: channels,
      hiChannelIds: hiChannelIds
    };
  },
  async asyncData({ route, store }) {
    // let channelName = route.params.channel;
    let channelid = route.params.channelid;
    let channelids = [];
    if (channelid && hiChannelIds.indexOf(channelid) > -1) channelids.push(channelid);
    else {
      const cChannels = channels[channels.current]
      const ids = cChannels.children[cChannels.current].ids
      channelids.push(...ids);
    }
    await Promise.all([
      store.dispatch('search/INIT_DATA'),
      store.dispatch('search/FETCH', {
        keyword: (route.params.q || '').split(','),
        channelid: channelids,
      }),
    ]);

    // 返回 SSR 上下文，用于插入到 head 内
    let headInfo = {
      title: `${route.params.q} | 电玩巴士`,
      notCommonEnd: true,
      meta: `
    <meta name="Description" content="${route.params.q},TGBUS中国综合游戏门户_电玩巴士_电视游戏_电子游戏_网络游戏_手机游戏_网页游戏" />
    <meta name="Keywords" content="${route.params.q},主机游戏,PS4,Xbox One,Switch,NS,PS4游戏,XboxOne游戏,Switch游戏,NS游戏,PC游戏" />
    <meta name="robots" content="all"> 
      `,
    };
    if (channelid) {
      switch (channelid.toString()) {
        case '6':
          headInfo.title = `${route.params.q} | Hi科技`;
          headInfo.notCommonEnd = true;
          headInfo.meta = `
      <meta name="Description" content="Hi科技聚焦科技智能硬件相关内容，发现并推荐更多新鲜有趣的智能硬件和消费电子产品，关注科技数码相关资讯和行业动向，触碰、联结、智享现在与未来的科技生活" />
      <meta name="Keywords" content="${route.params.q},5G,IOT,消费电子,人工智能AI, 手机数码,新能源汽车,自动驾驶汽车,智能家居,电脑影音" />
      <meta name="robots" content="all">
        `;
          break;
        case '7':
          headInfo.title = `${route.params.q} | 安卓中文网`;
          headInfo.notCommonEnd = true;
          headInfo.meta = `
      <meta name="Description" content="安卓中文网提供原创独家的手机数码，智能硬件，消费电子产品相关新闻、评测、视频、图赏类内容，同时关注汽车科技、智能家居等未来科技生活新动向。" />
      <meta name="Keywords" content="${route.params.q},android,安卓,安卓手机,手机评测,5G,IoT,华为,三星,小米,vivo,oppo" />
      <meta name="robots" content="all">
        `;
          break;
        case '8':
          headInfo.title = `${route.params.q} | iPhone中文网`;
          headInfo.notCommonEnd = true;
          headInfo.meta = `
      <meta name="Description" content="iPhone中文网为大家提供iPhoneXS Max，iPhone8，XR等新品苹果iPhone手机的新闻资讯，iPhone软件游戏推荐，苹果周边硬件评测等内容，及时报道苹果手机及其他iOS智能硬件的最新动态。" />
      <meta name="Keywords" content="${route.params.q},iPhone,iPhoneXS,iPhoneX,iPhone8,iPhone游戏,iPhone配件,iOS" />
      <meta name="robots" content="all">
        `;
          break;
      }
    }

    return headInfo;
  },
  created() {
    const { channels } = this
    const cChannels = channels[channels.current]
    this.isGame = cChannels.children[cChannels.current].isGame
    this.isMobileGame = cChannels.children[cChannels.current].isMobileGame
  },
  beforeMount() {
    const isFromMobileGame = document.referrer.indexOf('/mobilegamedb') > -1 || document.referrer.indexOf('/mobilegame') > -1
    if (isFromMobileGame) {
      this.channels.current = 'game'
      const cChannels = this.channels[this.channels.current]
      cChannels.current = 'mobilegame'
      this.handleChangeChannel(cChannels.children[cChannels.current])
    } else if (isDevelopment && this.$options.asyncData) {
      this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },
  computed: {
    searchLoading() {
      return this.$store.state.search.searchLoading;
    },
    searchZxNews() {
      return this.$store.state.search.searchZxNews;
    },
    canLoadMore() {
      return this.$store.state.search.canLoadMore;
    },
    pcHref() {
      let host = 'https://www.bestjourney.net/';
      let channelid = this.$route.params.channelid;
      if (channelid && hiChannelIds.indexOf(channelid) > -1) {
        switch (channelid) {
          case '6':
            host = 'https://tech.bestjourney.net/';
            break;
          case '7':
            host = 'https://android.bestjourney.net/';
            break;
          case '8':
            host = 'https://iphone.bestjourney.net/';
            break;
        }
      }
      return host + 'search/' + this.$route.params.q;
    },
    getSubDomain() {
      switch (this.$route.params.channelid) {
        case '6':
          return 'tech';
        case '7':
          return 'android';
        case '8':
          return 'iphone';
      }
      return '';
    },
  },
  methods: {
    async handleChangeChannel(channel) {
      await this.$store.dispatch('search/INIT_DATA');
      this.currentChannel = channel
      this.isGame = channel.isGame;
      this.isMobileGame = channel.isMobileGame
      this.onLoadMore();
    },
    onLoadMore() {
      if (this.searchLoading || !this.canLoadMore) {
        return;
      }
      let keyword = this.$route.params.q || '';

      let channelid = this.$route.params.channelid;
      let channelids = [];

      if (channelid && hiChannelIds.indexOf(channelid) > -1) channelids.push(channelid);
      else channelids.push(...this.currentChannel.ids);
      let params = {
        keyword: keyword.split(','),
        channelid: channelids,
      };
      if (this.isGame) {
        this.$store.dispatch('search/FETCH_GAMES', params)
      } else if (this.isMobileGame) {
        this.$store.dispatch('search/FETCH_MOBILEGAME', {
          shouyou_name: keyword
        })
      } else {
        this.$store.dispatch('search/FETCH_MORE', params)
      }
    },
    changeToItem(item) {
      return item;
    },
  },
  components: {
    TgNoMatch,
    TgSearchNav,
    Loading,
    TgCardItem
  },
};
</script>
