import actions from './actions';
import mutations from './mutations';
const state = {
  articleMain: {
    gameid: '',
    status: '',
    banner: '',
    titlefull: '',
    sourcedesc: '',
    author: '',
    displaydate: '',
    ctime: '',
    description: '',
    contentraw: '',
    wm_id: '',
    channelid: '',
    contentrawNew: '',
    contentImages: [],
    pageCount: 1,
    pageCurrent: 1,
    typeid: '',
    locationlink: '',
    reviewbanner: '',
    reviewscore: '',
    reviewsummary: '',
    reviewmaf: '',
    keywords: '',
    contents: '',
    tags: '',
    relatednews: [],
  },
  gameMain: [],
  keywords: [],
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
