import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';

/**
 * Available values for flexbox property flex.
 */
export type Flex =
  // region ======== Flex values ========
  | 'average'
  | 'auto'
  | 'initial'
  | 'none'
  | 33
  | 66
  | '33'
  | '66'
  | 5
  | 10
  | 15
  | 20
  | 25
  | 30
  | 35
  | 40
  | 45
  | 50
  | 55
  | 60
  | 65
  | 70
  | 75
  | 80
  | 85
  | 90
  | 95
  | 100
  | '5'
  | '10'
  | '15'
  | '20'
  | '25'
  | '30'
  | '35'
  | '40'
  | '45'
  | '50'
  | '55'
  | '60'
  | '65'
  | '70'
  | '75'
  | '80'
  | '85'
  | '90'
  | '95'
  | '100';
// endregion

/**
 * Available values for flexbox property direction.
 */
export enum FlexDirection {
  row = 'row',
  rowReverse = 'row-reverse',
  column = 'column',
  columnReverse = 'column-reverse',
}

/**
 * Available values for flexbox property wrap.
 */
export enum FlexWrap {
  nowrap = 'nowrap',
  wrap = 'wrap',
  wrapReverse = 'wrap-reverse',
}

/**
 * Available values for flexbox property justify.
 */
export enum FlexJustify {
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  spaceBetween = 'space-between',
  spaceAround = 'space-around',
}

/**
 * Available values for flexbox property align and align-self.
 */
export enum FlexAlign {
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  baseline = 'baseline',
  stretch = 'stretch',
}

/**
 * Component Flexbox
 */
@Component
export class TgFlexbox extends Vue {
  @Prop({ type: String, default: 'div' })
  public readonly tag!: string;

  /**
   * Flexibility.
   */
  @Prop({ type: [String, Number] })
  public readonly flex!: Flex;

  @Prop({ type: [String, Number] })
  public readonly flexXsmall!: Flex;

  @Prop({ type: [String, Number] })
  public readonly flexSmall!: Flex;

  @Prop({ type: [String, Number] })
  public readonly flexMedium!: Flex;

  @Prop({ type: [String, Number] })
  public readonly flexLarge!: Flex;

  @Prop({ type: [String, Number] })
  public readonly flexXlarge!: Flex;

  /**
   * Visibility.
   */
  @Prop({ type: Boolean, default: false })
  public readonly hidden!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly hiddenXsmall!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly hiddenSmall!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly hiddenMedium!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly hiddenLarge!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly hiddenXlarge!: boolean;

  /**
   * Gutter between sub flexbox content.
   */
  @Prop({ type: Boolean, default: false })
  public readonly gutter!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly gutterSmall!: boolean;

  /**
   * Flex flow direction.
   */
  @Prop({ type: String })
  public readonly direction!: FlexDirection;

  /**
   * Flex line wrapping.
   */
  @Prop({ type: String })
  public readonly wrap!: FlexWrap;

  // @Prop({ type: Number })
  // public readonly order: number;

  /**
   * Axis alignment.
   */
  @Prop({ type: String })
  public readonly justify!: FlexJustify;

  /**
   * Cross alignment.
   */
  @Prop({ type: String })
  public readonly align!: FlexAlign;

  /**
   * Cross alignment for itself.
   */
  @Prop({ type: String })
  public readonly alignSelf!: FlexAlign;

  private render(h: CreateElement): VNode {
    return h(
      this.tag,
      {
        staticClass: 'tg-flexbox',
        class: [
          {
            [`tgp-flex_${this.flex}`]: this.flex,
            [`tgp-flex-xsmall_${this.flexXsmall}`]: this.flexXsmall,
            [`tgp-flex-small_${this.flexSmall}`]: this.flexSmall,
            [`tgp-flex-medium_${this.flexMedium}`]: this.flexMedium,
            [`tgp-flex-large_${this.flexLarge}`]: this.flexLarge,
            [`tgp-flex-xlarge_${this.flexXlarge}`]: this.flexXlarge,
            'is-hidden': this.hidden,
            'is-hidden-xsmall': this.hiddenXsmall,
            'is-hidden-small': this.hiddenSmall,
            'is-hidden-medium': this.hiddenMedium,
            'is-hidden-large': this.hiddenLarge,
            'is-hidden-xlarge': this.hiddenXlarge,
            'is-gutter': this.gutter,
            'is-gutter-small': this.gutterSmall,
            [`tgp-direction_${this.direction}`]: this.direction,
            [`tgp-wrap_${this.wrap}`]: this.wrap,
            [`tgp-justify_${this.justify}`]: this.justify,
            [`tgp-align_${this.align}`]: this.align,
            [`tgp-align-self_${this.alignSelf}`]: this.alignSelf,
          },
        ],
      },
      [this.$slots.default],
    );
  }
}
