<template>
  <div id="app"
       class="tg-app">
    <router-view></router-view>
    <!-- <tg-download-app v-if="isLoad"></tg-download-app> -->
  </div>
</template>

<script>
const namesNews = ['home', 'newsDetail', 'list'];
const namesGamelib = ['gamelib', 'gamelib-game', 'gamelib-collection'];
export default {
  methods: {},
  computed: {
    isLoad() {
      if (this.$route.name === 'e3') {
        return false;
      } else if (this.$route.name === 'e3NewsDetail') {
        return false;
      } else if (this.$route.name === 'cj') {
        return false;
      } else if (this.$route.name === 'CjNewsDetail') {
        return false;
      } else if (this.$route.name === 'cj-list') {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
