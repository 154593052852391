/**
 * 文章页
 */
import { article, commonApi } from '../../../api/cms';
import * as types from '../../mutations-types';

export default {
  INIT_DATA: ({ state }) => {
    state.articleMain = {
      gameid: '',
      status: '',
      banner: '',
      titlefull: '',
      sourcedesc: '',
      author: '',
      displaydate: '',
      ctime: '',
      description: '',
      contentraw: '',
      wm_id: '',
      channelid: '',
      contentrawNew: '',
      contentImages: [],
      pageCount: 1,
      pageCurrent: 1,
      typeid: '',
      locationlink: '',
      reviewbanner: '',
      reviewscore: '',
      reviewsummary: '',
      reviewmaf: '',
      keywords: '',
      contents: '',
      tags: '',
      relatednews: [],
    };
    state.gameMain = [];
    state.relateNews = [];
    state.keywords = [];
  },
  // keyId: e3 cj 专题查找关键词使用
  FETCH: async ({ commit, state }, { newsId, keyId = '44579' }) => {
    let query =
      `{
      news (id: ` +
      newsId +
      `) {
        gameid,
        status,
        banner,
        titlefull,
        sourcedesc,
        author,
        displaydate,
        ctime,
        description,
        contentraw,
        wm_id,
        channelid,
        typeid,
        locationlink,
        reviewbanner,
        reviewscore,
        reviewsummary,
        reviewmaf{
          status
          good,
          fault
        },
        keywords,
        titleshort,
        contents{
          title,
          page,
          achor{
            title,
            name
          }
        },
        tags,
        relatednews(website:"tgbus"){
          locationlink
          cms_url
          banner
          titlefull
          displaydate
          tags
          nid
        }
      }
    }`;
    const queryArticle =
      `{
      news (id: ` +
      keyId +
      `) {
        contentraw
      }
    }`;
    let promise = null;
    promise = Promise.all([
      article.articleAPI({ query: queryArticle }),
      article.articleAPI({
        query: query,
      }),
    ]).then(resC => {
      commit(types.E3_SET_SEARCH_KEYWORDS, resC[0]);
      commit(types.SET_ARTICLE_DETAIL, { res: resC[1] });
    });
    return promise;
  },
  FETCH_GAME: ({ commit, state }, params = {}) => {
    let curGameid = params.gameid.split(',');
    let queryGame = '{';
    for (var key in curGameid) {
      queryGame =
        queryGame +
        `a${key}:games (id: ` +
        curGameid[key] +
        `) {
        platform
        game_name
        themes
        summary
        genres
        names{
          en_US
          zh_CN
        }
        url
        dbkey
        cover {
          img_path
        }
      }`;
    }
    queryGame = queryGame + '}';
    return Promise.all([commonApi.post({ query: queryGame })]).then(res => {
      commit(types.SET_RELATE_GAMES, res[0]);
    });
  },
};
