<template>
  <div :class="{'tg-special-card-group': true, 'bottom-border': bottomBorder}">
    <a :href="data.locationlink == null ? '/news/' + (data.id || data.nid) : data.locationlink">
      <tg-flexbox>
        <div class="tg-special-card-group_left">
          <img :src="'https://xyoss.g.com.cn/xy-production' + data.banner"
               alt="">
        </div>
        <tg-flexbox direction="column"
                    class="tg-special-card-group_right">
          <tg-flexbox class="tg-special-card-group_title">{{data.titlefull}}</tg-flexbox>
          <tg-flexbox class="tg-special-card-group_infobox">
            <tg-flexbox flex="none"
                        class="text"
                        :style="{color: showColor}">{{label == '' ? tag : label}}</tg-flexbox>
            <tg-flexbox flex="none"
                        class="line"
                        v-if="tag!=''"></tg-flexbox>
            <tg-flexbox flex="none"
                        class="line"
                        v-if="label!=''"></tg-flexbox>
            <tg-flexbox flex="none"
                        class="time">{{data.displaydate | timestampToTimeNoYear}}</tg-flexbox>
          </tg-flexbox>
        </tg-flexbox>
      </tg-flexbox>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showColor: '',
      tag: '',
    };
  },
  name: 'TgSpecialCardGroup',
  props: {
    bottomBorder: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'tgbuswap_360mw',
    },
    colorInfo: {
      type: String,
      default: 'zixun',
    },
    label: {
      type: String,
      default: '',
    },
    data: Object,
    tagsStyle: {
      type: Object,
      required: false,
      default: () => ({
        tagColors: ['#DED10A', '#029BC1', '#00A142', '#262626'],
        getTags: ['专访', '视频', '试玩', '资讯'],
        showTags: ['专访', '视频', '试玩', '资讯']
      })
    }
  },
  created() {
    const self = this;
    const { tagsStyle } = this
    const { tagColors, getTags, showTags } = tagsStyle
    if (!self.label) {
      const tags = this.data.tags || [];
      let index = 0;
      self.showColor = tagColors[3];
      self.tag = showTags[3];
      tags.forEach(item => {
        index = getTags.indexOf(item);
        if (index > -1) {
          self.showColor = tagColors[index];
          self.tag = showTags[index];
          return;
        }
      });
    } else {
      let index = showTags.indexOf(self.label);
      self.showColor = tagColors[index];
    }
  },
};
</script>
