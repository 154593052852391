<template>
  <div class='tg-tgs-list-page tg-tgsview'
       id="special-home">
    <tg-special-header-bar :keywords="keywords"
                           :search="search"
                           :channel="channel"></tg-special-header-bar>
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    back-to-top
                    gap="false"
                    @bottom='onLoadMore()'
                    class="tga-padding-top64">
      <tg-special-nav-bar id="tgs-list-nav-bar"
                          :items-source="navMenu"
                          :class="{'fixed-nav': fixedNav}"></tg-special-nav-bar>
      <tg-flexbox class="tg-tgs-list-page_listbox">
        <tg-flexbox flex="100"
                    v-if="listData && listData.length > 0">
          <!-- listData start -->
          <div v-if="listData && listData.length > 15">
            <tg-special-pic-panel :data="listData[0]" />
            <div v-for="(item, index) in listData.slice(1) || []"
                 :key="index">
              <tg-special-card-group :data="toCardData(item)"
                                     :bottomBorder="true" />
            </div>
          </div>
          <div v-else>
            <tg-special-card-info-item v-for="item in listData"
                                       :key="item.nid"
                                       :items-source="toCardData(item)" />
          </div>
          <!-- listData end -->
        </tg-flexbox>
        <!-- 加载更多 start -->
        <div class="tg-tgs-list-page_nodata"
             v-if="listData && listData.length > 0">
          {{loadingMore ? '更多内容加载中……' : (canLoadMore ? '上拉加载更多' : '没有更多内容了')}}
        </div>
        <!-- 加载更多 end -->
      </tg-flexbox>
    </tg-scroll-view>
  </div>
</template>

<script>
import TgSpecialHeaderBar from '@src/components/special/header-bar/TgSpecialHeaderBar';
import TgSpecialNavBar from '@src/components/special/nav-bar/TgSpecialNavBar';
import TgSpecialCardGroup from '@src/components/special/card-group/TgSpecialCardGroup';
import TgSpecialPicPanel from '@src/components/special/pic-panel/TgSpecialPicPanel';
import TgSpecialCardInfoItem from '@src/components/special/card-info-item/TgSpecialCardInfoItem'
import isDevelopment from '@src/utils/isDevelopment.ts';
import { tgsVersion as version } from '@src/store/containers.config';
import { navMenu } from '@src/store/modules/tgs/index'

export default {
  data() {
    return {
      channel: 'tgs',
      navMenu,
      fixedNav: false
    };
  },
  async asyncData({ route, store }) {
    const tags = route.query.tags || route.params.tags;
    const isFirst = true;
    await Promise.all([
      store.dispatch('tgs/INIT_DATA'),
      store.dispatch('tgs/FETCH_LIST', { tags, isFirst }),
    ]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: `${version}TGS专题报道_${version}东京电玩展_A9VG电玩部落`,
      meta: `<meta name="description" content="A9VG电玩部落${version}东京电玩展（TGS ${version}），为您提供索尼、世嘉、万代南梦宫、SE等厂商的最新的游戏发布会和TGS新闻，TGS视频，TGS图片，TGS试玩，TGS采访等信息。">
             <meta name="keywords" content="TGS,TGS${version},东京电玩展,TGS新游戏"/>
             <base target="_blank">
             <meta name="robots" content="all"/>`,
    };
  },
  async mounted() {
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
    const navBar = document.getElementById('tgs-list-nav-bar');
    const _this = this;
    window.onscroll = function () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const sideBarShow = _this.$children[0].sideBarShow;
      if (scrollTop > 67 && !sideBarShow) {
        _this.fixedNav = true
      } else {
        _this.fixedNav = false
      }
    };
  },
  computed: {
    listData() {
      return this.$store.state.tgs.listData;
    },
    loadingMore() {
      return this.$store.state.tgs.loadingMore;
    },
    canLoadMore() {
      return this.$store.state.tgs.canLoadMore;
    },
    keywords() {
      return this.$store.state.tgs.keywords;
    },
    search() {
      return this.$store.state.e3.search;
    },
  },
  methods: {
    onLoadMore() {
      if (this.loadingMore) {
        return;
      }
      const tags = this.$route.query.tags || this.$route.params.tags;
      this.$store.dispatch('tgs/FETCH_LIST', { tags });
    },
    toCardData(item) {
      !item.locationlink && (item.locationlink = '/tgs/news/' + item.nid)
      return {
        nid: item.nid,
        locationlink: item.locationlink,
        banner: item.banner,
        titlefull: item.titlefull,
        tags: item.tags,
        displaydate: item.displaydate,
      };
    },
  },
  components: {
    TgSpecialHeaderBar,
    TgSpecialNavBar,
    TgSpecialPicPanel,
    TgSpecialCardGroup,
    TgSpecialCardInfoItem
  },
};
</script>
