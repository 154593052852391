<template>
  <div class="tg-search-bar"
       v-if="searchBarShow">
    <div class="tg-search-bar_wrapper">
      <div class="tg-search-bar_wrapper-header">
        <div class="tg-search-bar_left">
          <span class="tg-search-bar_search"
                @click="toggleSearch">
            <tg-e3-search />
          </span>
          <input type="text"
                 v-model="keyWord"
                 :placeholder="placeholder"
                 ref="input"
                 id="searchInput"
                 @keyup.enter="toggleSearch" />
        </div>
        <div class="tg-search-bar_right">
          <button class="tg-search-bar_button times"
                  @click="closeToggleSearch">
            <img src="../../../assets/header-close.png" />
          </button>
        </div>
      </div>
      <div class="tg-search-bar_title">热门搜索</div>
      <div class="tg-search-bar_list">
        <tg-flexbox justify="flex-start">
          <tg-flexbox flex="none"
                      class="tg-search-bar_item"
                      v-for="(item, index) in keyWordList"
                      :key="index">
            <a href="javascript:;"
               @click="goSearch(item)">{{item}}</a>
          </tg-flexbox>
        </tg-flexbox>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tg-search-bar',
  data() {
    return {
      placeholder: '请输入搜索关键词',
      keyWord: '',
    };
  },
  props: {
    searchBarShow: {
      type: Boolean,
      default: false,
    },
    channel: {
      required: true,
      type: String,
      default: 'tgbus',
    },
  },
  async mounted() {
    let params = null;
    switch (this.channel) {
      case 'tech':
        params = '51783';
        break;
      case 'android':
        params = '51781';
        break;
      case 'iphone':
        params = '51782';
        break;
    }
    await Promise.all([
      this.$store.dispatch('common/INIT_DATA_HOT_SEARCH'),
      this.$store.dispatch('common/FETCH_HOT_SEARCH', params),
    ]);
  },
  computed: {
    keyWordList() {
      return this.$store.state.common.hotSearchKeyWords;
    },
  },
  methods: {
    toggleSearch() {
      if (this.keyWord.trim() === '') {
        return;
      } else {
        if (this.$store.state.general.digitalArr.indexOf(this.channel) > -1) {
          window.open(
            '/search/' +
            this.keyWord +
            '/' +
            this.$store.state.general.changeChannelId[this.channel],
            '_self',
          );
        } else {
          window.open('/search/' + this.keyWord, '_self');
        }
      }
    },
    goSearch(keyWord) {
      if (this.$store.state.general.digitalArr.indexOf(this.channel) > -1) {
        window.open(
          '/search/' +
          keyWord +
          '/' +
          this.$store.state.general.changeChannelId[this.channel],
          '_self',
        );
      } else {
        window.open('/search/' + keyWord, '_self');
      }
    },
    closeToggleSearch() {
      this.keyWord = '';
      this.$emit('toggleSearch');
    },
  },
};
</script>
