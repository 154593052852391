import actions from './actions';
import mutations from './mutations';
const state = {
  channelNews: [],
  banner: [],
  loadingState: 1,
  pageNum: 1,
  ps5Home: {
    ps5Tags: [],
    banner: [],
    hotNews: [],
    viewpoint: [],
    ps5News: [],
    newWork: [],
    videos: []
  },
  changeChannelId: {
    ps4: '2',
    ps5: '2',
    ns: '3',
    xbox: '4',
    tech: '6',
    android: '7',
    iphone: '8',
  },
  defaultTag: {
    ps4: '索尼频道',
    ps5: null,
    ns: '任天堂频道',
    xbox: '微软频道',
    tech: '硬件资讯',
    android: '硬件资讯',
    iphone: '硬件资讯',
    ol: '网游资讯',
    shouji: '手游资讯'
  },
  gamenArr: ['ns', 'ps4', 'ps5', 'xbox', 'ol', 'shouji'],
  digitalArr: ['tech', 'android', 'iphone'],
  // 手游游戏库
  mobileGameSearch: 'mobilegame'
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
