<template>
  <div class="tg-cj-link-nav">
    <tg-flexbox justify="space-around">
      <tg-flexbox flex="none">
        <a :href="'/chinajoy/news/' + navs.map"
           target="_blank">
          <img src="../../../assets/cj/link1.png"
               alt="">
          <p class="title">展馆地图</p>
        </a>
      </tg-flexbox>
      <tg-flexbox flex="none">
        <a :href="'/chinajoy/news/' +  + navs.buy"
           target="_blank">
          <img src="../../../assets/cj/link2.png"
               alt="">
          <p class="title">购票指南</p>
        </a>
      </tg-flexbox>
      <tg-flexbox flex="none">
        <a :href="'/chinajoy/news/' +  + navs.schedule"
           target="_blank">
          <img src="../../../assets/cj/link3.png"
               alt="">
          <p class="title">展会日程</p>
        </a>
      </tg-flexbox>
      <tg-flexbox flex="none">
        <a :href="'/chinajoy/news/' +  + navs.traffic"
           target="_blank">
          <img src="../../../assets/cj/link4.png"
               alt="">
          <p class="title">交通指南</p>
        </a>
      </tg-flexbox>
    </tg-flexbox>
  </div>
</template>

<script>
export default {
  name: 'tg-cj-link-nav',
  data() {
    return {
      navs: {
        'map': '162826',
        'buy': '162827',
        'schedule': '162832',
        'traffic': '162834'
      }
    };
  },
  methods: {},
};
</script>

