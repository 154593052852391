<!-- 当前页：年货节下架，游戏预售。跳转App指定商品详情页 -->
<template>
  <div class="tg-malldownload">
    <!-- 安卓微信浏览器:页面信息放进剪贴板 -->
    <div v-if="platformAndroidH5 && wxAppH5"
         v-clipboard:copy="copyMessage"
         v-clipboard:success="onCopy"
         v-clipboard:error="onError">
      <a href="https://sj.qq.com/myapp/detail.htm?apkName=com.donews.tgbus"><img src="../../../assets/mall_download/downloadApp.jpg"
             alt="" /></a>
    </div>
    <div v-else>
      <a :href="'https://m.bestjourney.net/download?type=goods&id=' + goods_id"><img src="../../../assets/mall_download/downloadApp.jpg"
             alt="" /></a>
    </div>
  </div>
</template>

<script>
// import { downloadApp, deviceType, downloadUrl } from '@src/utils/downloadApp';
import { isInAndroid, isInIOS, isWeiXin } from '@src/utils/downloadApp';
export default {
  name: 'tg-malldownload-page',
  data() {
    return {
      platformAndroidH5: null,
      wxAppH5: null,
      copyMessage: '',
      goods_id: 415,
    };
  },
  beforeMount() {
    if (this.$route.name == 'mallDownload') {
      this.copyMessage = `type=goods&id=${
        this.goods_id
      }&site=tgbus&time=${new Date().getTime()}`;
    }
    this.platformAndroidH5 = isInAndroid();
    this.wxAppH5 = isWeiXin();
  },
  methods: {
    onCopy() {
      console.log('复制成功！');
    },
    onError() {
      console.log('复制失败!');
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.windowScroll); // 销毁滚动事件
  },
};
</script>
