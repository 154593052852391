import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';

/**
 * Component: GameHeader
 */
@Component
export class TgGameHeader extends Vue {
  @Prop({ type: String, required: true })
  public readonly cover!: string;

  @Prop({ type: String, required: true })
  public readonly background!: string;

  @Prop({ type: String, required: true })
  public readonly nameZh!: string;
  @Prop({ type: String, required: true })
  public readonly nameEn!: string;

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-game-header">
        <tg-uniform-scale staticClass="tg-game-header_background" ratio="56.25">
          {this.background ? (
            <img
              staticClass="tg-game-header_background-image"
              src={this.background}
              alt={this.nameZh}
            />
          ) : (
            ''
          )}
          <h1 staticClass="tg-game-header_name">
            <div staticClass="tg-game-header_name-zh">{this.nameZh}</div>
            <div staticClass="tg-game-header_name-en">{this.nameEn}</div>
          </h1>
        </tg-uniform-scale>
        <div staticClass="tg-game-header_bottom">
          <img staticClass="tg-game-header_cover" src={this.cover} alt={this.nameZh} />
        </div>
      </div>
    );
  }
}
