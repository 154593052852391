import axios from 'axios';

let grahqlBaseURL = 'https://news-graphql-xy.bestjourney.net'
let instance = axios.create({
  // baseURL: 'https://cms-lite-xy.bestjourney.net/',
  baseURL: `${grahqlBaseURL}/graphql`,
  timeout: 50000,
  headers: {
    'content-type': 'application/json',
  },
});
export function setBaseURL(baseURL) {
  grahqlBaseURL = baseURL
}

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

instance.interceptors.response.use(
  function (res) {
    if (res.status === 200 && !res.errors) {
      if (res.config.url.indexOf('mobilegamedb-cms.bestjourney.net/api') > -1) {
        return res.data
      } else {
        return res.data.data || res.data.result || res.data;
      }
    } else {
      console.error('请求异常，错误码： ' + res.errors, 15);
    }
  },
  function (error) {
    console.error('-----------------------------');
    console.error('网络异常： ' + error, 15);
    console.error('请求路径：' + error.config.url);
    console.error('请求参数' + JSON.parse(error.config.data).query);
    console.error('-----------------------------');
    throw error;
  },
);

export const createAPI = (url, method = 'get', data = {}) => {
  let requestInfo = {
    url,
    method,
  };
  if (method === 'post') {
    requestInfo.data = data;
  } else {
    requestInfo.params = data;
  }
  return instance(requestInfo);
};
