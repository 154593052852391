<template>
  <div class="tg-range">
    <div class="tg-range_category">
      <span :class="{'is-active': currentIndex == 1}"
            @click="changeTab(1)">24小时</span>
      <span :class="{'is-active': currentIndex == 2}"
            @click="changeTab(2)">周榜</span>
      <span :class="{'is-active': currentIndex == 3}"
            @click="changeTab(3)">月榜</span>
    </div>
    <div class="tg-range_news"
         v-for="index in [1, 2, 3]"
         :key="index"
         v-show="index == currentIndex"
         :data-container='`{ "id": "tgbus_wap_home_range_${index}", "name": "tgbus-wap 首页热文排行" }`'>
      <tg-news-item v-for="(item, index) in hotNews"
                    :isGame="false"
                    :key="index"
                    class="tg-range_item"
                    :data="item"></tg-news-item>
      <div class="tg-range_loading"
           v-if="loading">
        <loading :show="true"></loading>
      </div>
      <div class="tg-range_loading"
           v-else-if="!loading && hotNews.length == 0">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import isDevelopment from '@src/utils/isDevelopment';
export default {
  data() {
    return {
      currentIndex: 1,
    };
  },
  async asyncData({ route, store }) {
    await Promise.all([
      store.dispatch('home/INIT_DATA'),
      store.dispatch('home/FETCH_HOME_NEWS', 'tgbus_wap_home_range_1'),
    ]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: '首页',
      meta: `
  <meta name="description" content="bestjourney.net 中国综合游戏门户">
`,
    };
  },
  async mounted() {
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },
  computed: {
    hotNews() {
      return this.$store.state.home.hotNews;
    },
    loading() {
      return this.$store.state.home.loading;
    },
  },
  methods: {
    changeTab(index) {
      if (this.currentIndex != index) {
        this.currentIndex = index;
        this.$store.dispatch('home/INIT_DATA');
        this.$store.dispatch(
          'home/FETCH_HOME_NEWS',
          'tgbus_wap_home_range_' + this.currentIndex,
        );
      }
    },
  },
};
</script>