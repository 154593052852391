import actions from './actions';
import mutations from './mutations';
const state = {
  errorInfo: [],
  game: {},
  categoryRange: [],
  sameDeveloper: [],
  recommends: [],
  isEnd: false,
  isLoading: false
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
