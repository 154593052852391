<template>
  <div class='tg-cj'
       :data-group="getContainer()"
       id="e3-home">
    <tg-e3-header-bar :keywords="keywords"
                      :search="search"
                      :channel="channel"></tg-e3-header-bar>
    <tg-flexbox class="tga-padding-top64">
      <tg-cj-nav-bar id="nav-bar"></tg-cj-nav-bar>
      <tg-flexbox flex="100"
                  class="tga-container"
                  :data-container="getContainer('banner', '首页banner轮播图')">
        <tg-e3-swiper :items-source="banner"
                      channel="chinajoy"
                      v-if='banner.length > 0'></tg-e3-swiper>
      </tg-flexbox>
      <!-- <tg-flexbox flex="100">
        <tg-cj-purchase></tg-cj-purchase>
      </tg-flexbox>
      <tg-flexbox flex="100">
        <tg-cj-link-nav></tg-cj-link-nav>
      </tg-flexbox> -->
      <tg-flexbox flex="100">
        <tg-cj-news :data="news"
                    :data-container="getContainer('news', '头条资讯')"></tg-cj-news>
      </tg-flexbox>
      <tg-flexbox flex="
                  100">
        <tg-cj-online-games :data="onlineGames"
                            :data-container="getContainer('onlineGames', '网游')"></tg-cj-online-games>
      </tg-flexbox>
      <tg-flexbox flex="100">
        <tg-cj-mobile-games :data="mobileGames"
                            :data-container="getContainer('mobileGames', '手游')"></tg-cj-mobile-games>
      </tg-flexbox>
      <!-- <tg-flexbox flex="100">
      <tg-cj-competition :data="competition"
                          :data-container='JSON.stringify({ "id": "tgbus_wap_cj"+ version +"_competition", "name": "tgbus-wap cj电竞" }'></tg-cj-competition>
    </tg-flexbox> -->
      <tg-flexbox flex="100">
        <tg-cj-interview :data="interview"
                         :data-container="getContainer('interview', '人物访谈')"></tg-cj-interview>
      </tg-flexbox>
      <tg-flexbox flex="100">
        <tg-cj-industry-voice :data="industryVoice"
                              :data-container="getContainer('industryVoice', '业内声音')"></tg-cj-industry-voice>
      </tg-flexbox>
      <!-- <tg-flexbox flex="100">
      <tg-cj-acg :data="acg"
                  data-container='JSON.stringify({ "id": "tgbus_wap_cj"+ version +"_acg", "name": "tgbus-wap cj"+ version +"ACG" })'></tg-cj-acg>
    </tg-flexbox> -->
      <tg-flexbox flex="100"
                  :data-container="getContainer('elegance', '现场风采')">
        <tg-cj-elegance :data="elegance"
                        v-if="elegance && elegance.length > 0"></tg-cj-elegance>
      </tg-flexbox>
    </tg-flexbox>
    <tg-flexbox>
      <p class="tg-cj_nodata">没有更多了</p>
    </tg-flexbox>
    <div id="tgbus_wap_e3_scroller"></div>
  </div>
</template>

<script>
import TgE3HeaderBar from '../components/e3/header-bar/TgE3HeaderBar';
import TgE3Swiper from '../components/e3/swiper/TgE3Swiper';
import TgCjPurchase from '../components/cj/purchase/TgPurchase';
import TgCjLinkNav from '../components/cj/link-nav/TgCjLinkNav';
import TgCjNavBar from '../components/cj/nav-bar/TgCjNavBar';
import TgCjNews from '../components/cj/news/TgCjNews';
import TgCjOnlineGames from '../components/cj/online-games/TgCjOnlineGames';
import TgCjMobileGames from '../components/cj/mobile-games/TgCjMobileGames';
import TgCjCompetition from '../components/cj/competition/TgCjCompetition';
import TgCjInterview from '../components/cj/interview/TgCjInterview';
import TgCjIndustryVoice from '../components/cj/industry-voice/TgCjIndustryVoice';
import TgCjAcg from '../components/cj/acg/TgCjAcg';
import TgCjElegance from '../components/cj/elegance/TgCjElegance';
import isDevelopment from '../utils/isDevelopment.ts';
import { cjVersion, cjVersionTitle } from '../store/containers.config';
export default {
  data() {
    return {
      navTop: 0,
      channel: 'cj',
    };
  },
  async asyncData({ route, store }) {
    await Promise.all([store.dispatch(`cj/INIT_DATA`), store.dispatch(`cj/FETCH`)]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: `ChinaJoy${cjVersionTitle}展览会专题_电玩巴士`,
      meta: `<meta name="description" content="电玩巴士ChinaJoy${cjVersionTitle}展览会专题（CJ${cjVersionTitle}）为您带来ChinaJoy新闻，ChinaJoy新游戏，ChinaJoy专访，ChinaJoy现场图片，ChinaJoy${cjVersion}精彩内容尽在tgbus CJ专题报道。">
             <meta name="keywords" content="hinaJoy${cjVersionTitle},ChinaJoy,CJ${cjVersionTitle},CJ cosplay,CJ showgirl"/>
             <base target="_blank">
             <meta name="robots" content="all"/>`,
    };
  },
  async mounted() {
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
    const navBar = document.getElementById('nav-bar');
    window.onscroll = function() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 67) {
        navBar.style.borderBottom = 'none';
        navBar.style.background = '#000000';
      } else {
        navBar.style.borderBottom = '1px solid #f5f5f5';
        navBar.style.background = 'none';
      }
    };
  },
  computed: {
    banner() {
      return this.$store.state.cj.banner;
    },
    news() {
      return this.$store.state.cj.news;
    },
    onlineGames() {
      return this.$store.state.cj.onlineGames;
    },
    mobileGames() {
      return this.$store.state.cj.mobileGames;
    },
    competition() {
      return this.$store.state.cj.competition;
    },
    interview() {
      return this.$store.state.cj.interview;
    },
    industryVoice() {
      return this.$store.state.cj.industryVoice;
    },
    acg() {
      return this.$store.state.cj.acg;
    },
    elegance() {
      return this.$store.state.cj.elegance;
    },
    keywords() {
      return this.$store.state.cj.keywords;
    },
    search() {
      return this.$store.state.e3.search;
    },
  },
  methods: {
    getContainer(id, name) {
      let data = {
        id: 'tgbus_wap_cj' + cjVersion + (id ? '_' + id : ''),
        name: 'tgbus-wap cj' + (name ? '_' + name : ''),
      };
      return JSON.stringify(data);
    },
  },
  components: {
    TgE3HeaderBar,
    TgE3Swiper,
    TgCjPurchase,
    TgCjLinkNav,
    TgCjNavBar,
    TgCjNews,
    TgCjOnlineGames,
    TgCjMobileGames,
    TgCjCompetition,
    TgCjInterview,
    TgCjIndustryVoice,
    TgCjAcg,
    TgCjElegance,
  },
};
</script>
