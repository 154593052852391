 <template>
  <div class="tg-mg-detail">
    <div class="tg-mg_container">
      <p class="tg-mg-detail_nav">
        <span>
          <a href="/">首页</a>
        </span>
        <span>></span>
        <span>
          <a href="/mobilegamedb">游戏库</a>
        </span>
        <span>></span>
        <span>{{itemsSource.shouyou_name}}</span>
      </p>
      <div class="tg-mg-detail_info">
        <div class="image">
          <img :src="itemsSource.logoimageurl" alt />
        </div>
        <div class="information">
          <p>{{itemsSource.shouyou_name}}</p>
          <p>
            <span>{{itemsSource.game_category}}</span>
            <span>|</span>
            <span>{{itemsSource.size}}</span>
          </p>
          <p>{{itemsSource.instruction}}</p>
          <p>
            <a :href="itemsSource.androiddownurl">Android下载</a>
            <a :href="itemsSource.appledownurl" v-if="itemsSource.appledownurl">iOS下载</a>
          </p>
        </div>
      </div>
      <div class="tg-mg-detail_desc" ref="desc" :class="{'overflow': isOpen}">
        <span ref="desc">{{itemsSource.description}}</span>
        <span v-show="isOpen" class="open" @click="isOpen=false">
          <label>...</label>
          <label>展开</label>
        </span>
      </div>
    </div>
    <div class="tg-mg-detail_banners" :class="{'vertical-image': !horImage}">
      <span v-for="(image, index) in itemsSource.images" :key="index">
        <img :src="image.screenshotImageurls" alt />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tg-card-item',
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    horImage() {
      return this.itemsSource.images[0].width > this.itemsSource.images[0].height;
    },
  },
  props: {
    itemsSource: {
      type: Object,
      require: true,
    },
  },
  mounted() {
    if (this.$refs.desc.offsetHeight > 94) {
      this.isOpen = true;
    }
  },
};
</script>
