<template>
  <div class="tg-content-list">
    <div class="tg-content-list_list"
         v-if="channel === 'ol' || channel === 'shouji' ? label === '新闻' : label === '资讯'"
         :data-container="dataGroup">
      <tg-banner-swiper :items-source="banner"
                        v-if='banner.length > 0'></tg-banner-swiper>
      <tg-news-item v-for="item in itemsSource.slice(0,4) || []"
                    :key="item.nid"
                    :data="item"
                    :subDomain="channel" />
      <!-- 广告位 -->
      <div :id="adId"
           class="tg-content-list_adv-position"></div>
      <tg-news-item v-for="item in itemsSource.slice(4) || []"
                    :key="item.nid"
                    :data="item"
                    :subDomain="channel" />
    </div>
    <div v-else-if="label === '视频' || label === '图赏' || label === '游戏视频'"
         class="tg-content-list_card-info-box">
      <tg-card-info-item v-for="item in itemsSource.slice(0,4) || []"
                         :key="item.nid"
                         :items-source="item" />
      <!-- 广告位 -->
      <div :id="adId"
           class="tg-content-list_adv-position"></div>
      <tg-card-info-item v-for="item in itemsSource.slice(4) || []"
                         :key="item.nid"
                         :items-source="item" />
    </div>
    <div class="tg-content-list_list"
         v-else>
      <div v-if="itemsSource.length>15">
        <tg-card-info-item v-if="itemsSource[0]"
                           class='card-info-margin'
                           :items-source="itemsSource[0]"></tg-card-info-item>
        <tg-news-item v-for="item in itemsSource.slice(1,5) || []"
                      :key="item.nid"
                      :data="item"
                      :add-score="(channel==='ps4' || channel==='ps5') && label == '评测'"
                      :subDomain="channel" />
        <!-- 广告位 -->
        <div :id="adId"
             class="tg-content-list_adv-position"></div>
        <tg-news-item v-for="item in itemsSource.slice(5) || []"
                      :key="item.nid"
                      :data="item"
                      :add-score="(channel==='ps4' || channel==='ps5') && label == '评测'"
                      :subDomain="channel" />
      </div>
      <div v-else-if="itemsSource.length > 0 && itemsSource.length <=15"
           class="tg-content-list_card-info-box">
        <tg-card-info-item v-for="item in itemsSource.slice(0,4) || []"
                           :key="item.nid"
                           :items-source="item" />
        <!-- 广告位 -->
        <div :id="adId"
             class="tg-content-list_adv-position"></div>
        <tg-card-info-item v-for="item in itemsSource.slice(4) || []"
                           :key="item.nid"
                           :items-source="item" />
      </div>
      <div v-else></div>
    </div>
    <div class="tg-content-list_loadmore"
         @click="loadMore"
         v-if="loadingState === 1">加载更多</div>
    <div class="tg-content-list_loadmore"
         v-else-if="loadingState === 2">加载中...</div>
    <div class="tg-content-list_loadmore"
         v-else>没有更多内容了</div>
  </div>
</template>

<script>
import * as ad from '@src/api/ad';
import TgBannerSwiper from '@src/components/general-channel/banner-swiper/TgBannerSwiper';
import TgCardInfoItem from '@src/components/general-channel/card-info-item/TgCardInfoItem';
export default {
  name: 'tg-content-list',
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
    banner: {
      type: Array,
      require: true,
    },
    loadingState: {
      type: Number,
      require: true,
    },
    channel: {
      type: String,
      require: false,
      default: '',
    },
    label: {
      type: String,
      require: true,
      default: '',
    },
  },
  data() {
    return {
      adId: ad.AD_ID_FLOW,
    };
  },
  methods: {
    loadMore() {
      this.$emit('loadMore');
    },
  },
  computed: {
    dataGroup() {
      let data = {
        id: 'tgbus_wap_general_' + this.channel + '_wall',
        name: this.channel + '-banner',
      };
      return JSON.stringify(data);
    },
  },
  components: {
    TgBannerSwiper,
    TgCardInfoItem,
  },
};
</script>

