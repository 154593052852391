// const baseURL = 'https://niuer-ads.dev.tagtic.cn/v1/xingyou/c/'
const baseURL = 'https://g1.tagtic.cn/v1/xingyou/c/'

const script = {
  methods: {
    generateScript(adID, container) {
      let script = document.createElement('script')
      let content = `
        SG_GG(${adID})
      `
      let text = document.createTextNode(content)
      script.appendChild(text)
      document.getElementById(container).appendChild(script)
      let div = document.createElement('div')
      div.setAttribute('id', 'SG_GG_CONTAINER_' + adID)
      document.getElementById(container).appendChild(div)
    }
  }
}

const coupletScript = {
  methods: {
    generateCoupletScript(id) {
      let adCouplet = document.createElement('script')
      adCouplet.type = 'text/javascript'
      adCouplet.src = baseURL + `${id}.js`
      let sArr = document.getElementsByTagName('script')
      let s = document.getElementsByTagName('script')[sArr.length - 1]
      s.parentNode.insertBefore(adCouplet, s)
    }
  }
}

const dropdownScript = {
  methods: {
    generateDropdownScript(id) {
      let adDropDown = document.createElement('script')
      adDropDown.type = 'text/javascript'
      adDropDown.src = baseURL + `${id}.js`
      let sArr = document.getElementsByTagName('script')
      let s = document.getElementsByTagName('script')[sArr.length - 1]
      s.parentNode.insertBefore(adDropDown, s)
    }
  }
}

export { script, coupletScript, dropdownScript }
