import * as types from '../../mutations-types';

export default {
  [types.SET_ARTICLE_DETAIL]: (state, { res }) => {
    if (res.news && res.news.length > 0) {
      state.articleMain = res.news[0];
      state.wm_id = res.news[0].wm_id;
      let reg = new RegExp('<img', 'gmi');
      state.articleMain.contentraw = state.articleMain.contentraw.replace(
        reg,
        "<img alt='" + state.articleMain.titlefull + "'",
      );
      /* eslint-disable */
      let regTag = new RegExp('_ueditor_page_break_tag', 'gmi');
      state.articleMain.contentraw = state.articleMain.contentraw.replace(regTag, ' ');
      let contentImages = [];
      // var regSrc = new RegExp(/<img.*?src="(.*?)"\/>/, 'gmi');
      var regSrc = new RegExp(`<img.*?src=['|"](.*?)['|"].*?/>`, 'gmi');
      state.articleMain.contentraw = state.articleMain.contentraw.replace(
        regSrc,
        function (str, key) {
          if (
            key.indexOf('//') !== 0 &&
            key.indexOf('http://') !== 0 &&
            key.indexOf('https://') !== 0
          ) {
            if (state.wm_id) {
              str = str.replace(
                '/cms3.0/',
                '//xyoss.g.com.cn/xy-production/cms3.0/',
              );
            } else {
              str = str.replace('/cms3.0/', '//xyoss.g.com.cn/xy-production/cms3.0/');
            }
            let imageSrc = key.replace('/cms3.0/', '//xyoss.g.com.cn/xy-production/cms3.0/');
            var regTitle = new RegExp(`<img.*?title=['|"](.*?)['|"].*?/>`, 'mi');
            let titleContent = str.match(regTitle);
            contentImages.push({
              image: imageSrc,
              title: titleContent ? titleContent[1] : '',
            });
          }
          return str;
        },
      );
      state.articleMain.contentImages = contentImages;
      /* eslint-enable */
    } else {
      state.articleMain.status = 0
    }
  },
  [types.SET_RELATE_GAMES]: (state, res) => {
    if (res && res.a0) {
      state.gameMain = res;
    }
  },
  [types.E3_SET_SEARCH_KEYWORDS]: (state, res) => {
    if (res.news && res.news.length > 0) {
      let reg = new RegExp(`<a.*?>(.*?)</a>`, 'gmi');
      let keywords = [];
      res.news[0].contentraw.replace(reg, function (str, keyword) {
        keywords.push(keyword);
      });
      state.keywords = keywords;
    }
  },
};
