<template>
  <div class="tg-news-detail tga-main">
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    content-wrapper
                    @bottom="scrollBottomDirection(true)"
                    @top="scrollBottomDirection(false)"
                    @toTop="scrollBottomDirection(false)"
                    @toBottom="scrollBottomDirection(true)"
                    :gap="false"
                    back-to-top
                    download>
      <ul class="tg-news-detail_crumbsbox"
          v-if="channelArr[articleMain.channelid]">
        <li v-if="indexShow">
          <a href="/">首页</a>
        </li>
        <li v-if="indexShow"
            class="arrow">></li>
        <li>
          <a :href="channelArr[articleMain.channelid] && channelArr[articleMain.channelid].url">{{channelArr[articleMain.channelid] && channelArr[articleMain.channelid].label}}</a>
        </li>
        <li class="arrow">></li>
        <li>
          <a :href="'/news/' + newsId">正文</a>
        </li>
      </ul>
      <article-main :articleMain="articleMain"
                    @openConfirmBox="openAppConfirm=true"
                    @downloadApp="openOrDownload"
                    ref="articleMain"></article-main>
      <relate-game :gameMain="gameMain"
                   :articleMain="articleMain"
                   v-if="articleMain.gameid !== '' && gameMain.a0 && gameMain.a0.length !== 0"></relate-game>
      <div class="tg-news-detail_related-reading">
        <div class="related-reading-title">相关阅读</div>
        <tg-news-item v-for="(item, index) in articleMain.relatednews.slice(0,3)"
                      :key="index"
                      :data="item" />
      </div>
    </tg-scroll-view>
    <div class="tg-news-detail_open-tip"
         v-show="openAppConfirm">
      <div class="container">
        <p class="title">打开电玩巴士APP</p>
        <p class="desc">享受最佳阅读体验</p>
        <p class="btn">
          <span @click="closeAppConfirm">取消</span>
          <span @click="openOrDownload">确定</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadApp, deviceType, downloadUrl } from '@src/utils/downloadApp';
import ArticleMain from './article-main/TgArticleMain';
import relateGame from './relate-game/TgRelateGame';
export default {
  name: 'newsDetail',
  props: ['articleMain', 'gameMain', 'newsId'],
  data() {
    return {
      openAppConfirm: false,
      deviceType: null,
      channelArr: {
        2: {
          label: 'PS4中文网',
          url: '/list/ps4',
        },
        3: {
          label: 'Switch中文网',
          url: '/list/ns',
        },
        4: {
          label: 'Xbox One中文网',
          url: '/list/xbox',
        },
        6: {
          label: 'Hi科技',
          url: '/list/tech',
        },
        7: {
          label: '安卓中文网',
          url: '/list/android',
        },
        8: {
          label: 'iPhone中文网',
          url: '/list/iphone',
        },
      },
    };
  },
  computed: {
    indexShow() {
      if (this.articleMain.channelid === 2) {
        return true;
      } else if (this.articleMain.channelid === 3) {
        return true;
      } else if (this.articleMain.channelid === 4) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    ArticleMain,
    relateGame,
  },
  methods: {
    scrollBottomDirection(toBottom) {
      this.$emit('changeScrollDirection', toBottom);
    },
    closeAppConfirm() {
      const articleMainRefs = this.$refs.articleMain.$refs;
      articleMainRefs.articleContent.style.height = 'auto';
      articleMainRefs.readMoreBox.style.display = 'none';
      if (articleMainRefs.readStatement) {
        articleMainRefs.readStatement.style.display = 'block';
      }
      document.body.style.overflowY = 'auto';
      this.openAppConfirm = false;
    },
    openOrDownload() {
      !this.deviceType && (this.deviceType = deviceType());
      const { deviceType } = this;
      const downloadInfo = downloadUrl[deviceType];
      downloadInfo &&
        downloadApp({
          downloadUrl: downloadInfo.download,
          openApp: downloadInfo.openApp,
        });
    },
  },
  beforeMount() {
    this.deviceType = deviceType();
  },
};
</script>
