<template>
  <div class="tg-cj-elegance">
    <tg-cj-title :title="title"></tg-cj-title>
    <div class="swiper-container relate-swiper-container"
         id="swiper-elegance">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for="(item, index) in data.slice(0, 6) || []"
             :key='index'>
          <a :href="item.locationlink == null ? '/chinajoy/news/' + (item.id || item.nid) : item.locationlink">
            <img :src="'https://xyoss.g.com.cn/xy-production' + item.pic"
                 alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import TgCjTitle from '../title/TgCjTitle';
export default {
  name: 'tg-cj-elegance',
  data() {
    return {
      title: '现场风采'
    };
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      /* eslint-disable */
      new Swiper('#swiper-elegance', {
        slidesPerView: 'auto',
        spaceBetween: 6,
        freeMode: true,
      });
    });
  },
  methods: {},
  components: {
    TgCjTitle,
  },
};
</script>

