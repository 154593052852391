/**
 * 推荐页面
 */
import { general, game, article, mobileGame } from '../../../api/cms';
import * as types from '../../mutations-types';

const searchQL = (keyword, channelid, start, offset = 20) => `{
  search(website: "tgbus", keyword: "${keyword}"${
  channelid ? ',channelid: [' + channelid + ']' : ''
  }) {
    total
    result(start: ${start}, offset: ${offset}){
      nid
      tags
      cms_url
      banner
      titlefull
      description
      author
      displaydate
      tags
      typename
      channelid
    }
  }
}`;
const searchGameQL = (keyword, page, offset = 20) =>
  `{
  esearch(query: "` +
  keyword +
  `", first: ${offset}, skip: ${offset * (page - 1)}) {
    total
    gameresults {
      game {
        id
        name
        names {
          content
          lang
        }
        covers {
          path
        },
        platforms {
          name
        },
        cms3_scores{
          reviewscore
        }
      }
    }
  }
}`;

export default {
  INIT_DATA: ({ state }) => {
    state.searchZxNews = [];
    state.searchLoading = false;
    state.canLoadMore = true;
    state.pageNum = 1;
  },

  FETCH: async ({ commit, state }, params = {}) => {
    state.searchLoading = true;
    let query = searchQL(params.keyword, params.channelid, state.pageNum);
    return general.generalAPI({ query: query }).then(res => {
      commit(types.SEARCH_FETCH_ZIXUN_LISTS, res);
    });
  },
  FETCH_MORE: async ({ commit, state }, params = {}) => {
    state.searchLoading = true;
    let queryMore = searchQL(params.keyword, params.channelid, state.pageNum);

    return general.generalAPI({ query: queryMore }).then(res => {
      commit(types.SEARCH_FETCH_ZIXUN_LISTS, res);
    });
  },
  FETCH_GAMES: async ({ commit, state }, params = {}) => {
    state.searchLoading = true;
    let queryMore = searchGameQL(params.keyword, state.pageNum);

    return api.get(queryMore).then(res => {
      commit(types.SEARCH_FETCH_GAME_LISTS, res);
    });
  },
  FETCH_MOBILEGAME: async ({ commit, state }, params = {}) => {
    state.searchLoading = true;
    return mobileGame.getGames({ page: state.pageNum, per_page: 20, ...params }).then(res => {
      commit(types.SEARCH_FETCH_MOBILEGAME_LISTS, res);
    });
  },
  INIT_DATA_HOT_SEARCH: ({ state }) => {
    state.hotSearchKeyWords = [];
  },
  FETCH_HOT_SEARCH: async ({ commit }) => {
    const queryArticle = `{
      news (id: 51606) {
        contentraw
      }
    }`;
    let promise = null;
    promise = Promise.all([article.articleAPI({ query: queryArticle })]).then(resC => {
      commit(types.SEARCH_FETCH_HOT_SEARCH, resC[0]);
    });
    return promise;
  },
};
