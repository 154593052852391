import Vue, { CreateElement, VNode } from 'vue';
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { GalleryItem } from '@src/controls/media/gallery/Gallery';
import { CombinedVueInstance } from 'vue/types/vue';

function isMouseEvent(event: Event): event is MouseEvent {
  return (
    (event as MouseEvent).screenX !== undefined &&
    (event as MouseEvent).screenY !== undefined
  );
}

function isTouchEvent(event: Event): event is TouchEvent {
  return (event as TouchEvent).touches !== undefined;
}

/**
 * Component: GalleryFullscreen
 */
@Component
export class TgGalleryFullscreen extends Vue {
  @Prop({ type: Array, required: true })
  public readonly itemsSource!: GalleryItem[];

  @Prop({ type: Boolean, default: false })
  public readonly show!: boolean;

  public index: number = 0;

  private holding!: boolean;
  private screenWidth!: number;
  private startScreenX!: number;
  private offsetX: number = 0;
  private sliding: boolean = false;

  private get translateX(): string {
    return `translateX(${(-this.index + this.offsetX) * 100}%)`;
  }

  private move(screenX: number): void {
    this.offsetX = (screenX - this.startScreenX) / this.screenWidth;
  }

  private onMouseMove(event: MouseEvent): void {
    if (!this.holding) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.move(event.screenX);
  }
  private onTouchMove(event: TouchEvent): void {
    if (!this.holding) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.move(event.touches[0].screenX);
  }

  private onStart(event: MouseEvent | TouchEvent): void {
    if (this.sliding) {
      return;
    }

    this.holding = true;
    this.screenWidth = this.hook.$el.clientWidth;
    if (isMouseEvent(event)) {
      this.startScreenX = event.screenX;
    } else {
      this.startScreenX = event.touches[0].screenX;
    }
  }
  private onEnd(event: MouseEvent | TouchEvent): void {
    this.holding = false;
    this.sliding = true;

    const newIndex: number =
      this.index + (this.offsetX < 0 ? 1 : this.offsetX > 0 ? -1 : 0);

    if (-1 < newIndex && newIndex < this.itemsSource.length) {
      this.index = newIndex;
    }
    this.offsetX = 0;

    window.setTimeout(() => (this.sliding = false), 300);
  }

  private onClose(event: Event): void {
    this.$emit('close', false);
  }

  private hookEl!: Element;
  // tslint:disable-next-line:no-any
  private hook!: CombinedVueInstance<Vue, object, object, object, Record<never, any>>;

  private mounted(): void {
    this.hookEl = document.createElement('div');
    document.body.appendChild(this.hookEl);

    this.hook = new Vue({
      render: h => this.hookRender(h),
    }).$mount(this.hookEl);
  }

  private beforeDestroy(): void {
    this.hook.$destroy();
    document.body.removeChild(this.hookEl);
  }

  private hookRender(h: CreateElement): VNode {
    return this.show ? (
      <div
        staticClass="tg-gallery-fullscreen"
        role="region"
        onMousemove={this.onMouseMove}
        onTouchmove={this.onTouchMove}
        onMousedown={this.onStart}
        onTouchstart={this.onStart}
        onMouseup={this.onEnd}
        onMouseleave={this.onEnd}
        onTouchend={this.onEnd}
      >
        <div
          staticClass="tg-gallery-fullscreen_wrapper"
          class={{ 'is-sliding': this.sliding }}
          style={{ transform: this.translateX }}
        >
          {this.itemsSource.map(item => (
            <img
              staticClass="tg-gallery-fullscreen_image"
              key={item.origin}
              src={item.origin}
              alt={item.alt}
            />
          ))}
        </div>
        <button staticClass="tg-gallery-fullscreen_toggle" onClick={this.onClose}>
          <fa-icon icon="times" />
        </button>
        <div staticClass="tg-gallery-fullscreen_indicator">
          {this.index + 1} / {this.itemsSource.length}
        </div>
      </div>
    ) : (
      // tslint:disable-next-line:no-any
      ('' as any)
    );
  }

  private render(h: CreateElement): VNode {
    // tslint:disable-next-line:no-any
    return '' as any;
  }
}
