<template>
  <div class="tg-special-card-info-item">
    <a class="content"
       :href="itemsSource.locationlink == null ? '/news/' + itemsSource.nid : itemsSource.locationlink">
      <img :src="'https://xyoss.g.com.cn/xy-production' + itemsSource.banner">
      <p>{{itemsSource.titlefull}}</p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'tg-special-card-info-item',
  props: {
    itemsSource: {
      type: Object,
      require: true,
    },
  },
};
</script>
