 <template>
  <a class="tg-mg-carditem"
     :href="'/mobilegame/' + itemsSource.id">
    <span class="image">
      <img :src="itemsSource.logoimageurl"
           alt="">
    </span>
    <p class="info">
      <span>{{itemsSource.shouyou_name}}</span>
      <span><label>{{itemsSource.game_category}}</label><label>|</label><label>{{itemsSource.size}}</label></span>
      <span>{{itemsSource.instruction}}</span>
    </p>
    <p class="download">
      <a :href="downloadUrl"
         @click.stop="">
        <i>
          <img src="../../../assets/handgame/download.png"
               alt="">
        </i>
        <label>下载</label>
      </a>
    </p>
  </a>
</template>
<script>
export default {
  name: 'tg-card-item',
  data() {
    return {
      downloadUrl: ''
    }
  },
  props: {
    itemsSource: {
      type: Object,
      require: true,
    },
  },
  beforeMount() {
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    if (isAndroid) {
      this.downloadUrl = this.itemsSource.androiddownurl
    }
  }
}
</script>
