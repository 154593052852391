// tslint:disable: no-unsafe-any
import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import { TgStatics, TgTileWall, TgRanking, HomeData } from '@src/components/gamelib';
import * as codepitApi from '@src/api/codepit-api';
import * as ad from '@src/api/ad';
import { AsyncData, MetaData } from 'vue/types/options';
import isDevelopment from '@src/utils/isDevelopment';
import { Route } from 'vue-router';
import { Store } from 'vuex';

/**
 * Component: Gamelib
 */
@Component({
  components: {
    TgStatics,
    TgTileWall,
    TgRanking,
  },
})
export default class TgGamelib extends Vue {
  // tslint:disable:no-any
  private async asyncData({
    route,
    store,
  }: {
    route: Route;
    store: Store<any>;
  }): Promise<MetaData> {
    await Promise.all([
      store.dispatch('gamelib/HOME_DATA'),
      store.dispatch('gamelib/INIT_DATA'),
      store.dispatch('gamelib/FETCH'),
    ]);

    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: '游戏库',
      meta: `
  <meta name="keywords" content="游戏库,资料库,电玩巴士,tgbus">
  <meta name="description" content="电玩巴士 游戏资料库">
`,
    };
  }
  // tslint:enable:no-any

  private async mounted(): Promise<void> {
    // tslint:disable:no-any
    (window as any)[`w${ad.AD_ID_GAMELIB_SPREAD}`] =
      document.documentElement.clientWidth - 32;
    (window as any)[`h${ad.AD_ID_GAMELIB_SPREAD}`] =
      ((document.documentElement.clientWidth - 32) * 90) / 640;
    // tslint:enable:no-any
    if (isDevelopment && this.$options.asyncData) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
      ad.appendAdAll([ad.AD_ID_GAMELIB_SPREAD]);
    } else {
      ad.appendAdAll([ad.AD_ID_GAMELIB_SPREAD]);
    }
  }

  private get homeData(): HomeData | null {
    return this.$store.state.gamelib.homeData;
  }

  // tslint:disable-next-line:no-any
  private get banner(): any[] {
    return this.$store.state.gamelib.banner;
  }

  private render(h: CreateElement): VNode {
    return (
      <div
        staticClass="tg-gamelib tga-main-with-bar"
        data-group={'{ "id": "tgbus_wap_gamelib", "name": "tgbus-wap游戏库" }'}
      >
        <tg-scroll-view direction="vertical" scroller="window" back-to-top>
          <tg-flexbox gutter>
            {/* 轮播图 */}
            <tg-flexbox
              flex="100"
              data-container={
                '{ "id": "tgbus_wap_gamelib_banner","name": "游戏库banner轮播图" }'
              }
            >
              {this.banner.length > 0 ? <tg-swiper items-source={this.banner} /> : ''}
            </tg-flexbox>
            {/* 收录统计 */}
            <tg-flexbox flex="100">
              <tg-statics items-source={this.homeData ? this.homeData.statics : []} />
            </tg-flexbox>

            {/* 广告应使用与其他部分相同的布局 */}
            <div id={ad.AD_ID_GAMELIB_SPREAD} class="tga-adv-position tga-adv-gamelib-position" />

            {this.homeData
              ? this.homeData.tileWalls.map(group => <tg-tile-wall group={group} />)
              : ''}

            <tg-flexbox flex="100">
              <h3 staticClass="tga-section-title">排行榜</h3>
            </tg-flexbox>
            <tg-flexbox flex="100">
              <tg-scroll-view direction="horizontal" gap="false">
                <tg-flexbox gutter wrap="nowrap" align="stretch">
                  {this.homeData
                    ? this.homeData.rankings.map(group => (
                      <tg-flexbox flex="none">
                        <tg-ranking group={group} limit={5} />
                      </tg-flexbox>
                    ))
                    : ''}
                </tg-flexbox>
              </tg-scroll-view>
            </tg-flexbox>
          </tg-flexbox>
        </tg-scroll-view>
        <tg-footer-bar />
        <tg-header-bar />
      </div>
    );
  }
}
