/**
 * 微信js-sdk
 * 参考文档：https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
 */
import wx from 'weixin-js-sdk'
import { article } from '@src/api/cms';
import md5 from 'js-md5';

const wxApi = {
  /**
  * [wxRegister 微信Api初始化]
  * @param  {Function} callback [ready回调函数]
  */
  async wxRegister(callback) {
    const time = Math.floor(new Date().getTime() / 1000);
    const url = window.location.href;
    const params = {
      url,
      time,
      sign: md5("tgbus_wx_" + time + url)
    }
    // 这边的接口请换成你们自己的
    await article.shareAPI(params).then(res => {
      wx.config({
        debug: false, // 开启调试模式
        appId: res.appId, // 必填，公众号的唯一标识
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名，见附录1
        // 客户端6.7.2及JSSDK 1.4.0以上版本支持的 wx.updateAppMessageShareData、wx.updateTimelineShareData接口
        jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      })
    }).catch((error) => {
      // alert('config start----------------------------');
      // alert(error)
      // alert('config end----------------------------');
    })
    wx.ready((res) => {
      // 如果需要定制ready回调方法
      if (callback) {
        callback()
      }
    })
  },
  /**
  * [ShareTimeline 微信分享到朋友圈]
  * @param {[type]} option [分享信息]
  * @param {[type]} success [成功回调]
  * @param {[type]} error   [失败回调]
  */
  OnMenuShareTimeline(option) {
    const _option = {
      title: option.title, // 分享标题
      link: option.link, // 分享链接
      imgUrl: 'https:' + option.imgUrl, // 分享图标
      // imgUrl: 'https://img2.a9vg.com/i/100_100/cms3.0/2022/0815/20220815104041-5406-73095.png', // 统一使用Tgbus app logo
      success() {
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        option.error()
      }
    }
    wx.onMenuShareTimeline(_option);
  },
  /**
  * [ShareAppMessage 微信分享给朋友]
  * @param {[type]} option [分享信息]
  * @param {[type]} success [成功回调]
  * @param {[type]} error   [失败回调]
  */
  OnMenuShareAppMessage(option) {
    const _option = {
      title: option.title, // 分享标题
      desc: option.desc, // 分享描述
      link: option.link, // 分享链接
      imgUrl: 'https:' + option.imgUrl, // 分享图标
      // imgUrl: 'https://img2.a9vg.com/i/100_100/cms3.0/2022/0815/20220815104041-5406-73095.png', // 统一使用Tgbus app logo
      success: function () {
        //回调要执行的代码
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        option.error()
      }
    }
    wx.onMenuShareAppMessage(_option);
  }
}
export default wxApi