<template>
  <div class="tg-shop-item">
    <a :href="data.link">
      <div class="tg-shop-item_banner">
        <span>
          <img :src="data.banner" />
          <p class="tg-shop-item_price">￥{{data.price}}</p>
        </span>
      </div>
      <div class="tg-shop-item_title">
        <span>{{data.title}}</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
};
</script>
