<template>
  <div class="tg-live-content-card">
    <div class="classification">
      <div class="classification-item">
        <div :class="{'unline': true, 'active': isLive}"
             @click="switchType('live')">直播</div>
      </div>
      <div class="classification-item">
        <div :class="{'unline': true, 'active': !isLive}"
             @click="switchType('comment')">评论</div>
      </div>
    </div>
    <div class="liveContent"
         v-if="isLive">
      <div class="login-information clearfix">
        <!-- <div class="head-portrait"><img src="@src/assets/headimg.png"
               alt=""></div> -->
        <!-- <div class="nickname">小王</div> -->
        <div class="switching-user"
             @click="handSort">
          <fa-icon v-if="reversed"
                   icon="sort-amount-down"></fa-icon>
          <fa-icon v-if="!reversed"
                   icon="sort-amount-up"></fa-icon>
          {{this.sortText}}
        </div>
      </div>
      <tg-flexbox v-if="data.liveChannel && data.liveChannel.isChannel && data.liveChannel.channel"
                  class="live-video"
                  direction="column">
        <tg-flexbox flex="none"
                    class="live-video-title">
          <tg-flexbox flex="none"><img src="@src/assets/liveVideoIcon.png"></tg-flexbox>
          <tg-flexbox class="live-video-text">视频直播</tg-flexbox>
        </tg-flexbox>
        <tg-flexbox flex="none"
                    id="tg-video-iframe"
                    style="background-color: #FFFFFF;"
                    class="live-video-content"
                    :data-src="data.liveChannel.channel">
          <iframe src="/zhibo"
                  class="live-video-content"
                  style="margin: 0"
                  frameborder="0"></iframe>
        </tg-flexbox>
      </tg-flexbox>
      <tg-flexbox v-if="data.livevideo && data.livevideo.isChannel && data.livevideo.channel"
                  class="live-video"
                  direction="column">
        <tg-flexbox flex="none"
                    class="live-video-title">
          <tg-flexbox flex="none"><img src="@src/assets/liveVideoIcon.png"></tg-flexbox>
          <tg-flexbox class="live-video-text">视频直播</tg-flexbox>
        </tg-flexbox>
        <tg-flexbox class="live-video-content"
                    flex="none"
                    v-html="data.livevideo && data.livevideo.channel"></tg-flexbox>
      </tg-flexbox>
      <tg-flexbox class="liveContent-list"
                  gutter-small>
        <tg-flexbox flex="100"
                    v-for="item in detailData"
                    :key="item._id">
          <div class="liveContent-item">
            <div class="blogger-info clearfix">
              <img class="blogger-info-avatar"
                   src="@src/assets/headimg.png">
              <div class="blogger-info-name">{{item.username ? item.username : '火星人'}}</div>
              <div class="blogger-info-time">{{item | dateFormat(item)}}</div>
            </div>
            <div class="blogger-content">{{item.description}}</div>
            <div class="blogger-picGroup">
              <tg-flexbox gutter-small>
                <tg-flexbox flex="none"
                            v-if="item.pictures.length > 0"
                            v-for="(picurl, j) in item.pictures"
                            :key="j">
                  <img :src="item.cdn+'/104_104/'+picurl"
                       class="blogger-picGroup-img"
                       @click="previewPicture(item, j)">
                </tg-flexbox>

              </tg-flexbox>
            </div>
          </div>
        </tg-flexbox>
      </tg-flexbox>
    </div>
    <div class="liveContent"
         v-if="!isLive">
      <div class="login-information clearfix">
        <div class="comment-count">共{{commentCount}}条</div>
      </div>
      <div class="comment-list">
        <tg-live-content-card-comment :data="item"
                                      v-if="commentData.length > 0"
                                      v-for="(item) in commentData"
                                      :key="item.id"></tg-live-content-card-comment>
      </div>
    </div>
  </div>
</template>

<script>
import TgLiveContentCardComment from '@src/components/live/live-content-card-comment/TgLiveContentCardComment.vue';
export default {
  name: 'TgLiveContentCard',
  props: {
    data: {
      type: Object,
      require: true,
    },
    detailData: {
      type: Array,
      require: true,
    },
    activeId: {
      type: String,
      require: true,
    },
    commentData: {
      type: Array,
      require: true,
    },
    commentCount: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      isLive: true,
      releaseAt: -1,
      reversed: true,
      sortText: '从新到旧',
    };
  },
  methods: {
    // 图片预览
    previewPicture(item, index) {
      var obj = {
        previewPictureOk: true,
        previewDate: item,
        previewIndex: index,
      };
      this.$emit('openPreviewPicture', obj);
    },
    switchType(type) {
      if (type === 'live') {
        this.isLive = true;
      } else {
        this.isLive = false;
      }
    },
    handSort() {
      this.reversed = !this.reversed;
      if (this.reversed) {
        this.sortText = '从新到旧';
        this.releaseAt = -1;
        this.$store.dispatch('live/GETDETAILDATA', {
          id: this.activeId,
          limit: 500,
          offset: 0,
          sort: { releaseAt: this.releaseAt },
        });
      } else {
        this.sortText = '从旧到新';
        this.releaseAt = 1;
        this.$store.dispatch('live/GETDETAILDATA', {
          id: this.activeId,
          limit: 500,
          offset: 0,
          sort: { releaseAt: this.releaseAt },
        });
      }
    },
  },
  filters: {
    dateFormat: function (item) {
      if (item.hasOwnProperty('dataMessage')) {
        return item.dataMessage;
      } else if (item.releaseAt) {
        var date = new Date(item.releaseAt);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var h = date.getHours();
        var mm = date.getMinutes();
        var s = date.getSeconds();
        var val =
          y +
          '-' +
          (m > 9 ? m : '0' + m) +
          '-' +
          (d > 9 ? d : '0' + d) +
          ' ' +
          (h > 9 ? h : '0' + h) +
          ':' +
          (mm > 9 ? mm : '0' + mm) +
          ':' +
          (s > 9 ? s : '0' + s);
        return val;
      } else {
        return '';
      }
    },
  },
  components: {
    TgLiveContentCardComment,
  },
};
</script>
