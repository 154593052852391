<template>
  <div class="tg-special-search-news" v-if="show">
    <tg-scroll-view direction="vertical"
                      id="a9-special-search-news"
                      scroller="window"
                      :gap="false"
                      @bottom='onLoadMore()'>
      <div class="tg-special-search-news_box">
        <tg-e3-search/>
        <input placeholder="请输入搜索关键词" v-model="keyword" @input="enterKeyword()"/>
        <span @click="onBackClick">取消</span>
      </div>
      <div class="tg-special-search-news_keywords" v-if="showKeywords">
        <p>热门搜索</p>
        <span v-for="(item, index) in keywords" :key="index" @click="handleSearch(item)">{{item}}</span>
      </div>
      <div class="tg-special-search-news_list">
        <div v-for="(item, index) in search.news || []" :key="index" class="tg-special-search-news_list-item">
           <tg-e3-card-group v-if="channel === 'e3'"
                            :data="item" />
          <tg-special-card-group v-else :data="item" />
        </div>
        <!-- 加载更多 start -->
        <div class="tga-load-bottom">
          <span class="tga-load-bottom-tip">
            {{!search.canLoadMore ? '没有更多了' : (search.loadingMore ? '更多内容加载中……' : '')}}
          </span>
        </div>
        <!-- 加载更多 end -->
      </div>
   </tg-scroll-view>
  </div>
</template>

<script>
import TgE3CardGroup from '../../e3/card-group/TgCardGroup';
import TgSpecialCardGroup from '../card-group/TgSpecialCardGroup';
export default {
  name: 'tg-special-header-search',
  data() {
    return {
      keyword: '',
      showKeywords: true,
      timeout: null
    };
  },
  components: {
    TgE3CardGroup,
    TgSpecialCardGroup
  },
  model: {
    prop: 'show',
    event: 'toggle',
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false
      }
    },
    keywords: {
      type: Array,
      require: false,
      default() {
        return []
      }
    },
    search: {
      type: Object,
      require: false,
      default() {
        return {
          news: [],
          pageNum: 1,
          loadingMore: false,
          canLoadMore: true
        }
      }
    },
    channel: {
      type: String,
      require: false,
      default() {
        return 'e3';
      },
    },
  },
  methods: {
    onBackClick() {
      document.getElementById('special-home').style.height = 'auto'
      document.getElementById('special-home').style.overflow = 'unset'
      this.keyword = ''
      this.showKeywords = true
      this.$store.dispatch('e3/INIT_SEARCH_NEWS')
      this.$emit('toggle', false);
    },
    enterKeyword() {
      if(this.keyword.trim() == '') {
        return
      }
      const self = this
      if(self.timeout === null){
        self.timeout = setTimeout(function() {
          self.handleSearch(self.keyword)
          clearTimeout(self.timeout)
          self.timeout = null
        }, 300)
      }
    },
    onLoadMore() {
      if(!this.search.canLoadMore || this.search.loadingMore) {
        return
      }
      this.$store.dispatch('e3/FETCH_SEARCH_NEWS', this.keyword)
    },
    handleSearch(keywords) {
      this.showKeywords = false
      this.keyword = keywords
      this.$store.dispatch('e3/INIT_SEARCH_NEWS')
      this.$store.dispatch('e3/FETCH_SEARCH_NEWS', this.keyword)
    }
  }
};
</script>
