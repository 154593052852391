<template>
  <div class="tg-about-bar">
    <ul class="tg-about-bar_wrapper">
      <li
        class="tg-about-bar_item"
        v-for="item in itemsSource"
        :key="item.label"
        :class="{'is-active': currenPath === item.path}"
      >
        <a :href="item.path" class="tg-about-bar_link">{{item.label}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsSource: [
        {
          label: '关于我们',
          path: '/about',
        },
        {
          label: '联系我们',
          path: '/contact',
        },
      ],
    };
  },
  computed: {
    currenPath() {
      return this.$route.path;
    },
  },
};
</script>

