import * as types from '../../mutations-types';

export default {
  [types.E3_SET_BANNER]: (state, res) => {
    const containerId = 'tgbus_wap_e3_banner2021'
    const groups = res.groups[containerId]
    if (groups && groups.length > 0) {
      state.banner = groups[0].hasSource
    }
  },
  [types.E3_SET_EXHIBITION_NEWS]: (state, res) => {
    const containerId = 'tgbus_wap_e3_exhibitionNews2021'
    const groups = res.groups[containerId]
    if (groups && groups.length > 0) {
      state.exhibitionNews = groups[0].hasSource
    }
  },
  [types.E3_SET_RELATE]: (state, res) => {
    const containerId = 'tgbus_wap_e3_relate2021'
    const groups = res.groups[containerId]
    if (groups && groups.length > 0) {
      state.relate = groups[0].hasSource
    }
  },
  [types.E3_SET_ZONE_NEWS]: (state, { res }) => {
    state.loadingMore = false;
    if (res.list.result && res.list.result.length > 0) {
      state.zoneNews.push(...res.list.result)
    } else {
      state.canLoadMore = false;
    }
  },
  [types.E3_SET_SEARCH_KEYWORDS]: (state, res) => {
    if (res.news && res.news.length > 0) {
      let reg = new RegExp(`<a.*?>(.*?)</a>`, 'gmi')
      let keywords = [];
      res.news[0].contentraw.replace(reg, function (str, keyword) {
        keywords.push(keyword)
      })
      state.keywords = keywords
    }
  },
  [types.E3_SET_SEARCH_NEWS]: (state, res) => {
    state.search.loadingMore = false
    state.search.pageNum = state.search.pageNum + 1
    if (res.search.result && res.search.result.length > 0) {
      state.search.news.push(...res.search.result)
    } else {
      state.search.canLoadMore = false
    }
  }
}
