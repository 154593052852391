<template>
  <div>
    <tg-news-card-page-item :typeid="typeid"
                            :cardData="cardData"></tg-news-card-page-item>
  </div>
</template>

<script>
import TgNewsCardPageItem from '@src/components/news-card-page/TgNewsCardPageItem.vue';
import axios from 'axios';
export default {
  mounted() {
    var typeid = this.$route.query.typeid;
    var linkid = this.$route.query.linkid;
    axios
      .get('https://tgbus-app-xy.bestjourney.net/home/api/getNewsLinkedData', {
        params: {
          typeid,
          linkid,
        },
      })
      .then(res => {
        if (res.data.code === 200) {
          var data = res.data.data;
          if (typeid === '1') {
            this.cardData.id = data.goods_id;
            this.cardData.imgUrl = data.goods_image;
            this.cardData.title = data.goods_name;
            this.cardData.goodsInfo.goods_price = data.goods_price;
            this.cardData.goodsInfo.if_voucher_price = data.if_voucher_price;
            this.cardData.goodsInfo.if_voucher_state = data.if_voucher_state;
            this.cardData.goodsInfo.goods_marketprice = data.goods_marketprice;
            this.cardData.goodsInfo.promotion_state =
              data.goods_marketprice > data.goods_price ? 1 : 0;
          } else if (typeid === '2') {
            this.cardData.id = data.nid;
            this.cardData.imgUrl = data.banner;
            this.cardData.title = data.titlefull;
            this.cardData.newsInfo.displaydate = data.displaydate;
            this.cardData.newsInfo.author = data.author[0];
          } else if (typeid === '3') {
            this.cardData.id = data.id;
            this.cardData.imgUrl = data.link_img_url;
            this.cardData.title = data.link_title;
            this.cardData.linkUrl = data.link_url;
          } else {
            this.cardData.id = data.id;
            this.cardData.imgUrl = data.img;
            this.cardData.title = data.names.title;
            this.cardData.subtitle = data.names.subtitle;
            this.cardData.gameInfo.displaydate = data['plat-date'][0].date;
            this.cardData.gameInfo.platforms = data['plat-date'];
          }
        } else {
          console.error('请求异常，错误码：' + res.errors);
        }
      });
  },
  data() {
    return {
      // 1商品 2文章 3链接 4游戏
      typeid: this.$route.query.typeid,
      linkid: this.$route.query.linkid,
      cardData: {
        id: '',
        imgUrl: '',
        title: '',
        subtitle: '',
        linkUrl: '',
        goodsInfo: {
          goods_price: '',
          if_voucher_price: '',
          if_voucher_state: '',
          promotion_state: '',
        },
        newsInfo: {
          displaydate: '',
        },
        gameInfo: {
          displaydate: '',
          platforms: [],
        },
      },
    };
  },
  components: {
    TgNewsCardPageItem,
  },
};
</script>
