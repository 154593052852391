// tslint:disable: no-unsafe-any
import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import { Store } from 'vuex';
import VueRouter, { Route } from 'vue-router';
import * as gamelibApi from '@src/api/gamelib-api';
import { TgGameHeader } from '@src/components/gamelib/game-detail/GameHeader';
import * as thumbor from '@src/api/thumbor';
import { GalleryItem } from '@src/controls/media/gallery/Gallery';
import { TgGameDetailWrapper } from '@src/components/gamelib/game-detail/GameDetailWrapper';
import { TgGameNewsList } from '@src/components/gamelib/game-detail/GameNewsList';
import isDevelopment from '@src/utils/isDevelopment';

/**
 * Component: GameDetail
 */
@Component({
  components: {
    TgGameHeader,
    TgGameDetailWrapper,
    TgGameNewsList,
  },
})
export default class TgGameDetail extends Vue {
  // tslint:disable:no-any
  public async asyncData({
    store,
    route,
  }: {
    store: Store<any>;
    route: Route;
  }): Promise<any> {
    await store.dispatch('gamelib/GAME_DATA', { id: parseInt(route.params.id, 10) });

    // 返回 SSR 上下文，用于插入到 head 内
    const fullName: string = gamelibApi.helper.filters.gameNameFull(
      store.state.gamelib.gameData,
    );

    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: fullName,
      meta: `
      <meta name="keywords" content="${fullName},游戏库,资料库,电玩巴士,tgbus">
      <meta name="description" content="电玩巴士 游戏资料库 ${fullName}">
    `,
    };
  }
  // tslint:en able:no-any

  private async mounted(): Promise<void> {
    if (isDevelopment && this.$options.asyncData) {
      await this.$options.asyncData({ store: this.$store, route: this.$route });
    }
  }

  public get game(): gamelibApi.model.Game | null {
    return this.$store.state.gamelib.gameData;
  }

  public get screenshots(): GalleryItem[] {
    const alt: string = this.game
      ? gamelibApi.helper.filters.gameNamePrimary(this.game)
      : '';

    return this.game
      ? this.game.screenshots.map<GalleryItem>(ss => ({
        thumbnail: thumbor.resolve(
          ss.path,
          'tgm-gl-gamedetail-screenshot-thumbnail_112_63',
        ),
        origin: thumbor.resolve(ss.path, 'tgm-gl-gamedetail-screenshot_375_0'),
        alt,
      }))
      : [];
  }

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-game-detail">
        <div staticClass="tga-main">
          <tg-header-bar transparent />
          {this.game ? (
            <tg-scroll-view direction="vertical" scroller="window" back-to-top download>
              <tg-game-header
                cover={thumbor.resolve(
                  gamelibApi.helper.filters.gameCover(this.game),
                  'tgm-gl-gamedetail-cover_96_0',
                )}
                background={thumbor.resolve(
                  this.game.screenshots[0] ? this.game.screenshots[0].path : '',
                  'tgm-gl-gamedetail-screenshot_375_0',
                )}
                name-zh={gamelibApi.helper.filters.gameNamePrimary(this.game)}
                name-en={gamelibApi.helper.filters.gameNameSecondary(this.game)}
              />
              <tg-flexbox gutter>
                <tg-flexbox flex="100">
                  <h2 staticClass="tga-section-title">游戏详情</h2>
                </tg-flexbox>
                <tg-flexbox flex="100">
                  <tg-collapse>{this.game.summary_text}</tg-collapse>
                </tg-flexbox>

                <hr staticClass="tga-flexbox-separator" />

                {this.screenshots.length > 0
                  ? [
                    <tg-flexbox flex="100">
                      <h2 staticClass="tga-section-title">
                        图册（
                          {this.screenshots.length}）
                        </h2>
                    </tg-flexbox>,
                    <tg-gallery itemsSource={this.screenshots} />,
                  ]
                  : ''}

                <tg-flexbox flex="100">
                  <h2 staticClass="tga-section-title">详情</h2>
                </tg-flexbox>
                <tg-flexbox flex="100">
                  <tg-collapse expanded-label="收起详情" collapsed-label="展开详情">
                    <tg-game-detail-wrapper game={this.game} />
                  </tg-collapse>
                </tg-flexbox>

                <hr staticClass="tga-flexbox-separator" />

                {this.game.news && this.game.news.length > 0
                  ? [
                    <tg-flexbox flex="100">
                      <h2 staticClass="tga-section-title">相关文章</h2>
                    </tg-flexbox>,
                    <tg-flexbox flex="100">
                      <tg-game-news-list items-source={this.game.news} />
                    </tg-flexbox>,
                  ]
                  : ''}
              </tg-flexbox>
            </tg-scroll-view>
          ) : (
              ''
            )}
          <tg-footer-bar />
          <tg-download-app></tg-download-app>
        </div>
      </div>
    );
  }
}
