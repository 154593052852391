/*
 * mutations-types
 */

// 公共
export const COMMON_DATA = 'common/COMMON_DATA';
export const ADD_HEAT_NEWS = 'common/ADD_HEAT_NEWS';
export const SEARCH_FETCH_HOT_SEARCH = 'common/SEARCH_FETCH_HOT_SEARCH';

// home
export const HOME_FETCH_BANNER = 'home/FETCH_BANNER';
export const HOME_FETCH_HOT_NEWS = 'home/HOME_FETCH_HOT_NEWS';
export const HOME_FETCH_TODAY_INFORM = 'home/FETCH_TODAY_INFORM';
export const HOME_FETCH_HOME_ADV = 'home/FETCH_HOME_ADV';
export const HOME_FETCH_DEEP_PROFILE = 'home/FETCH_DEEP_PROFILE';
export const HOME_FETCH_YXSP = 'home/HOME_FETCH_YXSP';
export const HOME_FETCH_YXGL = 'home/HOME_FETCH_YXGL';
export const HOME_FETCH_MORE_DATA = 'home/HOME_FETCH_MORE_DATA';

export const HOME_FETCH_LIST_NEWS = 'home/HOME_FETCH_LIST_NEWS';
export const HOME_FETCH_SALE_NEWS = 'home/HOME_FETCH_SALE_NEWS';
export const HOME_FETCH_SHOP_NEWS = 'home/HOME_FETCH_SHOP_NEWS';


// gamelib banner
export const GAMELIB_FETCH_BANNER = 'gamelib/FETCH_BANNER';
export const GAMELIB_FETCH_CHANNEL_ADVERTISE = 'gamelib/FETCH_CHANNEL_ADVERTISE';

// gamelib home data
export const GAMELIB_FETCH_HOME_DATA = 'gamelib/FETCH_HOME_DATA';
// gamelib game detail
export const GAMELIB_FETCH_GAME_DATA = 'gamelib/FETCH_GAME_DATA';
// gamelib collection detail
export const GAMELIB_FETCH_COLLECTION_DATA = 'gamelib/FETCH_COLLECTION_DATA';
export const GAMELIB_FETCH_COLLECTION_DATA_APPEND =
  'gamelib/FETCH_COLLECTION_DATA_APPEND';

// general
export const GENERAL_FETCH_CHANNEL_LISTS = 'general/FETCH_CHANNEL_LISTS';
export const GENERAL_FETCH_CHANNEL_BANNER = 'general/GENERAL_FETCH_CHANNEL_BANNER';

// search
export const SEARCH_FETCH_ZIXUN_LISTS = 'search/FETCH_ZIXUN_LISTS';
export const SEARCH_FETCH_GAME_LISTS = 'search/FETCH_GAME_LISTS';
export const SEARCH_FETCH_MOBILEGAME_LISTS = 'search/SEARCH_FETCH_MOBILEGAME_LISTS'

/* 资讯文章详情 */
export const SET_ARTICLE_DETAIL = 'article/SET_ARTICLE_DETAIL';
export const SET_RELATE_GAMES = 'article/SET_RELATE_GAMES';
export const SET_RELATE_NEWS = 'article/SET_RELATE_NEWS';

// live
export const LIVE_LIST = 'live/LIVE_LIST';
export const LIVE_ITEM = 'live/LIVE_ITEM';
export const LIVE_DETAIL = 'live/LIVE_DETAIL';
export const LIVE_COMMENT = 'live/LIVE_COMMENT';
export const LIVE_COMMENT_COUNT = 'live/LIVE_COMMENT_COUNT';
export const LIVE_USER_INFO = 'live/LIVE_USER_INFO';
export const LIVE_READ_COUNT = 'live/LIVE_READ_COUNT';

// e3
export const E3_SET_BANNER = 'e3/E3_SET_BANNER';
export const E3_SET_EXHIBITION_NEWS = 'e3/E3_SET_EXHIBITION_NEWS';
export const E3_SET_RELATE = 'e3/E3_SET_RELATE';
export const E3_SET_ZONE_NEWS = 'e3/E3_SET_ZONE_NEWS';
export const E3_SET_SEARCH_KEYWORDS = 'e3/E3_SET_SEARCH_KEYWORDS';
export const E3_SET_SEARCH_NEWS = 'e3/E3_SET_SEARCH_NEWS';

// cj
export const CJ_SET_SEARCH_KEYWORDS = 'cj/CJ_SET_SEARCH_KEYWORDS';
export const CJ_SET_SEARCH_NEWS = 'cj/CJ_SET_SEARCH_NEWS';
export const CJ_SET_LIST_DATA = 'cj/CJ_SET_LIST_DATA';

// tgs
export const TGS_SET_SEARCH_KEYWORDS = 'tgs/TGS_SET_SEARCH_KEYWORDS';
export const TGS_SET_LIST_DATA = 'tgs/TGS_SET_LIST_DATA';

// 手游游戏库
export const MOBILEGAME_FETCH = 'mobileGame/MOBILEGAME_FETCH';

// 手游游戏库详情
export const MOBILEGAME_SET_DETAIL = 'mobileGameDetail/MOBILEGAME_SET_DETAIL'
