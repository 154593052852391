<template>
  <div class="tg-game-video">
    <div class="tg-game-video_title">游戏视频</div>
    <div class="tg-game-video_list">
      <div v-for="(item, index) in itemsSource.slice(0,4) || []"
           :key="index">
        <tg-news-item :data="item" />
      </div>
      <!-- 广告 start -->
      <div :id='adIdf'
           class='tg-game-video_adv-position'></div>
      <!-- 广告 end -->
      <div v-for="(item, index) in itemsSource.slice(4) || []"
           :key="index + 4">
        <tg-news-item :data="item" />
      </div>
    </div>
    <div class="tg-game-video_loadmore"
         @click="loadMore"
         v-if="loadingState === 1">加载更多</div>
    <div class="tg-game-video_loadmore"
         v-else-if="loadingState === 2">加载中...</div>
    <div class="tg-game-video_loadmore"
         v-else>没有更多内容了</div>
  </div>
</template>

<script>
import * as ad from '@src/api/ad';
export default {
  name: 'tg-game-video',
  data() {
    return {
      adIdf: ad.AD_ID_FLOW,
    };
  },
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
  },
  methods: {
    loadMore() {
      let params = {
        dataName: 'yxsp',
        tag: '游戏视频',
        loadingStateName: 'sploadingState',
        glpageNumName: 'sppageNum',
      };
      this.$store.dispatch('home/FETCH_MORE', params);
    },
  },
  computed: {
    loadingState() {
      return this.$store.state.home.sploadingState;
    },
  },
};
</script>

