<template>
  <div class='tg-relate-game'>
    <h5 class="tg-relate-game_title">相关游戏</h5>
    <div class="tg-relate-game_lists">
      <ul>
        <li v-for="item in gameMain" :key='item[0].url'>
          <a :href='"/gamelib/game/" + item[0].dbkey'>
            <dl>
              <dd>
                <div class='gameCover'>
                  <tg-uniform-scale :ratio="`${1 / 1 * 100}%`">
                    <img :src="item[0].cover[0].img_path | imgURL('tgbuswap_120mw')"
                        :alt="item[0].names.zh_CN"
                        :style="{position: 'relative', width: '100%', height: '100%', objectFit: 'cover'}">
                  </tg-uniform-scale>
                </div>
              </dd>
              <dt>
                <h6>{{item[0].names.zh_CN}}</h6>
                <p>{{item[0].names.en_US}}</p>
              </dt>
            </dl>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'gameCard',
    props: ['gameMain', 'articleMain']
  };
</script>
