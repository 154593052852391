<template>
  <tg-scroll-view direction="vertical"
                  class="tg-e3-nav-list"
                  scroller="window"
                  :gap="false"
                  @bottom='onLoadMore()'>
    <div class="tg-e3-nav-list_list">
      <div v-for="(item, index) in data || []"
           :key="index"
           class="tg-e3-nav-list_list-item">
        <tg-card-group :data="item"
                       :label="label"
                       v-if="!showBigCard(index)" />
        <tg-pic-panel :data="item"
                      v-if="showBigCard(index)" />
      </div>
    </div>
    <!-- 加载更多 start -->
    <div class="tga-load-bottom">
      <span class="tga-load-bottom-tip"
            v-if="!canLoadMore">
        没有更多了
      </span>
      <span class="tga-load-bottom-tip"
            v-else>
        {{loadingMore ? '更多内容加载中……' : '上拉加载更多'}}
      </span>
    </div>
    <!-- 加载更多 end -->
  </tg-scroll-view>
</template>

<script>
import TgCardGroup from '../card-group/TgCardGroup';
import TgPicPanel from '../pic-panel/TgPicPanel';
export default {
  data() {
    return {
      currentIndex: 0,
      label: '',
    };
  },
  components: {
    TgPicPanel,
    TgCardGroup,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    loadingMore() {
      return this.$store.state.e3.loadingMore;
    },
    canLoadMore() {
      const canLoadMore = this.$store.state.e3.canLoadMore;
      return canLoadMore;
    },
  },
  mounted() {
    const self = this;
    this.$parent.$on('changeIndex', function(index, label) {
      self.currentIndex = index;
      if (label === '前线') {
        self.label = label;
      } else if (label === '视频') {
        self.label = label;
      } else if (label === '采访') {
        self.label = label;
      } else if (label === '试玩') {
        self.label = label;
      } else {
        self.label = '';
      }
      self.onLoadMore(true);
    });
    this.$parent.$on('searchShow', function(searchShow) {
      self.searchShow = searchShow;
    });
    this.$parent.$on('sideBarShow', function(sideBarShow) {
      self.sideBarShow = sideBarShow;
    });
  },
  methods: {
    onLoadMore(isFirst = false) {
      if (this.searchShow || this.sideBarShow) {
        return;
      }
      if ((!isFirst && !this.canLoadMore) || this.loadingMore) {
        return;
      }
      if (isFirst) {
        document
          .querySelector('#tgbus_wap_e3_scroller')
          .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }
      this.$store.dispatch('e3/FETCH_MORE', { index: this.currentIndex, isFirst });
    },
    showBigCard(index) {
      // 除"最新"外 列表内容小于25的全部展示大图
      if (this.currentIndex != 0 && this.data.length < 25) {
        return true;
      }
      // "最新"中 第 1 和 第15个展示大图
      if (this.currentIndex == 0 && (index == 5 || index == 16)) {
        return true;
      }
      return false;
    },
  },
};
</script>

