// tslint:disable:no-import-side-effect no-any no-unsafe-any
import '@src/class-component-hooks';
import '@src/main.scss';

import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/free-regular-svg-icons';
import {
  faSortAmountDown,
  faSortAmountUp,
  faAngleDoubleRight,
  faLocationArrow,
  faClock,
  faUser,
  faLink,
  faTimes,
  faBars,
  faSearch,
  faEllipsisV,
  faComment,
  faThumbsUp,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import {
  fab,
  faSteam,
  faPlaystation,
  faXbox,
  faLinux,
  faWindows,
  faApple,
  faNintendoSwitch,
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faSortAmountDown,
  faSortAmountUp,
  faAngleDoubleRight,
  faLocationArrow,
  faClock,
  faUser,
  faLink,
  faTimes,
  faBars,
  faSearch,
  faLinux,
  faEllipsisV,
  faComment,
  faThumbsUp,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faWindows,
  faApple,
  // 商标与logo
  faSteam,
  faPlaystation,
  faXbox,
  faNintendoSwitch,
);

Vue.component('fa-icon', FontAwesomeIcon);

import * as controls from '@src/controls/index';
Object.entries(controls).forEach(([name, ctrl]) => Vue.component(name, ctrl as any));

import * as filters from '@src/filters/index';
Object.entries(filters).forEach(([name, filter]) => Vue.filter(name, filter));

import * as thumbor from '@src/api/thumbor';
Vue.filter('thumborUrl', thumbor.resolve);

import App from '@src/App.vue';
import { Store } from 'vuex';
import Router from 'vue-router';
import { createRouter } from '@src/router';
import { createStore } from '@src/store';

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

Vue.config.productionTip = false;

/**
 * Main
 */
export function createApp(): { app: Vue; router: Router; store: Store<any> } {
  const router: Router = createRouter();
  const store: Store<any> = createStore();

  const app: Vue = new Vue({
    router,
    store,
    render: h => h(App),
  });

  return { app, router, store };
}
