<template>
  <div class='tg-cj-list-page'
       :data-theme="'cj'+version">
    <tg-cj-nav-bar></tg-cj-nav-bar>
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    back-to-top
                    gap="false"
                    @bottom='onLoadMore()'>
      <tg-flexbox class="tg-cj-list-page_listbox">
        <tg-flexbox flex="100">
          <!-- listData start -->
          <tg-cj-list :data="listData"
                      v-if="listData && listData.length > 0"></tg-cj-list>
          <!-- listData end -->
        </tg-flexbox>
        <!-- 加载更多 start -->
        <div class="tg-cj-list-page_nodata"
             v-if="listData && listData.length > 0">
          {{loadingMore ? '更多内容加载中……' : (canLoadMore ? '上拉加载更多' : '没有更多内容了')}}
        </div>
        <!-- 加载更多 end -->
      </tg-flexbox>
    </tg-scroll-view>
  </div>
</template>

<script>
import TgCjNavBar from '../components/cj/nav-bar/TgCjNavBar';
import TgCjList from '../components/cj/list/TgCjList';
import isDevelopment from '../utils/isDevelopment.ts';
import { cjVersion, cjVersionTitle } from '../store/containers.config';
export default {
  async asyncData({ route, store }) {
    const tags = route.query.tags || route.params.tags;
    const isFirst = true;
    await Promise.all([
      store.dispatch(`cj/INIT_DATA`),
      store.dispatch(`cj/FETCH_LIST`, { tags, isFirst }),
    ]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: `ChinaJoy${cjVersionTitle}展览会专题_电玩巴士`,
      meta: `<meta name="description" content="电玩巴士ChinaJoy${cjVersionTitle}展览会专题（CJ${cjVersionTitle}）为您带来ChinaJoy新闻，ChinaJoy新游戏，ChinaJoy专访，ChinaJoy现场图片，ChinaJoy${cjVersion}精彩内容尽在tgbus CJ专题报道。">
             <meta name="keywords" content="hinaJoy${cjVersionTitle},ChinaJoy,CJ${cjVersionTitle},CJ cosplay,CJ showgirl"/>
             <base target="_blank">
             <meta name="robots" content="all"/>`,
    };
  },
  async mounted() {
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },
  computed: {
    listData() {
      return this.$store.state.cj.listData;
    },
    loadingMore() {
      return this.$store.state.cj.loadingMore;
    },
    canLoadMore() {
      return this.$store.state.cj.canLoadMore;
    },
  },
  methods: {
    onLoadMore() {
      if (this.loadingMore) {
        return;
      }
      const tags = this.$route.query.tags || this.$route.params.tags;
      this.$store.dispatch(`cj/FETCH_LIST`, { tags });
    },
  },
  components: {
    TgCjNavBar,
    TgCjList,
  },
};
</script>
