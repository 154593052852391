/**
 * Fragments for splicing graphql query
 */
// tslint:disable:no-multiline-string

export const cover: string = `
height
path
src
width
`;

export const collectionCover: string = `
height
path
width
`;

export const name: string = `
content
lang
`;

export const user: string = `
user_badge
user_id
user_name
user_url
`;

export const imageLocation: string = `
img_height
img_path
img_src
img_width
`;

export const article: string = `
article_genre
click_count
comment_count
desc
img_location {${imageLocation}}
img_location_count
like_count
parsed_content
parsed_content_main_body
publish_time
referer {
  article_genre
  click_count
  comment_count
  desc
  img_location {${imageLocation}}
  img_location_count
  like_count
  parsed_content
  parsed_content_main_body
  publish_time
  referer {
    article_genre
  }
  repost_count
  response_url
  small_img_location {${imageLocation}}
  title
  url
  user {${user}}
  user_img_location {${imageLocation}}
  video_img_location {${imageLocation}}
}
repost_count
response_url
small_img_location {${imageLocation}}
title
url
user {${user}}
user_img_location {${imageLocation}}
video_img_location {${imageLocation}}
`;

export const subCollection: string = `
id
name
`;

/**
 * WARN first_release_date will make the games array to be empty.
 */
export const subGame: string = `
id
covers {${cover}}
name
names {${name}}
genres {${subCollection}}
themes {${subCollection}}
weight
`;

export const collection: string = `
id
count
name
names {
  alias
  en_us
  formal
  ja_jp
  old
  short
  zh_cn
}
content_text
cover {${collectionCover}}
links {
  title
  url
}
`;

export const subCollectionWithGames: string = `
id
name
names {
  alias
  en_us
  formal
  ja_jp
  old
  short
  zh_cn
}
count
games(first: 100) {${subGame}}
`;

export const tags: string = `
id
name
`;

export const game: string = `
id
audio_lang
bundles {${subGame}}
category
category_name
collections {${subCollectionWithGames}}
covers {${cover}}
developers {${subCollection}}
dlcs {${subGame}}
expansions {${subGame}}
external_links {
  id
  name
  url
}
first_release_date
franchises {${subCollection}}
game {${subGame}
  genres {
    name
  }
}
game_engines {${subCollection}}
game_modes {${subCollection}}
game_status
genres {${subCollection}}
interface_lang
name
names {${name}}
news(orderBy: {field: "publish_time", direction: DESC}) {${article}}
platforms {${subCollection}}
player_perspectives {${subCollection}}
prices {
  currency
  date
  price
  store
  store_name
  type
  type_name
  url
}
publishers {
  region
  publisher {${subCollection}}
}
ratings {
  id
  contents_html
  contents_text
  name
  system
}
release_dates {
  date
  region
  platform {
    id
    name
  }
}
scores {
  aggregated_score
  aggregated_score_count
  date
  score
  score_count
  store
  store_name
  total_score
  url
}
screenshots {${cover}}
standalone_expansions {${subGame}}
storyline_html
storyline_text
subtitle_lang
summary_html
summary_text
system_requirements_html
system_requirements_text
themes {${subCollection}}
updated_at
weibos {${article}}
weight
`;
