<template>
  <div class='tg-special-swiper'>
    <div class="tg-special-swiper_container swiper-container-one">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for='item in itemsSource'
             :key='item.id'>
          <tg-flexbox>
            <tg-uniform-scale :ratio="`${9 / 16 * 100}%`">
              <a :href="item.locationlink || (('/' + channel)  + '/news/' + item.id)">
                <img :src="item.pic | imgURL('tgbuswap_360mw')"
                     :style="{position: 'relative', width: '100%', height: '100%', objectFit: 'cover'}"
                     alt="item.title">
              </a>
            </tg-uniform-scale>
          </tg-flexbox>
          <h3>
            <p class='tg-special-swiper_title'>{{item.title}}</p>
          </h3>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'tg-special-swiper',
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
    channel: {
      type: String,
      require: false,
      default() {
        return 'special';
      },
    },
  },
  mounted() {
    /* eslint-disable */
    const mySwiper = new Swiper('.swiper-container-one', {
      loop: true,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: 'tg-special-swiper_bullets',
        bulletActiveClass: 'tg-special-swiper_bullets-active'
      }
    });
  }
};
</script>
