<template>
  <div class="tg-contact-card">
    <h2 class="tg-contact-card_title">联系我们</h2>

    <p class="tg-contact-card_p">内容合作:</p>
    <p class="tg-contact-card_p">内容中心</p>
    <p class="tg-contact-card_p">邮箱：hz@donews.com</p>
    <p class="tg-contact-card_p bottom20">电话：(010)-60845018-572</p>

    <p class="tg-contact-card_p">商务、市场合作:</p>
    <p class="tg-contact-card_p bottom20">邮箱：bd@donews.com</p>

    <p class="tg-contact-card_p">广告投放:</p>
    <p class="tg-contact-card_p bottom20">邮箱：ad@donews.com</p>

    <p class="tg-contact-card_p">联系地址:</p>
    <p class="tg-contact-card_p">北京多牛互动传媒股份有限公司</p>
    <p class="tg-contact-card_p">邮政编码：100192</p>
    <p class="tg-contact-card_p bottom20">电话：010-60845018</p>
  </div>
</template>

<script>
export default {
  name: 'TgContactCard',
};
</script>









