<template>
  <div class="tg-swiper-box">
    <div class='tg-swiper-box_container swiper-container'
         id="channel-banner-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for='item in itemsSource'
             :key='item.id'>
          <tg-flexbox>
            <tg-uniform-scale :ratio="`${9 / 16 * 100}%`">
              <a :href="'/news/' + item.id">
                <img :src="item.pic | imgURL('tgbuswap_640mw')"
                     :style="{position: 'relative', width: '100%', height: '100%', objectFit: 'cover'}"
                     alt="item.title">
              </a>
            </tg-uniform-scale>
          </tg-flexbox>
          <h3>
            <p>{{item.title}}</p>
          </h3>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'tg-swiper_container',
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
  },
  mounted() {
    /* eslint-disable */
    const mySwiper = new Swiper('#channel-banner-container', {
      loop: true,
      autoplay: {
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  },
};
</script>
