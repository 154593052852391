<template>
  <div class="tg-about-card">
    <h2 class="tg-about-card_title">关于我们</h2>
    <p class="tg-about-card_p martop20">综合游戏门户　</p>
    <p class="tg-about-card_p">电玩巴士（bestjourney.net）现属多牛传媒，秉承简单游戏，快乐生活这一运营理念，从资讯、专栏、评测、攻略、泛游戏等多维度解决游戏用户的多样性需求，是一家受到国内玩家群体认可的老牌综合性游戏门户网站。</p>
    <p class="tg-about-card_p martop20">综合游戏平台</p>
    <p class="tg-about-card_p">电玩巴士（bestjourney.net）成立于2004年9月，先后吸收整合了国内著名的掌机之王 gbgba.com, psps2.com, xboxsky.com 和 17game8.com 。电玩巴士（bestjourney.net）是玩家与厂商沟通的重要桥梁，以客观公正的媒体精神和时尚亲和的表现形式，将游戏领域的新产品、及理念以独到的方式展现在受众面前，得到广大玩家的认可。</p>
  </div>
</template>

<script>
export default {
  name: 'TgAboutCard',
  props: {},
};
</script>
