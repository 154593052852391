import actions from './actions';
import mutations from './mutations';
const state = {
  banner: [],
  news: [],
  onlineGames: [],
  mobileGames: [],
  competition: [],
  interview: [],
  industryVoice: [],
  acg: [],
  elegance: [],
  keywords: [],
  loadingMore: false,
  canLoadMore: [],
  pageNum: 1,
  listData: [],
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
