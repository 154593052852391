<template>
  <div class="tg-tgs-title">
    <div class="tg-tgs-title_content">
      <span class="line-left"></span>
      <span class="title">{{title}}</span>
      <span class="line-left line-right"></span>
    </div>
    <a v-if="moreUrl"
       :href="moreUrl"
       class="tg-tgs-title_more">查看更多</a>
  </div>
</template>

<script>
export default {
  name: 'tg-tgs-title',
  props: {
    title: {
      type: String,
      require: true,
    },
    moreUrl: {
      type: String,
      default: '',
      require: false,
    },
  },
};
</script>

