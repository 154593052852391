<template>
  <div class="tg-ps5-home_title">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'tg-ps5-home-title',
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>
