 <template>
  <div class="tg-mg-detail-sk">
    <div class="tg-mg_container">
      <tg-mobile-game-title :titleData="{title: '同类热门游戏'}"></tg-mobile-game-title>
      <tg-card-item class="tg-mg-detail-sk_item"
                    v-for="(item, index) in games"
                    :key="index"
                    :itemsSource="item"></tg-card-item>
    </div>
  </div>
</template>
<script>
import TgCardItem from '@src/components/mobile-game/card-item/TgCardItem'
import TgMobileGameTitle from '@src/components/mobile-game/title/TgMobileGameTitle';
export default {
  props: {
    games: {
      type: Array,
      require: true,
    },
  },
  components: {
    TgMobileGameTitle,
    TgCardItem
  },
  methods: {
    onLoadMore() {
      this.$store.dispatch('mobileGameDetail/FETCH_MORE')
    }
  }
}
</script>
