<template>
  <div class="tg-ps5-home">
    <tg-banner-swiper :items-source="banner"
                      class="tg-ps5-home_container"
                      :data-container="dataGroup('banner')"
                      v-if='banner.length > 0'></tg-banner-swiper>
    <div v-if="ps5Tags.length > 0"
         class="tags tg-ps5-home_container">
      <a v-for="(item, index) in ps5Tags"
         :key="index"
         :href="item.link">
        <label>{{item.title}}</label>
      </a>
    </div>
    <!-- 热门 -->
    <div class="tg-ps5-home_hotnews tg-ps5-home_container"
         v-if="hotNews"
         :data-container="dataGroup('hotNews')">
      <div class="item"
           v-for="(item, index) in hotNews.slice(0,2)"
           :key="index">
        <a :href="item.locationlink == null ? '/news/' + (item.id || item.nid) : item.locationlink">
          <span class="label"><span>热门</span></span><span class="title">{{item.title}}</span>
        </a>
      </div>
    </div>
    <!-- 巴士观点 -->
    <div :data-container="dataGroup('viewpoint')">
      <tg-ps5-home-viewpoint v-if="viewpoint && viewpoint.length > 0"
                             :data="viewpoint.slice(0, 5)" />
    </div>
    <!-- PS5视频 -->
    <div class="tg-ps5-home_video tg-ps5-home_container">
      <tg-ps5-home-title title="PS5视频"></tg-ps5-home-title>
      <tg-card-info-item v-if="videos && videos[0]"
                         class='card-info-margin'
                         :items-source="videos[0]"></tg-card-info-item>
      <tg-news-item v-for="item in videos.slice(1,5) || []"
                    :key="item.nid"
                    :data="item" />
    </div>
    <!-- PS5热门新作 -->
    <div class="tg-ps5-home_newwork tg-ps5-home_container">
      <tg-ps5-home-title title="PS5热门新作"></tg-ps5-home-title>
      <tg-new-work-list :items-source="newWork"></tg-new-work-list>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import { containers, groupName, pageName } from '@src/store/modules/general/container.js'
import { mapState } from 'vuex'
import TgPS5HomeTitle from './TgPS5HomeTitle'
import TgNewWorkList from '../new-work-list/TgNewWorkList'
import TgPS5HomeViewpoint from '../ps5-home/TgPS5HomeViewpoint';
import TgBannerSwiper from '../banner-swiper/TgBannerSwiper'
import TgCardInfoItem from '@src/components/general-channel/card-info-item/TgCardInfoItem';
export default {
  name: 'tg-ps5-home',
  data() {
    return {
      containers: containers.ps5Home
    }
  },
  computed: {
    ...mapState({
      ps5Tags: state => state.general.ps5Home.ps5Tags,
      banner: state => state.general.ps5Home.banner,
      hotNews: state => state.general.ps5Home.hotNews,
      viewpoint: state => state.general.ps5Home.viewpoint,
      ps5News: state => state.general.ps5Home.ps5News,
      newWork: state => state.general.ps5Home.newWork,
      videos: state => state.general.ps5Home.videos
    })
  },
  methods: {
    dataGroup(name) {
      const currentContainer = this.containers[name]
      let data = {
        id: currentContainer.containerId,
        name: currentContainer.containerName,
      };
      return JSON.stringify(data);
    },
  },
  components: {
    TgCardInfoItem,
    TgNewWorkList,
    TgBannerSwiper,
    'tg-ps5-home-title': TgPS5HomeTitle,
    'tg-ps5-home-viewpoint': TgPS5HomeViewpoint
  }
};
</script>
