import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';

/**
 * Component: Collapse
 */
@Component
export class TgCollapse extends Vue {
  public expanded: boolean = false;

  @Prop({ type: String, default: '收起' })
  public readonly expandedLabel!: string;

  @Prop({ type: String, default: '展开' })
  public readonly collapsedLabel!: string;

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-collapse" class={{ 'is-expanded': this.expanded }}>
        <div staticClass="tg-collapse_container">
          <div staticClass="tg-collapse_content">{this.$slots.default}</div>
          <div staticClass="tg-collapse_mask" />
        </div>

        <button
          staticClass="tg-collapse_toggle"
          onClick={() => (this.expanded = !this.expanded)}
        >
          {this.expanded ? this.expandedLabel : this.collapsedLabel}
          <fa-icon
            staticClass="tg-collapse_toggle-icon"
            icon={this.expanded ? 'chevron-up' : 'chevron-down'}
          />
        </button>
      </div>
    );
  }
}
