<template>
  <div class="tg-live-detail-preview">
    <div class="swiper-container"
         v-if="previewDate && previewDate.pictures && previewDate.pictures.length > 0">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for="item in previewDate.pictures"
             :key="item">
          <div class="swiper-zoom-container">
            <img :src="previewDate.cdn+'/375_/'+item">
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'TgLiveDetailPreview',
  props: {
    previewDate: {
      type: Object,
      required: true,
    },
    previewIndex: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    var _this = this;
    // eslint-disable-next-line
    new Swiper('.swiper-container', {
      zoom: true,
      width: window.innerWidth,
      spaceBetween: 10,
      initialSlide: this.previewIndex,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      on: {
        click: function() {
          _this.clickPreviewPicture();
        },
      },
    });
  },
  methods: {
    clickPreviewPicture() {
      this.$emit('closePreviewPicture');
    },
  },
};
</script>
