<template>
  <div class='tg-e3-article-main'>
    <tg-flexbox direction="column"
                class="tg-e3-article-main_imgbox">
      <img :src="articleMain.banner | imgURL('600_')"
           alt="">
      <tg-flexbox class="tg-e3-article-main_text">{{articleMain.titlefull}}</tg-flexbox>
      <div class="tg-e3-article-main_info">
        <div class="left">
          <span class="tag">{{getTag(articleMain.tags)}}</span>
          <span class="line"></span>
          <span>{{timestampToDateTimeTitle(articleMain.displaydate)}}</span>
        </div>
        <div class="right">
          <span class="author">作者 </span>
          <span>{{articleMain.author && articleMain.author.join(' ')}}</span>
        </div>
      </div>
    </tg-flexbox>
    <div class="tg-e3-article-main_textbox">
      <div class="tg-e3-article-main_textbox-inner">
        <div class="text">{{articleMain.description}}</div>
      </div>
      <div class="left horn"></div>
      <div class="top horn"></div>
      <div class="right horn"></div>
      <div class="bottom horn"></div>
    </div>
    <div class="tg-e3-article-main_content">
      <div class='article-main-contentraw'
           v-html='articleMain.contentraw'></div>
    </div>
  </div>
</template>

<script>
import * as thumbor from '@src/api/thumbor';
export default {
  name: 'E3ArticleMain',
  props: ['articleMain'],
  data() {
    return {
      getTags: ['E3图赏', 'E3视频', 'E3采访', 'E3试玩', 'E3新闻'],
      showTags: ['前线', '视频', '采访', '试玩', '资讯'],
    };
  },
  methods: {
    timestampToDateTimeTitle(date) {
      return thumbor.timestampToDateTimeTitle(date);
    },
    getTag(tags) {
      let tag = '资讯';
      if (typeof tags !== 'string') {
        tags.forEach(item => {
          let index = this.getTags.indexOf(item);
          if (index > -1) {
            tag = this.showTags[index];
          }
        });
      }
      return tag;
    },
  },
};
</script>
