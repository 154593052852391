/**
 * Helper functions for view
 */

// tslint:disable:no-relative-imports no-reserved-keywords no-bitwise

import * as model from './model';
import * as service from './service';

// region ======== model.Name ========

/**
 * Mapping language code and name.
 */
// TODO
const languageCodeNameMap: { [key: string]: string } = {};

function gameName(game: model.Game, languageCode: string): string {
  // TODO
  return '';
}

function gameNamePrimary(game: model.Game): string {
  const name: model.Name | undefined =
    game.names.find(
      n => (n.lang === '汉语（简体）' || n.lang === 'zh-CN') && !!n.content,
    ) ||
    game.names.find(
      n => n.lang === ('英语（美国）' || n.lang === 'en-US') && !!n.content,
    );

  return name ? name.content : game.name;
}

function gameNameSecondary(game: model.Game): string {
  const nameZhCN: model.Name | undefined = game.names.find(
    n => (n.lang === '汉语（简体）' || n.lang === 'zh-CN') && !!n.content,
  );
  const nameEnUS: model.Name | undefined = game.names.find(
    n => (n.lang === '英语（美国）' || n.lang === 'en-US') && !!n.content,
  );

  return nameZhCN && nameEnUS ? nameEnUS.content : '';
}

function gameNameFull(game: model.Game): string {
  const nameZhCN: model.Name | undefined = game.names.find(
    n => (n.lang === '汉语（简体）' || n.lang === 'zh-CN') && !!n.content,
  );
  const nameEnUS: model.Name | undefined = game.names.find(
    n => (n.lang === '英语（美国）' || n.lang === 'en-US') && !!n.content,
  );

  return nameZhCN && nameEnUS
    ? `${nameZhCN.content} ${nameEnUS.content}`
    : nameZhCN
      ? nameZhCN.content
      : nameEnUS
        ? nameEnUS.content
        : game.name;
}

function collectionNameFull(collection: model.Collection): string {
  if (collection.names.zh_cn && collection.names.en_us) {
    return `${collection.names.zh_cn} ${collection.names.en_us}`;
  }
  if (collection.names.zh_cn) {
    return collection.names.zh_cn;
  }
  if (collection.names.en_us) {
    return collection.names.en_us;
  }

  return collection.name;
}

const collectionTypeNameMap: { [key: string]: string } = {
  collection: '系列',
  developer: '开发商',
  franchise: '世界观',
  game_engine: '引擎',
  game_mode: '模式',
  genre: '类型',
  keyword: '关键字',
  platform: '平台',
  player_perspective: '视角',
  publisher: '发行商',
  theme: '题材',
};

function collectionTypeName(type: keyof service.CollectionMap): string {
  return collectionTypeNameMap[type] || '';
}

// endregion

// region ======== Image ========

export interface ImageInfo {
  width?: string | number;
  height?: string | number;
  path?: string;

  img_width?: string | number;
  img_height?: string | number;
  img_path?: string;
}

function gameCover(game: model.Game): string {
  const info: model.Cover | undefined =
    game.covers && game.covers.length > 0 ? game.covers.find(c => !!c.path) : undefined;

  return info ? info.path : '';
}

// endregion

// region ======== Datetime ========

function timestampToDate(timestamp: number | string): Date {
  let date: Date;

  if (typeof timestamp === 'number') {
    date = new Date(timestamp * 1000);
  } else if (typeof timestamp === 'string') {
    const correctedTimStamp: number = Number.parseInt(timestamp, 10);
    if (isNaN(correctedTimStamp)) {
      date = new Date(timestamp);
    } else {
      date = new Date(correctedTimStamp * 1000);
    }
  } else {
    date = new Date(0);
  }

  return date;
}

function timestampToDateText(
  timestamp: number | string,
  separator: string = '-',
): string {
  const date: Date = timestampToDate(timestamp);

  return `${date.getFullYear()}${separator}${date.getMonth() +
    1}${separator}${date.getDate()}`;
}

function timestampToDateTimeText(
  timestamp: number | string,
  separator: string = '-',
): string {
  const date: Date = timestampToDate(timestamp);

  return `${date.getFullYear()}${separator}${date.getMonth() +
    1}${separator}${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

// endregion

// region ======== model.Price ========

// https://zh.wikipedia.org/wiki/ISO_4217

/**
 * Mapping currency code to name.
 */
const currencyCodeNameMap: { [key: string]: string } = {
  CNY: '人民币',
  USD: '美元',
  EUR: '欧元',
  HKD: '港元',
  JPY: '日圆',
  NTD: '新台币',
};

/**
 * Mapping currency code to symbol.
 */
const currencyCodeSymbolMap: { [key: string]: string } = {
  CNY: '¥',
  USD: '$',
  EUR: '€',
  HKD: '$',
  JPY: '¥',
  NTD: '$',
};

function currencyCodeToName(code: string): string {
  return currencyCodeNameMap[code] || '';
}

function currencyCodeToSymbol(code: string): string {
  return currencyCodeSymbolMap[code] || '';
}

function priceCentToUnitText(cent: number): string {
  let unit: string = cent.toString().padStart(3, '0');

  unit = `${unit.substring(0, unit.length - 2)}.${unit.substring(unit.length - 2)}`;

  return unit;
}

const storeNameMap: { [key: string]: string } = {
  steam_cn: 'Steam商店国区',
  indienova: 'indienova',
  pss_cn_zhs: 'PS商店国区',
  pss_hk_zhs: 'PS商店港区（简）',
  pss_hk_zht: 'PS商店港区',
  pss_us: 'PS商店美区',
  pss_jp: 'PS商店日区',
  eshop_us: '任天堂eShop美区',
  xbox_us: '微软商店美区',
  xbox_tw: '微软商店台区',
  xbox_hk: '微软商店港区',
  xbox_cn: '微软商店国区',
};

function storeName(store: string): string {
  return storeNameMap[store] || store;
}

enum PriceType {
  Other,
  Origin,
  Discount,
  Vip,
  VipDiscount,
}

const priceTypeNameMap: { [index: number]: string } = {
  [PriceType.Discount]: '折扣价',
};

function pricesLatest(prices: model.Price[]): model.Price[] {
  const map: {
    [store: string]: { [index: number]: model.Price };
  } = {};

  for (const curPrice of prices) {
    if (map[curPrice.store]) {
      if (
        !map[curPrice.store][curPrice.type] ||
        map[curPrice.store][curPrice.type].date < curPrice.date
      ) {
        map[curPrice.store][curPrice.type] = curPrice;
      }
    } else {
      map[curPrice.store] = {
        [curPrice.type]: curPrice,
      };
    }
  }

  return Object.values(map)
    .map<model.Price[]>(sm => Object.values(sm))
    .reduce((pre, cur) => {
      return cur.sort((a, b) => a.type - b.type).concat(pre);
    }, []);
}

// endregion

// region ======== Link (Website) ========

enum WebsiteID {
  official = 1,
  wikia = 2,
  wikipedia = 3,
  facebook = 4,
  twitter = 5,
  twitch = 6,
  instagram = 8,
  youtube = 9,
  iphone = 10,
  ipad = 11,
  android = 12,
  steam = 13,
  reddit = 14,
  huijiwiki = 1 << 30,
  donews,
  tgbus,
  $178,
  A9VG,
  NGA,
  gamepedia,
  liquipedia,
  weibo,
  qq,
  weixin,
  tqq,
  douban,
  deviantart,
  imdb,
}

const websiteCategoryNameMap: { [key: number]: string } = {
  [WebsiteID.official]: '官方网站',
  [WebsiteID.wikia]: 'Wikia',
  [WebsiteID.wikipedia]: 'Wikipedia',
  [WebsiteID.facebook]: 'Facebook',
  [WebsiteID.twitter]: 'Twitter',
  [WebsiteID.twitch]: 'Twitch',
  [WebsiteID.instagram]: 'Instagram',
  [WebsiteID.youtube]: 'YouTube',
  [WebsiteID.iphone]: 'Iphone',
  [WebsiteID.ipad]: 'Ipad',
  [WebsiteID.android]: 'Android',
  [WebsiteID.steam]: 'Steam',
  [WebsiteID.reddit]: 'Reddit',
  [WebsiteID.huijiwiki]: 'huiji.wiki',
  [WebsiteID.donews]: 'donews',
  [WebsiteID.tgbus]: 'tgbus',
  [WebsiteID.$178]: '$178',
  [WebsiteID.A9VG]: 'A9VG',
  [WebsiteID.NGA]: 'NGA',
  [WebsiteID.gamepedia]: 'gamepedia',
  [WebsiteID.liquipedia]: 'liquipedia',
  [WebsiteID.weibo]: 'weibo',
  [WebsiteID.qq]: 'qq',
  [WebsiteID.weixin]: 'weixin',
  [WebsiteID.tqq]: 't.qq',
  [WebsiteID.douban]: 'douban',
  [WebsiteID.deviantart]: 'deviantart',
  [WebsiteID.imdb]: 'imdb',
};

function linkCategoryName(link: model.Links): string {
  return websiteCategoryNameMap[link.id as WebsiteID] || '';
}

// endregion

/**
 * Vue filters for formating data from graphql api.
 */
// tslint:disable-next-line:typedef
export const filters = {
  gameName,
  gameNamePrimary,
  gameNameSecondary,
  gameNameFull,
  collectionNameFull,

  collectionTypeName,

  gameCover,

  timestampToDate,
  timestampToDateText,
  timestampToDateTimeText,

  currencyCodeToName,
  currencyCodeToSymbol,
  priceCentToUnitText,
  storeName,
  pricesLatest,
};

// region ======== Resolve Image Data ========

// tslint:disable:no-any no-unsafe-any
function resolveImageFields(object: any, fields: string[]): void {
  if (object === null || object === undefined) {
    return;
  }

  fields.forEach(
    key =>
      (object[key] = Array.isArray(object[key])
        ? (object[key] as ImageInfo[]).filter(img => img.path || img.img_path)
        : object[key]),
  );
}

const gameImageFields: (keyof model.Game)[] = ['covers', 'screenshots'];
const arrayElementArrayFilter: (element: any) => boolean = element =>
  Array.isArray(element);

export function resolveGameImages(game: model.Game): void {
  resolveImageFields(game, gameImageFields);

  [
    game.avatars,
    game.bundles,
    game.dlcs,
    game.expansions,
    game.game,
    game.games,
    game.standalone_expansions,
    game.system_themes,
  ]
    .filter(arrayElementArrayFilter)
    .forEach(subGames => subGames.forEach(sg => resolveImageFields(sg, gameImageFields)));

  [
    game.collections,
    game.developers,
    game.franchises,
    game.game_engines,
    game.game_modes,
    game.genres,
    game.keywords,
    game.platforms,
    game.player_perspectives,
    game.themes,
  ]
    .filter(arrayElementArrayFilter)
    .forEach(resolveCollectionsGamesImages);
}

export function resolveCollectionsGamesImages(collections: model.Collection[]): void {
  if (!Array.isArray(collections)) {
    return;
  }

  collections
    .filter(c => Array.isArray(c.games))
    .forEach(c => c.games.forEach(resolveGameImages));
}

// endregion

// tslint:disable-next-line:typedef
export const deDuplicated = {
  collection(collections: model.Collection[]): model.Collection[] {
    return collections.reduce<model.Collection[]>((result, cur) => {
      if (!result.some(c => c.id === cur.id)) {
        result.push(cur);
      }

      return result;
    }, []);
  },
};
