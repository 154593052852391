<template>
  <div class="live tga-live-main-with-bar tga-bg_lower">
    <tg-scroll-view direction="vertical" scroller="window" content-wrapper back-to-top>
      <tg-live-header :activeData="listData.slice(0, 5)" v-if="listData.length > 0"></tg-live-header>
      <tg-flexbox gutter>
        <tg-flexbox flex="100">
          <h2 class="tga-section-title">近期直播</h2>
        </tg-flexbox>
        <tg-flexbox flex="100">
          <tg-live-info-card :data="listData"></tg-live-info-card>
        </tg-flexbox>
      </tg-flexbox>
    </tg-scroll-view>
    <tg-header-bar></tg-header-bar>
  </div>
</template>

<script>
import TgLiveHeader from '@src/components/live/live-header/TgLiveHeader.vue';
import TgLiveInfoCard from '@src/components/live/live-info-card/TgLiveInfoCard.vue';
export default {
  mounted() {
    this.$store.dispatch('live/FETCH');
    window.document.title = '巴士直播站';
  },
  computed: {
    listData() {
      return this.$store.state.live.liveListData;
    },
  },
  components: {
    TgLiveHeader,
    TgLiveInfoCard,
  },
};
</script>
