<template>
  <div class="tg-header-bar">
    <div class="tg-header-bar_box">
      <div class="tg-header-bar_left">
        <a :href="logoUrl">
          <component :is="logo[channel] || logo['tgbus']"></component>
        </a>
        <p v-if="headerInfo != ''">
          <span>
            <label class="before-arrow"></label>
            <label class="content">{{headerInfo}}</label>
            <label class="after-arrow"></label>
          </span>
        </p>
      </div>
      <div class="tg-header-bar_right">
        <span class="tg-header-bar_button">
          <a :href="pcHref">
            <img src="../../../assets/header-pc.png"
                 alt />
          </a>
        </span>
        <span class="tg-header-bar_button"
              @click="toggleSearch">
          <img src="../../../assets/header-search.png"
               alt />
        </span>
        <span class="tg-header-bar_button"
              @click="toggleSideBar">
          <img src="../../../assets/header-menu.png"
               v-if="!sideBarShow" />
          <img src="../../../assets/header-close.png"
               v-if="sideBarShow" />
        </span>
      </div>
    </div>
    <tg-side-bar :sideBarShow="sideBarShow"
                 @toggleSideBar="toggleSideBar"></tg-side-bar>
    <tg-search-bar :searchBarShow="searchBarShow"
                   :channel="channel"
                   @toggleSearch="toggleSearch"></tg-search-bar>
  </div>
</template>

<script>
export default {
  props: {
    channel: {
      type: String,
      required: false,
      default: 'tgbus',
    },
    headerInfo: {
      type: String,
      required: false,
      default: '',
    },
    pcHref: {
      type: String,
      default: 'https://www.bestjourney.net',
      required: false,
    },
  },
  data() {
    return {
      sideBarShow: false,
      searchBarShow: false,
      logo: {
        tgbus: 'tg-logo-tgbus',
        ps4: 'tg-logo-tgbus',
        ns: 'tg-logo-tgbus',
        xbox: 'tg-logo-tgbus',
        tech: 'tg-logo-tech',
        android: 'tg-logo-android',
        iphone: 'tg-logo-iphone',
      },
    };
  },
  methods: {
    toggleSideBar() {
      if (this.searchBarShow) {
        this.searchBarShow = false;
      }
      this.sideBarShow = !this.sideBarShow;
    },
    toggleSearch() {
      if (this.sideBarShow) {
        this.sideBarShow = false;
      }
      this.searchBarShow = !this.searchBarShow;
    },
  },
  computed: {
    logoUrl() {
      if (this.$store.state.general.digitalArr.indexOf(this.channel) > -1) {
        return '/list/' + this.channel;
      } else {
        return '/';
      }
    },
  },
};
</script>
