<template>
  <div class="tg-no-match">
    <tg-content-wrapper>
      <tg-flexbox flex="none"
                    justify="center"
                    align="center">
        <tg-empty></tg-empty>
      </tg-flexbox>
      <tg-flexbox flex="none"
                    justify="center"
                    align="center">
        <p>没有找到符合条件的结果</p>
      </tg-flexbox>
    </tg-content-wrapper>
    
  </div>
</template>

<script>
  export default {
    name: 'tg-no-match'
  };
</script>
