<template>
  <div class="generalChannel tga-main-with-bar-generalchannel"
       data-group='{"id": "tgbus_wap_general", "name": "tgbus-wap列表页页"}'>
    <tg-header-bar :channel="channel"
                   :headerInfo="headerInfo"
                   :pcHref="pcHref"></tg-header-bar>
    <tg-nav-bar :channel="channel"
                :label="label"
                @changeLabel="changeLabel"></tg-nav-bar>
    <!-- PS5首页 START-->
    <tg-ps5-home v-if="channel === 'ps5' && label==='首页'"></tg-ps5-home>
    <!-- PS5首页 END -->
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    content-wrapper
                    back-to-top
                    v-else-if="tag">
      <tg-flexbox flex="100">
        <tg-content-list :items-source="channelNews"
                         :loadingState="loadingState"
                         :channel="channel"
                         :banner="banner"
                         :label="label"
                         :ps5Tags="ps5Tags"
                         @loadMore="onLoadMore"></tg-content-list>
      </tg-flexbox>
    </tg-scroll-view>
    <tg-flexbox flex="100"
                :gap="true"
                v-else>
      <tg-new-work-list :items-source="channelNews">
      </tg-new-work-list>
    </tg-flexbox>
    <tg-footer-bar :pcHref="pcHref"></tg-footer-bar>
  </div>
</template>

<script>
import * as ad from '@src/api/ad';
import { mapState } from 'vuex';
import isDevelopment from '@src/utils/isDevelopment';
import TgContentList from '@src/components/general-channel/content-list/TgContentList';
import TgNewWorkList from '@src/components/general-channel/new-work-list/TgNewWorkList'
import TgPS5Home from '@src/components/general-channel/ps5-home/TgPS5Home'
const channelMap = {
  ps4: {
    title: 'PS4中文网,PS4国行价格,PS4游戏 - 电玩巴士',
    meta: `
  <meta name="Description" content="电玩巴士PS4中文网为用户提供最新最全的PS4新闻资讯,PS4游戏价格,PS4游戏发售表,PS4攻略秘籍,PS4游戏视频等内容，更多精彩尽在电玩巴士ps4中文网。" />
  <meta name="Keywords" content="PS4,PS4游戏,PS4中文游戏,PS4游戏攻略,PS4国行" />
  <meta name="robots" content="all"> 
    `,
  },
  ps5: {
    title: 'PS5中文网 - PS5游戏,PS5价格,PS5发售日期 - 电玩巴士',
    meta: `
  <meta name="Description" content="电玩巴士PS5中文网为用户提供最新最全的PS5新闻资讯,PS5游戏价格,PS5游戏发售表,PS5攻略秘籍,PS5游戏视频等内容，更多精彩尽在电玩巴士ps5中文网。" />
  <meta name="Keywords" content="PS5,PS5游戏,PS5中文游戏,PS5国行" />
  <meta name="robots" content="all"> 
    `,
  },
  xbox: {
    title:
      'Xbox One中文网_XboxOne游戏下载,Xbox One国行游戏价格,Xbox One游戏发售表 - 电玩巴士',
    meta: `
  <meta name="Description" content="Xbox one中文网,提供最新最全的XboxOne游戏下载资源,Xbox新闻资讯,Xbox国行游戏价格,Xbox游戏发售表,Xbox游戏攻略秘籍,Xbox游戏视频，更多精彩内容尽在电玩巴士Xbox One中文网。" />
  <meta name="Keywords" content="Xbox,Xbox One,Xbox one x,XboxOne游戏下载,Xbox One游戏发售表,Xbox One国行,Xbox One价格,Xbox手柄" />
  <meta name="robots" content="all"> 
    `,
  },
  ns: {
    title: '任天堂Switch中文网,NS国行价格,NS主机游戏 - 电玩巴士',
    meta: `
  <meta name="Description" content="电玩巴士Switch中文网为用户提供最新最全的Switch新闻资讯,NS国行游戏价格,Switch游戏发售表,Switch游戏攻略秘籍,以及NS主机游戏视频，更多内容尽在电玩巴士Switch中文网。" />
  <meta name="Keywords" content="Switch,NS,NS主机,Switch游戏,NS游戏,国行NS" />
  <meta name="robots" content="all"> 
    `,
  },
  tech: {
    title: 'Hi科技_智享未来科技生活',
    meta: `
    <meta name="Description" content="Hi科技聚焦科技智能硬件相关内容，发现并推荐更多新鲜有趣的智能硬件和消费电子产品，关注科技数码相关资讯和行业动向，触碰、联结、智享现在与未来的科技生活。" />
    <meta name="Keywords" content="5G,IOT,消费电子,人工智能AI, 手机数码,新能源汽车,自动驾驶汽车,智能家居,电脑影音" />
    <meta name="robots" content="all">
      `,
  },
  android: {
    title: '安卓中文网_手机智能数码硬件中文资讯门户',
    meta: `
    <meta name="Description" content="安卓中文网提供原创独家的手机数码，智能硬件，消费电子产品相关新闻、评测、视频、图赏类内容，同时关注汽车科技、智能家居等未来科技生活新动向。" />
    <meta name="Keywords" content="android,安卓,安卓手机,手机评测,5G,IoT,华为,三星,小米,vivo,oppo" />
    <meta name="robots" content="all">
      `,
  },
  iphone: {
    title: 'iPhone中文网_iPhoneXS Max / iPhone8_苹果iOS新闻资讯_App Store游戏软件',
    meta: `
    <meta name="Description" content="iPhone中文网为大家提供iPhoneXS Max，iPhone8，XR等新品苹果iPhone手机的新闻资讯，iPhone软件游戏推荐，苹果周边硬件评测等内容，及时报道苹果手机及其他iOS智能硬件的最新动态。" />
    <meta name="Keywords" content="iPhone,iPhoneXS,iPhoneX,iPhone8,iPhone游戏,iPhone配件,iOS" />
    <meta name="robots" content="all">
      `,
  },
  ol: {
    title: '巴士网游_好玩的网游排行榜 热门网络游戏大全 - 电玩巴士',
    meta: `
    <meta name="Description" content="巴士网游频道为您带来新鲜的网游资讯，网游评测，网游视频，网络游戏测试表和丰富的原创栏目，更有新网游测试发号活动等你参加。" />
    <meta name="Keywords" content="网游,热门网络游戏,网游新闻,网游资讯,网游评测,网游原创栏目,网游活动,网游礼包,网游视频,网游测试表" />
    <meta name="robots" content="all">
      `,
  },
  shouji: {
    title: '巴士手游_好玩的手机游戏下载_热门手游排行榜 - 电玩巴士',
    meta: `
    <meta name="Description" content="电玩巴士手游频道为用户提供好玩的热门手机游戏下载、好玩的单机手游下载，为用户提供手机游戏资讯、攻略、视频和原创相关内容。" />
    <meta name="Keywords" content="手游,手机平台,手机排行榜,热门手游,好玩的单机手游,苹果手游" />
    <meta name="robots" content="all">
      `,
  },
};

export default {
  async asyncData({ route, store }) {
    const channel = route.params.list
    let params = {
      channel,
      tag: store.state.general.defaultTag[route.params.list],
    };
    await Promise.all([
      store.dispatch('general/INIT_DATA'),
      channel === 'ps5' ? store.dispatch('general/FETCH_PS_HOME', params) : store.dispatch('general/FETCH', params)
    ]);
    // 返回 SSR 上下文，用于插入到 head 内
    let headInfo = channelMap[route.params.list];
    headInfo.notCommonEnd = true;
    return headInfo;
  },
  async mounted() {
    window.w9029 = document.documentElement.clientWidth - 32;
    window.h9029 = ((document.documentElement.clientWidth - 32) * 90) / 640;
    const AD_ID_ARR = [ad.AD_ID_FLOW];
    if (isDevelopment && this.$options.asyncData) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
      this.tag && ad.appendAdAll(AD_ID_ARR);
    } else {
      this.tag && ad.appendAdAll(AD_ID_ARR);
    }
  },
  data() {
    const channel = this.$route.params.list
    let label = '资讯'
    switch (channel) {
      case 'ol':
        label = '新闻'
        break;
      case 'shouji':
        label = '新闻'
        break;
      case 'ps5':
        label = '首页'
        break;
    }
    return {
      label: label,
      tag: this.$store.state.general.defaultTag[channel],
      channel,
    };
  },
  computed: {
    ...mapState({
      ps5Tags: state => state.general.ps5Tags,
      banner: state => state.general.banner,
      channelNews: state => state.general.channelNews,
      loadingState: state => state.general.loadingState
    }),
    headerInfo() {
      const listContent = this.$route.params.list;
      let info = '';
      switch (listContent) {
        case 'ns':
          info = 'Switch中文网';
          break;
        case 'xbox':
          info = 'Xbox One中文网';
          break;
        case 'ps4':
          info = 'PS4中文网';
          break;
        case 'ps5':
          info = 'PS5中文网';
          break;
        case 'shouji':
          info = '手游频道';
          break;
        case 'ol':
          info = '网游频道';
          break;
      }
      return info;
    },
    pcHref() {
      return `https://${this.$route.params.list}.bestjourney.net`;
    },
  },
  methods: {
    async changeLabel(label, tag) {
      this.label = label;
      this.tag = tag;
      let params = {
        channel: this.channel,
        tag: tag,
      };
      this.$store.dispatch('general/INIT_DATA');
      if (this.channel === 'ps5') {
        switch (label) {
          case '首页':
            await this.$store.dispatch('general/FETCH_PS_HOME')
            break;
          case '新作':
            await this.$store.dispatch('general/FETCH_NEWWORK')
            break;
          default:
            await this.$store.dispatch('general/FETCH', params)
            break;
        }
      } else {
        await this.$store.dispatch('general/FETCH', params)
      }
      ad.appendAdAll([ad.AD_ID_FLOW]);
    },
    onLoadMore() {
      let params = {
        channel: this.channel,
        tag: this.tag,
      };
      this.$store.dispatch('general/FETCH_MORE', params);
    },
  },
  components: {
    TgContentList,
    TgNewWorkList,
    'tg-ps5-home': TgPS5Home
  },
};
</script>
