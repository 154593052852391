/**
 * 推荐页面
 */
import { home, article } from '../../../api/cms';
import { tgsC, tgsVersion as version } from '../../containers.config';
import * as types from '../../mutations-types';
const nid = '134802'
const initRequest = (container) => {
  let promises = [];
  for (var key in container) {
    let query = `{
      groups(group_name:"tgbus_wap_tgs${version}") {
        status`;
    for (var item of container[key]) {
      query =
        query +
        `
      ${item.containerId}:containers(container_id:"${item.containerId}") {
          container_name
          container_id
          hasSource {
            ${item.hasSource}
          }
        }`;
    }
    query =
      query +
      `
      }
    }`;
    promises.push(home.homeAPI({ query: query }));
  }
  return promises;
};
const getContainerName = (name) => {
  return `tgbus_wap_tgs${version}_${name}`
}
export default {
  INIT_DATA: ({ state }) => {
    state.banner = [];
    state.news = [];
    state.interview = [];
    state.video = [];
    state.report = [];
    state.elegance = [];
    state.keywords = [];
    state.pageNum = 1;
    state.loadingMore = false;
    state.canLoadMore = true;
    state.listData = [];
  },
  FETCH: async ({ state, commit }) => {
    let promise = null;
    let promises = initRequest(tgsC);
    const queryArticle = `{
      news (id: ${nid}) {
        contentraw
      }
    }`;
    promise = Promise.all([
      ...promises,
      article.articleAPI({ query: queryArticle }),
    ]).then(resC => {
      if (
        resC[0] &&
        resC[0].groups[getContainerName('banner')] &&
        resC[0].groups[getContainerName('banner')].length > 0
      ) {
        state.banner = resC[0].groups[getContainerName('banner')][0].hasSource;
      }
      if (
        resC[1] &&
        resC[1].groups[getContainerName('news')] &&
        resC[1].groups[getContainerName('news')].length > 0
      ) {
        state.news = resC[1].groups[getContainerName('news')][0].hasSource;
      }
      if (
        resC[2] &&
        resC[2].groups[getContainerName('interview')] &&
        resC[2].groups[getContainerName('interview')].length > 0
      ) {
        state.interview = resC[2].groups[getContainerName('interview')][0].hasSource;
      }
      if (
        resC[3] &&
        resC[3].groups[getContainerName('video')] &&
        resC[3].groups[getContainerName('video')].length > 0
      ) {
        state.video = resC[3].groups[getContainerName('video')][0].hasSource;
      }
      // if (
      //   resC[4] &&
      //   resC[4].groups[getContainerName('report')] &&
      //   resC[4].groups[getContainerName('report')].length > 0
      // ) {
      //   state.report = resC[4].groups[getContainerName('report')][0].hasSource;
      // }
      // if (
      //   resC[5] &&
      //   resC[5].groups[getContainerName('elegance')] &&
      //   resC[5].groups[getContainerName('elegance')].length > 0
      // ) {
      //   state.elegance = resC[5].groups[getContainerName('elegance')][0].hasSource;
      // }
      // commit(types.TGS_SET_SEARCH_KEYWORDS, resC[6]);
      commit(types.TGS_SET_SEARCH_KEYWORDS, resC[4]);
    });
    return promise;
  },

  FETCH_LIST: ({ commit, state }, { tags, isFirst = false }) => {
    state.loadingMore = true;
    state.pageNum = state.pageNum + 1;
    if (isFirst) {
      state.pageNum = 1;
      state.listData = [];
      state.canLoadMore = true;
    }
    let queryMore =
      `{
      list (website: "tgbus", tags: ["${tags.split(',').join('","')}"]) {
        total
        result(start: ` +
      state.pageNum +
      `, offset: 25){
          nid
          banner
          tags
          titlefull
          displaydate
          locationlink
        }
      }
    }`;
    const queryArticle = `{
      news (id: ${nid}) {
        contentraw
      }
    }`;
    let promise = null;
    promise = Promise.all([
      home.homeAPI({ query: queryMore }),
      article.articleAPI({ query: queryArticle }),
    ]).then(res => {
      commit(types.TGS_SET_LIST_DATA, { res: res[0] });
      commit(types.TGS_SET_SEARCH_KEYWORDS, res[1]);
    });
    return promise;
  },
};
