<script>
import Spinner from './spinner';

export default {
  name: 'ReadMore',

  functional: true,

  components: {
    Spinner,
  },

  props: {
    show: Boolean,
    msg: String,
  },

  render(h, { props }) {
    const show = props.show;
    const msg = props.msg;
    return (
      <div
        class="loading"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
        }}
      >
        <Spinner show={show} />
        <span>{msg || '加载中...'}</span>
      </div>
    );
  },
};
</script>
