import * as types from '../../mutations-types';

export default {
  [types.SEARCH_FETCH_HOT_SEARCH]: (state, res) => {
    if (res.news && res.news.length > 0) {
      let reg = new RegExp(`<a.*?>(.*?)</a>`, 'gmi');
      let keywords = [];
      res.news[0].contentraw.replace(reg, function(str, keyword) {
        keywords.push(keyword);
      });
      state.hotSearchKeyWords = keywords;
    }
  },
};
