<template>
  <div class="contact">
    <tg-header-bar></tg-header-bar>
    <tg-about-bar></tg-about-bar>
    <tg-contact-card></tg-contact-card>
    <tg-footer-bar :pcHref="pcHref"></tg-footer-bar>
  </div>
</template>
<script>
import TgContactCard from '@src/components/about/TgContactCard.vue';
export default {
  mounted() {},
  computed: {
    pcHref() {
      return `https://www.bestjourney.net/about/contact`;
    },
  },
  components: {
    TgContactCard,
  },
};
</script>
