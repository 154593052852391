import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import { Group } from '@src/components/gamelib';
import * as thumbor from '@src/api/thumbor';

/**
 * Component: TileWall
 */
@Component
export class TgTileWall extends Vue {
  @Prop({ type: Object, required: true })
  public readonly group!: Group;

  private render(h: CreateElement): VNode {
    return (
      <tg-flexbox staticClass="tg-tile-wall" flex="100" gutter>
        <tg-flexbox flex="100">
          <h2 staticClass="tga-section-title">{this.group.title}</h2>
        </tg-flexbox>
        <tg-flexbox flex="100">
          <tg-scroll-view direction="horizontal" gap="false">
            <tg-flexbox gutter-small wrap="nowrap">
              {this.group.items.map(item => (
                <tg-flexbox
                  staticClass="tg-tile-wall_item"
                  tag="a"
                  href={`/gamelib/game/${item.id}`}
                  direction="column"
                >
                  <img
                    staticClass="tg-tile-wall_cover"
                    src={thumbor.resolve(item.cover, 'tgm-gl-tilewalls-cover_100_100')}
                    alt={item.nameZh}
                  />
                  <h3 staticClass="tg-tile-wall_name">
                    <div staticClass="tg-tile-wall_name-zh">{item.nameZh}</div>
                    <div staticClass="tg-tile-wall_name-en">{item.nameEn}</div>
                  </h3>
                </tg-flexbox>
              ))}
            </tg-flexbox>
          </tg-scroll-view>
        </tg-flexbox>
      </tg-flexbox>
    );
  }
}
