const state = {
  title: 'TGBUS - 电玩巴士',
  meta: {},
};

export default {
  namespaced: true,
  state,
  mutations: {
    META_DATA: (state, data) => {
      state.title =
        data && data.title ? `${data.title} - TGBUS - 电玩巴士` : 'TGBUS - 电玩巴士';
      state.meta = data.meta || {};
    },
  },
};
