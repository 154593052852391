import { createAPI } from './index';
import { createLiveAPI, createCommentAPI, createUserAPI } from './live/index';
import { api } from './gamelib-api';
import * as codepitAPI from './codepit-api';
const commonApi = {
  post: data => createAPI('', 'post', data),
};
let strategycmsBaseURL = 'https://strategycms-xy.bestjourney.net'
export const setStrategycmdBaseURL = (baseURL) => {
  strategycmsBaseURL = baseURL
}
const home = {
  homeAPI: data => createAPI('', 'post', data),
  recommend: data => createAPI('', 'post', data),
  game: data => createAPI('', 'post', data),
  ps4: data => createAPI('', 'post', data),
  shop: data => createAPI(`${strategycmsBaseURL}/strategic/group`, 'get', data),
  gameGraphqi: data => api.get(data)
};
const gamelib = {
  gamelibAPI: data => createAPI('', 'post', data),
};

const general = {
  generalAPI: data => createAPI('', 'post', data),
  newWork: data => codepitAPI.fetchGameDataRaw('/api/collections/get/tgbus_PS5_TopGame?token=0b71c560198929beedb1d9e81901b9', 'get', data),
};

const article = {
  articleAPI: data => createAPI('', 'post', data),
  shareAPI: data => createAPI('https://tgbus-user-xy.bestjourney.net/api/wechatshare', 'post', data)
};

const live = {
  listDataAPI: data =>
    createLiveAPI('https://tgbus-live-xy.bestjourney.net/lives', 'get', data),
  itemDataAPI: (data, id) =>
    createLiveAPI('https://tgbus-live-xy.bestjourney.net/lives/' + id, 'get', data),
  detailDataAPI: (data, id) =>
    createLiveAPI(
      'https://tgbus-live-xy.bestjourney.net/lives/' + id + '/contents',
      'get',
      data,
    ),
  readCountAPI: (data, id) =>
    createLiveAPI('https://www.bestjourney.net/api/live/getReadCount/' + id, 'get', data),
};

const comment = {
  commentDataAPI: data =>
    createCommentAPI('https://tgbus-comment-xy.bestjourney.net/api/comment', 'post', data),
  commentCountAPI: data =>
    createCommentAPI(
      'https://tgbus-comment-xy.bestjourney.net/api/comment/count',
      'post',
      data,
    ),
};

const user = {
  userinfoAPI: data =>
    createUserAPI('https://tgbus-user-xy.bestjourney.net/api/user/info', 'post', data),
};
let mgBaseURL = 'https://mobilegamedb-cms.bestjourney.net'
export const setMobileGameBaseURL = (baseURL) => {
  mgBaseURL = baseURL
}
// 手游游戏库
const mobileGame = {
  filters: data => createAPI(`${mgBaseURL}/api/index/cate`, 'get', data),
  getGames: data => createAPI(`${mgBaseURL}/api/index`, 'get', data),
}

const mobileGameDetail = {
  detail: gameid => createAPI(`${mgBaseURL}/api/index/gameshouyou/wap/${gameid}`, 'get'),
  categoryRange: (gameid) => createAPI(`${mgBaseURL}/api/index/catepoints?id=${gameid}`, 'get'),
  sameDeveloper: (gameid) => createAPI(`${mgBaseURL}/api/index/cpname?id=${gameid}`, 'get'),
  recommends: (data) => createAPI(`${mgBaseURL}/api/index/recommend`, 'get', data)
}
export { commonApi, home, general, gamelib, article, live, comment, user, mobileGame, mobileGameDetail };
