/**
 * 推荐页面
 */
import { general } from '../../../api/cms';
import { generalC } from '../../containers.config.js';
import { containers, groupName, pageName } from './container.js'
import * as types from '../../mutations-types';
import { fetchPS5RcommendTagsDataRaw } from '@src/api/codepit-api';
const initRequest = container => {
  let query = `{
    groups(group_name:"tgbus_wap_general") {
      status`;
  for (var item of container) {
    query =
      query +
      `
    ${item.containerId}:containers(container_id:"${item.containerId}") {
        container_name
        container_id
        hasSource {
          ${item.hasSource}
        }
      }`;
  }
  query =
    query +
    `
    }
  }`;
  return general.generalAPI({ query: query });
};

const commonSource = `
  nid
  banner
  titlefull
  tags
  displaydate
  locationlink
  reviewscore
`
const listQuery = ({ tags = [], channelId = null, start = 1, offset = 25, hasSource = commonSource }) => {
  return `{
    list (website: "tgbus", tags: ["${tags.join('","')}"], channelid: ${channelId}) {
      total
      result(start: ${start}, offset: ${offset}){
        ${hasSource}
      }
    }
  }`;
}

export default {
  INIT_DATA: ({ state }) => {
    state.channelNews = [];
    state.banner = [];
    state.ps5Home = {
      ps5Tags: [],
      banner: [],
      hotNews: [],
      viewpoint: [],
      ps5News: [],
      newWork: [],
      videos: []
    }
  },
  FETCH: async ({ commit, state }, params = {}) => {
    state.loadingState = 2;
    let promise = null;
    let query = null;
    let channelId = null;
    if (state.gamenArr.indexOf(params.channel) < 0) {
      channelId = state.changeChannelId[params.channel];
    }
    query = listQuery({ tags: params.tag.split(','), channelId })
    let channelBannerPromise = initRequest(generalC[params.channel]);
    const allPromise = [
      general.generalAPI({ query: query }),
      channelBannerPromise,
    ]
    promise = Promise.all(allPromise).then(resC => {
      const [lists, banner, tags] = resC
      commit(types.GENERAL_FETCH_CHANNEL_LISTS, lists);
      commit(types.GENERAL_FETCH_CHANNEL_BANNER, {
        res: banner,
        channel: params.channel,
      });
    });
    return promise;
  },
  FETCH_NEWWORK: ({ state }) => {
    state.loadingState = 2;
    return general.newWork().then(res => {
      state.channelNews = res.entries || [];
      state.loadingState = 3;
    });
  },
  FETCH_PS_HOME: ({ state }) => {
    const containerKeys = Object.keys(containers.ps5Home)
    const containersPs5Home = []
    containerKeys.forEach(key => {
      containersPs5Home.push(containers.ps5Home[key])
    })
    const promises = [
      fetchPS5RcommendTagsDataRaw(),
      general.generalAPI({ query: listQuery({ tags: ['PS5'] }) }),
      general.generalAPI({ query: listQuery({ tags: ['游戏视频', 'PS5'] }) }),
      general.newWork(),
      initRequest(containersPs5Home)
    ];
    return Promise.all(promises).then(resC => {
      const [
        ps5Tags,
        ps5News,
        videos,
        newWork,
        containerData
      ] = resC
      containerKeys.forEach(name => {
        const containerName = `${groupName}_${pageName}_${name}`
        const groupData = containerData.groups[containerName]
        state.ps5Home[name] = groupData && groupData.length > 0 && groupData[0].hasSource || []
      })
      state.ps5Home.ps5Tags = ps5Tags.entries || []
      state.ps5Home.ps5News = ps5News.list.result || []
      state.ps5Home.videos = videos.list.result || []
      state.ps5Home.newWork = newWork.entries || []
    })
  },
  FETCH_MORE: async ({ commit, state }, params = {}) => {
    let promise = null;
    let queryMore = null;
    state.loadingState = 2;
    state.pageNum = state.pageNum + 1;
    let TagNameArr = params.tag.split(',').join('","');
    let channelId = null;
    if (state.gamenArr.indexOf(params.channel) < 0) {
      channelId = state.changeChannelId[params.channel];
    }
    queryMore =
      `{
      list (website: "tgbus", tags: ["` +
      TagNameArr +
      `"], channelid: ` +
      channelId +
      `) {
        total
        result(start: ` +
      state.pageNum +
      `, offset: 25){
          nid
          banner
          titlefull
          tags
          displaydate
          locationlink
        }
      }
    }`;

    promise = Promise.all([general.generalAPI({ query: queryMore })]).then(resC => {
      commit(types.GENERAL_FETCH_CHANNEL_LISTS, resC[0]);
    });
    return promise;
  },
};
