<template>
  <div class="tg-mg-title">
    <div class="tg-mg-title_leftline"></div>
    <div class="tg-mg-title_rightline"></div>
    <div class="tg-mg-title_title">{{titleData.title}}</div>
    <a class="tg-mg-title_more"
       target="_blank"
       v-if="titleData.moreUrl"
       :href="titleData.moreUrl">更多+</a>
  </div>
</template>
<script>
export default {
  props: {
    titleData: {
      type: Object,
      default: {
        title: '',
        moreUrl: ''
      }
    }
  }
}
</script>
