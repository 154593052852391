<template>
  <div class="tg-walkthrough">
    <div class="tg-walkthrough_title">游戏攻略</div>
    <div class="tg-walkthrough_list">
      <div v-for="(item, index) in itemsSource || []"
           :key="index">
        <tg-news-item :data="item" />
      </div>
    </div>

    <div class="tg-walkthrough_loadmore"
         @click="loadMore"
         v-if="loadingState === 1">加载更多</div>
    <div class="tg-walkthrough_loadmore"
         v-else-if="loadingState === 2">加载中...</div>
    <div class="tg-walkthrough_loadmore"
         v-else>没有更多内容了</div>
  </div>
</template>

<script>
export default {
  name: 'tg-walkthrough',
  data() {
    return {};
  },
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
  },
  methods: {
    loadMore() {
      let params = {
        dataName: 'yxgl',
        tag: '游戏攻略',
        loadingStateName: 'glloadingState',
        glpageNumName: 'glpageNum',
      };
      this.$store.dispatch('home/FETCH_MORE', params);
    },
  },
  computed: {
    loadingState() {
      return this.$store.state.home.glloadingState;
    },
  },
};
</script>

