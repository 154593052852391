import Vue from 'vue';
import Router from 'vue-router';
import Gamelib from '@src/views/Gamelib/Gamelib';
import GameDetail from '@src/views/Gamelib/GameDetail';
import CollectionDetail from '@src/views/Gamelib/CollectionDetail';
import Test from '@src/views/ViewTest.vue';
import Home from '@src/views/Home.vue';
import HomeRecomend from '@src/components/home/recommend/TgHomeRecommend.vue';
import HomeRange from '@src/components/home/range/TgHomeRange.vue';
import HomeSales from '@src/components/home/sales/TgHomeSales.vue';
import HomeShop from '@src/components/home/shop/TgHomeShop.vue';
import HomeE32021 from '@src/components/home/e3-2021/TgHomeE32021.vue';
import E3 from '@src/views/E3.vue';
import Cj from '@src/views/Cj.vue';
import Tgs from '@src/views/special/tgs/Tgs.vue';
import StarAwards from '@src/views/special/star-awards/index.vue';
import StarAwards2022 from '@src/views/special/star-awards2022/index.vue';
import StarAwards2023 from '@src/views/special/star-awards2023/index.vue';
import Search from '@src/views/Search.vue';
import NotFound from '@src/views/NotFound.vue';
import Download from '@src/views/Download.vue';
import MallDownload from '@src/views/MallDownload.vue';
import NYJDownload from '@src/views/NYJDownload.vue';
import NewsDetail from '@src/views/NewsDetail.vue';
import E3NewsDetail from '@src/views/E3NewsDetail.vue';
import CjNewsDetail from '@src/views/CjNewsDetail.vue';
import NewsCardPage from '@src/views/NewsCardPage.vue';
import CjList from '@src/views/CjList.vue';
import TgsNewsDetail from '@src/views/special/tgs/TgsNewsDetail.vue';
import TgsList from '@src/views/special/tgs/TgsList.vue';
import GeneralChannel from '@src/views/GeneralChannel.vue';
import Live from '@src/views/Live.vue';
import LiveDetail from '@src/views/LiveDetail.vue';
import About from '@src/views/About.vue';
import Contact from '@src/views/Contact.vue';
import MobileGame from '@src/views/MobileGame.vue';
import MobileGameDetail from '@src/views/MobileGameDetail.vue';
import AboutPrivacy from '@src/views/about/privacy.vue'; // tgbus app中引用H5端 隐私协议
import AboutService from '@src/views/about/service.vue'; // tgbus app中引用H5端 服务协议
import AboutReport from '@src/views/about/report.vue'; // tgbus app中引用H5端 预报流程
Vue.use(Router);

/**
 * Router
 * 注意：为保证纯服务端渲染，项目中不是用 `<router-link/>` 组件，而直接使用原生 `<a/>` 标签
 */
export function createRouter(): Router {
  return new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [
      {
        path: '/test',
        component: Test,
      },
      {
        path: '/',
        component: Home,
        children: [
          { path: '', component: HomeRecomend },
          { path: 'range', component: HomeRange },
          { path: 'sales', component: HomeSales },
          { path: 'shop', component: HomeShop },
          { path: 'e32021', component: HomeE32021 },
        ],
      },
      {
        path: '/gamelib',
        name: 'gamelib',
        component: Gamelib,
      },
      {
        path: '/gamelib/game/:id',
        name: 'gamelib-game',
        component: GameDetail,
      },
      {
        path: '/gamelib/:type/:id',
        name: 'gamelib-collection',
        component: CollectionDetail,
      },
      {
        path: '/search/:q',
        name: 'search',
        component: Search,
      },
      {
        path: '/search/:q/:channelid',
        name: 'search_channelid',
        component: Search,
      },
      {
        path: '/download',
        name: 'download',
        component: Download,
      },
      {
        path: '/download_tgmall',
        name: 'mallDownload',
        component: MallDownload,
      },
      {
        path: '/nyj2020',
        name: 'nyj2020',
        component: NYJDownload,
      },
      {
        path: '/tasa2021',
        name: 'tasa2021',
        component: StarAwards,
      },
      {
        path: '/tasa2022',
        name: 'tasa2022',
        component: StarAwards2022,
      },
      {
        path: '/tasa2023',
        name: 'tasa2023',
        component: StarAwards2023,
      },
      {
        path: '/about/privacy',
        name: 'AboutPrivacy',
        component: AboutPrivacy,
      },
      {
        path: '/about/service',
        name: 'AboutService',
        component: AboutService,
      },
      {
        path: '/about/report',
        name: 'AboutReport',
        component: AboutReport,
      },
      {
        path: '/news/:newsid',
        name: 'newsDetail',
        component: NewsDetail,
      },
      {
        path: '/embedcard',
        name: 'embedcard',
        component: NewsCardPage,
      },
      {
        path: '/e3/news/:newsid',
        name: 'e3NewsDetail',
        component: E3NewsDetail,
      },
      {
        path: '/chinajoy2019/news/:newsid',
        name: 'CjNewsDetail2019',
        component: CjNewsDetail,
      },
      {
        path: '/chinajoy/news/:newsid',
        name: 'CjNewsDetail',
        component: CjNewsDetail,
      },
      {
        path: '/tgs/news/:newsid',
        name: 'TgsNewsDetail',
        component: TgsNewsDetail,
      },
      {
        path: '/list/:list',
        name: 'list',
        component: GeneralChannel,
      },
      {
        path: '/live',
        name: 'live',
        component: Live,
      },
      {
        path: '/live/:id',
        name: 'live-detail',
        component: LiveDetail,
      },
      {
        path: '/about',
        name: 'about',
        component: About,
      },
      {
        path: '/contact',
        name: 'contact',
        component: Contact,
      },
      {
        path: '/e3',
        name: 'e3',
        component: E3,
      },
      {
        path: '/chinajoy',
        name: 'cj',
        component: Cj,
      },
      {
        path: '/chinajoy/list/:tags',
        name: 'cj-list',
        component: CjList,
      },
      {
        path: '/tgs',
        name: 'tgs',
        component: Tgs,
      },
      {
        path: '/tgs/list/:tags',
        name: 'tgs-list',
        component: TgsList,
      },
      {
        path: '/mobilegamedb',
        name: 'mobilegamedb',
        component: MobileGame,
      },
      {
        path: '/mobilegame/:gameid',
        name: 'mobilegame-detail',
        component: MobileGameDetail,
      },
      {
        path: '*',
        name: 'NotFound',
        component: NotFound,
      },
    ],
  });
}
