<script>
import Spinner from './spinner'

export default {
  name: 'ReadMore',

  functional: true,

  components: {
    Spinner
  },

  props: {
    show: Boolean
  },

  render(h, { props }) {
    const show = props.show

    return (
      <div class="loading" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px'
      }}>
        <Spinner show={show} />
        <span>加载中...</span>
      </div>
    )
  }
}
</script>
