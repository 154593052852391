/* eslint-disable no-unused-expressions */


// 当年的日期不显示年
export const timestampToTimeNoThisYear = timestamp => {
  if (!timestamp) {
    return '';
  }
  var date = new Date(timestamp * 1000); // 时间戳为10位需*1000
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? '0' + d : d;
  var h = date.getHours();
  h = h < 10 ? '0' + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? '0' + minute : minute;
  var nowDate = new Date();
  var nowY = nowDate.getFullYear();
  if (nowY == y) {
    return m + '-' + d + ' ' + h + ':' + minute;
  } else {
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
  }
};

export const timestampToTime = timestamp => {
  if (!timestamp) {
    return '';
  }
  var date = new Date(timestamp * 1000); // 时间戳为10位需*1000
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? '0' + d : d;
  var h = date.getHours();
  h = h < 10 ? '0' + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? '0' + minute : minute;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
};

export const timestampToTimeNoYear = timestamp => {
  if (!timestamp) {
    return '';
  }
  var date = new Date(timestamp * 1000); // 时间戳为10位需*1000
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? '0' + d : d;
  var h = date.getHours();
  h = h < 10 ? '0' + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? '0' + minute : minute;
  return m + '-' + d + ' ' + h + ':' + minute;
};

export const timestampToDate = timestamp => {
  if (!timestamp) {
    return '';
  }
  var date = new Date(timestamp * 1000); // 时间戳为10位需*1000
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? '0' + d : d;
  return y + '-' + m + '-' + d;
};
/**
 * 将图片路径拼接成真实 CDN URL
 *
 * 缩略图平台：
 * http://thumbor-proxy-xy.bestjourney.net/admin#/
 *
 * size:
 * a9wap_240mw ：banner
 * a9wap_360mw ：卡片
 * a9wap_120mw ：新闻详情
 * a9wap_120x72fixed ：新闻列表小图
 *
 * - xyoss.g.com.cn
 * - img2.a9vg.com
 */
export const imgURL = (path, size) => {
  // return '//img2.a9vg.com/i/' + size + path;
  return '//xyoss.g.com.cn/xy-production' + path;
};

export const compareDate = array => {
  if (array.length === 0) return ''
  var timeArr = []
  for (var item of array) {
    timeArr.push(item.release_date_timestamp)
  }
  for (var i = 0;i < timeArr.length;i++) {
    for (var j = i + 1;j < timeArr.length;j++) {
      if (timeArr[i] > timeArr[j]) {
        var tmp = timeArr[i]
        timeArr[i] = timeArr[j]
        timeArr[j] = tmp
      }
    }
  }
  var timestamp = timeArr[0]
  if (!timestamp) {
    return ''
  }
  var date = new Date(timestamp * 1000) // 时间戳为10位需*1000
  var Y = date.getFullYear() + '-'
  var M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  return Y + M + D
}
