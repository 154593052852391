/**
 * 推荐页面
 */
import { home, article, general } from '../../../api/cms';
import { cjVersion, cjC } from '../../containers.config';
import * as types from '../../mutations-types';
const initRequest = (container, version) => {
  let promises = [];
  for (var key in container) {
    let query = `{
      groups(group_name:"tgbus_wap_cj${version}") {
        status`;
    for (var item of container[key]) {
      query =
        query +
        `
      ${item.containerId}:containers(container_id:"${item.containerId}") {
          container_name
          container_id
          hasSource {
            ${item.hasSource}
          }
        }`;
    }
    query =
      query +
      `
      }
    }`;
    promises.push(home.homeAPI({ query: query }));
  }
  return promises;
};

const createGetContainerName = (version) => {
  return (name) => {
    return `tgbus_wap_cj${version}_${name}`
  }
}

export default {
  INIT_DATA: ({ state }) => {
    state.banner = [];
    state.news = [];
    state.onlineGames = [];
    state.mobileGames = [];
    state.competition = [];
    state.interview = [];
    state.industryVoice = [];
    state.acg = [];
    state.elegance = [];
    state.keywords = [];
    state.pageNum = 1;
    state.loadingMore = false;
    state.canLoadMore = true;
    state.listData = [];
  },
  FETCH: async ({ state, commit }) => {
    let promise = null;
    let promises = initRequest(cjC, cjVersion);
    const queryArticle = `{
      news (id: 50023) {
        contentraw
      }
    }`;
    const getContainerName = createGetContainerName(cjVersion)
    promise = Promise.all([
      ...promises,
      article.articleAPI({ query: queryArticle }),
    ]).then(resC => {
      if (
        resC[0] &&
        resC[0].groups[getContainerName('banner')] &&
        resC[0].groups[getContainerName('banner')].length > 0
      ) {
        state.banner = resC[0].groups[getContainerName('banner')][0].hasSource;
      }
      if (
        resC[1] &&
        resC[1].groups[getContainerName('news')] &&
        resC[1].groups[getContainerName('news')].length > 0
      ) {
        state.news = resC[1].groups[getContainerName('news')][0].hasSource;
      }
      if (
        resC[2] &&
        resC[2].groups[getContainerName('onlineGames')] &&
        resC[2].groups[getContainerName('onlineGames')].length > 0
      ) {
        state.onlineGames = resC[2].groups[getContainerName('onlineGames')][0].hasSource;
      }
      if (
        resC[3] &&
        resC[3].groups[getContainerName('mobileGames')] &&
        resC[3].groups[getContainerName('mobileGames')].length > 0
      ) {
        state.mobileGames = resC[3].groups[getContainerName('mobileGames')][0].hasSource;
      }
      if (
        resC[4] &&
        resC[4].groups[getContainerName('competition')] &&
        resC[4].groups[getContainerName('competition')].length > 0
      ) {
        state.competition = resC[4].groups[getContainerName('competition')][0].hasSource;
      }
      if (
        resC[5] &&
        resC[5].groups[getContainerName('interview')] &&
        resC[5].groups[getContainerName('interview')].length > 0
      ) {
        state.interview = resC[5].groups[getContainerName('interview')][0].hasSource;
      }
      if (
        resC[6] &&
        resC[6].groups[getContainerName('industryVoice')] &&
        resC[6].groups[getContainerName('industryVoice')].length > 0
      ) {
        state.industryVoice = resC[6].groups[getContainerName('industryVoice')][0].hasSource;
      }
      if (
        resC[7] &&
        resC[7].groups[getContainerName('acg')] &&
        resC[7].groups[getContainerName('acg')].length > 0
      ) {
        state.acg = resC[7].groups[getContainerName('acg')][0].hasSource;
      }
      if (
        resC[8] &&
        resC[8].groups[getContainerName('elegance')] &&
        resC[8].groups[getContainerName('elegance')].length > 0
      ) {
        state.elegance = resC[8].groups[getContainerName('elegance')][0].hasSource;
      }
      commit(types.CJ_SET_SEARCH_KEYWORDS, resC[9]);
    });
    return promise;
  },

  FETCH_LIST: ({ commit, state }, { tags, isFirst = false }) => {
    state.loadingMore = true;
    state.pageNum = state.pageNum + 1;
    if (isFirst) {
      state.pageNum = 1;
      state.listData = [];
      state.canLoadMore = true;
    }
    let queryMore =
      `{
      list (website: "tgbus", tags: ["${tags.split(',').join('","')}"]) {
        total
        result(start: ` +
      state.pageNum +
      `, offset: 25){
          nid
          banner
          tags
          titlefull
          displaydate
          locationlink
        }
      }
    }`;
    return home.homeAPI({ query: queryMore }).then(res => {
      commit(types.CJ_SET_LIST_DATA, { res });
    });
  },
};
