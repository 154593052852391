<template>
  <div class="tg-search-nav">
    <div class="tg-search-nav_box">
      <div class="tg-search-nav_return-button">
        <img src="../../../assets/search/left-arrow.png"
             onClick="history.go(-1)" />
      </div>
      <div class="tg-search-nav_input">
        <div class="is-search-icon">
          <tg-e3-search />
        </div>
        <input type="text"
               placeholder="请输入关键字"
               v-model="keyword"
               @keyup.enter="handleKeyup()" />
        <div class="is-close-icon"
             @click="keyword=''">
          <img src="../../../assets/search/search-clear.png" />
        </div>
      </div>
      <div @click="handleChannel()"
           class="tg-search-nav_search-button">
        <router-link class="tg-search-nav_search"
                     :to="keyword == '' ? '' : {path:'/search/'+(keyword) + ($route.params.channelid ? '/' + $route.params.channelid : '')}">搜索</router-link>
      </div>
    </div>
    <div class="tg-search-nav_wrapper-box"
         v-if="!!!$route.params.channelid || hiChannelIds.indexOf($route.params.channelid) == -1">
      <ul class="tg-search-nav_wrapper">
        <li v-for="(item, key) in channels"
            :key="key"
            @click="handleChannel(channels, key, true)">
          <span v-if="key !== 'current'"
                class="tg-search-nav_item"
                :to="{path:'/search/'+(keyword)}"
                :class="{'is-active': channels.current == key}">
            {{item.label}}
          </span>
        </li>
      </ul>
      <ul class="tg-search-nav_category">
        <li v-for="(value, key, index) in cChannels.children"
            :key="index"
            :class="{'is-active': cChannels.current === key}"
            @click="handleChannel(cChannels, key)">
          <span class="tg-search-nav_link"
                :to="{path:'/search/'+(keyword)}">{{value.label}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tg-search-nav',
  data() {
    return {
      keyword: this.$route.params.q
    };
  },
  created() {
    this.keyword = this.$route.params.q;
  },
  props: {
    hiChannelIds: {
      type: Array,
      required: true,
    },
    channels: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleChannel(channels, key, isParent) {
      if (channels) {
        if (this.keyword == '' || channels.current === key) {
          return;
        }
        channels.current = key
      }
      this.$emit('changeChannel', this.cChannels.children[this.cChannels.current]);
    },
    handleKeyup(channel) {
      if (this.keyword == '') {
        return;
      }
      this.$router.push({
        path:
          '/search/' +
          this.keyword +
          (this.$route.params.channelid ? '/' + this.$route.params.channelid : ''),
      });
      this.handleChannel(channel);
    },
  },
  computed: {
    cChannels() {
      return this.channels[this.channels.current]
    }
  }
};
</script>
