<template>
  <div class='notFound tga-main-with-bar'>
    <tg-header-bar></tg-header-bar>
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    :gap="false">
      <tg-flexbox>
        <tg-not-found></tg-not-found>
      </tg-flexbox>
    </tg-scroll-view>
  </div>
</template>

<script>
import TgNotFound from '@src/components/not-found/TgNotFound';
export default {
  components: {
    TgNotFound,
  },
};
</script>
