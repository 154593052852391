import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import BScroll from 'better-scroll';

/**
 * Component: ContentWrapper
 */
@Component
export class TgContentWrapper extends Vue {
  @Prop({ type: Boolean, default: false })
  public readonly immersive!: boolean;

  private scroll?: BScroll;

  @Prop({ type: Boolean, default: false })
  public readonly betterScroll!: boolean;

  // @Watch('betterScroll')
  private initBetterScroll(value: boolean): void {
    if (value) {
      const scroll: BScroll = new BScroll(this.$refs.container, {
        probeType: 1,
        click: true,
      });
      scroll.on('scrollEnd', position => {
        scroll.refresh();
        if (
          this.$refs.content.clientHeight + position.y <
          this.$refs.container.clientHeight + 10
        ) {
          this.$emit('load');
        }
      });

      this.scroll = scroll;
    }
  }

  @Prop({ type: Boolean, default: false })
  public readonly loading!: boolean;

  @Watch('loading')
  private watchLoading(value: boolean): void {
    if (!this.loading && this.scroll) {
      this.scroll.refresh();
    }
  }

  private mounted(): void {
    this.initBetterScroll(this.betterScroll);
  }

  public get classes(): ClassName {
    return [
      {
        'is-immersive': this.immersive,
      },
    ];
  }

  public $refs!: {
    container: Element;
    content: Element;
  };

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-content-wrapper" class={this.classes}>
        <div staticClass="tg-content-wrapper_container" ref="container">
          <div staticClass="tg-content-wrapper_content" ref="content">
            {this.$slots.default}
            {this.betterScroll ? (
              <div staticClass="tg-content-wrapper_bottom">
                <tg-logo-two />
                <span staticClass="tg-content-wrapper_bottom-tip">
                  {this.loading ? '加载中……' : '下拉加载更多'}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}
