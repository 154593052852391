<template>
  <div class="tg-mg tga-main-with-bar-newsdetail"
       v-if="showContent">
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    content-wrapper
                    :gap="false"
                    back-to-top>
      <tg-mobile-game-detail :itemsSource="game"
                             v-if="game && Object.keys(game).length > 0"></tg-mobile-game-detail>
      <tg-mobile-game-recommend :games="recommends"
                                @loadMore="loadRecommend"></tg-mobile-game-recommend>
      <tg-mobile-game-same-kind :games="categoryRange"></tg-mobile-game-same-kind>
      <tg-mobile-game-sd :games="sameDeveloper"></tg-mobile-game-sd>
    </tg-scroll-view>
    <tg-footer-bar :pcHref="'//shouji.bestjourney.net/gamedb/' + $route.params.gameid"></tg-footer-bar>
    <tg-header-bar headerInfo="手游游戏库"
                   :pcHref="'//shouji.bestjourney.net/gamedb/' + $route.params.gameid"></tg-header-bar>
  </div>
</template>

<script>
import TgMobileGameRecommend from '@src/components/mobile-game-detail/recommend/TgMobileGameRec';
import TgMobileGameSD from '@src/components/mobile-game-detail/same-developer/TgMobileGameSD';
import TgMobileGameSK from '@src/components/mobile-game-detail/same-kind/TgMobileGameSK';
import TgMobileGameDetail from '@src/components/mobile-game-detail/detail/TgMobileGameDetail';
import isDevelopment from '@src/utils/isDevelopment';
export default {
  data() {
    return {
      recPage: 1,
      showContent: false
    };
  },
  async asyncData({ route, store }) {
    // let channelName = route.params.channel;
    let gameid = route.params.gameid;
    await Promise.all([
      store.dispatch('mobileGameDetail/INIT_DATA'),
      store.dispatch('mobileGameDetail/FETCH', gameid),
    ]);
    const game = store.state.mobileGameDetail.game;
    // 返回 SSR 上下文，用于插入到 head 内
    let headInfo = {
      title: `${game.shouyou_name} | 手机游戏库_手游频道 - 电玩巴士`,
      notCommonEnd: true,
      meta: `
    <meta name="Description" content="电玩巴士手游游戏库为用会提供${game.shouyou_name}下载、资讯、礼包、排行等信息。" />
    <meta name="Keywords" content="${game.shouyou_name} ${game.shouyou_name}下载, ${game.shouyou_name}礼包, ${game.shouyou_name}攻略" />
    <meta name="robots" content="all"> 
      `,
    };

    return headInfo;
  },
  created() {
    this.loadRecommend();
  },
  methods: {
    loadRecommend(params) {
      this.$store.dispatch('mobileGameDetail/FETCH_REC', {
        name: this.game.shouyou_name,
        ...params,
      });
    },
  },
  components: {
    TgMobileGameDetail,
    TgMobileGameRecommend,
    'tg-mobile-game-sd': TgMobileGameSD,
    'tg-mobile-game-same-kind': TgMobileGameSK,
  },
  computed: {
    game() {
      return this.$store.state.mobileGameDetail.game;
    },
    categoryRange() {
      return this.$store.state.mobileGameDetail.categoryRange;
    },
    recommends() {
      return this.$store.state.mobileGameDetail.recommends;
    },
    sameDeveloper() {
      return this.$store.state.mobileGameDetail.sameDeveloper;
    },
    errorInfo() {
      return this.$store.state.mobileGameDetail.errorInfo;
    },
  },
  beforeMount() {
    if (this.errorInfo.length > 0) {
      location.href = '/404';
    }
    // 文章已下线
    if (this.game.status === 0) {
      location.href = '/404'
    }
  },
  mounted() {
    if (!isDevelopment && this.game.status !== 0) {
      this.showContent = true
    }
    if (isDevelopment && this.$options.asyncData) {
      this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },
};
</script>
