<template>
  <div class="tg-live-detail-header-card">
    <tg-flexbox>
      <a>
        <div class="banner-info">
          <img :src="data.cdn+'/375_154/'+data.cover"
               class="banner-info-img">
          <div :class="{'banner-info-title': true, 'jijiang': data.status === 1, 'over': data.status === 3}">
            <span class="state"
                  v-if="data.status === 1">即将开始</span>
            <span class="state"
                  v-if="data.status === 2">正在直播</span>
            <span class="state"
                  v-if="data.status === 3">已结束</span>
            <span class="discuss">
              <fa-icon icon="comment"></fa-icon> {{data.commentSize}}条讨论
            </span>
          </div>
        </div>
        <div class="introduce">
          <div class="introduce-title">{{data.title}}</div>
          <div class="introduce-time-position">
            <span class="time"
                  v-if='data.liveTime && data.liveTime[0] !== ""'>
              <fa-icon icon="clock"></fa-icon> {{data.liveTime[0]}}</span>
            <span class="location">
              <svg style="position:relative; top:4px;" t="1719453265735" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7128" width="20" height="20"><path d="M512 437.4c-41.2 0-74.6 33.4-74.6 74.6 0 41.3 33.4 74.7 74.6 74.7 41.2 0 74.7-33.4 74.7-74.7 0-41.2-33.5-74.6-74.7-74.6z" p-id="7129" fill="#707070"></path><path d="M512 213.3C264.6 213.3 64 512 64 512s200.6 298.7 448 298.7S960 512 960 512 759.4 213.3 512 213.3z m0 485.4c-103.1 0-186.7-83.6-186.7-186.7S408.9 325.3 512 325.3 698.7 408.9 698.7 512 615.1 698.7 512 698.7z" p-id="7130" fill="#707070"></path></svg> {{readCount}}</span>
          </div>
          <div class="introduce-content">{{data.description}}</div>
        </div>
      </a>
    </tg-flexbox>
  </div>
</template>

<script>
export default {
  name: 'TgLiveDetailHeader',
  props: {
    data: {
      type: Object,
      require: true,
    },
    readCount: {
      type: Number,
      require: true,
    }
  },
};
</script>
