<template>
  <div class="tg-e3-search-news"
       v-if="show"
       id="tg-e3-search-news">
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    :gap="false"
                    @bottom='onLoadMore()'>
      <div class="tg-e3-search-news_box">
        <tg-e3-search />
        <input placeholder="请输入搜索关键词"
               v-model="keyword"
               @input="enterKeyword()" />
        <span @click="onBackClick">取消</span>
      </div>
      <div class="tg-e3-search-news_keywords"
           v-if="showKeywords">
        <p>热门搜索</p>
        <span v-for="(item, index) in keywords"
              :key="index"
              @click="handleSearch(item)">{{item}}</span>
      </div>
      <div class="tg-e3-search-news_list">
        <div v-for="(item, index) in search.news || []"
             :key="index"
             class="tg-e3-search-news_list-item">
          <e3-tg-card-group v-if="channel === 'e3'"
                            :data="item" />
          <cj-tg-card-group v-else
                            :data="item" />
        </div>
        <!-- 加载更多 start -->
        <div class="tga-load-bottom">
          <span class="tga-load-bottom-tip">
            {{!search.canLoadMore ? '没有更多了' : (search.loadingMore ? '更多内容加载中……' : '')}}
          </span>
        </div>
        <!-- 加载更多 end -->
      </div>
    </tg-scroll-view>
  </div>
</template>

<script>
import E3TgCardGroup from '../card-group/TgCardGroup';
import CjTgCardGroup from '../../cj/card-group/TgCardGroup';
export default {
  name: 'tg-e3-slide-bar',
  data() {
    return {
      keyword: '',
      showKeywords: true,
      timeout: null,
      scroller: null,
    };
  },
  components: {
    E3TgCardGroup,
    CjTgCardGroup
  },
  model: {
    prop: 'show',
    event: 'toggle',
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    keywords: {
      type: Array,
      require: false,
      default() {
        return [];
      },
    },
    search: {
      type: Object,
      require: false,
      default() {
        return {
          news: [],
          pageNum: 1,
          loadingMore: false,
          canLoadMore: true,
        };
      },
    },
    channel: {
      type: String,
      require: false,
      default() {
        return 'e3';
      },
    },
  },
  mounted() {
    const self = this;
    this.scroller = document.getElementById('tg-e3-search-news');
    window.onscroll = function () {
      if (!self.show) {
        return;
      } // 变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight; // 变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight; // 滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        // 写后台加载数据的函数
        self.onLoadMore();
      }
    };
  },
  methods: {
    onBackClick() {
      document.getElementById('e3-home').style.height = 'auto';
      document.getElementById('e3-home').style.overflow = 'unset';
      this.keyword = '';
      this.showKeywords = true;
      this.$store.dispatch('e3/INIT_SEARCH_NEWS');
      this.$emit('toggle', false);
    },
    enterKeyword() {
      if (this.keyword.trim() == '') {
        return;
      }
      const self = this;
      if (self.timeout === null) {
        self.timeout = setTimeout(function () {
          self.handleSearch(self.keyword);
          clearTimeout(self.timeout);
          self.timeout = null;
        }, 300);
      }
    },
    onLoadMore() {
      if (!this.search.canLoadMore || this.search.loadingMore) {
        return;
      }
      this.$store.dispatch('e3/FETCH_SEARCH_NEWS', this.keyword);
    },
    handleSearch(keywords) {
      this.showKeywords = false;
      this.keyword = keywords;
      this.$store.dispatch('e3/INIT_SEARCH_NEWS');
      this.$store.dispatch('e3/FETCH_SEARCH_NEWS', this.keyword);
    },
  },
};
</script>
