import Vue, { CreateElement, VNode } from 'vue';
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import * as gamelibApi from '@src/api/gamelib-api';

/**
 * Component: CollectionHeader
 */
@Component
export class TgCollectionHeader extends Vue {
  @Prop({ type: Object, required: true })
  public readonly collection!: gamelibApi.model.Collection;

  public get mainTitle(): string {
    return (
      this.collection.names.zh_cn || this.collection.names.en_us || this.collection.name
    );
  }
  public get subTitle(): string {
    return this.collection.names.zh_cn ? this.collection.names.en_us || '' : '';
  }

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-collection-header">
        <h1 staticClass="tg-collection-header_main-title">{this.mainTitle}</h1>
        <h2 staticClass="tg-collection-header_sub-title">{this.subTitle}</h2>
      </div>
    );
  }
}
