 <template>
  <div class="tg-mg-filter"
       :class="{'more-filter': showMoreFilter}">
    <div class="tg-mg_container">
      <tg-mobile-game-title :titleData="{title: '游戏分类'}"></tg-mobile-game-title>
      <ul class="tg-mg-filter_list">
        <li class="tg-mg-filter_item"
            :class="{'is-active': selected === null}">
          <span @click="checkFilter()">全部</span>
        </li>
        <li v-for="(item, index) in filters"
            :key="index"
            :class="{'is-active': selected === item.value}"
            class="tg-mg-filter_item">
          <span @click="showMoreFilter = true"
                class="open"
                v-if="index === 10 && !showMoreFilter">更多</span>
          <span @click="showMoreFilter = false"
                class="close"
                v-else-if="index === filters.length - 1 && showMoreFilter">收起</span>
          <span @click="checkFilter(item)"
                v-else>{{item.name}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import emptyVue from '../list/empty.vue'
import TgMobileGameTitle from '@src/components/mobile-game/title/TgMobileGameTitle';
export default {
  data() {
    return {
      selected: null,
      showMoreFilter: false
    }
  },
  computed: {
    filters() {
      return this.$store.state.mobileGame.filters;
    }
  },
  methods: {
    checkFilter(item) {
      if (item) {
        this.selected = item.value
      } else {
        this.selected = null
      }
      let params = {}
      if (this.selected) {
        params.category_id = this.selected
      }
      emptyVue.$emit('fetchGames', params)
    }
  },
  components: {
    TgMobileGameTitle
  }
}
</script>
