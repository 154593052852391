import * as types from '../../mutations-types';

export default {
  [types.LIVE_LIST]: (state, res) => {
    if (res && res.length > 0) {
      state.liveListData = res;
    } else {
      state.liveListData = [];
    }
  },
  [types.LIVE_ITEM]: (state, res) => {
    if (res) {
      state.liveItemData = res;
    } else {
      state.liveItemData = {};
    }
  },
  [types.LIVE_DETAIL]: (state, res) => {
    if (res) {
      state.liveDetailData = res;
    } else {
      state.liveDetailData = [];
    }
  },
  [types.LIVE_COMMENT]: (state, res) => {
    if (res.code === 200) {
      state.commentData = res.data;
    } else {
      state.commentData = [];
    }
  },
  [types.LIVE_COMMENT_COUNT]: (state, res) => {
    if (res.code === 200) {
      state.commentCount = res.data && res.data.total;
    } else {
      state.commentCount = 0;
    }
  },
  [types.LIVE_USER_INFO]: (state, res) => {
    if (res.code === 200) {
      state.commentUserInfo = res.data;
    } else {
      state.commentUserInfo = [];
    }
  },
  [types.LIVE_READ_COUNT]: (state, res) => {
    if (res) {
      state.liveReadCount = res.readcount;
    } else {
      state.liveReadCount = 0;
    }
  },
};
