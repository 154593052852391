<template>
  <div class="tg-hot-news">
    <div class="tg-hot-news_item"
         v-for="(item, index) in itemsSource.slice(0,2)"
         :key="index">
      <a :href="item.locationlink == null ? '/news/' + (item.id || item.nid) : item.locationlink">
        <span class="label"><span>热门</span></span><span class="title">{{item.titleshort}}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tg-hot-news',
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
  },
};
</script>

