<!-- 当前页：电玩巴士年货节 -->
<template>
  <div class="tg-nyjdownload"
       @click="openOrDownload">
    <img class="bapic"
         src="../../../assets/nyj/nyj_bg.png"
         alt="">
    <!-- <div class="tg-nyjdownload_blessingbagmask"
         v-show="isShow">
      <div class="votingtips">
        <div class="close">
          <div class="img-box"
               @click.stop="closeModel"><img src="../../../assets/nyj/tip_close.png"
                 alt=""></div>
        </div>
        <p>请下载APP参与活动赢取奖</p>
        <img @click="openOrDownload"
             class="ikonwbt"
             src="../../../assets/nyj/tip_iknow.png"
             alt="">
      </div>
    </div> -->
  </div>
</template>

<script>
import { downloadApp, deviceType, downloadUrl } from '@src/utils/downloadApp';
export default {
  name: 'tg-nyjdownload-page',
  data() {
    return {
      deviceType: null,
      isShow: false,
    };
  },
  beforeMount() {
    this.deviceType = deviceType();
  },
  methods: {
    openModel() {
      this.isShow = true;
    },
    closeModel() {
      this.isShow = false;
    },
    openOrDownload() {
      this.isShow = false;
      !this.deviceType && (this.deviceType = deviceType());
      const { deviceType } = this;
      const downloadInfo = downloadUrl[deviceType];
      downloadInfo &&
        downloadApp({
          downloadUrl: downloadInfo.download,
          openApp: downloadInfo.openApp,
        });
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.windowScroll); // 销毁滚动事件
  },
};
</script>
