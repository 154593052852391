/**
 * 推荐页面
 */
import { live, comment, user } from '../../../api/cms';
import * as types from '../../mutations-types';

export default {
  FETCH: async ({ commit, state }, params = {}) => {
    const query = {
      limit: 20,
      offset: 0,
      status: [1, 2, 3],
    };
    return live.listDataAPI({ query: query }).then(res => {
      commit(types.LIVE_LIST, res);
    });
  },
  FETCHBYID: async ({ commit, state }, params = {}) => {
    return live.itemDataAPI({}, params.id).then(res => {
      commit(types.LIVE_ITEM, res);
    });
  },
  GETDETAILDATA: async ({ commit, state }, params = {}) => {
    var id = params.id;
    if (params.hasOwnProperty('id')) {
      delete params['id'];
    }
    return live.detailDataAPI(params, id).then(res => {
      commit(types.LIVE_DETAIL, res);
    });
  },
  GETCOMMENTDATA: async ({ commit, state }, params = {}) => {
    return comment.commentDataAPI(params).then(res => {
      commit(types.LIVE_COMMENT, res);
    });
  },
  GETCOMMENTCOUNT: async ({ commit, state }, params = {}) => {
    return comment.commentCountAPI(params).then(res => {
      commit(types.LIVE_COMMENT_COUNT, res);
    });
  },
  GETUSERINFO: async ({ commit, state }, params = {}) => {
    return user.userinfoAPI(params).then(res => {
      commit(types.LIVE_USER_INFO, res);
    });
  },
  FETCHLIVECOUNT: async ({ commit, state }, params = {}) => {
    return live.readCountAPI({}, params.id).then(res => {
      commit(types.LIVE_READ_COUNT, res);
    });
  },
};
