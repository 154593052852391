<template>
  <div class="download tga-main">
    <tg-mall-download-page></tg-mall-download-page>
  </div>
</template>

<script>
import TgMallDownloadPage from '@src/components/download/download-page/TgMallDownloadPage';
export default {
  components: {
    TgMallDownloadPage,
  },
};
</script>
