import * as types from '../../mutations-types';

export default {
  [types.GENERAL_FETCH_CHANNEL_LISTS]: (state, res) => {
    if (res.list.result && res.list.result.length === 25) {
      state.channelNews.push(...res.list.result);
      state.loadingState = 1;
    } else if (
      res.list.result &&
      res.list.result.length > 0 &&
      res.list.result.length < 25
    ) {
      state.channelNews.push(...res.list.result);
      state.loadingState = 3;
    } else {
      state.loadingState = 3;
    }
  },
  [types.GENERAL_FETCH_CHANNEL_BANNER]: (state, { res, channel }) => {
    if (
      res.groups['tgbus_wap_general_' + channel + '_wall'] &&
      res.groups['tgbus_wap_general_' + channel + '_wall'].length > 0
    ) {
      state.banner = res.groups['tgbus_wap_general_' + channel + '_wall'][0].hasSource;
    }
  },
};
