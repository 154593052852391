<template>
  <div :class="['tg-e3-header-bar', 'is-transparent', channel]"
       id="e3-home-slide">
    <a class="tg-e3-header-bar_left"
       :href="goUrl[channel]">
      <img :src="logoImg[channel]" />
    </a>
    <div class="tg-e3-header-bar_right">
      <button class="tg-e3-header-bar_button">
        <a @click="toggleSearch()"
           class="cj-search">
          <tg-e3-search v-if="channel === 'e3'" />
          <img v-else
               :src="require(`../../../assets/special/search.png`)"
               alt="">
        </a>
        <a @click="toggleSideBar()"
           class="cj-menu">
          <tg-e3-menus v-if="channel === 'e3'" />
          <img v-else
               :src="require(`../../../assets/special/menu.png`)"
               alt="">
        </a>
      </button>
    </div>
    <tg-e3-slide-bar v-model="sideBarShow"
                     :channel="channel"
                     id="e3-home-slide"></tg-e3-slide-bar>
    <tg-e3-search-news :keywords="keywords"
                       :search="search"
                       :channel="channel"
                       v-model="searchShow"
                       id="e3-home-search"></tg-e3-search-news>
  </div>
</template>

<script>
import TgE3SlideBar from '../slide-bar/TgE3SlideBar';
import TgE3SearchNews from '../search-news/TgE3SearchNews';

export default {
  name: 'tg-e3-header-bar',
  data() {
    return {
      sideBarShow: false,
      searchShow: false,
      logoImg: {
        e3: require('../../../assets/e3/e3-header.png'),
        cj: require(`../../../assets/cj/cj-header.png`),
        tgs: require(`../../../assets/tgs/tgs-header.png`),
      },
      goUrl: {
        e3: '/e3',
        cj: '/chinajoy',
        tgs: '/tgs',
      },
    };
  },
  props: {
    keywords: {
      type: Array,
      require: false,
      default() {
        return [];
      },
    },
    search: {
      type: Object,
      require: false,
      default() {
        return {
          news: [],
          pageNum: 1,
          loadingMore: false,
          canLoadMore: true,
        };
      },
    },
    channel: {
      type: String,
      default() {
        return 'e3';
      },
    },
  },
  components: {
    TgE3SlideBar,
    TgE3SearchNews,
  },
  watch: {
    sideBarShow(val) {
      this.$parent.$emit('sideBarShow', val);
    },
    searchShow(val) {
      this.$parent.$emit('searchShow', val);
    },
  },
  methods: {
    toggleSideBar() {
      this.sideBarShow = !this.sideBarShow;
      this.$parent.$emit('sideBarShow', this.sideBarShow);
      if (this.sideBarShow) {
        document.getElementById('e3-home').style.height =
          document.getElementById('e3-home-slide').offsetHeight + 'px';
        document.getElementById('e3-home').style.overflow = 'hidden';
      }
    },
    toggleSearch() {
      this.searchShow = !this.searchShow;
      this.$parent.$emit('searchShow', this.searchShow);
      if (this.searchShow) {
        document.getElementById('e3-home').style.height =
          document.getElementById('e3-home-slide').offsetHeight + 'px';
        document.getElementById('e3-home').style.overflow = 'hidden';
      }
    },
  },
};
</script>
