<template>
  <div :class="{'tg-card-group': true, 'bottom-border': bottomBorder}">
    <a :href="data.locationlink == null ? '/chinajoy/news/' + (data.id || data.nid) : data.locationlink">
      <tg-flexbox>
        <div class="tg-card-group_left">
          <img :src="'https://xyoss.g.com.cn/xy-production' + data.banner"
               alt="">
        </div>
        <tg-flexbox direction="column"
                    class="tg-card-group_right">
          <tg-flexbox class="tg-card-group_title">{{data.titlefull}}</tg-flexbox>
          <tg-flexbox class="tg-card-group_infobox">
            <tg-flexbox flex="none"
                        class="text"
                        :style="{color: showColor}">{{label == '' ? tag : label}}</tg-flexbox>
            <tg-flexbox flex="none"
                        class="line"
                        v-if="tag!=''"></tg-flexbox>
            <tg-flexbox flex="none"
                        class="line"
                        v-if="label!=''"></tg-flexbox>
            <tg-flexbox flex="none"
                        class="time">{{data.displaydate | timestampToTimeNoYear}}</tg-flexbox>
          </tg-flexbox>
        </tg-flexbox>
      </tg-flexbox>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tagColors: [
        '#FF0000',
        '#FF00AA',
        '#AB00FF',
        '#3A00FF',
        '#cca100',
        '#00B1FF',
        '#00FFC2',
        '#2c7900',
      ],
      getTags: [
        'CJACG',
        'CJ电竞',
        'CJ专访',
        'CJ产业',
        'CJ试玩',
        'CJ视频',
        'CJ现场',
        'CJ新闻',
      ],
      showTags: ['ACG', '电竞', '访谈', '产业', '试玩', '视频', '现场', '资讯'],
      showColor: '',
      tag: ''
    };
  },
  name: 'TgCardGroup',
  props: {
    bottomBorder: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'tgbuswap_360mw',
    },
    colorInfo: {
      type: String,
      default: 'zixun',
    },
    label: {
      type: String,
      default: '',
    },
    data: Object,
    preLink: {
      type: String,
      default: '/chinajoy/news/'
    }
  },
  created() {
    const self = this;
    if (!self.label) {
      const tags = this.data.tags || [];
      let index = 0;
      self.showColor = self.tagColors[7];
      self.tag = self.showTags[7];
      tags.forEach(item => {
        index = self.getTags.indexOf(item);
        if (index > -1) {
          self.showColor = self.tagColors[index];
          self.tag = self.showTags[index];
          return;
        }
      });
    } else {
      let index = self.showTags.indexOf(self.label);
      self.showColor = self.tagColors[index];
    }
  },
};
</script>
