import Vue, { CreateElement, VNode } from 'vue';
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import * as gamelibApi from '@src/api/gamelib-api';
import * as thumbor from '@src/api/thumbor';

/**
 * Component: GameNewsList
 */
@Component
export class TgGameNewsList extends Vue {
  @Prop({ type: Array, required: true })
  public readonly itemsSource!: gamelibApi.model.Article[];

  private render(h: CreateElement): VNode {
    return (
      <tg-list staticClass="tg-game-news-list" show-separator>
        {this.itemsSource.map(news => (
          <tg-list-item tag="a" href={news.response_url}>
            <tg-flexbox gutter-small>
              <tg-flexbox tag="h3" flex="100" staticClass="tg-game-news-list_title">
                {news.title}
              </tg-flexbox>
              <tg-flexbox staticClass="tg-game-news-list_date">
                {new Date(news.publish_time * 1000).toLocaleDateString()}
              </tg-flexbox>
            </tg-flexbox>
            {news.img_location && news.img_location.length > 0 ? (
              <img
                slot="right"
                staticClass="tg-game-news-list_thumbnail"
                src={thumbor.resolve(
                  news.img_location[0].img_path,
                  'tgm-gl-gamedetail-news-thumbnail_96_54',
                )}
                alt={news.title}
              />
            ) : (
              ''
            )}
          </tg-list-item>
        ))}
      </tg-list>
    );
  }
}
