import actions from './actions';
import mutations from './mutations';
const state = {
  filters: [],
  isLoading: false,
  games: [],
  pageNum: 0,
  total: 0
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
