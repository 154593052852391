<template>
  <div :class="['tg-e3-news-detail', 'tga-main', channel]">
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    content-wrapper
                    back-to-top>
      <article-main :articleMain='articleMain'></article-main>
      <!-- <div>
        <span>分享至：</span>
        <div class="bdsharebuttonbox bdshare-button-style0-16">
          <a class="iconfont tb-icon-weibo tg-weibo"
             data-cmd="tsina"></a>
          <a class="iconfont tb-icon-weixin tg-weixin"
             data-cmd="weixin"></a>
          <a class="iconfont tb-icon-QQ tg-qq"
             data-cmd="sqq"></a>
        </div>
      </div> -->
      <div class="related-reading"
           v-if="channel === 'e3'">
        <div class="related-reading-title">相关阅读</div>
        <E3CardGroup v-for="(item, index) in articleMain.relatednews.slice(0,3)"
                     :key="index"
                     :data="item"
                     :bottomBorder="index != 2" />

      </div>
      <div class="related-reading">
        <div class="related-reading-title">相关阅读</div>
        <CjCardGroup v-for="(item, index) in articleMain.relatednews.slice(0,3)"
                     :key="index"
                     :data="item"
                     :bottomBorder="index != 2" />

      </div>
    </tg-scroll-view>
  </div>
</template>

<script>
import ArticleMain from './article-main/TgArticleMain';
import E3CardGroup from '@src/components/e3/card-group/TgCardGroup';
import CjCardGroup from '@src/components/cj/card-group/TgCardGroup';
export default {
  name: 'E3NewsDetail',
  props: ['articleMain', 'channel'],
  components: {
    ArticleMain,
    E3CardGroup,
    CjCardGroup
  },
  methods: {
    baiduShare() {
      window._bd_share_config = {
        common: {
          bdSnsKey: {},
          bdDesc: '',
          bdText: '一起来看巴士图文直播',
          bdMini: '2',
          bdPic: '',
          bdStyle: '0',
          bdSize: '16',
        },
        share: {},
        // 'image': {
        //   'viewList': ['sqq', 'weixin', 'tsina'],
        //   'viewText': '',
        //   'viewSize': '16'
        // },
        selectShare: {
          bdContainerClass: null,
          bdSelectMiniList: ['sqq', 'weixin', 'tsina'],
        },
      };
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src =
        'http://bdimg.share.bestjourney.net/static/api/js/share.js?v=89860593.js?cdnversion=' +
        ~(-new Date() / 36e5);
      document.body.appendChild(s);
    },
  },
};
</script>
