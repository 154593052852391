<template>
  <div class="download tga-main">
    <tg-download-page></tg-download-page>

    <!-- <tg-header-bar transparent></tg-header-bar> -->
  </div>
</template>

<script>
import TgDownloadPage from '@src/components/download/download-page/TgDownloadPage';
export default {
  components: {
    TgDownloadPage,
  },
};
</script>
