import actions from './actions';
import mutations from './mutations';
const state = {
  banner: [],
  gamelibAdv: [],
  homeData: null,
  gameData: null,
  collectionData: null,
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
