import * as types from '../../mutations-types';

export default {
  [types.GAMELIB_FETCH_BANNER]: (state, res) => {
    if (res.groups.a && res.groups.a.length > 0) {
      state.banner = res.groups.a[0].hasSource;
    }
  },
  [types.GAMELIB_FETCH_HOME_DATA]: (state, data) => {
    state.homeData = data;
  },
  [types.GAMELIB_FETCH_GAME_DATA]: (state, data) => {
    state.gameData = data;
  },
  [types.GAMELIB_FETCH_COLLECTION_DATA]: (state, data) => {
    state.collectionData = data;
  },
  [types.GAMELIB_FETCH_COLLECTION_DATA_APPEND]: (state, games) => {
    state.collectionData.games.push(...games);
  },
};
