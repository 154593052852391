/**
 * 推荐页面
 */
import { article } from '../../../api/cms';
import * as types from '../../mutations-types';

export default {
  INIT_DATA_HOT_SEARCH: ({ state }) => {
    state.hotSearchKeyWords = [];
  },
  FETCH_HOT_SEARCH: async ({ commit }, params) => {
    let nid = '51606';
    if (params) {
      nid = params;
    }
    const queryArticle =
      `{
      news (id: ` +
      nid +
      `) {
        contentraw
      }
    }`;
    let promise = null;
    promise = Promise.all([article.articleAPI({ query: queryArticle })]).then(resC => {
      commit(types.SEARCH_FETCH_HOT_SEARCH, resC[0]);
    });
    return promise;
  },
};
