<template>
  <div class='tg-e3-relate'>
    <div v-for="(item, index) in data.slice(0, 4) || []"
         class="tg-e3-relate_item"
         :key="index">
      <tg-card-group :data="toCardData(item)"
                     :bottomBorder="index != 3" />
    </div>
    <div class="swiper-container relate-swiper-container"
         id="swiperone">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for="(item, index) in data.slice(4, 7) || []"
             :key='index'>
          <tg-pic-panel :data="toCardData(item)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import TgCardGroup from '../card-group/TgCardGroup';
import TgPicPanel from '../pic-panel/TgPicPanel';
export default {
  name: 'tg-e3-relate',
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      /* eslint-disable */
      new Swiper('#swiperone', {
        direction: 'horizontal',
        loop: true,
        slideToClickedSlide: true,
        loopAdditionalSlides: 5,
        // autoplay: true,
        centeredSlides: true,
        autoplayDisableOnInteraction: false,
        slidesPerView: 'auto',
        spaceBetween: 18,
      });
    });
  },
  methods: {
    toCardData(item) {
      return {
        id: item.id,
        banner: item.pic,
        titlefull: item.title,
        tags: item.tags,
        displaydate: item.date,
      };
    },
  },
  components: {
    TgPicPanel,
    TgCardGroup,
  },
};
</script>
