import Vue, { CreateElement, VNode } from 'vue';
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { TgGalleryFullscreen } from '@src/controls/media/gallery/GalleryFullscreen';

export interface GalleryItem {
  thumbnail?: string;
  origin: string;
  alt?: string;
}

/**
 * Component: Gallery
 */
@Component
export class TgGallery extends Vue {
  @Prop({ type: Array, required: true })
  public readonly itemsSource!: GalleryItem[];

  public fullscreenShow: boolean = false;

  public $refs!: {
    fullscreen: TgGalleryFullscreen;
  };

  private render(h: CreateElement): VNode {
    return (
      <tg-flexbox staticClass="tg-gallery" flex="100">
        <tg-scroll-view direction="horizontal" gap="false">
          <tg-flexbox wrap="nowrap" gutter-small>
            {this.itemsSource.map((item, index) => (
              <tg-flexbox flex="none">
                <img
                  staticClass="tg-gallery_thumbnail"
                  key={item.origin}
                  src={item.thumbnail || item.origin}
                  alt={item.alt}
                  onClick={() => {
                    this.$refs.fullscreen.index = index;
                    this.fullscreenShow = true;
                  }}
                />
              </tg-flexbox>
            ))}
          </tg-flexbox>
        </tg-scroll-view>
        <tg-gallery-fullscreen
          ref="fullscreen"
          itemsSource={this.itemsSource}
          show={this.fullscreenShow}
          onClose={(value: boolean) => (this.fullscreenShow = value)}
        />
      </tg-flexbox>
    );
  }
}
