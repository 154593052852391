export const downloadUrl = {
  android: {
    openApp: 'tgbus://com.donews.tgbus',
    download: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.donews.tgbus',
  },
  ios: {
    download:
      'https://apps.apple.com/cn/app/%E7%94%B5%E7%8E%A9%E5%B7%B4%E5%A3%ABtgbus/id1422237582',
  },
  unkown: null,
};

// 设备类型
export const deviceType = (): String => {
  const isAndroid = /(Android)/i.test(navigator.userAgent); // android终端
  const isiOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent); // ios终端
  let type = 'unkown';
  if (isAndroid) {
    type = 'android';
  }
  if (isiOS) {
    type = 'ios';
  }
  return type;
};

export const downloadApp = ({ downloadUrl = '', openApp = '' }) => {
  if (downloadUrl !== '' && openApp !== '') {
    let ifr = document.createElement('iframe');
    ifr.src = openApp;
    ifr.style.display = 'none';
    document.body.appendChild(ifr);
    window.setTimeout(() => {
      window.location.href = downloadUrl;
    }, 3000);
  } else {
    downloadUrl !== '' && (window.location.href = downloadUrl);
  }
};

export const isInAndroid = (): Boolean => {
  var u = navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
};
export const isInIOS = (): Boolean => {
  var u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};
export const isWeiXin = (): Boolean => {
  var ua = window.navigator.userAgent.toLowerCase();
  var uaString = '';
  if (ua.match(/MicroMessenger/i) == null) {
    uaString = '';
  } else if (ua.match(/MicroMessenger/i) == undefined) {
    uaString = '';
  } else {
    uaString = ua.match(/MicroMessenger/i)!.toString();
  }
  if (uaString.match('micromessenger')) {
    return true;
  } else {
    return false;
  }
};
