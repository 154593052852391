<template>
  <div class="tg-special-nav-bar">
    <div id="topNav"
         class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for="(item, index) in itemsSource"
             :key="index"
             :class="{'is-active': getActive(item.url)}">
          <a class="tg-nav-bar_link link"
             target="_self"
             :href="item.url">{{item.label}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  data() {
    return {
      activeUrl: '',
      mySwiper: null,
      activeIndex: 0,
    };
  },
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
  },
  methods: {
    getActive(url) {
      if (this.$route.path === url || this.$route.path === encodeURI(url)) {
        return true;
      } else {
        return false;
      }
    },
    getSlider(index) {
      if (index != -1) {
        var swiperWidth = this.mySwiper.width;
        var maxTranslate = this.mySwiper.maxTranslate();
        var maxWidth = -maxTranslate + swiperWidth / 2;

        var slide = this.mySwiper.slides[index];
        var slideLeft = slide.offsetLeft;
        var slideWidth = slide.clientWidth;
        var slideCenter = slideLeft + slideWidth / 2;

        this.mySwiper.setTransition(300);
        if (slideCenter < swiperWidth / 2) {
          this.mySwiper.setTranslate(0);
        } else if (slideCenter > maxWidth) {
          this.mySwiper.setTranslate(maxTranslate);
        } else {
          var nowTranslate = slideCenter - swiperWidth / 2;
          this.mySwiper.setTranslate(-nowTranslate);
        }
      }
    },
  },
  mounted() {
    var _this = this;
    const barwidth = 36;
    const tSpeed = 300;
    this.mySwiper = new Swiper('#topNav', {
      freeMode: true,
      freeModeMomentumRatio: 0.5,
      slidesPerView: 'auto',
    });
    this.activeIndex = this.itemsSource.findIndex(item => item.url === this.$route.path);
    this.getSlider(this.activeIndex);
  },
};
</script>
