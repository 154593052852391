<template>
  <div class="tg-side-bar"
       v-if="sideBarShow"
       @touchmove.prevent>
    <div class="tg-side-bar_wrapper">
      <div class="tg-side-bar_title">常用导航</div>
      <div class="tg-side-bar_list">
        <tg-flexbox justify="space-between">
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/">首页</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/sales">发售表</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/live">直播</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/gamelib">游戏库</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/mobilegamedb">手游库</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/shop">商城</a>
          </tg-flexbox>
        </tg-flexbox>
      </div>
      <div class="tg-side-bar_title">电玩频道</div>
      <div class="tg-side-bar_list">
        <tg-flexbox justify="space-between">
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/ps4">PS4</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/ps5">PS5</a>
            <p>
              <img src="../../../assets/tag-new.png"
                   alt="">
            </p>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/ns">Switch</a>
            <p>
              <img src="../../../assets/tag-hot.png"
                   alt="">
            </p>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/xbox">Xbox</a>
            <p>
              <img src="../../../assets/tag-hot.png"
                   alt="">
            </p>
          </tg-flexbox>
        </tg-flexbox>
      </div>
      <div class="tg-side-bar_title">游戏频道</div>
      <div class="tg-side-bar_list">
        <tg-flexbox justify="space-between">
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/ol">网游</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/shouji">手游</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="http://a-m-pc.bestjourney.net">单机</a>
          </tg-flexbox>
        </tg-flexbox>
      </div>
      <div class="tg-side-bar_title">数码频道</div>
      <div class="tg-side-bar_list">
        <tg-flexbox justify="space-between">
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/tech">Hi科技</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/android">安卓中文网</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/list/iphone">iPhone中文网</a>
          </tg-flexbox>
        </tg-flexbox>
      </div>
      <div class="tg-side-bar_title">专题活动</div>
      <div class="tg-side-bar_list">
        <tg-flexbox justify="space-between">
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/e3">E3游戏展</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/chinajoy">ChinaJoy</a>
          </tg-flexbox>
          <tg-flexbox flex="none"
                      class="tg-side-bar_item">
            <a href="/tgs">TGS</a>
          </tg-flexbox>
        </tg-flexbox>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tg-side-bar',
  data() {
    return {
    };
  },
  props: {
    sideBarShow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
