import * as types from '../../mutations-types';

export default {
  [types.HOME_FETCH_BANNER]: (state, res) => {
    if (res.groups.tgbus_wap_home_banner && res.groups.tgbus_wap_home_banner.length > 0) {
      state.banner = res.groups.tgbus_wap_home_banner[0].hasSource;
    }
  },
  [types.HOME_FETCH_HOT_NEWS]: (state, res) => {
    if (res.groups.tgbus_wap_home_rmxw && res.groups.tgbus_wap_home_rmxw.length > 0) {
      state.hotNews = res.groups.tgbus_wap_home_rmxw[0].hasSource;
    }
  },
  [types.HOME_FETCH_DEEP_PROFILE]: (state, res) => {
    if (res.groups.tgbus_wap_home_sdpc && res.groups.tgbus_wap_home_sdpc.length > 0) {
      state.deepProfile = res.groups.tgbus_wap_home_sdpc[0].hasSource;
    }
  },
  [types.HOME_FETCH_TODAY_INFORM]: (state, res) => {
    if (res.list.result && res.list.result.length === 10) {
      state.todayInform.push(...res.list.result);
      state.newsloadingState = 1;
    } else if (
      res.list.result &&
      res.list.result.length > 0 &&
      res.list.result.length < 10
    ) {
      state.todayInform.push(...res.list.result);
      state.newsloadingState = 3;
    } else {
      state.newsloadingState = 3;
    }
  },
  [types.HOME_FETCH_YXSP]: (state, res) => {
    if (res.list.result && res.list.result.length === 10) {
      state.yxsp.push(...res.list.result);
      state.sploadingState = 1;
    } else if (
      res.list.result &&
      res.list.result.length > 0 &&
      res.list.result.length < 10
    ) {
      state.yxsp.push(...res.list.result);
      state.sploadingState = 3;
    } else {
      state.sploadingState = 3;
    }
  },
  [types.HOME_FETCH_YXGL]: (state, res) => {
    if (res.list.result && res.list.result.length === 10) {
      state.yxgl.push(...res.list.result);
      state.glloadingState = 1;
    } else if (
      res.list.result &&
      res.list.result.length > 0 &&
      res.list.result.length < 10
    ) {
      state.yxgl.push(...res.list.result);
      state.glloadingState = 3;
    } else {
      state.glloadingState = 3;
    }
  },
  [types.HOME_FETCH_MORE_DATA]: (state, { params, res }) => {
    if (res.list.result && res.list.result.length === 10) {
      state[params.dataName].push(...res.list.result);
      state[params.loadingStateName] = 1;
    } else if (
      res.list.result &&
      res.list.result.length > 0 &&
      res.list.result.length < 10
    ) {
      state[params.dataName].push(...res.list.result);
      state[params.loadingStateName] = 3;
    } else {
      state[params.loadingStateName] = 3;
    }
  },
  [types.HOME_FETCH_LIST_NEWS]: (state, { params, res }) => {
    let result = [];
    if (res.groups.status) {
      for (var key in res.groups) {
        if (key != 'status') {
          result = res.groups[key][0] ? res.groups[key][0].hasSource : [];
          break;
        }
      }
    }
    if (res.groups.status && result.length > 0) {
      state.hotNews.push(...result);
    }
    state.loading = false;
  },

  [types.HOME_FETCH_SALE_NEWS]: (state, { res }) => {
    let result = res.game_release_schedule;
    if (result && result.length > 0) {
      state.hotNews.push(...result);
    }
    state.loading = false;
  },
  [types.HOME_FETCH_SHOP_NEWS]: (state, { container, res }) => {
    if (container == 1 || container == 2) {
      if (res[0] && res[0].length > 0 && res[0][0].result.length > 0) {
        let result = res[0][0];
        state.shopPS4News.push(...result.result);
      }
      if (res[1] && res[1].length > 0 && res[1][0].result.length > 0) {
        let result = res[1][0];
        state.shopSwitchNews.push(...result.result);
      }
    } else {
      if (res && res.length > 0 && res[0].result.length > 0) {
        let result = res[0];
        state.hotNews.push(...result.result);
      }
    }
    state.loading = false;
  },
};
