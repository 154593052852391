import Vue from 'vue';
import Vuex from 'vuex';
import pageinfo from './modules/pageinfo';
import home from './modules/home';
import e3 from './modules/e3';
import cj from './modules/cj';
import tgs from './modules/tgs';
import gamelib from './modules/gamelib';
import general from './modules/general';
import article from './modules/article';
import search from './modules/search';
import live from './modules/live';
import mobileGame from './modules/mobileGame'
import mobileGameDetail from './modules/mobileGameDetail'
import common from './modules/common';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    modules: {
      pageinfo,
      home,
      gamelib,
      general,
      article,
      search,
      live,
      e3,
      cj,
      tgs,
      common,
      mobileGame,
      mobileGameDetail
    },
  });
}
