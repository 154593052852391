<template>
  <div :id="containerId"
       :class="`ad__${type}`">
    <slot name="adContent"></slot>
  </div>
</template>

<script>
import { script, coupletScript, dropdownScript } from '@src/utils/ad';

export default {
  name: 'Advertisement',

  props: {
    id: [String, Number],
    type: {
      type: String,
      default: 'column',
    },
    mode: {
      type: String,
      default: 'inline',
      validator(value) {
        // 两种类型，内联或外链，部分对联广告是脚本的形式
        return ['inline', 'link'].indexOf(value) !== -1;
      },
    },
  },

  mixins: [script, coupletScript, dropdownScript],

  computed: {
    containerId() {
      return `ad_${this.type}-${this.id}`;
    },
  },

  methods: {
    generate() {
      const { type, mode, id } = this;
      if (mode === 'link') {
        if (type === 'couplet') {
          this.generateCoupletScript(id);
        } else if (type === 'dropdown') {
          this.generateDropdownScript(id);
        }
      }
    },
  },

  mounted() {
    const { id, type, mode, containerId, addScript } = this;
    if (mode === 'link') {
      if (type === 'couplet') {
        this.generateCoupletScript(id);
      } else if (type === 'dropdown') {
        this.generateDropdownScript(id);
      }
    } else {
      this.generateScript(id, containerId);
    }
  },
};
</script>
