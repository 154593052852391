<template>
  <div class="tg-newwork-list">
    <div class="tg-newwork-list_list">
      <div class="tg-newwork-list_item"
           v-for="(item, index) in itemsSource || []"
           :key="index">
        <a class="image-box"
           :href="item.gamepage">
          <img :src="item.img && item.img.path"
               alt="item.gamename">
          <p>
            <span>{{item.gamename}}</span>
          </p>
        </a>
        <div class="btn-box">
          <a :href="item.purchaselink ? item.purchaselink : 'javascript:void(0)'"
             :class="{'no-data': !item.purchaselink}">预购</a>
          <a :href="item.gamespecial ? item.gamespecial : 'javascript:void(0)'"
             :class="{'no-data': !item.gamespecial}">专题</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'tg-card-info-item',
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
  },
};
</script>
