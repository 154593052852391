/**
 * 推荐页面
 */
import { mobileGameDetail } from '../../../api/cms';
import * as types from '../../mutations-types';
export default {
  INIT_DATA: ({ state }) => {
    state.errorInfo = []
    state.game = {}
    state.categoryRange = []
    state.sameDeveloper = []
    state.recommends = []
    state.isEnd = false
    state.isLoading = false
  },

  FETCH: async ({ commit, state }, gameid) => {
    let promises = [mobileGameDetail.detail(gameid), mobileGameDetail.categoryRange(gameid), mobileGameDetail.sameDeveloper(gameid)]
    const [game, categoryRange, sameDeveloper] = await Promise.all(promises.map(function (promiseItem) {
      return promiseItem.catch(function (err) {
        state.errorInfo.push(err)
        return err
      })
    }))
    commit(types.MOBILEGAME_SET_DETAIL, { game, categoryRange, sameDeveloper });
  },
  FETCH_REC: ({ state }, { page = 1, perPage = 6, ...recParams }) => {
    if (page === 1) {
      state.recommends = []
      state.isEnd = false
    }
    state.isLoading = true
    mobileGameDetail.recommends({ page, per_page: perPage, ...recParams }).then(res => {
      if (res.code === 1) {
        state.recommends.push(...res.data)
        res.data.length === 0 ? state.isEnd = true : state.isEnd = false
      } else {
        res.data.length === 0 ? state.isEnd = true : state.isEnd = false
        console.log(res.msg)
      }
      state.isLoading = false
    })
  },
}
