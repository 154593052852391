import Vue, { CreateElement, VNode } from 'vue';
import * as gamelibApi from '@src/api/gamelib-api';
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';

/**
 * Component: GameDetailWrapper
 */
@Component
export class TgGameDetailWrapper extends Vue {
  @Prop({ type: Object, required: true })
  public readonly game!: gamelibApi.model.Game;

  private renderRow<T>(
    label: string,
    values: T[],
    valueWrapper: (value: T) => VNode,
    block: boolean = false,
  ): VNode {
    return values && values.length > 0 ? (
      <div staticClass="tg-game-detail-wrapper_row">
        <div staticClass="tg-game-detail-wrapper_label">{label}</div>
        <div
          staticClass={`tg-game-detail-wrapper_value-wrapper${block ? ' is-block' : ''}`}
        >
          {block
            ? values.map(valueWrapper)
            : values.map(v => [
                valueWrapper(v),
                <span staticClass="tg-game-detail-wrapper_separator">,&nbsp;</span>,
              ])}
        </div>
      </div>
    ) : (
      // tslint:disable-next-line:no-any
      ('' as any)
    );
  }

  private getCollectionWrapper(
    collectionType: string,
  ): (collection: gamelibApi.model.Collection) => VNode {
    return c => (
      <a
        staticClass="tg-game-detail-wrapper_value"
        href={`/gamelib/${collectionType}/${c.id}`}
      >
        {(c.names && (c.names.zh_cn || c.names.en_us)) || c.name}
      </a>
    );
  }

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-game-detail-wrapper">
        {this.renderRow(
          '平台',
          this.game.platforms,
          this.getCollectionWrapper('platform'),
        )}
        {this.renderRow('类型', this.game.genres, this.getCollectionWrapper('genre'))}
        {this.renderRow('题材', this.game.themes, this.getCollectionWrapper('theme'))}
        {this.renderRow(
          '开发商',
          this.game.developers,
          this.getCollectionWrapper('developer'),
        )}
        {this.renderRow('发行商', this.game.publishers, pub => (
          <a
            staticClass="tg-game-detail-wrapper_value"
            href={`/gamelib/publisher/${pub.publisher.id}`}
          >
            {(pub.publisher.names &&
              (pub.publisher.names.zh_cn || pub.publisher.names.en_us)) ||
              pub.publisher.name}
            （{pub.region}）
          </a>
        ))}
        {this.game.first_release_date > 0
          ? this.renderRow(
              '首发日期',
              [this.game.first_release_date],
              date => (
                <span staticClass="tg-game-detail-wrapper_value">
                  {new Date(date * 1000).toLocaleDateString()}
                </span>
              ),
              true,
            )
          : ''}
        {this.renderRow(
          '发售日期',
          this.game.release_dates.filter(
            rd => rd.date > 0 && rd.platform.id > 0 && rd.region,
          ),
          rd => (
            <span staticClass="tg-game-detail-wrapper_value">
              {new Date(rd.date * 1000).toLocaleDateString()}
              <span staticClass="tg-game-detail-wrapper_sub">
                {' （'}
                {(rd.platform.names &&
                  (rd.platform.names.zh_cn || rd.platform.names.en_us)) ||
                  rd.platform.name}
                {' - '}
                {rd.region}
                {'）'}
              </span>
            </span>
          ),
          true,
        )}
        {this.renderRow(
          '模式',
          this.game.game_modes,
          this.getCollectionWrapper('game_mode'),
        )}
        {this.renderRow(
          '视角',
          this.game.player_perspectives,
          this.getCollectionWrapper('player_perspective'),
        )}
        {this.renderRow(
          '引擎',
          this.game.game_engines,
          this.getCollectionWrapper('game_engine'),
        )}
        {this.renderRow(
          '分级',
          this.game.ratings,
          rt => (
            <span staticClass="tg-game-detail-wrapper_value">
              {rt.system} {rt.name}
            </span>
          ),
          true,
        )}
      </div>
    );
  }
}
