<template>
  <div class="tg-tody-information">
    <div class="tg-tody-information_title">今日资讯</div>
    <div class="tg-tody-information_banner"
         v-if="itemsSource[0]">
      <a class="content"
         :href="itemsSource[0].locationlink == null ? '/news/' + itemsSource[0].nid : itemsSource[0].locationlink">
        <img :src="'https://xyoss.g.com.cn/xy-production' + itemsSource[0].banner" />
        <p>{{itemsSource[0].titlefull}}</p>
      </a>
    </div>
    <div class="tg-tody-information_list">
      <tg-news-item :data="item"
                    v-for="(item, index) in itemsSource.slice(1,4) || []"
                    :key="index" />
      <!-- 广告 start -->
      <div :id="adId"
           class="tg-tody-information_adv-position"></div>
      <!-- 广告 end -->
      <tg-news-item :data="item"
                    v-for="(item, index) in itemsSource.slice(4) || []"
                    :key="index + 5" />
    </div>
    <div class="tg-tody-information_loadmore"
         @click="loadMore"
         v-if="loadingState === 1">加载更多</div>
    <div class="tg-tody-information_loadmore"
         v-else-if="loadingState === 2">加载中...</div>
    <div class="tg-tody-information_loadmore"
         v-else>没有更多内容了</div>
  </div>
</template>

<script>
import * as ad from '@src/api/ad';
export default {
  name: 'tg-tody-information',
  data() {
    return {
      adId: ad.AD_ID_HOME_SPREAD,
    };
  },
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
  },
  methods: {
    loadMore() {
      let params = {
        dataName: 'todayInform',
        tag: '巴士首页要闻',
        loadingStateName: 'newsloadingState',
        glpageNumName: 'newspageNum',
      };
      this.$store.dispatch('home/FETCH_MORE', params);
    },
  },
  computed: {
    loadingState() {
      return this.$store.state.home.newsloadingState;
    },
  },
};
</script>

