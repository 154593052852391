/**
 * 推荐页面
 */
import * as types from '../../mutations-types';
import { gamelib } from '@src/api/cms';
import { fetchGamelibHomeDataRaw, fetchGamelibHomeData } from '@src/api/codepit-api';
import * as gamelibApi from '@src/api/gamelib-api';

export default {
  INIT_DATA: ({ state }) => {
    state.banner = [];
  },
  FETCH: async ({ commit, state }, params = {}) => {
    let promise = null;
    let query = `{
      groups(group_name:"tgbus_wap_gamelib") {
        status
        a:containers(container_id:"tgbus_wap_gamelib_banner") {
          container_name
          container_id
          hasSource {
            id
            date
            title
            pic
          }
        }
      }
    }`;
    promise = Promise.all([gamelib.gamelibAPI({ query: query })]).then(resC => {
      commit(types.GAMELIB_FETCH_BANNER, resC[0]);
    });
    return promise;
  },

  HOME_DATA: async ({ commit, state }, params = {}) => {
    const raw = await fetchGamelibHomeDataRaw();
    const data = await fetchGamelibHomeData(raw);
    commit(types.GAMELIB_FETCH_HOME_DATA, data);
  },

  GAME_DATA: async ({ commit, state }, params = {}) => {
    if (params.id === undefined || typeof params.id !== 'number' || isNaN(params.id)) {
      throw new Error(`Invalid game id: ${params.id}`);
    }

    const data = await gamelibApi.service.fetchGame(params.id);
    commit(types.GAMELIB_FETCH_GAME_DATA, data);
  },

  COLLECTION_DATA: async ({ commit, state }, params = {}) => {
    if (params.id === undefined || typeof params.id !== 'number' || isNaN(params.id)) {
      throw new Error(`Invalid collection id: ${params.id}`);
    }
    if (
      params.type === undefined ||
      typeof params.type !== 'string' ||
      params.type === ''
    ) {
      throw new Error(`Invalid collection type ${params.type}`);
    }

    const first = 10;

    if (params.append) {
      const data = await gamelibApi.service.fetchCollection(params.type, params.id, {
        first,
        skip: state.collectionData.games.length,
        orderBy: {
          field: 'weight',
          direction: gamelibApi.model.Direction.DESC,
        },
      });
      commit(types.GAMELIB_FETCH_COLLECTION_DATA_APPEND, data.games);
    } else {
      const data = await gamelibApi.service.fetchCollection(params.type, params.id, {
        first,
        skip: 0,
        orderBy: {
          field: 'weight',
          direction: gamelibApi.model.Direction.DESC,
        },
      });
      commit(types.GAMELIB_FETCH_COLLECTION_DATA, data);
    }
  },
};
