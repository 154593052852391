<template>
  <div class='news-detail tg-tgs-news-detail tg-tgs-view'
       id="e3-home">
    <tg-e3-header-bar :keywords="keywords"
                      :search="search"
                      :channel="channel"></tg-e3-header-bar>
    <tg-e3-news-detail :articleMain='articleMain'
                       :channel="channel"></tg-e3-news-detail>
  </div>
</template>

<script>
import TgE3NewsDetail from '@src/components/e3-news-detail/TgNewsDetail';
import TgE3HeaderBar from '@src/components/e3/header-bar/TgE3HeaderBar';
import isDevelopment from '@src/utils/isDevelopment';

export default {
  async asyncData({ route, store }) {
    const newsId = route.query.newsid || route.params.newsid;
    const keyId = '134801';
    await Promise.all([
      store.dispatch('article/INIT_DATA'),
      store.dispatch('article/FETCH', {
        newsId,
        keyId,
      }),
    ]);

    const main = store.state.article.articleMain;

    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: main.titlefull,
      meta: `
  <meta name="keywords" content="${[...main.keywords, ...main.tags].join(
        ', ',
      )}, tgbus, 电玩巴士">
  <meta name="description" content="电玩巴士 ${main.description}">
  <meta property="og:url" content="https://m.bestjourney.net/news/${newsId}" />
  <meta property="og:title" content="${main.titlefull} - TGBUS - 电玩巴士" />
  <meta property="og:description" content="${main.description}" />
  <meta property="og:image" content="${main.banner}" />
    `,
    };
  },
  data() {
    return {
      channel: 'tgs',
    };
  },
  mounted() {
    if (
      !isDevelopment &&
      (this.articleMain.typeid === 4 && this.articleMain.locationlink !== '')
    ) {
      location.href = this.articleMain.locationlink;
      return;
    }
    if (
      !isDevelopment &&
      (this.articleMain.titlefull === '' || this.articleMain.status !== 1)
    ) {
      location.href = '/404';
      return;
    }
    if (isDevelopment && this.$options.asyncData) {
      this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },

  computed: {
    articleMain() {
      return this.$store.state.article.articleMain;
    },
    keywords() {
      return this.$store.state.article.keywords;
    },
    search() {
      return this.$store.state.e3.search;
    },
  },
  components: {
    TgE3NewsDetail,
    TgE3HeaderBar,
  },
};
</script>
