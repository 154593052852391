<template>
  <div class="tg-star-awards-2022">
    <div class="tg-star-awards-2022_banner">
    </div>
    <div class="tg-star-awards-2022_category tg-star-awards-2022_container tg-star-awards-2022_flexc">
      <p v-for="(item, index) in categoryTitle" :key="item" @click="activeIndex = index">
        <img :src="activeIndex === index ? require(`./images/t${index+1}_ok.png`):require(`./images/t${index+1}.png`)" alt="">
      </p>
    </div>
     <!-- 奖项内容 -->
    <div
      v-for="(awards, index) in categoryContent"
      :key="index"
      class="tg-star-awards-2022_content tg-star-awards-2022_container"
      v-show="activeIndex === index"
    >
      <div class="awards-item" v-for="(award, aindex) in awards" :key="'award'+aindex">
        <div class="title">
          <h3>{{award.name}}</h3>
          <p>{{award.intro}}</p>
        </div>
        <div class="flex-between">
          <div class="game-item" v-for="(game, gindex) in award.games" :key="'game'+gindex">
            <div>
              <img :src="require(`./images/${categoryImg[index]}/${aindex+1}-${gindex+1}.png`)" onerror="this.src='./images/game/1-1.png'" alt="">
            </div>
            <div>{{ game }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async asyncData({ route, store }) {
    await Promise.all([store.dispatch(`tgs/INIT_DATA`), store.dispatch(`tgs/FETCH`)]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {};
  },
  data() {
    return {
      activeIndex: 0,
      categoryTitle: ['游戏类', '业界类', '硬件类'],
      categoryImg: ['game', 'industry', 'hardware'],
      categoryContent: [
        [
          {
            name: '年度游戏巨星奖',
            intro: '今年销量和口碑双双最佳的游戏作品',
            games: [
              '艾尔登法环',
              '战神诸神黄昏',
              '地平线西之绝境',
              '异度神剑3'
            ]
          },
          {
            name: '独立游戏新星奖',
            intro: '在独立游戏中脱颖而出的亮眼之作',
            games: [
              '咩咩启示录',
              'Brewmaster 酿酒大师',
              '暖雪',
              '暗夜长梦'
            ]
          },
          {
            name: '单机游戏杰出奖',
            intro: '在单机游戏中获得市场认可的佳作',
            games: [
              '沙石镇时光',
              '迷失Stray',
              '师父',
              '忍者神龟：施莱德的复仇'
            ]
          },
          {
            name: '国产游戏不凡奖',
            intro: '国内开发者推出的具有浓郁中国特色的作品',
            games: [
              '黑羊',
              '生死轮回',
              '完美的一天',
              '仙剑客栈2'
            ]
          },
          {
            name: '移动游戏翘楚奖',
            intro: '在移动市场推出便获得成功的新手游',
            games: [
              '幻塔',
              '暗黑破坏神不朽',
              'nikke胜利女神',
              '弹壳特工队'
            ]
          }
        ],
        [
          {
            name: '卓越电竞战队',
            intro: '国内今年表现亮眼且具有话题性的电竞战队',
            games: [
              'RNG',
              'TES',
              'JDG',
              'EDG'
            ]
          },
          {
            name: '卓越游戏发行',
            intro: '在海内外成功发行单款/多款游戏的游戏发行商',
            games: [
              '腾讯游戏',
              '网易游戏',
              '完美世界',
              '盛趣游戏'
            ]
          },
          {
            name: '卓越电竞选手',
            intro: '今年最具人气的电竞选手',
            games: [
              'TheShy',
              '369',
              'Yagao',
              'Kanavi'
            ]
          },
          {
            name: '卓越游戏运营',
            intro: '今年游戏运营表现最佳的游戏和发行商团队',
            games: [
              '最终幻想14',
              '原神',
              '幻塔',
              '原始征途'
            ]
          },
          {
            name: '卓越游戏主播',
            intro: '今年最具人气的游戏主播',
            games: [
              '白鲨AyoM',
              '逍遥散人',
              'DK-不求人',
              '韦朕'
            ]
          }
        ],
        [
          {
            name: '超人气游戏外设',
            intro: '今年上市获得成功的游戏周边外设',
            games: [
              '北通宙斯2手柄',
              '图马思特方向盘T-GT2 II',
              '罗技鼠标MX MASTER 3S',
              '傲风M6四向护腰电竞椅'
            ]
          },
          {
            name: '超人气游戏硬件',
            intro: '今年上市获得成功的游戏硬件',
            games: [
              'GPD WIN MAX2',
              'AYANEO 2掌机',
              '罗技 G 云游戏掌机',
              '壹号本OnexPlayer mini'
            ]
          },
          {
            name: '超人气游戏手机',
            intro: '今年上市获得成功的游戏手机',
            games: [
              '华硕 ROG Phone 6 Pro',
              '努比亚红魔7S Pro',
              '小米黑鲨5 Pro',
              '苹果 iPhone 14 Pro Max'
            ]
          },
          {
            name: '超人气数码产品',
            intro: '今年上市获得成功的游戏数码类产品',
            games: [
              '樱桃MX BOARD 8.0键盘',
              '雷蛇猎魂光蛛v2键盘',
              '索尼H9耳机',
              'ROG枪神6游戏本'
            ]
          },
          {
            name: '超人气显示设备',
            intro: '今年上市获得成功的显示器等显示外设',
            games: [
              '小米 24.5寸 240Hz',
              'LG27GP83B',
              'AOC AG274QZP',
              '索尼XR-75X90k'
            ]
          },
          {
            name: '超人气音频产品',
            intro: '今年上市获得成功的音频产品',
            games: [
              '索尼WH-1000XM4',
              'AirPods Pro',
              '森海塞尔Momentum True Wireless 2',
              '华为FreeBuds Pro 2'
            ]
          }
        ]
      ]
    };
  }
};
</script>
