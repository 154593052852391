<template>
  <div class="download tga-main">
    <tg-nyj-download-page></tg-nyj-download-page>
  </div>
</template>

<script>
import TgNyjDownloadPage from '@src/components/download/download-page/TgNyjDownloadPage';
export default {
  components: {
    TgNyjDownloadPage,
  },
};
</script>
