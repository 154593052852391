<template>
  <div class="tg-nav-bar">
    <ul class="tg-nav-bar_wrapper">
      <li class="tg-nav-bar_item"
          v-for="item in itemsSource[channel]"
          :key="item.label"
          @click="changeLabel(item)"
          :class="{'is-active': item.label === label, 'is-newwork': item.label === '新作'}">
        {{item.label}}
        <span class="item-line"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsSource: {
        ns: [
          {
            label: '资讯',
            tag: '任天堂频道',
          },
          {
            label: '评测',
            tag: '任天堂频道,游戏评测',
          },
          {
            label: '攻略',
            tag: '任天堂频道,游戏攻略',
          },
          {
            label: '视频',
            tag: '任天堂频道,游戏视频',
          },
          {
            label: '特稿',
            tag: '任天堂频道,巴士首页特稿',
          },
          {
            label: '周边',
            tag: '任天堂频道,硬件周边',
          },
          {
            label: '使用宝典',
            tag: '任天堂频道,使用宝典',
          },
        ],
        xbox: [
          {
            label: '资讯',
            tag: '微软频道',
          },
          {
            label: '评测',
            tag: '微软频道,游戏评测',
          },
          {
            label: '攻略',
            tag: '微软频道,游戏攻略',
          },
          {
            label: '视频',
            tag: '微软频道,游戏视频',
          },
          {
            label: '特稿',
            tag: '微软频道,巴士首页特稿',
          },
          {
            label: '周边',
            tag: '微软频道,硬件周边',
          },
          {
            label: '使用宝典',
            tag: '微软频道,使用宝典',
          },
        ],
        ps4: [
          {
            label: '资讯',
            tag: '索尼频道',
          },
          {
            label: '评测',
            tag: '索尼频道,游戏评测',
          },
          {
            label: '攻略',
            tag: '索尼频道,游戏攻略',
          },
          {
            label: '视频',
            tag: '索尼频道,游戏视频',
          },
          {
            label: '特稿',
            tag: '索尼频道,巴士首页特稿',
          },
          {
            label: '周边',
            tag: '索尼频道,硬件周边',
          },
          {
            label: '使用宝典',
            tag: '索尼频道,使用宝典',
          },
        ],
        ps5: [
          {
            label: '首页',
            tag: null,
          },
          {
            label: '资讯',
            tag: '索尼频道,PS5',
          },
          {
            label: '新作',
            tag: null,
          },
          {
            label: '评测',
            tag: '索尼频道,游戏评测,PS5',
          },
          // {
          //   label: '攻略',
          //   tag: '索尼频道,游戏攻略,PS5',
          // },
          {
            label: '视频',
            tag: '索尼频道,游戏视频,PS5',
          },
          {
            label: '特稿',
            tag: '索尼频道,巴士首页特稿,PS5',
          },
          {
            label: '周边',
            tag: '索尼频道,硬件周边,PS5',
          },
          {
            label: '使用宝典',
            tag: '索尼频道,使用宝典,PS5',
          },
        ],
        tech: [
          {
            label: '资讯',
            tag: '硬件资讯',
          },
          {
            label: '评测',
            tag: '硬件评测',
          },
          {
            label: '电脑影音',
            tag: '电脑影音',
          },
          {
            label: '智能生活',
            tag: '智能生活',
          },
          {
            label: '智车科技',
            tag: '智车科技',
          },
        ],
        android: [
          {
            label: '资讯',
            tag: '硬件资讯',
          },
          {
            label: '评测',
            tag: '硬件评测',
          },
          {
            label: '视频',
            tag: '热点视频',
          },
          {
            label: '图赏',
            tag: '时尚美图',
          },
          {
            label: '智能生活',
            tag: '智能生活',
          },
          {
            label: '行情导购',
            tag: '行情导购',
          },
        ],
        iphone: [
          {
            label: '资讯',
            tag: '硬件资讯',
          },
          {
            label: '配件',
            tag: '周边配件',
          },
          {
            label: '软件',
            tag: '软件',
          },
          {
            label: '游戏',
            tag: '游戏',
          },
          {
            label: '教程',
            tag: '教程',
          },
        ],
        ol: [
          {
            label: '新闻',
            tag: '网游资讯'
          },
          {
            label: '评测',
            tag: '网游评测'
          },
          {
            label: '原创',
            tag: '网游原创'
          },
          {
            label: '视频',
            tag: '网游视频'
          }
        ],
        shouji: [
          {
            label: '新闻',
            tag: '手游资讯'
          },
          {
            label: '评测',
            tag: '手游评测'
          },
          {
            label: '原创',
            tag: '手游原创'
          },
          {
            label: '视频',
            tag: '手游视频'
          },
          {
            label: '手游库',
            href: '/mobilegamedb'
          }
        ]
      },
    };
  },
  props: {
    channel: {
      default: 'game',
      type: String,
      require: true,
    },
    label: {
      default: '资讯',
      type: String,
      require: true,
    },
  },
  methods: {
    changeLabel(item) {
      if (item.href) {
        window.location.href = item.href
      } else {
        console.log(item.tag)
        this.$emit('changeLabel', item.label, item.tag);
      }
    },
  },
};
</script>

