<template>
  <div class="tg-tgs tg-tgsview"
       :data-group="getContainer('', '首页')"
       id="special-home">
    <tg-special-header-bar :keywords="keywords"
                           :search="search"
                           :channel="channel"></tg-special-header-bar>
    <tg-flexbox class="tga-padding-top64 tg-tgs_content">
      <tg-special-nav-bar id="nav-bar"
                          :items-source="navMenu"
                          :class="{'fixed-nav': fixedNav}"></tg-special-nav-bar>
      <tg-flexbox flex="100"
                  class="tga-tgs-container"
                  :data-container="getContainer('banner', '首页banner轮播图')">
        <tg-special-swiper :items-source="banner"
                           :channel="channel"
                           v-if="banner && banner.length > 0"></tg-special-swiper>
      </tg-flexbox>
      <!-- 新闻速报 -->
      <tg-flexbox flex="100"
                  class="tg-tgs_news"
                  :data-container="getContainer('news', '新闻速报')">
        <tg-tgs-title title="新闻速报"></tg-tgs-title>
        <div v-if="news && news.length > 0">
          <tg-special-card-group v-for="(item, index) in news.slice(0, 10) || []"
                                 :key="index"
                                 :data="toCardData(item)"
                                 :bottomBorder="index != 9" />
        </div>
      </tg-flexbox>
      <!-- 特约专访 -->
      <tg-flexbox flex="100"
                  class="tg-tgs_interview border-style"
                  :data-container="getContainer('interview', '特约专访')">
        <tg-tgs-title title="特约专访"
                      class="title"></tg-tgs-title>
        <tg-special-swiper-hor v-if="interview && interview.length > 0"
                               :data='interview.slice(0, 5)'
                               emblyId="tgs-interview-swiper"></tg-special-swiper-hor>
      </tg-flexbox>
      <!-- 精彩视频 -->
      <tg-flexbox flex="100"
                  class="tg-tgs_video border-style"
                  :data-container="getContainer('video', '精彩视频')">
        <tg-tgs-title title="精彩视频"></tg-tgs-title>
        <tg-special-pic-panel v-if="video && video.length > 0"
                              :data="toCardData(video[0])" />
        <div v-if="video && video.length > 0">
          <tg-special-card-group v-for="(item, index) in video.slice(1, 5) || []"
                                 :key="index"
                                 :data="toCardData(item)"
                                 :bottomBorder="index != 3" />
        </div>
      </tg-flexbox>
      <!-- 试玩报告 -->
      <!-- <tg-flexbox flex="100"
                  class="tg-tgs_video border-style"
                  :data-container="getContainer('report', '试玩报告')">
        <tg-tgs-title title="试玩报告"></tg-tgs-title>
        <tg-special-pic-panel :data="toCardData(report[0])"
                              v-if="elegance && elegance.length > 0" />
        <template v-if="elegance && elegance.length > 1">
          <tg-special-card-group v-for="(item, index) in report.slice(1, 5) || []"
                                 :key="index"
                                 :data="toCardData(item)"
                                 :bottomBorder="index != 3" />
        </template>
      </tg-flexbox>

      <tg-flexbox flex="100"
                  class="tg-tgs_elegance border-style"
                  :data-container="getContainer('elegance', '现场直击')">
        <tg-tgs-title title="现场直击"></tg-tgs-title>
        <div class="swiper-container relate-swiper-container"
             v-if="elegance && elegance.length > 0"
             id="swiper-elegance">
          <div class="swiper-wrapper">
            <div class="swiper-slide"
                 v-for="(item, index) in elegance.slice(0, 10) || []"
                 :key='index'>
              <a :href="item.locationlink == null ? '/tgs/news/' + (item.id || item.nid) : item.locationlink">
                <img :src="'https://img2.a9vg.com/i/tgbuswap_360mw' + item.pic"
                     alt="">
              </a>
            </div>
          </div>
        </div>
      </tg-flexbox> -->
    </tg-flexbox>
    <div id="a9vg_wap_e3_scroller"></div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { mapState } from 'vuex'
import TgSpecialHeaderBar from '@src/components/special/header-bar/TgSpecialHeaderBar';
import TgSpecialSwiper from '@src/components/special/swiper-banner/TgSpecialSwiper';
import TgSpecialNavBar from '@src/components/special/nav-bar/TgSpecialNavBar';
import TgSpecialCardGroup from '@src/components/special/card-group/TgSpecialCardGroup';
import TgSpecialSwiperHor from '@src/components/special/swiper-hor/TgSpecialSwiperHor';
import TgTgsTitle from './title/TgTgsTitle';
import TgSpecialPicPanel from '@src/components/special/pic-panel/TgSpecialPicPanel';
import isDevelopment from '@src/utils/isDevelopment.ts';
import { tgsVersion as version } from '@src/store/containers.config';
import { navMenu } from '@src/store/modules/tgs/index'
export default {
  data() {
    return {
      navTop: 0,
      channel: 'tgs',
      navMenu,
      fixedNav: false
    };
  },
  async asyncData({ route, store }) {
    await Promise.all([store.dispatch(`tgs/INIT_DATA`), store.dispatch(`tgs/FETCH`)]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: `${version}TGS专题报道_${version}东京电玩展_A9VG电玩部落`,
      meta: `<meta name="description" content="9VG电玩部落${version}东京电玩展（TGS ${version}），为您提供索尼、世嘉、万代南梦宫、SE等厂商的最新的游戏发布会和TGS新闻，TGS视频，TGS图片，TGS试玩，TGS采访等信息。">
             <meta name="keywords" content="TGS,TGS${version},东京电玩展,TGS新游戏"/>
             <base target="_blank">
             <meta name="robots" content="all"/>`,
    };
  },
  async mounted() {
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
    if (this.elegance && this.elegance.length > 0) {
      this.$nextTick(() => {
        /* eslint-disable */
        new Swiper('#swiper-elegance', {
          slidesPerView: 'auto',
          spaceBetween: 6,
          freeMode: true,
        });
      });
    }
    const _this = this
    window.onscroll = function () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const sideBarShow = _this.$children[0].sideBarShow;
      if (scrollTop > 67 && !sideBarShow) {
        _this.fixedNav = true
      } else {
        _this.fixedNav = false
      }
    };
  },
  computed: {
    ...mapState({
      banner: state => state.tgs.banner,
      news: state => state.tgs.news,
      interview: state => state.tgs.interview,
      video: state => state.tgs.video,
      report: state => state.tgs.report,
      elegance: state => state.tgs.elegance,
      keywords: state => state.tgs.keywords,
      search: state => state.e3.search
    })
  },
  methods: {
    getContainer(id, name) {
      let data = {
        id: `tgbus_wap_tgs${version}${id ? '_' + id : ''}`,
        name: 'tgbus-wap tgs' + (name ? name : '')
      };
      return JSON.stringify(data);
    },
    toCardData(item) {
      item.locationlink = item.locationlink || '/tgs/news/' + item.id
      return {
        id: item.id,
        locationlink: item.locationlink,
        banner: item.pic,
        titlefull: item.title,
        tags: item.tags,
        displaydate: item.date,
      };
    },
  },
  components: {
    TgSpecialHeaderBar,
    TgSpecialSwiper,
    TgSpecialNavBar,
    TgSpecialCardGroup,
    TgSpecialSwiperHor,
    TgTgsTitle,
    TgSpecialPicPanel
  },
};
</script>
