<template>
  <div class="about">
    <tg-header-bar></tg-header-bar>
    <tg-about-bar></tg-about-bar>
    <tg-about-card></tg-about-card>
    <tg-footer-bar :pcHref="pcHref"></tg-footer-bar>
  </div>
</template>
<script>
import TgAboutCard from '@src/components/about/TgAboutCard.vue';
export default {
  data() {
    return {};
  },
  computed: {
    pcHref() {
      return `https://www.bestjourney.net/about/contact`;
    },
  },
  components: {
    TgAboutCard,
  },
};
</script>
