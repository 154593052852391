<template>
  <div class="tg-cj-purchase">
    <div class="tg-cj-purchase_contentbox">
      <div class="tg-cj-purchase_content">
        <div class="tg-cj-purchase_item"
             @click="openMask('ChinaJoy')">
          <div class="imgbox"><img :src="ChinaJoy.pic"
                 alt=""></div>
          <div class="infobox">
            <p class="title">扫一扫购买</p>
            <p class="name">{{ChinaJoy.name}}</p>
          </div>
        </div>
        <div class="tg-cj-purchase_item"
             @click="openMask('BtoB')">
          <div class="imgbox"><img :src="BtoB.pic"
                 alt=""></div>
          <div class="infobox">
            <p class="title">扫一扫购买</p>
            <p class="name">{{BtoB.name}}</p>
          </div>
        </div>
      </div>
      <div v-show="maskIsShow"
           class="tg-cj-purchase_mask">
        <div @click="closeMask">
          <div class="content">
            <div class="closebox">
              <img class="close"
                   @click="closeMask"
                   src="../../../assets/special/close.png"
                   alt="">
            </div>
            <div class="main"
                 @click.stop>
              <img :src="bigPic">
              <p class="title">扫一扫购买</p>
              <p class="name">{{name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TgPurchase',
  data() {
    return {
      ChinaJoy: {
        name: 'ChinaJoy电子票',
        pic: require(`../../../assets/cj/ChinaJoy.png`)
      },
      BtoB: {
        name: 'BtoB及会议门票',
        pic: require(`../../../assets/cj/BtoB.png`)
      },
      maskIsShow: false,
      name: '',
      bigPic: '',
    };
  },
  methods: {
    openMask(type) {
      if (type === 'ChinaJoy') {
        this.name = this.ChinaJoy.name;
        this.bigPic = this.ChinaJoy.pic;
      } else {
        this.name = this.BtoB.name;
        this.bigPic = this.BtoB.pic;
      }
      this.maskIsShow = true;
    },
    closeMask() {
      this.maskIsShow = false;
    },
  },
};
</script>

