<template>
  <div class="tg-shop">
    <div class="tg-shop_category">
      <span :class="{'is-active': currentIndex == 1}"
            @click="changeTab(1)">现货</span>
      <span :class="{'is-active': currentIndex == 2}"
            @click="changeTab(2)">预售</span>
      <span :class="{'is-active': currentIndex == 3}"
            @click="changeTab(3)">配件</span>
      <span :class="{'is-active': currentIndex == 4}"
            @click="changeTab(4)">周边</span>
    </div>
    <div class="tg-shop_news"
         v-for="index in [1, 2, 3, 4]"
         :key="index"
         v-show="index == currentIndex">
      <div v-if="index == 1 || index == 2"
           :data-container='`{"id": "tgbus_wap_home_shop_${index}_ps4", "name": "tgbus-wap 首页巴士商城_ps4" }`'>
        <span class="tg-shop_news-title">PS4</span>
        <tg-home-shop-item class="tg-shop_item"
                           v-for="(item, index) in shopPS4News"
                           :data="{'banner': item.sdata.ad_img_detail.imgsrc, 'link': item.sdata.itemlink, 'title': item.sdata.itemtitle, 'price': item.sdata.discountminprice / 100}"
                           :key="index"></tg-home-shop-item>

        <div class="tg-shop_loading"
             v-if="!loading && shopPS4News.length == 0">
          暂无数据
        </div>
      </div>
      <div v-if="index == 1 || index == 2"
           :data-container='`{"id": "tgbus_wap_home_shop_${index}_switch", "name": "tgbus-wap 首页巴士商城_switch" }`'>
        <span class="tg-shop_news-title">Switch</span>
        <tg-home-shop-item class="tg-shop_item"
                           v-for="(item, index) in shopSwitchNews"
                           :data="{'banner': item.sdata.ad_img_detail.imgsrc, 'link': item.sdata.itemlink, 'title': item.sdata.itemtitle, 'price': item.sdata.discountminprice / 100}"
                           :key="index"></tg-home-shop-item>

        <div class="tg-shop_loading"
             v-if="!loading && shopSwitchNews.length == 0">
          暂无数据
        </div>
      </div>
      <div v-if="index == 3 || index == 4"
           :data-container='`{"id": "tgbus_wap_home_shop_${index}", "name": "tgbus-wap 首页巴士商城" }`'>
        <tg-home-shop-item class="tg-shop_item"
                           v-for="(item, index) in hotNews"
                           :data="{'banner': item.sdata.ad_img_detail.imgsrc, 'link': item.sdata.itemlink, 'title': item.sdata.itemtitle, 'price': item.sdata.discountminprice / 100}"
                           :key="index"></tg-home-shop-item>

        <div class="tg-shop_loading"
             v-if="!loading && hotNews.length == 0">
          暂无数据
        </div>
      </div>
      <div class="tg-shop_loading"
           v-if="loading">
        <loading :show="true"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import isDevelopment from '@src/utils/isDevelopment';
import TgHomeShopItem from '@src/components/home/shop/shop-item/TgShopItem';
export default {
  data() {
    return {
      currentIndex: 1,
    };
  },
  async asyncData({ route, store }) {
    await Promise.all([
      store.dispatch('home/INIT_DATA'),
      store.dispatch('home/FETCH_HOME_SHOP', 1),
    ]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: '首页',
      meta: `
  <meta name="description" content="bestjourney.net 中国综合游戏门户">
`,
    };
  },
  async mounted() {
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },
  computed: {
    hotNews() {
      return this.$store.state.home.hotNews;
    },
    shopPS4News() {
      return this.$store.state.home.shopPS4News;
    },
    shopSwitchNews() {
      return this.$store.state.home.shopSwitchNews;
    },
    loading() {
      return this.$store.state.home.loading;
    },
  },
  components: {
    TgHomeShopItem,
  },
  methods: {
    changeTab(index) {
      if (this.currentIndex != index) {
        this.currentIndex = index;
        this.$store.dispatch('home/INIT_DATA');
        this.$store.dispatch('home/FETCH_HOME_SHOP', this.currentIndex);
      }
    },
  },
};
</script>