<template>
  <div class="container">
    <div class="body">
      <div class='service_content'>
        <p class='bold'>电玩巴士TGBUS网络服务使用协议</p>
        <p class='bold'>1 特别提示</p>
        <p class='bold'>1.1</p>
        <p>TGBUS电玩巴士同意按照本协议的规定及其不时发布的操作规则提供基于互联网以及移动网的相关服务（以下称“网络服务”），为获得网络服务，服务使用人（以下称“用户”）应当同意本协议的全部条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击“注册”按钮即表示用户完全接受本协议项下的全部条款。</p>
        <p class='bold'>1.2</p>
        <p>用户注册成功后，TGBUS电玩巴士将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</p>
        <p class='bold'>2 服务内容</p>
        <p class='bold'>2.1</p>
        <p>TGBUS电玩巴士网络服务的具体内容由TGBUS电玩巴士根据实际情况提供，例如搜索、论坛(BBS)、电子邮件、发表新闻评论等。</p>
        <p class='bold'>2.2</p>
        <p>用户理解，TGBUS电玩巴士仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。</p>
        <p class='bold'>3 服务变更、中断或终止</p>
        <p class='bold'>3.1</p>
        <p>鉴于网络服务的特殊性，用户同意TGBUS电玩巴士有权随时变更、中断或终止部分或全部的网络服务。TGBUS电玩巴士无需通知用户，也无需对任何用户或任何第三方承担任何责任。</p>
        <p class='bold'>3.2</p>
        <p>用户理解，TGBUS电玩巴士需要定期或不定期地对提供网络服务的平台（如互联网网站等）或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，TGBUS电玩巴士无需为此承担任何责任，但TGBUS电玩巴士应尽可能事先进行通告。</p>
        <p class='bold'>3.3</p>
        <p>如发生下列任何一种情形，TGBUS电玩巴士有权随时中断或终止向用户提供本协议项下的网络服务而无需对用户或任何第三方承担任何责任：</p>
        <ol>
          <li>3.3.1 用户提供的个人资料不真实；</li>
          <li>3.3.2 用户违反本协议中规定的使用规则；</li>
        </ol>
        <p class='bold'>4 使用规则</p>
        <p class='bold'>4.1</p>
        <p>用户在申请使用TGBUS电玩巴士网络服务时，必须向TGBUS电玩巴士提供准确的个人资料，如个人资料有任何变动，必须及时更新。</p>
        <p class='bold'>4.2</p>
        <p>用户不应将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用，应立即通知TGBUS电玩巴士。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，TGBUS电玩巴士不承担任何责任。</p>
        <p class='bold'>4.3</p>
        <p>用户同意TGBUS电玩巴士有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在TGBUS电玩巴士网站的任何页面上投放广告），并且，用户同意接受TGBUS电玩巴士通过电子邮件或其他方式向用户发送商品促销或其他相关商业信息。</p>
        <p class='bold'>4.4</p>
        <p>对于用户通过TGBUS电玩巴士网络服务（包括但不限于论坛、BBS、新闻评论、个人中心）上传到TGBUS电玩巴士网站上可公开获取区域的任何内容，用户同意TGBUS电玩巴士在全世界范围内具有免费的、永久性的、不可撤销的、非独家的（特定版块TGBUS电玩巴士与用户有特别约定的除外）和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</p>
        <p class='bold'>4.5</p>
        <p>用户在使用TGBUS电玩巴士网络服务过程中，必须遵循以下原则：</p>
        <ol>
          <li>4.5.1 遵守中国有关的法律和法规；</li>
          <li>4.5.2 遵守所有与网络服务有关的网络协议、规定和程序；</li>
          <li>4.5.3 遵守TGBUS电玩巴士制定的使用细则(包括但不限于各版面的版规)；</li>
          <li>4.5.4 不得为任何非法目的而使用网络服务系统；</li>
          <li>4.5.5 不得以任何形式使用TGBUS电玩巴士网络服务侵犯TGBUS电玩巴士的商业利益，包括并不限于发布非经TGBUS电玩巴士许可的商业广告；</li>
          <li>4.5.6 不得利用TGBUS电玩巴士网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；</li>
          <li>4.5.7 不得利用TGBUS电玩巴士提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</li>
          <li>4.5.8 不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；</li>
          <li>4.5.9 不得利用TGBUS电玩巴士网络服务系统进行任何不利于TGBUS电玩巴士的行为；</li>
        </ol>
        <p class='bold'>4.6</p>
        <p>TGBUS电玩巴士有权对用户使用TGBUS电玩巴士网络服务的情况进行审查和监督(包括但不限于对用户存储在TGBUS电玩巴士的内容进行审核)，如用户在使用网络服务时违反任何上述规定，TGBUS电玩巴士或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容等、暂停或终止用户使用服务的权利）以减轻用户不当行为造成的影响。</p>
        <p class='bold'>4.7</p>
        <p>TGBUS电玩巴士针对某些特定的TGBUS电玩巴士网络服务的使用通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，用户如使用该等TGBUS电玩巴士网络服务，视为用户同意该等声明、通知、警示的内容。</p>
        <p class='bold'>5 知识产权</p>
        <p class='bold'>5.1</p>
        <p>TGBUS电玩巴士提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。TGBUS电玩巴士不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。</p>
        <p class='bold'>5.2</p>
        <p>TGBUS电玩巴士为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（reverse engineer）、反向编译（decompile）或反汇编（disassemble）。</p>
        <p class='bold'>6 隐私保护</p>
        <p class='bold'>6.1</p>
        <p>保护用户隐私是TGBUS电玩巴士的一项基本政策，TGBUS电玩巴士保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在TGBUS电玩巴士的非公开内容，不会将用户信息（包括地理位置）和手机信息泄漏给第三方，但下列情况除外：</p>
        <ol>
          <li>6.1.1 事先获得用户的明确授权；</li>
          <li>6.1.2 根据有关的法律法规要求；</li>
          <li>6.1.3 按照相关政府主管部门的要求；</li>
          <li>6.1.4 为维护社会公众的利益；</li>
          <li>6.1.5 为维护TGBUS电玩巴士的合法权益。</li>
        </ol>
        <p class='bold'>6.2</p>
        <p>TGBUS电玩巴士可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与TGBUS电玩巴士同等的保护用户隐私的责任，则TGBUS电玩巴士有权将用户的注册资料等提供给该第三方。</p>
        <p class='bold'>6.3</p>
        <p>在不透露单个用户隐私资料的前提下，TGBUS电玩巴士有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
        <p class='bold'>7 免责声明</p>
        <p class='bold'>7.1</p>
        <p>用户明确同意其使用TGBUS电玩巴士网络服务所存在的风险将完全由其自己承担；因其使用TGBUS电玩巴士网络服务而产生的一切后果也由其自己承担，TGBUS电玩巴士对用户不承担任何责任。</p>
        <p class='bold'>7.2</p>
        <p>TGBUS电玩巴士不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</p>
        <p class='bold'>7.3</p>
        <p>TGBUS电玩巴士不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由TGBUS电玩巴士实际控制的任何网页上的内容，TGBUS电玩巴士不承担任何责任。</p>
        <p class='bold'>7.4</p>
        <p>对于因不可抗力或TGBUS电玩巴士不能控制的原因造成的网络服务中断或其它缺陷，TGBUS电玩巴士不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
        <p class='bold'>7.5</p>
        <p>用户同意，对于TGBUS电玩巴士向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，TGBUS电玩巴士无需承担任何责任：</p>
        <ol>
          <li>7.5.1 TGBUS电玩巴士向用户免费提供的各项网络服务；</li>
          <li>7.5.2 TGBUS电玩巴士向用户赠送的任何产品或者服务；</li>
          <li>7.5.3 TGBUS电玩巴士向收费网络服务用户附赠的各种产品或者服务。</li>
        </ol>
        <p class='bold'>8 违约赔偿</p>
        <p class='bold'>8.1</p>
        <p>如因TGBUS电玩巴士违反有关法律、法规或本协议项下的任何条款而给用户造成损失，TGBUS电玩巴士同意承担由此造成的损害赔偿责任。</p>
        <p class='bold'>8.2</p>
        <p>用户同意保障和维护TGBUS电玩巴士及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给TGBUS电玩巴士或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</p>
        <p class='bold'>9 协议修改</p>
        <p class='bold'>9.1</p>
        <p>TGBUS电玩巴士有权随时修改本协议的任何条款，一旦本协议的内容发生变动，TGBUS电玩巴士将会直接在TGBUS电玩巴士网站上公布修改之后的协议内容，该公布行为视为TGBUS电玩巴士已经通知用户修改内容。TGBUS电玩巴士也可通过其他适当方式向用户提示修改内容。</p>
        <p class='bold'>9.2</p>
        <p>如果不同意TGBUS电玩巴士对本协议相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受TGBUS电玩巴士对本协议相关条款所做的修改。</p>
        <p class='bold'>10 通知送达</p>
        <p class='bold'>10.1</p>
        <p>本协议项下TGBUS电玩巴士对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</p>
        <p class='bold'>10.2</p>
        <p>用户对于TGBUS电玩巴士的通知应当通过TGBUS电玩巴士对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</p>
        <p class='bold'>11 法律管辖</p>
        <p class='bold'>11.1</p>
        <p>本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。</p>
        <p class='bold'>11.2</p>
        <p>如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向TGBUS电玩巴士所在地的人民法院提起诉讼。</p>
        <p class='bold'>12 其他规定</p>
        <p class='bold'>12.1</p>
        <p>本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。</p>
        <p class='bold'>12.2</p>
        <p>如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
        <p class='bold'>12.3</p>
        <p>本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutService',
  data() {
    return {};
  },
};
</script>


<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.body {
  padding: 0.0375rem;
  .service_content {
    p {
      font-size: 0.043rem;
      line-height: 0.06rem;
      margin-bottom: 0.05rem;
      &.bold {
        font-weight: bold;
      }
    }
    ol {
      list-style: none outside none;
      padding: 0;
      li {
        font-size: 0.043rem;
        line-height: 0.06rem;
        margin-bottom: 0.05rem;
      }
    }
  }
}
</style>
