const hasSource = ['pic', 'title', 'date', 'id', 'locationlink']
export const groupName = 'tgbus_wap_general'
export const pageName = 'ps5home'
export const containers = {
  ps5Home: {
    banner: {
      containerId: `${groupName}_${pageName}_banner`,
      containerName: `${pageName}-banner`,
      hasSource
    },
    hotNews: {
      containerId: `${groupName}_${pageName}_hotNews`,
      containerName: `${pageName}-热门`,
      hasSource
    },
    viewpoint: {
      containerId: `${groupName}_${pageName}_viewpoint`,
      containerName: `${pageName}-巴士观点`,
      hasSource
    }
  }
}