import actions from './actions';
import mutations from './mutations';
const state = {
  hotSearchKeyWords: [],
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
