/**
 * 推荐页面
 */
import { home, article, general } from '../../../api/cms';
import { e3C } from '../../e3.containers.config';
import * as types from '../../mutations-types';
const initRequest = container => {
  let promises = [];
  for (var key in container) {
    let query = `{
      groups(group_name:"tgbus_wap_e3") {
        status`;
    for (var item of container[key]) {
      query =
        query +
        `
      ${item.containerId}:containers(container_id:"${item.containerId}") {
          container_name
          container_id
          hasSource {
            ${item.hasSource}
          }
        }`;
    }
    query =
      query +
      `
      }
    }`;
    promises.push(home.homeAPI({ query: query }));
  }
  return promises;
};

const zoneTags = [
  'E3',
  'E3图赏,E3',
  'E3视频,E3',
  'E3采访,E3',
  'E3试玩,E3',
  'E3任天堂,E3',
  'E3微软,E3',
  'E3育碧,E3',
  'E3世嘉,E3',
  'E3SE,E3',
  'E3万代南梦宫,E3',
];

export default {
  INIT_DATA: ({ state }) => {
    state.banner = [];
    state.exhibitionNews = [];
    state.relate = [];
    state.keywords = [];
    state.loadingMore = false;
    state.search = {
      news: [],
      pageNum: 1,
      loadingMore: false,
      canLoadMore: true,
    };
    state.zoneNews = [];
    state.pageNum = 1;
    state.canLoadMore = true;
    // for (let i = 0; i < zoneTags.length; i++) {
    //   state.zoneNews.push([])
    //   state.pageNums.push(1)
    //   state.canLoadMore.push(true)
    // }
  },
  FETCH: async ({ commit }, index = 0) => {
    let promise = null;
    let promises = initRequest(e3C);
    const queryMore = `{
      list (website: "tgbus", tags:["${zoneTags[index].split(',').join('","')}"]) {
        total
        result(start: 1, offset: 25){
          nid
          banner
          tags
          titlefull
          displaydate
          locationlink
        }
      }
    }`;
    const queryArticle = `{
      news (id: 44579) {
        contentraw
      }
    }`;
    promise = Promise.all([
      ...promises,
      home.homeAPI({ query: queryMore }),
      article.articleAPI({ query: queryArticle })
    ]).then(resC => {
      commit(types.E3_SET_BANNER, resC[0])
      commit(types.E3_SET_EXHIBITION_NEWS, resC[1])
      commit(types.E3_SET_RELATE, resC[2])
      commit(types.E3_SET_ZONE_NEWS, { res: resC[3], index })
      commit(types.E3_SET_SEARCH_KEYWORDS, resC[4])
    });
    return promise;
  },
  FETCH_MORE: ({ commit, state }, { index = 0, isFirst = false }) => {
    state.loadingMore = true
    state.pageNum = state.pageNum + 1
    if (isFirst) {
      state.pageNum = 1
      state.zoneNews = []
      state.canLoadMore = true
    }
    let queryMore =
      `{
      list (website: "tgbus", tags: ["${zoneTags[index].split(',').join('","')}"]) {
        total
        result(start: ` +
      state.pageNum +
      `, offset: 25){
          nid
          banner
          tags
          titlefull
          displaydate
          locationlink
        }
      }
    }`;
    home.homeAPI({ query: queryMore }).then(res => {
      commit(types.E3_SET_ZONE_NEWS, { res, index });
    });
  },
  INIT_SEARCH_NEWS: ({ state }) => {
    state.search = {
      news: [],
      pageNum: 1,
      loadingMore: false,
      canLoadMore: true,
    };
  },
  FETCH_SEARCH_NEWS: ({ commit, state }, keyword) => {
    state.search.loadingMore = true;
    let query =
      `{
      search (website: "tgbus", keyword: "` +
      keyword +
      `") {
        total
        result(start: ` +
      state.search.pageNum +
      `, offset: 15){
          nid
          banner
          tags
          titlefull
          displaydate
          locationlink
        }
      }
    }`;
    general.generalAPI({ query: query }).then(res => {
      commit(types.E3_SET_SEARCH_NEWS, res);
    });
  },
};
