<template>
  <div class="container">
    <div class="body">
      <div class="privacy_content">
        <p class="bold">引言</p>
        <p>
          北京月神之箭网络科技有限公司（公司地址：北京市海淀区宝盛东路兴华绿色产业楼3层309室，联系电话：010-60845018，以下简称“我们”）是电玩巴士APP（以下简称“电玩巴士”）的开发运营者，我们非常重视用户的隐私。您在使用我们的服务时，我们会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私政策》与您所使用的电玩巴士服务息息相关，希望您仔细阅读，在需要时，按照本《隐私政策》的指引，做出您认为适当的选择。本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您的理解。
        </p>
        <p>
          我们提供服务时，会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
        </p>
        <p class="bold">您提供的信息</p>
        <p>
          您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件或银行卡号等；
        </p>
        <p>
          您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。
        </p>
        <p>其他方分享的您的信息</p>
        <p>其他方使用我们的服务时所提供有关您的共享信息。</p>
        <p class="bold">我们获取的您的信息</p>
        <p>您使用我们服务时我们会收集如下信息：</p>
        <p>
          日志信息指您使用我们服务时，系统会通过cookies、web
          beacon或其他方式自动采集的技术信息，包括：
        </p>
        <p>
          设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；
        </p>
        <p>
          在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；
        </p>
        <p>
          您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等
        </p>
        <p>
          位置信息指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：
        </p>
        <p>
          您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息；
        </p>
        <p>
          您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息；
        </p>
        <p>您可以通过关闭定位功能，停止对您的地理位置信息的收集。</p>
        <p class="bold">我们如何使用信息</p>
        <p>我们将在向您提供服务的过程之中所收集的信息用作下列用途：向您提供服务；</p>
        <p>
          在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
        </p>
        <p>帮助我们设计新服务，改善我们现有服务；</p>
        <p>
          使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；
        </p>
        <p>向您提供的产品和服务的安全性；</p>
        <p>评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；</p>
        <p>软件认证或管理软件升级；</p>
        <p>让您参与有关我们产品和服务的调查。</p>
        <p>
          为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
        </p>
        <p class="bold">您如何访问和控制自己的个人信息</p>
        <p>
          我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们会要求您进行身份验证，以保障账户安全。
        </p>
        <p>
          您可在 我的-设置-账号安全
          中选择执行注销账号，在满足注销条件的前提下可直接注销账号。敬请您注意并知悉，注销账号是不可撤销且不可逆转的操作，账号注销后您曾通过该账号登录、使用相关产品与服务下的所有内容、信息、数据、记录将会被全部删除，您也无法再检索、访问、获取、继续使用和找回这些信息或数据。
        </p>
        <p>注销条件：</p>
        <p>1.账号注销过程中需验证手机号，未绑定手机号的用户不可申请注销</p>
        <p>2.账号当前为“正常”状态，被禁言用户需在禁言期过后申请注销</p>
        <p>3.账号需在15天内无交易记录</p>
        <p>4.账号下的所有订单状态如有“未完成”或涉及售后状态，需完成订单后申请注销</p>
        <p class="bold">我们分享的信息</p>
        <p>
          除以下情形外，未经您同意，我们以及我们的关联公司不会与任何第三方分享您的个人信息：
        </p>
        <p>
          我们以及我们的关联公司，将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享（他们可能并非位于您所在的法域），用作下列用途：
        </p>
        <p>向您提供我们的服务；</p>
        <p>实现“我们如何使用信息”部分所述目的；</p>
        <p>履行我们在《电玩巴士服务协议》或本《隐私政策》中的义务和行使我们的权利；</p>
        <p>理解、维护和改善我们的服务。</p>
        <p>
          如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施。
        </p>
        <p>
          随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。
        </p>
        <p>我们或我们的关联公司还可能为以下需要而保留、保存或披露您的个人信息：</p>
        <p>遵守适用的法律法规；</p>
        <p>遵守法院命令或其他法律程序的规定；</p>
        <p>遵守相关政府机关的要求；</p>
        <p>
          我们认为为遵守适用的法律法规、维护社会公共利益、或保护我们或我们的集团公司、我们的客户、其他用户或雇员的人身和财产安全或合法权益所合理必需的。
        </p>
        <p class="bold">信息安全</p>
        <p>
          我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。
          我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
        </p>
        <p class="bold">您分享的信息</p>
        <p>
          我们的多项服务，可让您不仅与自己的社交网络，也与使用该服务的所有用户公开分享您的相关信息，例如，您在我们的服务中所上传或发布的信息（包括您公开的个人信息、您建立的名单）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用我们服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。特别是，我们的社交媒体服务，是专为使您与世界各地的用户共享信息而设计，您可以使共享信息实时、广泛地传递。只要您不删除共享信息，有关信息会一直留存在公共领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公共领域保存
        </p>
        <p>
          因此，请您谨慎考虑通过我们的服务上传、发布和交流的信息内容。在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。如要求从我们的服务中删除您的相关信息，请通过该等特别服务条款提供的方式操作。
        </p>
        <p class="bold">您分享的敏感个人信息</p>
        <p>
          某些个人信息因其特殊性可能被认为是敏感个人信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，敏感个人信息受到更加严格的保护。
        </p>
        <p>
          请注意，您在使用我们的服务时所提供、上传或发布的内容和信息（例如有关您社交活动的照片等信息），可能会泄露您的敏感个人信息。您需要谨慎地考虑，是否在使用我们的服务时披露相关敏感个人信息。
        </p>
        <p>您同意按本《隐私政策》所述的目的和方式来处理您的敏感个人信息。</p>
        <p class="bold">权限获取说明</p>
        <p>
          为了向您提供服务以及保障您的账户安全，我们可能需要在必要范围内向您申请获取下列权限。请您放心，我们不会默认开启这些权限，仅在您同意授权对应权限的情况下，才有可能通过这些权限收集您的信息。需要特别说明的是，我们获取具体某一项权限并不代表我们必然会收集您的相关信息；即使您已经确认向我们开启权限，我们也仅会在合法、正当、必要的范围内收集您的相关信息。
        </p>
        <p>
          读取及写入权限：当您安装电玩巴士时，电玩巴士会向您请求授权此权限，目的是为了保证电玩巴士的稳定运行，并承诺电玩巴士仅读取或缓存必要的信息。如拒绝此项授权，您将无法使用与此权限相关的功能，但不影响您正常使用电玩巴士的其他功能。
        </p>
        <p>
          相机及相册权限：当您使用相机拍摄照片作为头像、上传分享您喜爱的图片等功能时，电玩巴士会请求您授权开启相机权限和/或相册权限（以您使用的具体功能为准）。如拒绝此项授权，您将无法使用与拍摄或图片上传相关的功能，但不影响您正常使用电玩巴士的其他功能。
        </p>
        <p>
          位置信息权限：当您发布评论回复、发帖是分享发帖地点和相关功能时，电玩巴士会向您请求授权此权限。如拒绝此项授权，您将无法使用与此权限相关的功能，但不影响您正常使用电玩巴士的其他功能。
        </p>
        <p>
          读取电话状态权限：为了保证电玩巴士的运营的质量及效率，当您在使用电玩巴士的过程中，电玩巴士会在您选择同意的情况下收取您使用的硬件品牌型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC）、网络环境、网络运营商、屏幕分辨率等。
        </p>
        <p>
          日历权限：当您在游戏发售表中使用预约功能时，电玩巴士会向您请求授权此权限。如拒绝此项授权，您将无法使用与此权限相关的功能，但不影响您正常使用电玩巴士的其他功能。
        </p>
        <p>
          通知权限：当您选择接收最新游戏资等而使用消息推送功能时，电玩巴士会向您请求授权此权限。如拒绝此项授权，您将无法使用与此权限相关的功能，但不影响您正常使用电玩巴士的其他功能。
        </p>
        <p class="bold">我们如何收集信息</p>
        <p>
          我们或我们的第三方合作伙伴，通过cookies和web
          beacon收集和使用您的信息，并将该等信息储存为日志信息。
        </p>
        <p>
          我们使用自己的cookies和web
          beacon，目的是为您提供更个性化的用户体验和服务，并用于以下用途：
        </p>
        <p>
          记住您的身份。例如：cookies和web
          beacon有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；
        </p>
        <p>
          分析您使用我们服务的情况。例如，我们可利用cookies和web
          beacon来了解您使用我们的服务进行什么活动，或哪些网页或服务最受您的欢迎；
        </p>
        <p>
          广告优化。Cookies和web
          beacon有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。
        </p>
        <p>
          我们为上述目的使用cookies和web beacon的同时，将通过cookies和web
          beacon收集的非个人身份信息，经统计加工后提供给广告商或其他合作伙伴，用于分析用户如何使用我们的服务，并用于广告服务。
        </p>
        <p>
          我们的产品和服务上会有广告商或其他合作方放置的cookies和web
          beacon。这些cookies和web
          beacon会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方cookies和web
          beacon收集和使用该等信息，不受本《隐私政策》约束，而是受相关使用者的隐私政策约束，我们不对第三方的cookies或web
          beacon承担责任。
        </p>
        <p>
          您可以通过浏览器设置拒绝或管理cookies或web
          beacon。但请注意，如果停用cookies或web
          beacon，您有可能无法享受最佳的服务体验，某些服务也可能无法正常使用。同时，您还会收到同样数量的广告，但这些广告与您的相关性会降低。
        </p>
        <p class="bold">广告相关隐私信息及共享</p>
        <p>
          广告推送：我们与委托我们进行推广和广告投放的合作伙伴共享信息，但我们不会因此共享用于识别您个人身份的信息（姓名、身份证号、手机号），仅会向这些合作伙伴提供不能识别您个人身份的间接画像标签及去标识化或匿名化后的信息，以帮助其在不识别您个人身份的前提下提升广告有效触达率。我们承诺：未经您的同意，我们不会与其共享可以识别您身份的个人信息。
        </p>
        <p>
          广告统计与反作弊：我们与广告业务的服务商和其他合作伙伴共享分析去标识化的统计信息，这些信息难以与您的身份信息相关联，这些信息将帮助我们分析、衡量产品内广告的有效性、判断广告作弊行为。我们会收集您的位置信息及相关设备信息（例如硬件品牌型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC）、网络环境、网络运营商、屏幕分辨率等）经过去标识化后自行使用或提供给前述服务商。
        </p>
        <p>
          第三方广告SDK：我们的产品中除了自研SDK之外，会包含第三方SDK或其他类似的应用程序（例如：腾讯广告SDK、穿山甲广告SDK、快手联盟广告SDK），第三方SDK在我们平台上提供广告推送和广告统计等广告必要服务时，您同意将由其直接收集和处理您的去标识化信息（例如包含位置信息、设备信息、必要的应用安装信息（用于跳转到目标应用）。前述SDK或应用程序收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。
        </p>
        <p class="bold">邮件和信息推送</p>
        <p>
          您在使用我们的服务时，我们使用您的信息向您的设备发送电子邮件、或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。
        </p>
        <p>与服务有关的公告</p>
        <p>
          我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
        </p>
        <p class="bold">隐私政策的适用例外</p>
        <p>
          我们的服务可能包括或链接至第三方提供的社交媒体或其他服务（包括网站）。例如：
        </p>
        <p>
          您利用
          “分享”键将某些内容分享到我们的服务，或您利用第三方连线服务登录我们的服务。这些功能可能会收集您的相关信息（包括您的日志信息），并可能在您的电脑装置cookies，从而正常运行上述功能；
        </p>
        <p>
          我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站。
        </p>
        <p>
          该等第三方社交媒体或其他服务可能由相关的第三方或我们运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非《通用服务条款》或本《隐私政策》）约束，您需要仔细阅读其条款。
        </p>
        <p>
          本《隐私政策》仅适用于我们所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任。
        </p>
        <p class="bold">未成年人使用我们的服务</p>
        <p>
          我们鼓励父母或监护人指导未满十八岁的未成年人使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交的个人信息之前寻求父母或监护人的同意和指导。
        </p>
        <p class="bold">隐私政策的适用范围</p>
        <p>
          除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本《隐私政策》的一部分。如相关特定服务的隐私政策与本《隐私政策》有不一致之处，适用该特定服务的隐私政策。
        </p>
        <p>
          除本《隐私政策》另有规定外，本《隐私条款》所用词语将与《电玩巴士服务协议》所定义的词语具有相同的涵义。
        </p>
        <p>请您注意，本《隐私政策》不适用于以下情况：</p>
        <p>通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；</p>
        <p>通过在我们服务中进行广告服务的其他公司或机构所收集的信息。</p>
        <p class="bold">变更</p>
        <p>
          我们可能适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
        </p>
        <h2>涉及的第三方SDK说明</h2>
        <p class="dn-p-indent">第三方SDK收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。为了最大程度保障您的信息安全，我们建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。</p>
        <table>
            <tr>
                <th>SDK服务</th>
                <th>具体功能</th>
                <th>客户端</th>
                <th>获取权限或信息</th>
                <th>第三方隐私政策</th>
            </tr>
            <tr>
                <td>腾讯优量汇SDK</td>
                <td>程序化广告推送、广告监测与归因</td>
                <td>安卓、iOS</td>
                <td>
                    <ul>
                        <li>·粗略位置信息</li>
                        <li>·设备信息（如设备品牌、设备制造商、设备型号、操作系统版本等）、设备标识符（如IMEI、IDFA等）</li>
                        <li>·应用信息（宿主应用的包名、版本号）</li>
                    </ul>
                </td>
                <td>
                    <a href="https://qzs.gdtimg.com/union/res/union_cdn/page/dev_rules/ylh_sdk_privacy_statement.html">点击查看</a>
                </td>
            </tr>
            <tr>
                <td>穿山甲SDK</td>
                <td>广告投放合作、广告监测与归因、反作弊、崩溃监测</td>
                <td>安卓、iOS</td>
                <td>
                    <ul>
                        <li>·设备信息：设备品牌、型号、软件、设备标识符、系统版本相关信息、手机系统重启时间、磁盘总空间、系统总内存空间、CPU数目等基础信息</li>
                        <li>·应用信息：应用名、应用包名、版本号、应用安装列表等信息</li>
                        <li>·其他信息：运营商信息、设备时区、设备语言</li>
                    </ul>
                </td>
                <td>
                    <a href="https://www.csjplatform.com/privacy/partner">点击查看</a>
                </td>
            </tr>
            <tr>
                <td>QQ互联SDK</td>
                <td>QQ登录和分享功能</td>
                <td>安卓</td>
                <td>
                    <ul>
                        <li>·设备信息：操作系统信息、设备型号信息、应用列表信息等</li>
                    </ul>
                </td>
                <td>
                    <a href="https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e">点击查看</a>
                </td>
            </tr>
            <tr>
                <td>微信Open SDK</td>
                <td>微信登录和分享功能</td>
                <td>安卓</td>
                <td>
                    <ul>
                        <li>·设备信息（设备型号、操作系统版本等）、设备标识符（如IMEI、IDFA等）</li>
                    </ul>
                </td>
                <td>
                    <a href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8">点击查看</a>
                </td>
            </tr>
        </table>
        <p></p>
        <p class="bold">其他补充说明</p>
        <p>
          我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android
          ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
          信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。
        </p>
        <p>更新日期：2022-07-19</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPrivacy',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.body {
  padding: 0.0375rem;
  .privacy_content {
    p {
      font-size: 0.043rem;
      line-height: 0.06rem;
      margin-bottom: 0.05rem;
      &.bold {
        font-weight: bold;
      }
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border:1px solid #ddd;
  td, th {
    padding: 10px;
    border:1px solid #ddd;
  }
}


</style>
