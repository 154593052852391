<template>
  <div class="tg-cj-title">
    <div class="tg-cj-title_content">
      {{title}}
      <div class="bottomLine"></div>
    </div>
    <a v-if="moreUrl"
       :href="moreUrl"
       class="tg-cj-title_more">查看更多</a>
  </div>
</template>

<script>
export default {
  name: 'tg-cj-title',
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    moreUrl: {
      type: String,
      default: '',
      require: false,
    },
  },
};
</script>

