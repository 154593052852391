<template>
  <div class="tg-sales">
    <div class="tg-sales_category">
      <span :class="{'is-active': currentIndex == 1}"
            @click="changeTab(1)">PS5</span>
      <span :class="{'is-active': currentIndex == 2}"
            @click="changeTab(2)">Switch</span>
      <span :class="{'is-active': currentIndex == 3}"
            @click="changeTab(3)">PS4</span>
      <span :class="{'is-active': currentIndex == 4}"
            @click="changeTab(4)">Xbox</span>
      <span :class="{'is-active': currentIndex == 5}"
            @click="changeTab(5)">PC</span>
    </div>
    <div class="tg-sales_news">
      <tg-news-item v-for="(item, index) in hotNews"
                    :isGame="true"
                    :key="index"
                    class="tg-sales_item"
                    :data="toNewsItem(item)"></tg-news-item>
      <div class="tg-sales_loading"
           v-if="loading">
        <loading :show="true"></loading>
      </div>
      <div class="tg-sales_loading"
           v-else-if="!loading && hotNews.length == 0">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import isDevelopment from '@src/utils/isDevelopment';
import { compareDate } from '@src/filters';
export default {
  data() {
    return {
      currentIndex: 1,
    };
  },
  async asyncData({ route, store }) {
    await Promise.all([
      store.dispatch('home/INIT_DATA'),
      store.dispatch('home/FETCH_HOME_SALES', 0),
    ]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: '首页',
      meta: `
  <meta name="description" content="bestjourney.net 中国综合游戏门户">
`,
    };
  },
  async mounted() {
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },
  computed: {
    hotNews() {
      return this.$store.state.home.hotNews;
    },
    loading() {
      return this.$store.state.home.loading;
    },
  },
  methods: {
    changeTab(index) {
      if (this.currentIndex != index) {
        this.currentIndex = index;
        this.$store.dispatch('home/INIT_DATA');
        this.$store.dispatch('home/FETCH_HOME_SALES', this.currentIndex - 1);
      }
    },
    toNewsItem(item) {
      let isSupportZH = false;
      item.tags.length > 0
        ? item.tags.forEach(element => {
          if (element.name == '支持中文') {
            isSupportZH = true;
            return;
          }
        })
        : (isSupportZH = false);
      return {
        language: isSupportZH ? '中文' : null,
        game: {
          id: item.related_game.id,
          name: item.title,
          names: [
            {
              content: compareDate(item.platforms),
            },
          ],
          platforms: item.platforms.map(item => {
            return {
              name: item.platform,
            };
          }),
          covers: [item.cover],
        },
      };
    },
  },
};
</script>