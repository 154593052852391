// http://thumbor-proxy-xy.bestjourney.net/admin#/
// 游戏库 Thumbor 图片裁剪与缩放尺寸
// width 和 height 其中之一为 0 时表示只进行等比缩放而不裁剪
// 0x0 表示原始尺寸，不进行任何裁剪和缩放
// smart = true 表示裁剪时启用人脸识别与文字识别

/**
 * 本项目中的图片使用语义化 ID （key）
 * 以本项目 tgbus-mobile 的缩写 tgm 作为前缀
 *
 * ID 命名方式：tgm-{name}_{width}_{height}
 *
 * 示例：
 * tgm-article-detail-cover_375_216
 * tgm-article-detail-img_320_180
 *
 * 特殊（游戏库相关图片）：
 * tgm-gl-tilewalls-cover_100_100
 */
export type ThumborKey =
  | 'tgm-article-detail-cover_375_216' // 文章详情页封面
  | 'tgm-gl-game-list-cover_80_80'
  | 'tgm-gl-tilewalls-cover_100_100' // 游戏库 tilewalls 封面
  | 'tgm-gl-rankings-cover_164_80'
  | 'tgm-gl-rankings-cover_40_40'
  | 'tgm-gl-gamedetail-cover_96_0' // 游戏库 游戏详情 封面
  | 'tgm-gl-gamedetail-screenshot_375_0'
  | 'tgm-gl-gamedetail-screenshot-thumbnail_112_63'
  | 'tgm-gl-gamedetail-news-thumbnail_96_54'
  | 'unknown';

/**
 * 将图片路径转换成 Thumbor 缩略图 url
 * @param key 列表ID
 * @param path 图片路径
 */
export function resolve(path: string, key: ThumborKey): string {
  return key && path
    ? `https://xyoss.g.com.cn/xy-production${path.startsWith('/') ? '' : '/'}${path}`
    : '';
}

export function timestampToDateTimeTitle(timeStamp = 0) {
  let date = new Date(timeStamp * 1000);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  let d = date.getDate();
  let h = date.getHours();
  let i = date.getMinutes();
  let s = date.getSeconds();
  return (
    (m > 9 ? m : '0' + m) +
    '/' +
    (d > 9 ? d : '0' + d) +
    ' ' +
    (h > 9 ? h : '0' + h) +
    ':' +
    (i > 9 ? i : '0' + i)
  );
}
