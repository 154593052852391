import actions from './actions';
import mutations from './mutations';
const state = {
  banner: [],
  hotNews: [],
  todayInform: [],
  homeAdv: [],
  deepProfile: [],
  yxsp: [],
  yxgl: [],
  newsloadingState: 1,
  newspageNum: 1,
  sploadingState: 1,
  sppageNum: 1,
  glloadingState: 1,
  glpageNum: 1,

  loading: false,
  shopPS4News: [],
  shopSwitchNews: []
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
