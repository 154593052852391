<template>
  <div class="home tga-main-with-bar-home"
       data-group='{ "id": "tgbus_wap_home", "name": "tgbus-wap推荐首页" }'>
    <tg-nav-tabs></tg-nav-tabs>

    <tg-scroll-view class="tg-sales"
                    direction="vertical"
                    scroller="window"
                    content-wrapper
                    gap="false"
                    back-to-top
                    download>
      <router-view />
    </tg-scroll-view>
    <tg-footer-bar :pcHref="$route.path == '/shop' ? 'https://store.bestjourney.net' : false"></tg-footer-bar>
    <tg-header-bar></tg-header-bar>
    <tg-download-app></tg-download-app>
    <advertisement :id="ad.AD_ID_HOME_FLOAT"
                   class="home_ad-float"
                   type="dropdown"
                   mode="link" />
  </div>
</template>
<script>
import * as ad from '@src/api/ad';
import Advertisement from '@src/components/advertisement';
import TgNavTabs from '@src/components/home/nav-tabs/TgNavTabs';
export default {
  data() {
    return {
      ad,
    };
  },
  components: {
    TgNavTabs,
    Advertisement
  }
};
</script>