<template>
  <div class="tg-cj-nav-bar">
    <ul class="tg-cj-nav-bar_wrapper">
      <li class="tg-cj-nav-bar_item"
          v-for="(item, index) in itemsSource"
          :key="index"
          :class="{'is-active': $route.path === item.url || $route.path === encodeURI(item.url)}">
        <a :href="item.url"
           class="tg-nav-bar_link link">{{item.label}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsSource: [
        {
          label: '首页',
          url: `/chinajoy`,
        },
        {
          label: '头条资讯',
          url: `/chinajoy/list/CJ重点`,
        },
        {
          label: '网游',
          url: `/chinajoy/list/CJ网游`,
        },
        {
          label: '手游',
          url: `/chinajoy/list/CJ手游`,
        },
        {
          label: '人物访谈 ',
          url: `/chinajoy/list/CJ专访`,
        },
        {
          label: '业内声音',
          url: `/chinajoy/list/CJ产业`,
        },
        {
          label: '现场风采',
          url: `/chinajoy/list/CJ现场`,
        },
      ],
      activeUrl: '',
    };
  }
};
</script>

