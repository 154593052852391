<template>
  <div :class="['tg-special-header-bar', 'is-transparent', channel]"
       id="special-home-slide">
    <a class="tg-special-header-bar_left"
       :href="goUrl[channel]">
      <img :src="logoImg[channel]" />
    </a>
    <div class="tg-special-header-bar_right">
      <button class="tg-special-header-bar_button">
        <a @click="toggleSearch()"
           class="tgs-search">
          <img :src="require(`../../../assets/${channel === 'tgs' ? 'tgs' : 'special'}/search.png`)">
        </a>
        <a @click="toggleSideBar()"
           class="tgs-meun"
           v-if="channel !== 'ps5'">
          <img :src="require(`../../../assets/${channel === 'tgs' ? 'tgs' : 'special'}/menu.png`)">
        </a>
      </button>
    </div>
    <tg-special-slide-bar v-model="sideBarShow"
                          :channel="channel"
                          id="special-home-slide"></tg-special-slide-bar>
    <tg-special-search-news :keywords="keywords"
                            :search="search"
                            v-model="searchShow"
                            :channel="channel"
                            id="special-home-search"></tg-special-search-news>
  </div>
</template>

<script>
import TgSpecialSlideBar from '@src/components/special/slide-bar/TgSpecialSlideBar';
import TgSpecialSearchNews from '@src/components/special/search-news/TgSpecialSearchNews';
export default {
  name: 'tg-special-header-bar',
  data() {
    return {
      sideBarShow: false,
      searchShow: false,
      logoImg: {
        tgs: require('../../../assets/tgs/tgs-header.png')
      },
      goUrl: {
        tgs: '/tgs'
      },
    }
  },
  components: {
    TgSpecialSlideBar,
    TgSpecialSearchNews
  },
  props: {
    keywords: {
      type: Array,
      require: false,
      default() {
        return []
      }
    },
    search: {
      type: Object,
      require: false,
      default() {
        return {
          news: [],
          pageNum: 1,
          loadingMore: false,
          canLoadMore: true
        }
      }
    },
    channel: {
      type: String,
      default() {
        return 'special';
      },
    },
  },
  watch: {
    sideBarShow(val, oldval) {
      this.$parent.$emit('sideBarShow', val)
    },
    searchShow(val, oldval) {
      this.$parent.$emit('searchShow', val)
    }
  },
  methods: {
    toggleSideBar() {
      this.sideBarShow = !this.sideBarShow;
      if (this.sideBarShow) {
        document.getElementById('special-home').style.height = document.getElementById('special-home-slide').offsetHeight + 'px'
        document.getElementById('special-home').style.overflow = 'hidden'
      }
    },
    toggleSearch() {
      this.searchShow = !this.searchShow
      if (this.searchShow) {
        document.getElementById('special-home').style.height = document.getElementById('special-home-slide').offsetHeight + 'px'
        document.getElementById('special-home').style.overflow = 'hidden'
      }
    },
    toHome() {
      this.$router.push('/')
    }
  }
};
</script>
