<template>
  <div class="live tga-live-main-with-bar tga-bg_lower">
    <tg-scroll-view direction="vertical"
                    scroller="window"
                    content-wrapper
                    back-to-top>
      <tg-live-detail-header :data="itemData" :readCount="readCount"></tg-live-detail-header>
      <tg-flexbox gutter>
        <tg-flexbox flex="100">
          <tg-live-content-card :data="itemData"
                                :detailData="detailData && detailData"
                                :activeId="activeId"
                                :commentData="commentFormatData"
                                :commentCount="commentCount"
                                @openPreviewPicture="openPreviewPicture"></tg-live-content-card>
        </tg-flexbox>
      </tg-flexbox>
    </tg-scroll-view>
    <tg-header-bar></tg-header-bar>
    <tg-live-detail-preview v-if="previewPictureOk"
                            :previewDate="previewDate"
                            :previewIndex="previewIndex"
                            @closePreviewPicture="closePreviewPicture"></tg-live-detail-preview>
  </div>
</template>

<script>
import TgLiveDetailHeader from '@src/components/live/live-detail-header/TgLiveDetailHeader.vue';
import TgLiveContentCard from '@src/components/live/live-content-card/TgLiveContentCard.vue';
import TgLiveDetailPreview from '@src/components/live/live-detail-preview/TgLiveDetailPreview.vue';
export default {
  data() {
    return {
      activeId: '',
      commentFormatData: [],
      previewPictureOk: false,
      previewDate: {},
      previewIndex: 0,
    };
  },
  mounted() {
    this.activeId = this.$route.params && this.$route.params.id;
    this.init();
    this.$store.dispatch('live/GETDETAILDATA', {
      id: this.activeId,
      limit: 500,
      offset: 0,
      sort: { releaseAt: -1 },
    });
    this.$store
      .dispatch('live/GETCOMMENTDATA', {
        site: 'tgbus',
        page_type: 'live',
        page_id: this.activeId,
        base_id: 0,
        pageSize: 500,
      })
      .then(() => {
        if (this.commentData && this.commentData.length > 0) {
          this.commentListFormat();
        }
      });
    this.$store.dispatch('live/GETCOMMENTCOUNT', {
      site: 'tgbus',
      page_type: 'live',
      page_id: this.activeId,
    });
    this.$store.dispatch('live/FETCHLIVECOUNT', {
      id: this.activeId,
    });
  },
  computed: {
    itemData() {
      return this.$store.state.live.liveItemData;
    },
    detailData() {
      return this.$store.state.live.liveDetailData;
    },
    commentData() {
      return this.$store.state.live.commentData;
    },
    commentCount() {
      return this.$store.state.live.commentCount;
    },
    commentUserInfo() {
      return this.$store.state.live.commentUserInfo;
    },
    readCount() {
      return this.$store.state.live.liveReadCount;
    }
  },
  methods: {
    async init() {
      await this.$store.dispatch('live/FETCHBYID', { id: this.activeId });
      window.document.title = this.itemData.title;
    },
    // 图片预览
    openPreviewPicture(obj) {
      this.previewPictureOk = obj.previewPictureOk;
      this.previewDate = obj.previewDate;
      this.previewIndex = obj.previewIndex;
    },
    // 关闭图片预览
    closePreviewPicture() {
      this.previewPictureOk = false;
      this.previewDate = {};
      this.previewIndex = 0;
    },
    commentListFormat() {
      var useridArr = [];
      this.commentData.forEach(val => {
        if (useridArr.indexOf(val.user_id) < 0) {
          useridArr.push(val.user_id);
        }
      });
      this.getUserInfoa(useridArr);
    },
    // 获取用户信息
    getUserInfoa(useridArr) {
      this.$store
        .dispatch('live/GETUSERINFO', {
          ids: JSON.stringify(useridArr),
        })
        .then(() => {
          for (var i in this.commentData) {
            if (this.commentData.hasOwnProperty(i)) {
              var userid = this.commentData[i].user_id;
              for (var j in this.commentUserInfo) {
                if (this.commentUserInfo.hasOwnProperty(j)) {
                  if (Number(this.commentUserInfo[j].id) === Number(userid)) {
                    this.commentData[i]['nickname'] = this.commentUserInfo[
                      j
                    ].detail.nickname;
                  }
                }
              }
            }
          }
          this.commentFormatData = this.commentData;
        });
    },
  },
  components: {
    TgLiveDetailHeader,
    TgLiveContentCard,
    TgLiveDetailPreview,
  },
};
</script>
