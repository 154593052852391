import actions from './actions';
import mutations from './mutations';
const state = {
  liveListData: [],
  liveItemData: {},
  liveDetailData: [],
  commentData: [],
  commentCount: 0,
  commentUserInfo: [],
  liveReadCount: 0,
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
