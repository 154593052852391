import Vue, { CreateElement, VNode } from 'vue';
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';


import { downloadApp, deviceType, downloadUrl } from '@src/utils/downloadApp'

export type ScrollDirection = 'horizontal' | 'vertical';

function clamp(v: number): number {
  return v > 128 ? 128 : v < -128 ? -128 : v;
}

/**
 * Component: ScrollView
 */
@Component
export class TgScrollView extends Vue {
  private static polyfill: boolean = false;

  private deviceType: String = '';
  private beforeMount(): void {
    this.deviceType = deviceType()
  }

  private openOrDownload(): void {
    const { deviceType } = this
    // @ts-ignore
    const downloadInfo = downloadUrl[deviceType]
    downloadInfo && downloadApp({
      downloadUrl: downloadInfo.download,
      openApp: downloadInfo.openApp
    })
  }
  // private beforeMount(): void {
  //   if (!TgScrollView.polyfill && window && window.document && window.document.body) {
  //     window.document.body.addEventListener('touchstart', (event: TouchEvent) => {
  //       event.preventDefault();
  //     });
  //     window.document.body.addEventListener('touchmove', (event: TouchEvent) => {
  //       event.preventDefault();
  //     });
  //     TgScrollView.polyfill = true;
  //     console.log('scroll view polyfill');
  //   }
  // }

  @Prop({
    type: String,
    default: 'vertical',
  })
  public readonly direction!: ScrollDirection;

  @Prop({ type: [Boolean, String], default: true })
  public readonly gap!: boolean | string;

  @Prop({ type: Boolean, default: false })
  public readonly download!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly backToTop!: boolean;

  public get classes(): ClassName {
    return [
      `tgp-direction_${this.direction}`,
      {
        'is-scroller': !this.scroller,
        'is-gap': (typeof this.gap === 'boolean' && this.gap) || this.gap === 'true',
      },
    ];
  }

  @Prop([String, Object])
  public readonly scroller?: string | HTMLElement | Vue;
  private scrollerElement?: HTMLElement | Window | null;

  @Prop([String, Object])
  public readonly wrapper?: string | HTMLElement | Vue;
  private wrapperElements?: (HTMLElement | null | undefined)[] | null;

  @Watch('scroller')
  private watchScroller(
    newValue?: string | HTMLElement | Vue,
    oldValue?: string | HTMLElement | Vue,
  ): void {
    this.scrollerElement = undefined;
    [newValue, oldValue].forEach((item, index) => {
      if (item) {
        let element: HTMLElement | Window | null | undefined;
        if (typeof item === 'string') {
          if (item === 'window') {
            element = window;
          } else {
            element = document.querySelector<HTMLElement>(item);
          }
        } else if ('$el' in item) {
          element = item.$el as HTMLElement;
        } else {
          element = item;
        }
        if (index === 0) {
          this.scrollerElement = element;
        }
        if (element) {
          if (index === 0) {
            element.addEventListener('scroll', this.onScroll);
          } else {
            element.removeEventListener('scroll', this.onScroll);
          }
        }
      }
    });
    if (!newValue) {
      this.scrollerElement = this.$el as HTMLElement;
      this.scrollerElement.addEventListener('scroll', this.onScroll);
    }
  }

  @Watch('wrapper')
  private watchWrapper(
    newValue?: string | HTMLElement | Vue,
    oldValue?: string | HTMLElement | Vue,
  ): void {
    this.$nextTick(() => {
      this.wrapperElements = undefined;
      [newValue, oldValue].forEach((item, index) => {
        if (item) {
          let element: HTMLElement | null | undefined;
          if (typeof item === 'string') {
            element = document.querySelector<HTMLElement>(item);
          } else if ('$el' in item) {
            element = item.$el as HTMLElement;
          } else {
            element = item;
          }
          if (index === 0) {
            this.wrapperElements = [element];
          }
        }
      });
      if (!newValue) {
        if (this.scroller === 'window') {
          this.wrapperElements = [document.documentElement, document.body];
        } else {
          this.wrapperElements = [this.$refs.wrapper];
        }
      }
    });
  }

  private onBackToTop(event: MouseEvent): void {
    const el = this.scrollerElement;
    if (el) {
      el.scroll(0, 0);
    }
  }

  private payload: {
    scrollY: number;
    scrollerHeight: number;
    wrapperHeight: number;
    scrollX: number;
    scrollerWidth: number;
    wrapperWidth: number;
  } = {
      scrollY: 0,
      scrollerHeight: 0,
      wrapperHeight: 0,
      scrollX: 0,
      scrollerWidth: 0,
      wrapperWidth: 0,
    };

  private edges: {
    toTop: boolean;
    toBottom: boolean;
    top: boolean;
    bottom: boolean;
    left: boolean;
    right: boolean;
  } = {
      toTop: false,
      toBottom: false,
      top: false,
      bottom: false,
      left: false,
      right: false,
    };

  // tslint:disable-next-line:max-func-body-length cyclomatic-complexity
  private trackScroll(emit: boolean = true): void {
    if (!this.scrollerElement || !this.wrapperElements) {
      return;
    }
    const scroller = this.scrollerElement;
    const [wrapper1, wrapper2] = this.wrapperElements;

    const scrollY = Math.round(
      (scroller as Window).scrollY || (scroller as HTMLElement).scrollTop || 0,
    );
    const scrollerHeight =
      (scroller as Window).innerHeight || (scroller as HTMLElement).clientHeight || 0;
    const wrapperHeight = Math.max(
      (wrapper1 && wrapper1.clientHeight) || 0,
      (wrapper2 && wrapper2.clientHeight) || 0,
    );
    const scrollX = Math.round(
      (scroller as Window).scrollX || (scroller as HTMLElement).scrollWidth || 0,
    );
    const scrollerWidth =
      (scroller as Window).innerWidth || (scroller as HTMLElement).clientWidth || 0;
    const wrapperWidth = Math.max(
      (wrapper1 && wrapper1.clientWidth) || 0,
      (wrapper2 && wrapper2.clientWidth) || 0,
    );

    let top = false;
    let bottom = false;
    let left = false;
    let right = false;

    if (this.direction === 'horizontal') {
      if (scrollX <= 0) {
        left = true;
      } else if (scrollX + scrollerWidth >= wrapperWidth) {
        right = true;
      }
    } else {
      if (scrollY <= 0) {
        top = true;
      } else if (scrollY + scrollerHeight >= wrapperHeight) {
        bottom = true;
      }
    }
    const toTop = this.payload.scrollY - scrollY > 0
    const toBottom = this.payload.scrollY - scrollY < 0
    Object.assign(this.payload, {
      scrollY,
      scrollerHeight,
      wrapperHeight,
      scrollX,
      scrollerWidth,
      wrapperWidth,
    });
    Object.assign(this.edges, {
      toTop,
      toBottom,
      top,
      bottom,
      left,
      right,
    });
    if (emit) {
      Object.entries(this.edges).forEach(([k, v]) => v && this.$emit(k));
    }
  }

  @Watch('direction')
  private watchDirection(newValue: ScrollDirection, oldValue: ScrollDirection): void {
    this.trackScroll();
  }

  private onScroll(event: Event): void {
    this.trackScroll();
  }

  private mounted(): void {
    this.$nextTick(() => {
      this.watchScroller(this.scroller);
      this.watchWrapper(this.wrapper);
      this.$nextTick(() => {
        this.trackScroll(false);
      });
    });
  }

  private beforeDestroy(): void {
    if (this.scrollerElement) {
      this.scrollerElement.removeEventListener('scroll', this.onScroll);
    }
    this.scrollerElement = undefined;
    this.wrapperElements = undefined;
  }

  public $refs!: {
    wrapper: HTMLElement;
  };

  private render(h: CreateElement): VNode {
    return (
      <div
        staticClass="tg-scroll-view"
        class={this.classes}
      // onScroll={this.scroller ? undefined : this.onScroll}
      // onWheel={this.onWheel}
      >
        <div ref="wrapper" staticClass="tg-scroll-view_wrapper" role="region">
          {this.$slots.default}
        </div>

        {this.backToTop && (
          <div
            staticClass="tg-scroll-view_back-to-top">
            <button onClick={this.onBackToTop}
              class={{ 'is-show': this.payload.scrollY > 512 }}>
              {/* <fa-icon icon="chevron-up" /> */}
              <span>
                <img src={require('../../../assets/white-arrow-up.png')} alt="" />
              </span>
            </button>
            {
              this.download ? (
                <div staticClass="download-content" class={{ 'is-show': (this.edges.toBottom && !this.edges.top) || this.edges.bottom }}
                  onClick={this.openOrDownload}>
                  <span>
                    <img src={require('../../../assets/logo.png')}
                      alt="" />
                  </span>
                  <span>打开APP</span>
                </div>
              ) : ''
            }
          </div>
        )}
        {/* {this.direction === 'vertical' && (
          <div staticClass="tg-scroll-view_debug">
            <pre>{JSON.stringify([this.payload, this.edges], undefined, '  ')}</pre>
          </div>
        )} */}
      </div >
    );
  }
}
