<template>
  <div class="tg-cj-elegance-more">
    <div class="tg-cj-elegance-more_list">
      <tg-flexbox flex="100"
                  justify="space-between">
        <tg-flexbox class="tg-cj-elegance-more_item"
                    flex="none"
                    v-for="(item, index) in data || []"
                    :key="index">
          <a :href="item.locationlink == null ? '/chinajoy/news/' + (item.id || item.nid) : item.locationlink">
            <img :src="'https://xyoss.g.com.cn/xy-production' + item.banner">
          </a>
        </tg-flexbox>
      </tg-flexbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tg-cj-elegance-more',
  data() { },
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
  methods: {},
};
</script>

