<template>
  <div class="tg-live-content-card-comment">
    <div class="comment-item">
      <div class="comment-item-header">
        <img class="avatar"
             src="@src/assets/headimg.png">
        <div class="title">{{data.nickname ? data.nickname : '火星号'}}</div>
        <div class="time">{{data.created_at}}</div>
      </div>
      <div :class="{'comment-item-content': true , 'showAll': showAll, 'all': showReadMore}"
           ref="textCont">{{data.content}}
        <div class="imgboxes clearfix">
          <div class="imgbox"
               v-for="(item, index) in data.images"
               :key="index"><img :src="'https://xyoss.g.com.cn/xy-production/' + item.img_path"></div>
        </div>
      </div>
      <div class="comment-item-footer">
        <div class="open-more"
             v-if="showReadMore"
             @click="changeRuleState">{{ruleText}}</div>
        <div class="thumbs">
          <fa-icon icon="thumbs-up"></fa-icon> {{data.ups}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import TgLiveContentCardComment from '@src/components/live/live-content-card-comment/TgLiveContentCardComment.vue';
export default {
  name: 'TgLiveContentCard',
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      ruleText: '展开全部',
      showReadMore: false,
      showAll: false,
    };
  },
  methods: {
    changeRuleState() {
      this.showAll = !this.showAll;
      if (this.showAll) {
        this.ruleText = '收起';
      } else {
        this.ruleText = '展开全部';
      }
    },
    clickFabulous(id) {
      this.$emit('clickFabulous', id);
    },
  },
  updated() {
    if (this.$refs.textCont) {
      var realHeight = this.$refs.textCont.offsetHeight;
      if (realHeight > 90) {
        this.showReadMore = true;
      }
    }
  },
  mounted() {
    if (this.$refs.textCont) {
      var realHeight = this.$refs.textCont.offsetHeight;
      if (realHeight > 90) {
        this.showReadMore = true;
      }
    }
  },
};
</script>
