import actions from './actions';
import mutations from './mutations';
const state = {
  banner: [],
  news: [],
  interview: [],
  video: [],
  report: [],
  elegance: [],
  keywords: [],
  loadingMore: false,
  canLoadMore: [],
  pageNum: 1,
  listData: [],
};

export default {
  namespaced: true,
  state,
  mutations: mutations,
  actions: actions,
};
export const navMenu = [
  {
    label: '首页',
    url: `/tgs`,
  },
  {
    label: '资讯',
    url: `/tgs/list/TGS,资讯`,
  },
  {
    label: '专访',
    url: `/tgs/list/TGS,TGS专访`,
  },
  {
    label: '视频',
    url: `/tgs/list/TGS,视频`,
  },
  {
    label: '索尼',
    url: `/tgs/list/索尼,TGS`,
  },
  {
    label: '世嘉',
    url: `/tgs/list/世嘉,TGS`,
  },
  {
    label: '万代南梦宫',
    url: `/tgs/list/万代南梦宫,TGS`,
  },
  {
    label: 'SE',
    url: `/tgs/list/TGS,史克威尔艾尼克斯`,
  },
  {
    label: '光荣特库摩',
    url: `/tgs/list/TGS,光荣特库摩`,
  },
  {
    label: '卡普空',
    url: `/tgs/list/TGS,卡普空`,
  }
]
