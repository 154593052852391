<template>
  <div :class="['tg-special-slide-bar', channel]"
       v-if="show">
    <div class="tg-special-slide-bar_header">
      <div class="header-left">
        <img :src="headerImg[channel]" />
      </div>
      <div class="header-right"
           @click="onBackClick">
        <img class="tgs-header-button"
             :src="require(`../../../assets/${channel === 'tgs' ? 'tgs' : 'special'}/close.png`)">
      </div>
    </div>
    <div class="tg-special-slide-bar_wrapper">
      <div class="tg-special-slide-bar_logo">
        <img :src="logoImg[channel]" />
      </div>
      <div class="tg-special-slide-bar_nav">
        <a class="tg-special-slide-bar_item"
           v-for="(item, index) in navs"
           :href="item.href"
           :key="index"
           target="_blank">
          <div :style="{backgroundColor: item.backgroundColor}">
            <component :is="item.icon"></component>
          </div>
          <span>{{item.title}}</span>
          <span>{{item.label1}}</span>
          <span>{{item.label2}}</span>
        </a>
      </div>
      <div class="tg-special-slide-bar_links">
        <div class="link-item"
             v-for="(item, index) in links"
             :key="index">
          <a :href="item.href">{{item.label}}</a>
        </div>
      </div>
      <!-- <div class="tg-special-slide-bar_footer">
        <div class="tg-special-slide-bar_footer-logo">
          <img src="../../../assets/e3/footer-logo.png" />
        </div>
        <p>多牛传媒旗下网站</p>
        <p>2017 · A9VG电玩部落 | 多牛传媒旗下网站</p>
        <p>京ICP备16021487号-4 京公网安备 11010802027580号</p>
      </div> -->
      <div class="tg-special-slide-bar_footer">
        <div class="tg-e3-slide-bar_footer-logo">
          <img src="../../../assets/e3/footer-logo.png" />
        </div>
        <p>多牛传媒旗下网站</p>
        <p>京ICP备16021487号-6 
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005287"
           target="_blank"><img src="https://xyoss.g.com.cn/xy-production/network_security_logo.png" alt="" style="vertical-align: middle;">川公网安备 51019002005287号</a>
        </p>
        <p>Copyright 2004-{{new Date().getFullYear()}} TGBUS Corporation, All Rights Reserved</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tg-special-slide-bar',
  data() {
    const navs = [
      {
        backgroundColor: '#F2F9FF',
        href: 'https://m.bestjourney.net/',
        title: '首页',
        label1: '简单游戏',
        label2: '快乐生活',
        icon: 'TgHome',
      },
      {
        backgroundColor: '#E8FBEE',
        href: 'https://m.bestjourney.net/gamelib',
        title: '游戏库',
        label1: '海量游戏',
        label2: '相关资讯查询',
        icon: 'tg-game',
      },
      {
        backgroundColor: '#FEEEEF',
        href: 'https://live.bilibili.com/94644',
        title: '直播',
        label1: '图文视频直播',
        label2: '同步精彩',
        icon: 'tg-video',
      },
      {
        backgroundColor: '#FEEEEF',
        href: 'http://m.bestjourney.net/list/ns',
        title: '任天堂',
        label1: 'Switch资讯',
        label2: '游戏推荐',
        icon: 'tg-switch',
      },
      {
        backgroundColor: '#F2F9FF',
        href: 'http://m.bestjourney.net/list/ps4',
        title: '索尼',
        label1: 'PS4热门大作',
        label2: '攻略评测',
        icon: 'tg-PS4',
      },
      {
        backgroundColor: '#E8FBEE',
        href: 'http://m.bestjourney.net/list/xbox',
        title: '微软',
        label1: 'Xbox新闻推荐',
        label2: '热门新游',
        icon: 'tg-xbox',
      },
    ]
    return {
      headerImg: {
        tgs: require(`../../../assets/tgs/tgs-header.png`)
      },
      logoImg: {
        tgs: require(`../../../assets/tgs/slide-logo.jpg`)
      },
      sideBarShow: false,
      navs,
      links: [
        {
          label: '关于我们',
          href: 'http://m.bestjourney.net/about',
        },
        {
          label: '联系我们',
          href: 'http://m.bestjourney.net/contact',
        },
        {
          label: '切换PC版',
          href: 'https://www.bestjourney.net/',
        },
      ],
    };
  },
  model: {
    prop: 'show',
    event: 'toggle',
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return false
      }
    },
    channel: {
      type: String,
      default() {
        return 'tgs';
      },
    }
  },
  methods: {
    onBackClick() {
      document.getElementById('special-home').style.height = 'auto'
      document.getElementById('special-home').style.overflow = 'unset'
      this.$emit('toggle', false);
    }
  }
};
</script>
