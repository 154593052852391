import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import { Group } from '@src/components/gamelib';
import * as thumbor from '@src/api/thumbor';

/**
 * Component: Ranking
 */
@Component
export class TgRanking extends Vue {
  @Prop({ type: Object, required: true })
  public readonly group!: Group;

  @Prop({ type: Number, default: 5 })
  public readonly limit!: number;

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-ranking">
        <tg-flexbox gutter-small direction="column">
          <tg-flexbox staticClass="tg-ranking_title">{this.group.title}</tg-flexbox>

          {this.group.items[0] ? (
            <tg-flexbox
              staticClass="tg-ranking_first-item"
              tag="a"
              href={`/gamelib/game/${this.group.items[0].id}`}
            >
              <img
                staticClass="tg-ranking_first-cover"
                src={thumbor.resolve(
                  this.group.items[0].cover,
                  'tgm-gl-rankings-cover_164_80',
                )}
                alt={this.group.items[0].nameZh}
              />
              <h3 staticClass="tg-ranking_first-name">
                <div staticClass="tg-ranking_name-zh">{this.group.items[0].nameZh}</div>
                <div staticClass="tg-ranking_name-en">{this.group.items[0].nameEn}</div>
              </h3>
              <span staticClass="tg-ranking_label">1</span>
            </tg-flexbox>
          ) : (
            ''
          )}

          {this.group.items.slice(1, this.limit).map((item, index) => (
            <tg-flexbox
              staticClass="tg-ranking_item"
              align="center"
              gutter-small
              tag="a"
              href={`/gamelib/game/${item.id}`}
            >
              <tg-flexbox flex="none">
                <img
                  staticClass="tg-ranking_cover"
                  src={thumbor.resolve(item.cover, 'tgm-gl-rankings-cover_40_40')}
                  alt={item.nameZh}
                />
              </tg-flexbox>
              <tg-flexbox tag="h3" staticClass="tg-ranking_name" direction="column">
                <div staticClass="tg-ranking_name-zh">{item.nameZh}</div>
                <div staticClass="tg-ranking_name-en">{item.nameEn}</div>
              </tg-flexbox>
              <span staticClass="tg-ranking_label">{index + 2}</span>
            </tg-flexbox>
          ))}
        </tg-flexbox>
      </div>
    );
  }
}
