import {
  Vue,
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import { StaticItem } from '@src/components/gamelib';

/**
 * Component: Statics
 */
@Component
export class TgStatics extends Vue {
  @Prop({ type: Array, required: true })
  public readonly itemsSource!: StaticItem[];

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-statics">
        {this.itemsSource.map(item => (
          <div staticClass="tg-statics_item">
            <div staticClass="tg-statics_item-info">
              <div staticClass="tg-statics_label">
                <fa-icon staticClass="tg-statics_icon" icon={['fab', item.icon]} />
                {item.label}
              </div>
              <div staticClass="tg-statics_value">{item.value}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
