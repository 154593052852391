<template>
  <div class='tg-article-note'>
    <div class="swiper-container"
         id="note"
         v-if="articleMain && articleMain.contentImages && articleMain.contentImages.length > 0">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for="(item, index) in articleMain.contentImages"
             :key="index"
             @click="aaa">
          <div class="tg-article-note_zoom-container">
            <img :data-src="item.image"
                 class="swiper-lazy">
            <div class="swiper-lazy-preloader"></div>
          </div>
          <div :class="{'tg-article-note_picInfo' : true, 'active' : !picInfoShow}">
            <div class="titleBox">
              <div class="title">{{articleMain.titlefull}}</div>
            </div>
            <div class="des">
              {{articleMain.description}}
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev swiper-button-white"></div>
      <div class="swiper-button-next swiper-button-white"></div>
      <div class="tg-article-note_goback"><a href="javascript:;"
           @click.stop="goback"></a></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'note',
  props: ['articleMain'],
  data() {
    return {
      picInfoShow: true,
    };
  },
  mounted() {
    // eslint-disable-next-line
    new Swiper('#note', {
      zoom: false,
      width: window.innerWidth,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      lazy: {
        loadPrevNext: true,
      },
    });
  },
  methods: {
    aaa() {
      this.picInfoShow = !this.picInfoShow;
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
