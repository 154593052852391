<template>
  <div class='tg-e3-exhibition-news'>
    <div class="tg-e3-exhibition-news_container">
      <div class='tg-e3-exhibition-news_title'>
        <span class="split-line"></span>
        <span class="split-line"></span>
        <span>展会要闻</span>
      </div>
      <div class='tg-e3-exhibition-news_content'>
        <a v-for="(item, index) in data.slice(0, 2)"
           :href="item.locationlink == null ? '/e3/news/' + item.id : item.locationlink"
           :key="index">{{item.title}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tg-e3-exhibition-news',
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
};
</script>
