<template>
  <div class="tg-banner-box">
    <div class='tg-banner-box_container swiper-container'>
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for='(item, index) in itemsSource'
             :key='index'>
          <tg-flexbox>
            <tg-uniform-scale :ratio="`${9 / 16 * 100}%`">
              <a :href="item.locationlink == null ? '/news/' + item.id : item.locationlink">
                <img :src="item.pic | imgURL('tgbuswap_640mw')"
                     :style="{position: 'relative', width: '100%', height: '100%', objectFit: 'cover'}"
                     alt="item.title">
              </a>
            </tg-uniform-scale>
          </tg-flexbox>
          <h3>
            <p>{{item.title}}</p>
          </h3>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'tg-swiper_container',
  props: {
    itemsSource: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    /* eslint-disable */
    this.swiper = new Swiper('.tg-banner-box_container', {
      loop: true,
      autoplay: {
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  },
};
</script>
