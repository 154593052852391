/* 策略容器配置 */
const homeC = {
  banner: [
    {
      containerId: 'tgbus_wap_home_banner',
      containerName: '推荐-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  rmxw: [
    {
      containerId: 'tgbus_wap_home_rmxw',
      containerName: '推荐-热门新闻',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink', 'titleshort'],
    },
  ],
  sdpc: [
    {
      containerId: 'tgbus_wap_home_sdpc',
      containerName: '推荐-深度评测',
      hasSource: [
        'pic',
        'title',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
};

const gamelibC = {
  banner: [
    {
      containerId: 'tgbus_wap_gamelib_banner',
      containerName: '游戏库-banner',
      hasSource: ['pic', 'url', 'locationLink'],
    },
  ],
};

const cjVersionTitle = '2022';
const cjVersion = '2021';
const cjC = {
  banner: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_banner',
      containerName: 'tgbus-wap cj' + cjVersion + '首页banner轮播图',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  news: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_news',
      containerName: 'tgbus-wap cj' + cjVersion + '头条资讯',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  onlineGames: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_onlineGames',
      containerName: 'tgbus-wap cj' + cjVersion + '网游',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
  mobileGames: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_mobileGames',
      containerName: 'tgbus-wap cj' + cjVersion + '手游',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
  competition: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_competition',
      containerName: 'tgbus-wap cj' + cjVersion + '电竞',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
  interview: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_interview',
      containerName: 'tgbus-wap cj' + cjVersion + '人物专访',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
  industryVoice: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_industryVoice',
      containerName: 'tgbus-wap cj' + cjVersion + '业内声音',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
  acg: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_acg',
      containerName: 'tgbus-wap cj' + cjVersion + 'ACG',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
  elegance: [
    {
      containerId: 'tgbus_wap_cj' + cjVersion + '_elegance',
      containerName: 'tgbus-wap cj' + cjVersion + '现场风采',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ]
}
const tgsVersion = '2021'
const tgsC = {
  banner: [
    {
      containerId: `tgbus_wap_tgs${tgsVersion}_banner`,
      containerName: 'tgbus-wap tgs首页banner轮播图',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  news: [
    {
      containerId: `tgbus_wap_tgs${tgsVersion}_news`,
      containerName: 'tgbus-wap tgs新闻速报',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  interview: [
    {
      containerId: `tgbus_wap_tgs${tgsVersion}_interview`,
      containerName: 'tgbus-wap tgs特约专访',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
  video: [
    {
      containerId: `tgbus_wap_tgs${tgsVersion}_video`,
      containerName: 'tgbus-wap tgs精彩视频',
      hasSource: [
        'pic',
        'title',
        'tags',
        'date',
        'id',
        'reviewscore',
        'summary',
        'type',
        'locationlink',
      ],
    },
  ],
  // report: [
  //   {
  //     containerId: `tgbus_wap_tgs${tgsVersion}_report`,
  //     containerName: 'tgbus-wap tgs试玩报告',
  //     hasSource: [
  //       'pic',
  //       'title',
  //       'tags',
  //       'date',
  //       'id',
  //       'reviewscore',
  //       'summary',
  //       'type',
  //       'locationlink',
  //     ],
  //   },
  // ],
  // elegance: [
  //   {
  //     containerId: `tgbus_wap_tgs${tgsVersion}_elegance`,
  //     containerName: 'tgbus-wap tgs现场直击',
  //     hasSource: [
  //       'pic',
  //       'title',
  //       'tags',
  //       'date',
  //       'id',
  //       'reviewscore',
  //       'summary',
  //       'type',
  //       'locationlink',
  //     ],
  //   },
  // ],
}
const generalC = {
  tech: [
    {
      containerId: 'tgbus_wap_general_tech_wall',
      containerName: 'tech-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  android: [
    {
      containerId: 'tgbus_wap_general_android_wall',
      containerName: 'android-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  iphone: [
    {
      containerId: 'tgbus_wap_general_iphone_wall',
      containerName: 'iphone-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  ps4: [
    {
      containerId: 'tgbus_wap_general_ps4_wall',
      containerName: 'ps4-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  ps5: [
    {
      containerId: 'tgbus_wap_general_ps5_wall',
      containerName: 'ps5-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  ns: [
    {
      containerId: 'tgbus_wap_general_ns_wall',
      containerName: 'ns-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  xbox: [
    {
      containerId: 'tgbus_wap_general_xbox_wall',
      containerName: 'xbox-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  ol: [
    {
      containerId: 'tgbus_wap_general_ol_wall',
      containerName: 'ol-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
  shouji: [
    {
      containerId: 'tgbus_wap_general_shouji_wall',
      containerName: 'shouji-banner',
      hasSource: ['pic', 'title', 'date', 'id', 'locationlink'],
    },
  ],
};
export { homeC, gamelibC, cjC, cjVersion, cjVersionTitle, tgsVersion, tgsC, generalC };
