<template>
  <div class='e3-home tga-position-static'
       data-group='{ "id": "tgbus_wap_e3", "name": "tgbus-wap e3首页" }'
       id="e3-home">
    <tg-e3-header-bar :keywords="keywords"
                      :search="search"></tg-e3-header-bar>
    <tg-flexbox class="tga-padding-top64">
      <tg-flexbox flex="100"
                  class="tga-container"
                  data-container='{ "id": "tgbus_wap_e3_banner2021", "name": "tgbus-wap e32021首页banner轮播图" }'>
        <tg-e3-swiper :items-source="banner"
                      v-if='banner.length > 0'></tg-e3-swiper>
      </tg-flexbox>
      <tg-flexbox flex="100">
        <tg-e3-exhibition-news :data="exhibitionNews"
                               data-container='{ "id": "tgbus_wap_e3_exhibitionNews2021", "name": "tgbus-wap e32021首页展会要闻" }'></tg-e3-exhibition-news>
      </tg-flexbox>
      <tg-flexbox flex="100"
                  data-container='{ "id": "tgbus_wap_e3_relate2021", "name": "tgbus-wap e32021首页相关列表" }'>
        <tg-e3-relate :data="relate"
                      v-if="relate && relate.length > 0"></tg-e3-relate>
      </tg-flexbox>
    </tg-flexbox>
    <div id="tgbus_wap_e3_scroller"></div>
    <tg-e3-nav-bar></tg-e3-nav-bar>
    <tg-e3-nav-list :data="zoneNews"></tg-e3-nav-list>
  </div>
</template>

<script>
import TgE3HeaderBar from '../components/e3/header-bar/TgE3HeaderBar';
import TgE3Swiper from '../components/e3/swiper/TgE3Swiper';
import TgE3ExhibitionNews from '../components/e3/exhibition-news/TgE3ExhibitionNews';
import TgE3Relate from '../components/e3/relate-news/TgE3Relate';
import TgE3NavBar from '../components/e3/nav-bar/TgE3NavBar';
import TgE3NavList from '../components/e3/nav-list/TgE3NavList';
import isDevelopment from '../utils/isDevelopment.ts';

export default {
  data() {
    return {
      navTop: 0,
    };
  },
  async asyncData({ store }) {
    await Promise.all([store.dispatch('e3/INIT_DATA'), store.dispatch('e3/FETCH')]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: '2021年E3游戏展_电玩巴士专题',
      meta: `<meta name="description" content="电玩巴士2021 E3游戏展专题（E3 2021），为您提供索尼、任天堂、育碧、微软、EA、SE等厂商的最新的游戏发布会和E3新闻，E3视频，E3图片，E3试玩，E3采访等信息。">
             <meta name="keywords" content="e3游戏展,2021 E3,E3 2021,e3新游戏"/>
             <base target="_blank">
             <meta name="robots" content="all"/>`,
    };
  },
  async mounted() {
    if (isDevelopment) {
      await this.$options.asyncData({
        route: this.$route,
        store: this.$store,
      });
    }
  },
  computed: {
    banner() {
      return this.$store.state.e3.banner;
    },
    zoneNews() {
      return this.$store.state.e3.zoneNews;
    },
    exhibitionNews() {
      return this.$store.state.e3.exhibitionNews;
    },
    relate() {
      return this.$store.state.e3.relate;
    },
    keywords() {
      return this.$store.state.e3.keywords;
    },
    search() {
      return this.$store.state.e3.search;
    },
  },
  methods: {
    pageY(elem) {
      return elem.offsetParent
        ? elem.offsetTop + this.pageY(elem.offsetParent)
        : elem.offsetTop;
    },
  },
  components: {
    TgE3HeaderBar,
    TgE3Swiper,
    TgE3ExhibitionNews,
    TgE3Relate,
    TgE3NavBar,
    TgE3NavList,
  },
};
</script>
