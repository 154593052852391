<template>
  <div class='tg-not-found'>
    <div class="tg-not-found_pic">
      <img class="404Img" src="../../assets/404.png" alt/>
    </div>
    <div class="tg-not-found_intro">
      <p>你访问的页面被吃掉啦</p>
    </div>
    <div class="tg-not-found_link">
      <p><a href="/">电玩巴士首页</a></p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'tg-not-found'
  };
</script>
