 <template>
  <div class="tg-mg-detail-recommend"
       v-if="games.length > 0">
    <div class="tg-mg_container">
      <tg-mobile-game-title :titleData="{title: '相关文章'}"></tg-mobile-game-title>
      <a class="tg-mg-detail-recommend_item"
         :href="item.url"
         v-for="(item, index) in games"
         :key="index">
        <div class="image">
          <img :src="item.banner"
               alt="">
        </div>
        <span class="title">
          {{item.titlefull}}
        </span>
      </a>
      <div class="tg-mg-detail-recommend_loadmore">
        <div @click="$emit('loadMore', {page: ++page})"
             v-if="!isEnd && games.length !== 0">{{isLoading ? '正在加载...' : '点击加载更多'}}</div>
        <div v-else-if="isEnd && games.length !== 0">没有更多数据了</div>
        <div v-else>暂无推荐数据</div>
      </div>
      <!-- 加载更多 end -->
    </div>
  </div>
</template>
<script>
import TgMobileGameTitle from '@src/components/mobile-game/title/TgMobileGameTitle';
export default {
  data() {
    return {
      page: 1
    }
  },
  props: {
    games: {
      type: Array,
      require: true,
    },
  },
  components: {
    TgMobileGameTitle
  },
  computed: {
    isLoading() {
      return this.$store.state.mobileGameDetail.isLoading;
    },
    isEnd() {
      return this.$store.state.mobileGameDetail.isEnd;
    }
  },
  methods: {
    onLoadMore() {
      this.$store.dispatch('mobileGameDetail/FETCH_MORE')
    }
  }
}
</script>
