<template>
  <div class="tg-special-pic-panel">
    <a :href="data.locationlink == null ? '/news/' + (data.id || data.nid) : data.locationlink">
      <tg-flexbox direction="column">
        <tg-flexbox flex="1"
                    class="tg-special-pic-panel_imgbox"><img :src="'https://xyoss.g.com.cn/xy-production' + data.banner"
               alt=""></tg-flexbox>
        <tg-flexbox class="tg-special-pic-panel_text">{{data.titlefull}}</tg-flexbox>
      </tg-flexbox>
    </a>
  </div>
</template>
<script>
export default {
  name: 'TgPicPanel',
  props: {
    data: Object,
    size: {
      type: String,
      default: '600_',
    },
  },
  data() {
    return {};
  },
};
</script>
