<template>
  <div class='tg-special-swiper-hor'>
    <div class="swiper-container"
         :id="emblyId">
      <div class="swiper-wrapper">
        <div class="swiper-slide"
             v-for="(item, index) in data|| []"
             :key='index'>
          <tg-special-pic-panel :data="toCardData(item)"
                        :size="size" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import TgSpecialPicPanel from '../pic-panel/TgSpecialPicPanel';
export default {
  name: 'tg-swiper_container',
  data() {
    return {
      size: '340_180',
    };
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
    emblyId: {
      type: String,
      require: true,
    },
  },
  mounted() {
    /* eslint-disable */
    this.$nextTick(() => {
      /* eslint-disable */
      new Swiper('#' + this.emblyId, {
        direction: 'horizontal',
        loop: true,
        slideToClickedSlide: true,
        loopAdditionalSlides: 5,
        // autoplay: true,
        centeredSlides: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
      });
    });
  },
  methods: {
    toCardData(item) {
      return {
        id: item.id,
        banner: item.pic,
        titlefull: item.title,
        tags: item.tags,
        displaydate: item.date,
      };
    },
  },
  components: {
    TgSpecialPicPanel,
  },
};
</script>
