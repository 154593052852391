<template>
  <div class="tg-download-page">
    <img src="../../../assets/download/download.png?v=1"
         alt
         class="tg-download-page_bg" />
    <div class="tg-download-page_title">
      <h3>电玩巴士APP</h3>
      <p>for Android & iOS</p>
    </div>
    <!-- <div class="tg-download-page_intro">
      <span class="news">
        <img src="../../../assets/download/news.png" />
        <label>原创游戏资讯</label>
      </span>
      <span class="recommend">
        <img src="../../../assets/download/game.png" />
        <label>良心游戏推荐</label>
      </span>
      <span class="gamedata">
        <img src="../../../assets/download/data.png" />
        <label>超全面的游戏数据</label>
      </span>
    </div>-->
    <div class="tg-download-page_dl">
      <a class="ios"
         href="https://apps.apple.com/cn/app/%E7%94%B5%E7%8E%A9%E5%B7%B4%E5%A3%ABtgbus/id1422237582">
        <img src="../../../assets/download/iphone.png"
             class="bg"
             alt />
      </a>
      <a class="android"
         href="https://a.app.qq.com/o/simple.jsp?pkgname=com.donews.tgbus">
        <img src="../../../assets/download/android.png"
             class="bg"
             alt />
      </a>
      <div class="tip">
        <span>立刻下载，马上开车！</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tg-download-page',
  methods: {
    androidDownload() {
      let ifr = document.createElement('iframe');
      ifr.src = 'tgbus://com.donews.tgbus';
      ifr.style.display = 'none';
      document.body.appendChild(ifr);
      window.setTimeout(() => {
        window.location.href =
          'https://a.app.qq.com/o/simple.jsp?pkgname=com.donews.tgbus';
        // window.location.href ='http://xyoss.g.com.cn/static/appupgrade/app-release-qijian.apk';
      }, 3000);
    },
  },
};
</script>
