<template>
  <div class="tg-cj-news">
    <tg-cj-title :title="title"></tg-cj-title>
    <div v-for="(item, index) in data.slice(0, 4) || []"
         :key="index">
      <tg-card-group :data="toCardData(item)"
                     :bottomBorder="index != 3" />
    </div>
  </div>
</template>

<script>
import TgCardGroup from '../card-group/TgCardGroup';
import TgCjTitle from '../title/TgCjTitle';
export default {
  name: 'tg-cj-news',
  data() {
    return {
      title: '头条资讯',
    };
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
  },
  methods: {
    toCardData(item) {
      return {
        id: item.id,
        banner: item.pic,
        titlefull: item.title,
        tags: item.tags,
        displaydate: item.date,
      };
    },
  },
  components: {
    TgCjTitle,
    TgCardGroup,
  },
};
</script>

