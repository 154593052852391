import * as types from '../../mutations-types';

export default {
  [types.CJ_SET_LIST_DATA]: (state, { res }) => {
    state.loadingMore = false;
    if (res.list.result && res.list.result.length === 25) {
      state.listData.push(...res.list.result);
    } else if (
      res.list.result &&
      res.list.result.length > 0 &&
      res.list.result.length < 25
    ) {
      state.listData.push(...res.list.result);
      state.canLoadMore = false;
    } else {
      state.canLoadMore = false;
    }
  },
  [types.CJ_SET_SEARCH_KEYWORDS]: (state, res) => {
    if (res.news && res.news.length > 0) {
      let reg = new RegExp(`<a.*?>(.*?)</a>`, 'gmi');
      let keywords = [];
      res.news[0].contentraw.replace(reg, function(str, keyword) {
        keywords.push(keyword);
      });
      state.keywords = keywords;
    }
  },
};
