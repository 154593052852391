// tslint:disable: no-unsafe-any
import Vue, { CreateElement, VNode } from 'vue';
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Watch,
} from 'vue-property-decorator';
import { Store } from 'vuex';
import { Route } from 'vue-router';
import isDevelopment from '@src/utils/isDevelopment';
import * as gamelibApi from '@src/api/gamelib-api';
import { TgCollectionHeader } from '@src/components/gamelib/collection-detail/CollectionHeader';
import { TgGameList } from '@src/components/gamelib/game-list/GameList';

/**
 * Component: Collection
 */
@Component({
  components: {
    TgCollectionHeader,
    TgGameList,
  },
})
export default class TgCollectionDetail extends Vue {
  // tslint:disable:no-any
  public async asyncData({
    store,
    route,
  }: {
    store: Store<any>;
    route: Route;
  }): Promise<any> {
    await store.dispatch('gamelib/COLLECTION_DATA', {
      type: route.params.type,
      id: parseInt(route.params.id, 10),
    });

    const fullName: string = gamelibApi.helper.filters.collectionNameFull(
      store.state.gamelib.collectionData,
    );

    // 返回 SSR 上下文，用于插入到 head 内
    return {
      title: fullName,
      meta: `
  <meta name="keywords" content="${fullName},游戏库,资料库,电玩巴士,tgbus">
  <meta name="description" content="电玩巴士资料库 ${fullName}">
`,
    };
  }
  // tslint:enable:no-any

  private async mounted(): Promise<void> {
    if (isDevelopment && this.$options.asyncData) {
      await this.$options.asyncData({ store: this.$store, route: this.$route });
    }
  }

  public get collection(): gamelibApi.model.Collection | null {
    return this.$store.state.gamelib.collectionData;
  }

  private loading: boolean = false;

  private async appendData(): Promise<void> {
    if (this.loading) {
      return;
    }

    this.loading = true;

    await this.$store.dispatch('gamelib/COLLECTION_DATA', {
      type: this.$route.params.type,
      id: parseInt(this.$route.params.id, 10),
      append: true,
    });

    this.loading = false;
  }

  private render(h: CreateElement): VNode {
    return (
      <div staticClass="tg-collection-detail">
        <div staticClass="tga-main">
          <tg-header-bar transparent />
          {this.collection ? (
            <tg-scroll-view
              direction="vertical"
              scroller="window"
              back-to-top
              onBottom={this.appendData}
            >
              <tg-flexbox gutter>
                <tg-flexbox flex="100">
                  <tg-collection-header collection={this.collection} />
                </tg-flexbox>
                <tg-flexbox flex="100">
                  <span staticClass="tga-text_primary">{this.collection.count}</span>
                  个游戏
                </tg-flexbox>
                <tg-flexbox flex="100">
                  <tg-game-list items-source={this.collection.games} />
                </tg-flexbox>
              </tg-flexbox>

              {this.loading ? (
                <div class="tga-load-bottom">
                  <tg-logo-two />
                  <span class="tga-load-bottom-tip">更多内容加载中……</span>
                </div>
              ) : (
                ''
              )}
            </tg-scroll-view>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
