<template>
  <div class="tg-download-app"
       ref='downloadApp'
       v-show="showFooterDownloadApp">
    <tg-flexbox flex="100"
                align="stretch">
      <tg-flexbox flex="100"
                  align="center">
        <!-- <span @click='handleClose'>
          <tg-close class='close'></tg-close>
        </span> -->
        <div class="tg-download-app_logo">
          <img :src="require('../../../assets/logo.png')"
               alt="">
        </div>
        <div class="tg-download-app_intro">
          <p>电玩巴士TGBUS</p>
          <p>简单游戏 快乐生活</p>
        </div>
        <div class="tg-download-app_open"
             @click="openOrDownload">
          <span>打开App</span>
        </div>
      </tg-flexbox>
    </tg-flexbox>
  </div>
</template>

<script>
import { downloadApp, deviceType, downloadUrl } from '@src/utils/downloadApp'
export default {
  name: 'tg-download-app',
  data() {
    return {
      deviceType: null,
      showFooterDownloadApp: true,
      scrollY: 0
    };
  },
  beforeMount() {
    this.deviceType = deviceType()
  },
  methods: {
    openOrDownload() {
      !this.deviceType && (this.deviceType = deviceType())
      const { deviceType } = this
      const downloadInfo = downloadUrl[deviceType]
      downloadInfo && downloadApp({
        downloadUrl: downloadInfo.download,
        openApp: downloadInfo.openApp
      })
    },
  },
  mounted() {
    const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
    window.addEventListener('scroll', () => {
      const scrollHeight = Math.max(document.documentElement.scrollHeight || document.body.scrollHeight);
      const scrollY = window.scrollY
      if (scrollY > this.scrollY) {
        this.showFooterDownloadApp = false
      }
      if (scrollY < this.scrollY) {
        this.showFooterDownloadApp = true
      }
      if (0 >= scrollY) {
        this.showFooterDownloadApp = true
      }
      if (scrollY + clientHeight >= scrollHeight) {
        this.showFooterDownloadApp = false
      }
      this.scrollY = scrollY

    });
  },
  destroyed() {
    window.removeEventListener('scroll')
  }
};
</script>
