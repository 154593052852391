/**
 * Service logic on GraphQL API
 */
// tslint:disable:no-relative-imports no-multiline-string no-reserved-keywords no-any

import axios, { AxiosResponse, AxiosPromise } from 'axios';
import * as model from './model';
import * as api from './api';
import * as fragments from './fragments';
import { resolveGameImages } from './helper';

export interface TagsMap {
  genres: model.Collection[];
  themes: model.Collection[];
  game_modes: model.Collection[];
  player_perspectives: model.Collection[];
}

/**
 * Fetch a set of collection types data.
 */
async function fetchTags(): Promise<TagsMap> {
  const tagFieldNames: (keyof model.Query)[] = [
    'game_modes',
    'genres',
    'player_perspectives',
    'themes',
  ];
  const query: model.Query = await api.get(
    `
{
${tagFieldNames.map(t => `${t} {${fragments.tags}}`).join('\n')}
}
`,
  );

  tagFieldNames.forEach(t => {
    query[t] = (query[t] as model.Collection[])
      .filter(c => c.name)
      .sort((a, b) => b.count - a.count);
  });

  return query;
}

export interface CollectionMap {
  collection: model.Collection;
  developer: model.Collection;
  franchise: model.Collection;
  game_engine: model.Collection;
  game_mode: model.Collection;
  genre: model.Collection;
  keyword: model.Collection;
  platform: model.Collection;
  player_perspective: model.Collection;
  publisher: model.Collection;
  theme: model.Collection;
}

export interface FetchCollectionOptions {
  first?: number;
  skip?: number;
  orderBy?: model.OrderBy;
}

/**
 * Fetch collection data by id, used for collection, game_mode, genre...
 * @param type the field name with type model.Collection in model.Query
 * @param id the id for the specified collection
 */
async function fetchCollection<K extends keyof CollectionMap>(
  type: K,
  id: number,
  options?: FetchCollectionOptions,
): Promise<model.Collection> {
  const collection: model.Collection = await api.get(
    type,
    `
{
${type}(id: ${id}) {${fragments.collection}
games${options ? api.argumentsSerialize(options) : ''} {${fragments.subGame}}
}
}
`,
  );

  collection.type = type;

  if (Array.isArray(collection.games) && collection.games.length > 0) {
    collection.games.forEach(resolveGameImages);
  }

  return collection;
}

/**
 * Fetch collection data by id, used for collection, game_mode, genre...
 * @param type the field name with type model.Collection in model.Query
 * @param id the id for the specified collection
 */
async function fetchCollectionAppend<K extends keyof CollectionMap>(
  type: K,
  id: number,
  options?: FetchCollectionOptions,
): Promise<model.Collection> {
  const collection: model.Collection = await api.get(
    type,
    `
{
${type}(id: ${id}) {
games${options ? api.argumentsSerialize(options) : ''} {${fragments.subGame}}
}
}
`,
  );

  collection.type = type;

  if (Array.isArray(collection.games) && collection.games.length > 0) {
    collection.games.forEach(resolveGameImages);
  }

  return collection;
}

/**
 * Fetch game data by id, e.g. XCOM 2 id 10919.
 */
async function fetchGame(id: number): Promise<model.Game> {
  const game: model.Game = await api.get(
    'game',
    `
{
game(id: ${id}) {${fragments.game}}
}
`,
  );

  resolveGameImages(game);

  return game;
}

async function fetchGameList(ids: number[]): Promise<model.Game[]> {
  const games: model.Game[] = await api.get(
    'game_list',
    `
{
  game_list(ids: [${[...new Set(ids)].join(',')}]) {
    id
    name
    names {
      lang
      content
    }
    covers {
      path
    }
  }
}
`,
  );

  games.forEach(resolveGameImages);

  return games;
}

export interface FetchSearchOptions {
  query: string;
  first?: number;
  skip?: number;
  type?: model.ItemType;
  filters?: model.Filter;
}

async function fetchSearch(options: FetchSearchOptions): Promise<model.Game[]> {
  if (options.filters) {
    const correctedFilters: model.Filter = {};
    Object.entries(options.filters)
      .filter(([key, value]: [string, number]) => value > 0)
      .forEach(([key, value]: [string, number]) => {
        correctedFilters[key as keyof model.Filter] = value;
      });
    options.filters = correctedFilters;
  }

  const games: model.Game[] = await api.get(
    'search',
    `
{
  search${api.argumentsSerialize(options)} {${fragments.subGame}}
}
`,
  );

  if (Array.isArray(games)) {
    games.forEach(resolveGameImages);
  }

  return games;
}

export { fetchTags, fetchCollection, fetchGame, fetchGameList, fetchSearch };
