import axios from 'axios';

const instanceLive = axios.create({
  baseURL: 'https://tgbus-live-xy.bestjourney.net',
  timeout: 50000,
  headers: {
    'content-type': 'application/json',
  },
});

instanceLive.interceptors.request.use(
  function (config) {
    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

instanceLive.interceptors.response.use(
  function (res) {
    if (res.status === 200) {
      return res.data;
    } else {
      console.error('请求异常，错误码： ' + res.errors, 15);
    }
  },
  function (error) {
    console.log('-----------------------------');
    console.log('网络异常： ' + error, 15);
    console.log('请求路径：' + error.config.url);
    console.log('请求参数' + JSON.parse(error.config.data).query);
    console.log('-----------------------------');
    throw error;
  },
);

export const createLiveAPI = (url, method = 'get', data = {}) => {
  let requestInfo = {
    url,
    method,
  };
  if (method === 'post') {
    requestInfo.data = data;
  } else {
    requestInfo.params = data;
  }
  return instanceLive(requestInfo);
};

// 评论系统
const instanceComment = axios.create({
  baseURL: 'https://tgbus-comment-xy.bestjourney.net',
  timeout: 50000,
  headers: {
    'content-type': 'application/json',
    'appid': 'tgbus'
  },
});

instanceComment.interceptors.request.use(
  function (config) {
    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

instanceComment.interceptors.response.use(
  function (res) {
    if (res.status === 200) {
      return res.data;
    } else {
      console.error('请求异常，错误码： ' + res.errors, 15);
    }
  },
  function (error) {
    console.log('-----------------------------');
    console.log('网络异常： ' + error, 15);
    console.log('请求路径：' + error.config.url);
    console.log('请求参数' + JSON.parse(error.config.data).query);
    console.log('-----------------------------');
    throw error;
  },
);

export const createCommentAPI = (url, method = 'get', data = {}) => {
  let requestInfo = {
    url,
    method,
  };
  if (method === 'post') {
    requestInfo.data = data;
  } else {
    requestInfo.params = data;
  }
  return instanceComment(requestInfo);
};

// 用户系统
const instanceUser = axios.create({
  baseURL: 'https://tgbus-user-xy.bestjourney.net',
  timeout: 50000,
  headers: {
    'content-type': 'application/json',
    'appid': 'tgbus'
  },
});

instanceUser.interceptors.request.use(
  function (config) {
    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

instanceUser.interceptors.response.use(
  function (res) {
    if (res.status === 200) {
      return res.data;
    } else {
      console.error('请求异常，错误码： ' + res.errors, 15);
    }
  },
  function (error) {
    console.log('-----------------------------');
    console.log('网络异常： ' + error, 15);
    console.log('请求路径：' + error.config.url);
    console.log('请求参数' + JSON.parse(error.config.data).query);
    console.log('-----------------------------');
    throw error;
  },
);

export const createUserAPI = (url, method = 'get', data = {}) => {
  let requestInfo = {
    url,
    method,
  };
  if (method === 'post') {
    requestInfo.data = data;
  } else {
    requestInfo.params = data;
  }
  return instanceUser(requestInfo);
};
