<template>
  <div class="tg-news-list">
    <tg-uniform-scale v-if="firstIsLarge && first"
                      ratio="42.28%"
                      class="tg-news-list_first">
      <img class="tg-news-list_first-cover"
           :src="(itemsMore ? first.banner : first.pic) | imgURL('tgbuswap_360mw')"
           :alt="itemsMore ? first.titlefull : first.title">
      <h3 class="tg-news-list_first-title">
        <p>{{itemsMore ? first.titlefull : first.title}}</p>
      </h3>
      <a :href="(first.locationlink !== '' && first.locationlink !== null) ? first.locationlink : ('/news/' + (itemsMore ? first.nid : first.id))"
         class="tg-news-list_link"></a>
    </tg-uniform-scale>

    <tg-list show-separator>
      <template v-for="(item, index) in this.items">
        <tg-list-item :key="item.id">

          <tg-flexbox gutter>
            <tg-flexbox flex="100">
              <h3 class="tg-news-list_title">{{itemsMore ? item.titlefull : item.title}}</h3>
            </tg-flexbox>
            <tg-flexbox flex="100"
                        gutter>
              <tg-flexbox class="tg-news-list_date">{{(itemsMore ? item.displaydate : item.date) | timestampToTime}}</tg-flexbox>

              <!-- 点赞数 -->
              <!-- <tg-flexbox flex="none">
            <fa-icon icon="thumbs-up"></fa-icon>
            256
          </tg-flexbox> -->
            </tg-flexbox>
          </tg-flexbox>
          <a :href="(item.locationlink !== '' && item.locationlink !== null) ? item.locationlink : ('/news/' + (itemsMore ? item.nid : item.id))"
             class="tg-news-list_link"></a>

          <img slot="right"
               class="tg-news-list_cover"
               :src="(itemsMore ? item.banner : item.pic) | imgURL('tgbuswap_120x72fixed')"
               :alt="itemsMore ? item.titlefull : item.title">
          <a slot="right"
             :href="(item.locationlink !== '' && item.locationlink !== null) ? item.locationlink : ('/news/' + (itemsMore ? item.nid : item.id))"
             class="tg-news-list_link"></a>

        </tg-list-item>
        <!-- 广告位 -->
        <div :id="adId"
             v-if="index === 3 && itemsMore === true"
             :key="index"
             class="tga-adv-position-list"></div>
      </template>
    </tg-list>
  </div>
</template>

<script>
import * as ad from '@src/api/ad';

/**
 * Controls: NewsList
 */
export default {
  name: 'tg-news-list',
  filters: {
    dateFormatter: value => '',
  },
  props: {
    firstIsLarge: {
      type: Boolean,
      default: false,
    },
    itemsSource: {
      type: Array,
      require: true,
    },
    itemsMore: {
      default: false,
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      adId: ad.AD_ID_FLOW,
    };
  },
  computed: {
    first() {
      return this.itemsSource[0];
    },
    items() {
      return this.firstIsLarge ? this.itemsSource.slice(1) : this.itemsSource;
    },
  },
};
</script>
