import AboutPrivacy from '@src/views/about/privacy.vue';
<template>
  <div class="container">
    <div class="body">
      <div class='service_content'>
        <p class='bold'>电玩巴士TGBUS举报受理和处置管理办法</p>
        <p>一、为规范电玩巴士TGBUS投诉举报受理工作，树立良好的社会形象，根据有关法律法规规定,结合网站实际情况，制定本制度。</p>
        <p>二、电玩巴士TGBUS已安排专人负责举报投诉的受理工作，向社会公布举报途径。</p>
        <p>三、举报人的举报方式不受限制，鼓励举报人使用真实姓名、工作单位、住址或提供其它通讯方式，并提供相关证据，以便核查情况。</p>
        <p>四、发现已受理的举报投诉管辖权限不明的，会先予以报告。不属于本网站处理范围的，会及时告知举报人，有条件的移送相关部门处理。</p>
        <p>五、已专人负责受理各类举报投诉，遵循及时受理、快速处理、迅速反馈的原则，畅通举报投诉渠道。</p>
        <p>六、按照以下程序处理举报投诉受理。</p>
        <p class='bold'>（一）受理登记</p>
        <p>1. 受理来源分为来电、电子邮件、网上举报、相关部门移送等方式。</p>
        <p>2. 投诉举报的受理登记实行首接负责制。接到举报投诉时,最先受理的人员会详细记录相关信息。对电话举报的，会指派专人听取内容并做好记录；对电子邮件举报的，会做好登记核实；对网络举报的，会安排专人负责研判处理。举报投诉人的姓名、联系电话等由举报投诉人自愿陈述，匿名举报投诉亦会受理。</p>
        <p>3. 不属于本网站职责范围的举报、投诉事项，会先向举报投诉人说明，告知其向有关主管部门举报投诉。</p>
        <p class='bold'>（二）受理违法和不良信息举报的范围</p>
        <p>1. 垃圾广告（垃圾推广信息，违禁物品信息举报）</p>
        <p>2. 色情淫秽（色情图片、色情文字内容，低俗内容举报）</p>
        <p>3. 钓鱼诈骗（木马病毒、钓鱼信息、诈骗信息举报）</p>
        <p>4. 网络敲诈（网络敲诈和有偿删帖）</p>
        <p class='bold'>（三）报告和交办</p>
        <p>对受理的举报，一般性信息由举报专责人员分班次分时段负责核实处理，经研判属重大事项者，会向主管领导汇报，领导签批后立即进行处理。</p>
        <p class='bold'>（四）反馈</p>
        <p>1. 举报投诉处理完成后，会由首接人员向举报投诉者及时反馈，无法反馈的予以注明。</p>
        <p>2. 对于相关部门移送的举报投诉，会将查处结果向相关部门反馈。</p>
        <p>七、举报投诉受理及查处过程中所形成的资料，会及时收集整理归档。</p>
        <p>八、在处理举报投诉受理过程中应做好保密工作，对检举、揭发、投诉的材料及有关内容会妥善保管,严禁泄露给被投诉举报单位或个人。与投诉、举报案件由利害关系的，会予以回避。</p>
        <p>九、目前网站接受不良信息举报有以下五个途径：</p>
        <p>（一）官方客户端app的举报及意见反馈平台；</p>
        <p>（二）违法和不良信息举报电话： 010-60845018-572； 举报邮箱：jubao@infinities.com.cn；</p>
        <p>（三）通过设置在首页的网络警察报警平台或不良信息举报中心入口举报，网站首页底部位置设有多个举报平台的LOGO链接入口。</p>
        <p>十、不良信息举报具体可参照以下方法：</p>
        <p>（一）官方客户端APP举报：在手机移动客户端，用户首先可通过点击“我的 -右上设置 - 关于我们 - 意见反馈”进行举报，也可通过点击“我的 -右上设置 - 关于我们 – 举报流程”查看举报方式及渠道。</p>
        <p>（二）7*24小时违法和不良信息电话、邮箱举报：</p>
        <ol>
          <li>举报电话：010-60845018-572；</li>
          <li>举报邮箱：jubao@infinities.com.cn；</li>
        </ol>
        <p>（三）网络警察报警平台或不良信息举报中心入口举报：</p>
        <ol>
          <li>在电玩巴士TGBUS网站首页的底端，设置有“中国互联网违法和不良信息举报中心”点击相关文字即可进入举报窗口。用户还可通过拨打举报电话或发送材料到举报邮箱进行举报。</li>
        </ol>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutReport',
  data() {
    return {};
  },
};
</script>


<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.body {
  padding: 0.0375rem;
  .service_content {
    p {
      font-size: 0.043rem;
      line-height: 0.06rem;
      margin-bottom: 0.05rem;
      &.bold {
        font-weight: bold;
      }
    }
    ol {
      list-style: none outside none;
      padding: 0;
      li {
        font-size: 0.043rem;
        line-height: 0.06rem;
        margin-bottom: 0.05rem;
      }
    }
  }
}
</style>
