<template>
  <div class="tg-news-card-page clearfix"
       @click="onCardTap">
    <div class="left-box">
      <div class="picture-box">
        <img class="img"
             v-if="cardData && cardData.imgUrl"
             :src="'https://xyoss.g.com.cn/xy-production' + cardData.imgUrl"
             alt="">
        <div class="label"
             :style="getLabelBgcolor()">{{typeid | getLabelText}}</div>
      </div>
    </div>
    <div class="right-box"
         :style="{'padding-top': typeid != '4' ? '10px' : '3px'}">
      <div v-if="typeid == '4'">
        <div class="title">{{cardData.title}}</div>
        <div class="title">{{cardData.subtitle}}</div>
      </div>
      <div v-else>
        <div class="description">{{cardData.title}}</div>
      </div>
      <div class="goods-box"
           v-if="typeid == '1'">
        <span class="span price-logo">￥</span>
        <span class="span price">{{cardData.goodsInfo.goods_price}}</span>
        <span class="span label-box promotion"
              v-if="cardData.goodsInfo.promotion_state == 1">促销</span>
        <span class="span label-box coupon"
              v-if="cardData.goodsInfo.if_voucher_state == 1">{{cardData.goodsInfo.if_voucher_price}}元券</span>
      </div>
      <div class="news-box"
           v-else-if="typeid == '2'">
        <div>
          <span class="span">
            <fa-icon icon="user"></fa-icon><span>{{cardData.newsInfo.author}}</span>
          </span>
        </div>
        <div>
          <span class="span">
            <fa-icon icon="clock"></fa-icon><span>{{cardData.newsInfo.displaydate | timestampToTimeNoThisYear}}</span>
          </span>
        </div>
      </div>
      <div class="link-box"
           v-else-if="typeid == '3'">
        <span class="icon">
          <fa-icon icon="link"></fa-icon>
        </span>
        <span class="span">{{cardData.linkUrl}}</span>
      </div>
      <div class="game-box"
           v-else>
        <div class="date-box">
          <span class="span text">发售日期:</span>
          <span class="span date">{{cardData.gameInfo.displaydate | timestampToDate}}</span>
        </div>
        <div class="platform">
          <span class="span text">支持平台:</span>
          <span class="span logo"
                v-for="(item, index) in cardData.gameInfo.platforms"
                :key="index">
            <fa-icon :icon="['fab','playstation']"
                     v-show='item.id == 48'></fa-icon>
            <fa-icon :icon="['fab','xbox']"
                     v-show='item.id == 49'></fa-icon>
            <fa-icon :icon="['fab','nintendo-switch']"
                     v-show='item.id == 130'></fa-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newsCardPageItem',
  props: {
    typeid: {
      type: String,
      default: '',
    },
    cardData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    getLabelBgcolor() {
      // 1商品 2文章 3链接 4游戏
      if (this.typeid == '1') {
        return { 'background-color': '#FF9426' };
      } else if (this.typeid == '2') {
        return { 'background-color': '#E73434' };
      } else if (this.typeid == '3') {
        return { 'background-color': '#037AFF' };
      } else {
        return { 'background-color': '#8DBB09' };
      }
    },
    onCardTap() {
      if (typeof OnNewsCardTap != 'undefined') {
        OnNewsCardTap.postMessage(
          '{"type":"' +
            this.typeid +
            '","id":"' +
            this.cardData.id +
            '","linkUrl":"' +
            this.cardData.linkUrl +
            '"}',
        );
      } else {
        this.getGoUrlLink();
      }
    },
    getGoUrlLink() {
      var isMobile =
        window &&
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
        );
      var url = '';
      if (isMobile) {
        if (this.typeid == '1') {
          url =
            'https://mallh5.bestjourney.net/home/goodsShareDetail?goods_id=' + this.cardData.id;
        } else if (this.typeid == '2') {
          url = '/news/' + this.cardData.id;
        } else if (this.typeid == '3') {
          url = this.cardData.linkUrl;
        } else {
          url = '/gamelib/game/' + this.cardData.id;
        }
      } else {
        if (this.typeid == '1') {
          url =
            'https://mallh5.bestjourney.net/home/goodsShareDetail?goods_id=' + this.cardData.id;
        } else if (this.typeid == '2') {
          url = '/news/' + this.cardData.id;
        } else if (this.typeid == '3') {
          url = this.cardData.linkUrl;
        } else {
          url = 'https://game.bestjourney.net/game/' + this.cardData.id;
        }
      }
      window.open(url);
    },
  },
  filters: {
    getLabelText: function (typeid) {
      if (typeid == '1') {
        return '商品';
      } else if (typeid == '2') {
        return '文章';
      } else if (typeid == '3') {
        return '链接';
      } else {
        return '游戏';
      }
    },
  },
};
</script>
