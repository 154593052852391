import * as gamelibApi from '@src/api/gamelib-api';
import { StaticItem, Group, GroupItem, HomeData } from '@src/components/gamelib';
import axios from 'axios';

const mockingIds: number[] = [36926, 28856, 1942, 11800, 14748];

const API_KEY: string = 'e996d568b6b9515d3f00e66c8e918b';
// tslint:disable-next-line:no-http-string
let cmsBaseURL = 'https://cms-lite-xy.bestjourney.net'

export const setBaseURL = (baseURL: string) => {
  cmsBaseURL = baseURL;
}
export interface GamelibHomeDataRaw {
  statics: StaticItem[];
  tilewalls: GroupRaw[];
  rankings: GroupRaw[];
}

export interface GroupRaw {
  title: string;
  items: number[];
}

/**
 * Fetch the raw data from codepit
 */
export async function fetchGamelibHomeDataRaw(): Promise<GamelibHomeDataRaw> {
  let API_URL: string = `${cmsBaseURL}/api/regions/data/tgbus_m_gamelib_home_data?token=${API_KEY}`;
  const { data } = await axios.get<GamelibHomeDataRaw>(API_URL);

  return data;
}

/**
 * Fetch the raw data from codepit
 */
export async function fetchPS5RcommendTagsDataRaw(): Promise<GamelibHomeDataRaw> {
  let API_URL: string = `${cmsBaseURL}/api/collections/get/tgbus_wap_PS5_recommend?token=0b71c560198929beedb1d9e81901b9`
  const { data } = await axios.get<GamelibHomeDataRaw>(API_URL);
  return data;
}
/**
 * Fetch the raw data from codepit
 */
 export async function fetchGameDataRaw(url: string): Promise<GamelibHomeDataRaw> {
  let API_URL: string = `${cmsBaseURL}${url}`
  const { data } = await axios.get<GamelibHomeDataRaw>(API_URL);
  return data;
}
/**
 * Fetch the data from graphql api
 * @param raw raw data from codepit
 */
export async function fetchGamelibHomeData(raw: GamelibHomeDataRaw): Promise<HomeData> {
  const games: gamelibApi.model.Game[] = await gamelibApi.service.fetchGameList(
    [...raw.tilewalls, ...raw.rankings]
      .map(group => group.items)
      .reduce<number[]>((ids, items) => ids.concat(items), []),
  );

  // tslint:disable-next-line:typedef
  const getGame = (id: number): GroupItem | undefined => {
    const game: gamelibApi.model.Game | undefined = games.find(g => g.id === id);

    return game
      ? {
        id: game.id,
        cover: gamelibApi.helper.filters.gameCover(game),
        nameZh: gamelibApi.helper.filters.gameNamePrimary(game),
        nameEn: gamelibApi.helper.filters.gameNameSecondary(game),
      }
      : undefined;
  };

  // tslint:disable-next-line:typedef
  const getGroup = (groupRaw: GroupRaw): Group => ({
    title: groupRaw.title,
    items: groupRaw.items
      .map(id => getGame(id))
      .filter((item): item is GroupItem => !!item),
  });

  return {
    statics: raw.statics,
    tileWalls: raw.tilewalls.map(getGroup),
    rankings: raw.rankings.map(getGroup),
  };
}
