<template>
  <div class="tg-star-awards">
    <div class="tg-star-awards_banner">
      <!-- <img src="./images/banner.png" srcset="./images/banner@2x.png 2x" alt=""> -->
      <img src="./images/banner.png" alt="">
    </div>
    <div class="tg-star-awards_category tg-star-awards_container tg-star-awards_flexc">
      <p v-for="(item, index) in categoryTitle" :key="item" @click="activeIndex = index" :class="{'is-active': activeIndex === index}">{{ item }}</p>
    </div>
     <!-- 奖项内容 -->
    <div
      v-for="(awards, index) in categoryContent"
      :key="index"
      class="tg-star-awards_content tg-star-awards_container"
      v-show="activeIndex === index"
    >
      <div class="awards-item" v-for="(award, aindex) in awards" :key="'award'+aindex">
        <div class="title">{{award.name}}</div>
        <div class="flex-between">
          <div class="game-item" v-for="(game, gindex) in award.games" :key="'game'+gindex">
            <div :class="{'is-square': award.isSquare}">
              <img :src="require(`./images/${categoryImg[index]}/${aindex+1}-${gindex+1}.jpg`)" onerror="this.src='./images/game/1-1.jpg'" alt="">
            </div>
            <div><p v-for="(item) in game.split(',')" :key="item">{{ item }}</p></div>
          </div>
        </div>
      </div>
    </div>
    <div class="tg-star-awards_media tg-star-awards_crosswise">
      <div class="tg-star-awards_center-line"></div>
      <div class="tg-star-awards_header">
        <!-- <img src="./images/media-header.png" srcset="./images/media-header@2x.png 2x" alt=""> -->
        <img src="./images/media-header.png" alt="">
      </div>
      <div class="media-list tg-star-awards_container tg-star-awards_flexcc tg-star-awards_flexw">
        <a class="media-item tg-star-awards_flexcc " v-for="(media, index) in medias" :key="index" :href="media.href">
          <!-- <img
            :src="media.img"
            :srcset="`${media.img2x} 2x`"
            :style="{width: (media.width / 750 * 100) + 'vw', height: (media.height / 750 * 100) + 'vw'}"
            alt=""
          /> -->
          <img
            :src="media.img"
            :style="{width: (media.width / 750 * 100) + 'vw', height: (media.height / 750 * 100) + 'vw'}"
            alt=""
          />
        </a>
        <a class="media-item tg-star-awards_flexcc "></a>
      </div>
    </div>
    <div class="tg-star-awards_copyright tg-star-awards_crosswise">
      Copyright © www.bestjourney.net. All Rights Reserved.简单游戏 快乐生活
    </div>
  </div>
</template>

<script>
export default {
  async asyncData({ route, store }) {
    await Promise.all([store.dispatch(`tgs/INIT_DATA`), store.dispatch(`tgs/FETCH`)]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {};
  },
  data() {
    return {
      activeIndex: 0,
      categoryTitle: ['游戏类', '业界类', '硬件类'],
      categoryImg: ['game', 'industry', 'hardware'],
      categoryContent: [
        [
          {
            name: '年度游戏大奖',
            games: [
              '双人成行',
              '生化危机8',
              '极限竞速地平线5',
              '密特罗德：生存恐惧'
            ]
          },
          {
            name: '年度优秀独立游戏',
            games: [
              '邪恶冥刻',
              '部落与弯刀',
              'Unpacking',
              '逐光之旅'
            ]
          },
          {
            name: '年度优秀单机游戏',
            games: [
              '雷霆一号',
              '瑞奇与叮当',
              '死亡回归',
              '怪物猎人崛起'
            ]
          },
          {
            name: '年度优秀国产游戏',
            games: [
              '暗影火炬城',
              '永劫无间',
              '鬼谷八荒',
              '天命奇御二'
            ]
          },
          {
            name: '年度优秀手机游戏',
            games: [
              '明日方舟',
              '幻塔',
              '天地劫',
              '赛马娘'
            ]
          }
        ],
        [
          {
            name: '年度优秀电竞战队',
            games: [
              'EDG',
              'RNG',
              'FPX',
              'BLG'
            ]
          },
          {
            name: '年度优秀游戏发行商',
            games: [
              'Gamera Game',
              'Xbox Game Studios',
              '任天堂',
              'Devolver Digital'
            ]
          },
          {
            name: '年度优秀电竞选手',
            isSquare: true,
            games: [
              'EDG.Scout',
              'EDG.Viper',
              'EDG.Jiejie',
              'RNG.Xiaohu'
            ]
          },
          {
            name: '年度优秀游戏运营商',
            games: [
              '网易游戏',
              '米哈游',
              'SE',
              'Remedy Entertainment'
            ]
          },
          {
            name: '年度优秀游戏主播',
            isSquare: true,
            games: [
              '大司马',
              'PDD',
              '一条小团团OvO',
              '老番茄'
            ]
          },
          {
            name: '年度优秀游戏人',
            games: [
              '刘伟（米哈游）',
              '冯骥（游戏科学）',
              '张弢（钛核）',
              '小岛秀夫（小岛工作室）'
            ]
          }
        ],
        [
          {
            name: '年度优秀游戏外设',
            isSquare: true,
            games: [
              '罗技G923',
              'Pico neo 3',
              '八位堂 8BitDo Pro 2',
              'PlayStation PULSE 3D耳机'
            ]
          },
          {
            name: '年度优秀游戏硬件',
            isSquare: true,
            games: [
              'MSI超龙,GeForce RTX 3080 Ti',
              '西部数据,SN850 NVMe™ SSD',
              '玩家国度Z690 FORMULA',
              'AMD 锐龙9 5950X'
            ]
          },
          {
            name: '年度优秀游戏手机',
            isSquare: true,
            games: [
              'Redmi K40 Pro',
              '黑鲨游戏手机4S Pro',
              'IQOO 8',
              '小米11 Ultra'
            ]
          },
          {
            name: '年度优秀数码产品',
            isSquare: true,
            games: [
              '大疆 DJI Air 2S 航拍无人机',
              '荣耀MagicBook 16 Pro',
              'GPD win 3掌机',
              '小米路由器AX9000'
            ]
          },
          {
            name: '年度优秀显示设备',
            isSquare: true,
            games: [
              '索尼XR-83A90J',
              '海信85U7G-PRO',
              'AOC PD27',
              'Redmi MAX'
            ]
          },
          {
            name: '年度优秀音频产品',
            isSquare: true,
            games: [
              '索尼WF-1000XM4',
              '小米小爱音箱',
              '索尼HT-X8500回音壁',
              '苹果AirPods 3'
            ]
          }
        ]
      ],
      medias: [
        {
          img: require('./images/media-178.png'),
          img2x: require('./images/media-178@2x.png'),
          width: 87,
          height: 40,
          href: 'http://www.178.com/phone.html'
        },
        {
          img: require('./images/media-a9vg.png'),
          img2x: require('./images/media-a9vg@2x.png'),
          width: 115,
          height: 27,
          href: 'https://m.a9vg.com'
        },
        {
          img: require('./images/media-donews.png'),
          img2x: require('./images/media-donews@2x.png'),
          width: 106,
          height: 31,
          href: 'https://www.donews.com'
        },
        {
          img: require('./images/media-hi.png'),
          img2x: require('./images/media-hi@2x.png'),
          width: 120,
          height: 33,
          href: 'http://m.bestjourney.net/list/tech'
        },
        {
          img: require('./images/media-nga.png'),
          img2x: require('./images/media-nga@2x.png'),
          width: 101,
          height: 35,
          href: 'http://www.nga.cn/mobile/index.html'
        },
        {
          img: require('./images/media-psn.png'),
          img2x: require('./images/media-psn@2x.png'),
          width: 103,
          height: 35,
          href: 'https://psnine.com'
        },
        {
          img: require('./images/media-android.png'),
          img2x: require('./images/media-android@2x.png'),
          width: 135,
          height: 45,
          href: 'http://m.bestjourney.net/list/android'
        },
        {
          img: require('./images/media-yinli.png'),
          img2x: require('./images/media-yinli@2x.png'),
          width: 129,
          height: 38,
          href: 'http://wap.g.com.cn/wire-h5.html'
        }
      ]
    }
  }
}
</script>
