<template>
  <div>
    <!-- game item -->
    <div v-if="isGame"
         class="tg-game-item">
      <a :href="'/gamelib/game/' + (data.game.id)">
        <div class="tg-game-item_image-box">
          <img v-if="data.game.covers.length > 0 && data.game.covers[0].path != ''"
               :src="'https://xyoss.g.com.cn/xy-production' + data.game.covers[0].path"
               alt />
          <img v-else
               src="@src/assets/default.png"
               alt />
        </div>
        <div class="tg-game-item_info-box">
          <p>{{data.game.name}}</p>
          <p>{{data.game.names[0].content}}</p>
          <p>
            <span v-for="(item, index) in data.game.platforms"
                  :key="index">
              <label v-if="allPlatforms.indexOf(item.name) > -1">
                <fa-icon v-if="item.name == 'PlayStation 4' || item.name == 'PS4'"
                         :icon="['fab', 'playstation']"
                         :style="{'stroke': '#4985F2', 'color': '#4985F2'}" />
                <fa-icon v-if="item.name == 'Nintendo Switch' || item.name == 'Switch'"
                         :icon="['fab', 'nintendo-switch']"
                         :style="{'stroke': '#E60012', 'color': '#E60012'}" />
                <fa-icon v-if="item.name == 'Xbox One'"
                         :icon="['fab', 'xbox']"
                         :style="{'stroke': '#107C10', 'color': '#107C10'}" />
                <tg-linux v-if="item.name == 'Linux'" />
                <!-- <fa-icon
                  v-if="item.name == 'Linux'"
                  :icon="['fab', 'linux']"
                  :style="{'stroke': '#F5BD0C', 'color': '#F5BD0C'}"
                />-->
                <fa-icon v-if="item.name == 'Mac'"
                         :icon="['fab', 'apple']"
                         :style="{'stroke': '#000000', 'color': '#000000'}" />
                <tg-windows v-if="item.name == 'Microsoft Windows' || item.name == 'PC'" />
              </label>
              <!-- <fa-icon staticClass="tg-statics_icon" icon={['fab', item.icon]} /> -->
              <!-- <fa-icon :icon="['fab', 'linux']" /> -->
            </span>
          </p>
        </div>
      </a>
      <div class="tg-game-item_language"
           v-if="data.language">
        <span>{{data.language}}</span>
      </div>
    </div>

    <!-- news-item -->
    <div class="tg-news-item"
         v-else>
      <a :href="'/news/' + (data.id || data.nid)">
        <tg-flexbox>
          <div class="tg-news-item_left">
            <img :src="'https://xyoss.g.com.cn/xy-production' + (data.banner || data.pic)"
                 alt />
            <p v-if="addScore">
              <label>{{data.reviewscore.indexOf('.') > -1 ? data.reviewscore : data.reviewscore + '.0'}}</label>
            </p>
          </div>
          <tg-flexbox direction="column"
                      class="tg-news-item_right">
            <tg-flexbox class="tg-news-item_title">{{data.titlefull || data.title}}</tg-flexbox>
            <tg-flexbox class="tg-news-item_infobox"
                        align="center">
              <tg-flexbox flex="none"
                          class="text"
                          style="color: #A3A3A3">{{getTag}}</tg-flexbox>
              <tg-flexbox flex="none"
                          class="circle"></tg-flexbox>
              <tg-flexbox flex="none"
                          class="time">{{(data.displaydate || data.date) | timestampToTimeNoYear}}</tg-flexbox>
            </tg-flexbox>
          </tg-flexbox>
        </tg-flexbox>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataTags: ['巴士首页特稿', '游戏视频', '游戏评测', '游戏攻略'],
      dataShowTags: ['特稿', '视频', '评测', '攻略'],
      techDataTags: ['智车科技', '智能生活', '电脑影音', '硬件评测'],
      techDataShowTags: ['智车科技', '智能生活', '电脑影音', '评测'],
      androidDataTags: [
        '热点视频',
        '时尚美图',
        '智能生活',
        '行业观点',
        '行情导购',
        '硬件评测',
      ],
      androidDataShowTags: ['视频', '图赏', '智能生活', '行业观点', '行情导购', '评测'],
      iphoneDataTags: [
        '谍报集中营',
        '巴士观点',
        '软件',
        '游戏',
        '教程',
        '周边配件',
        '硬件评测',
      ],
      iphoneDataShowTags: [
        '谍报',
        '巴士观点',
        '软件',
        '游戏',
        '教程',
        '周边配件',
        '评测',
      ],
      tag: '资讯',
      allPlatforms: [
        'PlayStation 4',
        'PS4',
        'Nintendo Switch',
        'Switch',
        'Xbox One',
        'Linux',
        'Mac',
        'Microsoft Windows',
        'PC',
      ],
    };
  },
  name: 'TgNewsItem',
  props: {
    isGame: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'tgbuswap_360mw',
    },
    subDomain: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
    },
    addScore: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getTag() {
      const self = this;
      const tags = this.data.tags || [];
      let index = 0;
      let dataTags = this.dataTags;
      let dataShowTags = this.dataShowTags;
      if (this.subDomain != '') {
        switch (this.subDomain) {
          case 'tech':
            dataTags = this.techDataTags;
            dataShowTags = this.techDataShowTags;
            break;
          case 'android':
            dataTags = this.androidDataTags;
            dataShowTags = this.androidDataShowTags;
            break;
          case 'iphone':
            dataTags = this.iphoneDataTags;
            dataShowTags = this.iphoneDataShowTags;
            break;
        }
      }

      tags.forEach(item => {
        index = dataTags.indexOf(item);
        if (index > -1) {
          self.tag = dataShowTags[index];
        }
      });
      return self.tag;
    },
  },
};
</script>
