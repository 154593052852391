<template>
  <div class="tg-star-awards-2023">
    <div class="tg-star-awards-2023_banner">
    </div>
    <div class="tg-star-awards-2023_category tg-star-awards-2023_container tg-star-awards-2023_flexc">
      <p v-for="(item, index) in categoryTitle" :key="item" @click="activeIndex = index">
        <img :src="activeIndex === index ? require(`./images/t${index+1}_ok.png`):require(`./images/t${index+1}.png`)" alt="">
      </p>
    </div>
     <!-- 奖项内容 -->
    <div
      v-for="(awards, index) in categoryContent"
      :key="index"
      class="tg-star-awards-2023_content tg-star-awards-2023_container"
      v-show="activeIndex === index"
    >
      <div class="awards-item" v-for="(award, aindex) in awards" :key="'award'+aindex">
        <div class="title">
          <h3>{{award.name}}</h3>
          <p>{{award.intro}}</p>
        </div>
        <div class="flex-between">
          <div class="game-item" v-for="(game, gindex) in award.games" :key="'game'+gindex">
            <div>
              <img :src="require(`./images/${categoryImg[index]}/${aindex+1}-${gindex+1}.png`)" onerror="this.src='./images/game/1-1.png'" alt="">
            </div>
            <div>{{ game }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async asyncData({ route, store }) {
    await Promise.all([store.dispatch(`tgs/INIT_DATA`), store.dispatch(`tgs/FETCH`)]);
    // 返回 SSR 上下文，用于插入到 head 内
    return {};
  },
  data() {
    return {
      activeIndex: 0,
      categoryTitle: ['游戏类', '业界类', '硬件类'],
      categoryImg: ['game', 'industry', 'hardware'],
      categoryContent: [
        [
          {
            name: '年度游戏巨星奖',
            intro: '今年销量和口碑双双最佳的游戏作品',
            games: [
              '博德之门3',
              '塞尔达传说：王国之泪',
              '漫威蜘蛛侠2',
              '星空'
            ]
          },
          {
            name: '独立游戏新星奖',
            intro: '在独立游戏中脱颖而出的亮眼之作',
            games: [
              '潜水员戴夫',
              '星之海',
              '渎神2',
              '幽灵行者2'
            ]
          },
          {
            name: '单机游戏杰出奖',
            intro: '在单机游戏中获得市场认可的佳作',
            games: [
              '匹诺曹的谎言',
              '霍格沃茨之遗',
              '马力欧兄弟：惊奇',
              '心灵杀手2'
            ]
          },
          {
            name: '国产游戏不凡奖',
            intro: '国内开发者推出的具有浓郁中国特色的作品',
            games: [
              '完蛋！我被美女包围了',
              '火山的女儿',
              '三伏',
              '沙石镇时光'
            ]
          },
          {
            name: '移动游戏翘楚奖',
            intro: '在移动市场推出便获得成功的新手游',
            games: [
              '崩坏：星穹铁道',
              '少女前线2：追放',
              '扶摇一梦',
              '晶核'
            ]
          }
        ],
        [
          {
            name: '卓越电竞战队',
            intro: '国内今年表现亮眼且具有话题性的电竞战队',
            games: [
              'RNG',
              'EDG',
              'OMG',
              'JDG'
            ]
          },
          {
            name: '卓越游戏发行',
            intro: '在海内外成功发行单款/多款游戏的游戏发行商',
            games: [
              '腾讯游戏',
              '三七互娱',
              '完美世界',
              '盛趣游戏'
            ]
          },
          {
            name: '卓越电竞选手',
            intro: '今年最具人气的电竞选手',
            games: [
              'The shy',
              'Ning',
              'FAKER',
              'eStarPro诺言'
            ]
          },
          {
            name: '卓越游戏运营',
            intro: '今年游戏运营表现最佳的游戏和发行商团队',
            games: [
              '赛博朋克2077',
              '崩坏：星穹铁道',
              '元梦之星',
              '剑网三'
            ]
          },
          {
            name: '卓越游戏主播',
            intro: '今年最具人气的游戏主播',
            games: [
              '大司马',
              '寅子',
              '楚河',
              '旭旭宝宝'
            ]
          }
        ],
        [
          {
            name: '超人气游戏外设',
            intro: '今年上市获得成功的游戏周边外设',
            games: [
              '傲风机械大师M6旗舰版',
              '八位堂猎户座青春版蓝牙手柄',
              '图马思特T818直驱基座',
              'PlayStation Portal'
            ]
          },
          {
            name: '超人气游戏硬件',
            intro: '今年上市获得成功的游戏硬件',
            games: [
              'steam deck',
              'Legion Go',
              'rog ally',
              'AYANEO KUN'
            ]
          },
          {
            name: '超人气游戏手机',
            intro: '今年上市获得成功的游戏手机',
            games: [
              '红魔8 Pro+',
              '荣耀Magic5',
              'ROG7',
              'Redmi K70系列'
            ]
          },
          {
            name: '超人气数码产品',
            intro: '今年上市获得成功的游戏数码类产品',
            games: [
              '华硕灵耀14 2023',
              'iphone15 Pro Max',
              '小米14',
              '拯救者y700二代'
            ]
          },
          {
            name: '超人气显示设备',
            intro: '今年上市获得成功的显示器等显示外设',
            games: [
              '海信U7K',
              '三星S95Z',
              '华为智慧屏V 3代',
              '索尼A95L'
            ]
          },
          {
            name: '超人气音频产品',
            intro: '今年上市获得成功的音频产品',
            games: [
              '华为FreeBuds Pro 3',
              '韶音OpenFit',
              'Beats Studio Buds+',
              'bose qc45二代'
            ]
          }
        ]
      ]
    };
  }
};
</script>
