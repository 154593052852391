<template>
  <div class="tg-article-main">
    <tg-flexbox direction="column"
                class="tg-article-main_imgbox">
      <img :src="articleMain.banner | imgURL('600_')"
           alt />
      <!-- <img src="http://img.mp.itc.cn/upload/20160727/3ea74769ede04a83bf5abc7fe4553e3e_th.jpg"> -->
      <tg-flexbox class="tg-article-main_text">{{articleMain.titlefull}}</tg-flexbox>
      <div class="tg-article-main_info">
        <div class="left">
          <span class="label">作者</span>
          <span class="value">{{articleMain.author && articleMain.author.join(' ')}}</span>
          <span class="label mr18">来源</span>
          <span class="value">{{articleMain.sourcedesc}}</span>
        </div>
        <div class="right">
          <span class="label">{{articleMain.displaydate | timestampToDate}}</span>
        </div>
      </div>
    </tg-flexbox>
    <advertisement :id="ad.AD_ID_DETAIL_COLUMN"
                   class="tg-article-main_ad-column"
                   type="dropdown"
                   mode="link">
      <div :id="'SG_GG_CONTAINER_' + ad.AD_ID_DETAIL_COLUMN"
           slot="adContent"></div>
    </advertisement>
    <div class="tg-article-main_textbox">
      <div class="tg-article-main_textbox-inner">
        <div class="text">{{articleMain.description}}</div>
      </div>
      <div class="left horn"></div>
      <div class="top horn"></div>
      <div class="right horn"></div>
      <div class="bottom horn"></div>
    </div>
    <div class="tg-article-main_content">
      <div class="article-main-contentraw"
           ref="articleContent"
           v-html="articleMain.contentraw"></div>
      <p class="tg-article-main_state"
         ref="readStatement"
         v-if="articleMain.channelid === 27">声明：本站转载此文目的在于传递更多信息，并不代表赞同其观点和对其真实性负责。如涉及作品内容、版权和其它问题，请在30日内与本站联系，我们将在第一时间删除内容，本站对此声明具有最终解释权。</p>
      <div v-if="false" class="tg-article-main_open-app"
           ref="readMoreBox">
        <div class="down"
             @click="openConfirmBox">
          <!-- <fa-icon icon="chevron-down" /> -->
          <img :src="require('../../../assets/arrow-down.png')" />
        </div>
        <div class="open"
             @click="downloadApp">
          <span>打开APP，阅读体验更佳</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as thumbor from '@src/api/thumbor';
import * as ad from '@src/api/ad';
import Advertisement from '@src/components/advertisement';
export default {
  data() {
    return {
      ad,
    };
  },
  name: 'ArticleMain',
  props: ['articleMain'],
  components: {
    Advertisement,
  },
  mounted() {
    this.$nextTick(() => {
      // const dom = this.$refs.articleContent;
      // const articleHeight = dom.offsetHeight;
      // const clientHeight = window.screen.availHeight;
      // dom.style.height = articleHeight / 2 + 'px';
      // if (this.$refs.readStatement) {
      //   this.$refs.readStatement.style.display = 'none';
      // }
    });
  },
  methods: {
    openConfirmBox() {
      document.body.style.overflowY = 'hidden';
      this.$emit('openConfirmBox');
    },
    downloadApp() {
      this.$emit('downloadApp');
    },
  },
};
</script>
