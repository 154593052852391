/**
 * 将广告 JS script 标签添加到文档中
 */
function appendAd(id: number): void {
  const script: HTMLScriptElement = window.document.createElement('script');
  // tslint:disable-next-line:no-http-string
  // script.src = `http://182.92.203.215:9001/v1/xingyou/c/${id}.js`;
  script.src = `https://g1.tagtic.cn/v1/xingyou/c/${id}.js`;
  window.document.body.appendChild(script);
}

// 首页推广广告ID
export const AD_ID_HOME_SPREAD: number = 9028;

// 信息流列表广告ID
export const AD_ID_FLOW: number = 9029;

// 游戏库推广广告ID
export const AD_ID_GAMELIB_SPREAD: number = 9030;

// 详情页面广告ID
export const AD_ID_DETAIL_COLUMN: number = 201991;
export const AD_ID_DETAIL_FLOAT: number = 201990;
// 首页浮动广告
export const AD_ID_HOME_FLOAT: number = 201989;

export function appendAdAll(adArr: number[]): void {
  adArr.forEach(appendAd);
}
