<template>
  <div class="tg-navtabs">
    <ul class="tg-navtabs_wrapper">
      <li class="tg-navtabs_item"
          v-for="item in itemsSource"
          :key="item.label"
          :class="{'is-active': item.href === $route.path}">
        <a :href="item.href">
          <span>{{item.label}}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsSource: [
        {
          label: '推荐',
          href: '/',
        },
        {
          label: '巴士直播',
          href: '/news/187757',
        },
        {
          label: '热文排行',
          href: '/range',
        },
        {
          label: '发售表',
          href: '/sales',
        },
        {
          label: '巴士商城',
          href: '/shop',
        }
      ],
    };
  },
  methods: {
    changeLabel(item) {
      this.$emit('changeLabel', item.label, item.tag);
    },
  },
};
</script>
