<template>
  <div class="tg-footer-bar">
    <div class="tg-footer-bar_label-content">
      <tg-flexbox flex="none"
                  justify="center">
        <tg-flexbox>
          <a href="/about">关于我们</a>
        </tg-flexbox>
        <tg-flexbox>
          <a :href="pcHref || host">切换到PC版</a>
        </tg-flexbox>
        <tg-flexbox>
          <a href="/download">下载App</a>
        </tg-flexbox>
      </tg-flexbox>
    </div>
    <tg-flexbox flex="100">
      <div class="tg-footer-bar_license">
        <p>京网文【2020】3348-567号</p>
        <p>京ICP备16021487号-6 
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005287"
           target="_blank"><img src="https://xyoss.g.com.cn/xy-production/network_security_logo.png" alt="" style="vertical-align: middle;">川公网安备 51019002005287号</a>
        </p>
        <p>违法和不良信息举报电话：010-60845018-572</p>
        <p>违法和不良信息举报邮箱：jubao@infinities.com.cn</p>
        <p>Copyright 2004-{{new Date().getFullYear()}} TGBUS Corporation, All Rights Reserved</p>
      </div>
    </tg-flexbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      host: 'https://www.bestjourney.net',
    };
  },
  props: {
    pcHref: {
      required: false,
    },
  },
  methods: {},
};
</script>
